import {
  HEALTH_ROLE_KEY,
  LAB_ROLE_KEY,
  OUT_ROLE_KEY,
  PMY_ROLE_KEY,
  SUR_ROLE_KEY,
} from '@routes/config/admin.nav.confg';

// 전광판 타입
export const BoardNames = [
  {
    type: 'check',
    role: LAB_ROLE_KEY,
    label: '내시경',
  },
  {
    type: 'check',
    role: LAB_ROLE_KEY,
    label: '영상/1열',
  },
  {
    type: 'check',
    role: LAB_ROLE_KEY,
    label: '1열/영상',
  },
  {
    type: 'check',
    role: LAB_ROLE_KEY,
    label: '영상/2열',
  },
  {
    type: 'check',
    role: LAB_ROLE_KEY,
    label: '2열/영상',
  },
  {
    type: 'check',
    role: LAB_ROLE_KEY,
    label: '1열/영상/1열',
  },
  {
    type: 'patient',
    role: OUT_ROLE_KEY,
    label: '영상/2열 (대대합)',
  },
  {
    type: 'patient',
    role: OUT_ROLE_KEY,
    label: '2열/영상 (대대합)',
  },
  {
    type: 'patient',
    role: OUT_ROLE_KEY,
    label: '1열/영상/1열 (대대합)',
  },
  {
    type: 'patient',
    role: OUT_ROLE_KEY,
    label: '열',
  },
  {
    type: 'patient',
    role: OUT_ROLE_KEY,
    label: '항암주사실',
  },
  {
    type: 'patient',
    role: OUT_ROLE_KEY,
    label: '동영상 (대대합)',
  },
  {
    type: 'patient',
    role: OUT_ROLE_KEY,
    label: '외래대기전광판',
  },
  {
    type: 'patient',
    role: OUT_ROLE_KEY,
    label: '외래4열대기전광판',
  },
  // ==================================== 수술실
  {
    type: 'surgery',
    role: SUR_ROLE_KEY,
    label: '수술실 타입1',
  },
  {
    type: 'surgery',
    role: SUR_ROLE_KEY,
    label: '수술실 타입2',
  },
  // ==================================== 약국
  {
    type: 'pharmaacy',
    role: PMY_ROLE_KEY,
    label: '외래약국',
  },
  {
    type: 'pharmaacy',
    role: PMY_ROLE_KEY,
    label: '퇴원약국',
  },
  // ==================================== 검진
  {
    type: 'health',
    role: HEALTH_ROLE_KEY,
    label: '외래검진',
  },
  {
    type: 'health',
    role: HEALTH_ROLE_KEY,
    label: '검사검진',
  },
  {
    type: 'health',
    role: HEALTH_ROLE_KEY,
    label: '종합현황검진',
  },
];
