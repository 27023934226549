import { FontItemTypes } from '@typedef/components/Admin/BoardSetting/components/Font/admin.board.setting.type.font.types';
import { useCallback } from 'react';
import FontSettingItem from '../../components/Font/FontSetttingItem';

type Props = {
  content: string;
  fontId: string;
  font: FontItemTypes;
  onFontChange: (fontId: string, fontValue: FontItemTypes) => void;
};

const FontSettingItemContainer = ({
  content,
  fontId,
  font,
  onFontChange,
}: Props) => {
  const onFamilyChange = useCallback(
    (value: string) => {
      onFontChange(fontId, {
        id: undefined,
        family: value,
        size: font?.size,
        bold: font?.bold,
        color: font?.color,
      });
    },
    [font?.bold, font?.color, font?.size, fontId, onFontChange],
  );

  const onSizeChange = useCallback(
    (value: number) => {
      onFontChange(fontId, {
        id: undefined,
        family: font.family,
        size: value,
        bold: font.bold,
        color: font.color,
      });
    },
    [font?.bold, font?.color, font?.family, fontId, onFontChange],
  );

  const onBoldChange = useCallback(
    (value: boolean) => {
      onFontChange(fontId, {
        id: undefined,
        family: font.family,
        size: font.size,
        bold: value,
        color: font.color,
      });
    },
    [font?.color, font?.family, font?.size, fontId, onFontChange],
  );

  const onColorChange = useCallback(
    (value: string) => {
      onFontChange(fontId, {
        id: undefined,
        family: font.family,
        size: font.size,
        bold: font.bold,
        color: value,
      });
    },
    [font?.bold, font?.family, font?.size, fontId, onFontChange],
  );

  return (
    <FontSettingItem
      content={content}
      fontId={fontId}
      font={font}
      onFamilyChange={onFamilyChange}
      onSizeChange={onSizeChange}
      onBoldChange={onBoldChange}
      onColorChange={onColorChange}
    />
  );
};

export default FontSettingItemContainer;
