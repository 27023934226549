import styled from 'styled-components';

const AdminNavStyle = styled.div`
  .header {
    font-weight: bold;
    font-size: 1.1rem;
    .header-nav {
      margin: 0;
      height: 100%;
      .nav-item {
        margin: 0 1.4em;
        .nav-link {
          cursor: pointer;
          padding: 0;
          color: gray;
          font-size: 1rem;
          &:hover {
            color: #000;
          }
        }
      }
    }
    .profile {
      margin-left: auto;
      margin-right: 0.75em;
      .btn {
        padding: 0;
        margin: 0;
        .avatar {
          svg {
            font-size: 2rem;
            display: flex;
            height: 100%;
          }
        }
        &::after {
          display: none;
        }
      }
      .dropdown-item {
        text-align: center;
      }
      .logout {
        cursor: pointer;
        p {
          margin: 0;
          span {
            margin-left: 1em;
          }
        }
      }
    }
  }
  @media (max-width: 1280px) {
    .header {
      .header-nav {
        .nav-item {
          .nav-link {
            font-size: 0.9rem;
          }
        }
      }
    }
  }
`;

export default AdminNavStyle;
