import useViewer from '@hooks/store/useViewer';
import { alertMessage } from '@libs/alert';
import { apiRoute, requestPost } from '@libs/api';
import { getCurrentList } from '@libs/factory';
import { PharmacyPatientItemTypes } from '@typedef/components/PharmacyWaiting/pharmacy.waiting.types';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import ExitPharmacyWaiting from '../components/ExitPharmacyWaiting';

const EXIT_PAGE_SIZE = 28;

const LOAD_PATIENT_LIST_INTERVAL = 5000;

const ExitPharmacyWaitingContainer = () => {
  const { basic } = useViewer();

  const [pharmacyList, setPharmacyList] = useState<PharmacyPatientItemTypes[]>(
    [],
  );

  const [intervalCounter, setIntervalCounter] = useState(0);

  const currentPharmacyList = useMemo(() => {
    return getCurrentList(pharmacyList, EXIT_PAGE_SIZE, intervalCounter);
  }, [basic, pharmacyList, intervalCounter]);

  const loadExitPharmacyList = useCallback(async () => {
    if (!basic) {
      alertMessage('전광판 기본 정보를 불러오지 못했습니다!');
      return;
    }

    const { data, config } = await requestPost<PharmacyPatientItemTypes[]>(
      apiRoute.viewer.pharmacy.exitPharmacy,
      {},
      {},
    );

    if (config.status === 200) {
      setPharmacyList(data);
    } else {
      alertMessage('퇴원환자 투약 리스트를 불러오지 못했습니다');
    }
  }, []);

  useEffect(() => {
    loadExitPharmacyList();
  }, [basic, intervalCounter]);

  useEffect(() => {
    const interval = setInterval(
      () => setIntervalCounter((prev) => prev + 1),
      LOAD_PATIENT_LIST_INTERVAL,
    );

    return () => {
      clearInterval(interval);
    };
  }, []);

  return <ExitPharmacyWaiting currentPharmacyList={currentPharmacyList} />;
};

export default ExitPharmacyWaitingContainer;
