import VideoModalSettingStyle from '@components/Admin/BoardSetting/styles/Video/Modal/VideoModalSettingStyle';
import {
  CButton,
  CCol,
  CFormInput,
  CFormLabel,
  CModal,
  CModalBody,
  CModalFooter,
  CModalHeader,
  CModalTitle,
  CRow,
  CSpinner,
  CTable,
  CTableBody,
  CTableHead,
} from '@coreui/react';
import { faSearch } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  VideoListItemTypes,
  VideoModalTableFieldsItemTypes,
} from '@typedef/components/Admin/BoardSetting/components/Video/admin.board.setting.type.video.types';
import React, { ChangeEvent, SetStateAction } from 'react';
import _ from 'lodash';

type Props = {
  videoList: VideoListItemTypes[];
  fields: VideoModalTableFieldsItemTypes[];
  role: string;
  loadingSpinner: boolean;
  setVideoUrl: React.Dispatch<SetStateAction<string>>;
  setVideoUrlName: React.Dispatch<SetStateAction<string>>;
  setCurrentModal: React.Dispatch<SetStateAction<string | null>>;
  onCheckChange: (videoId: string, check: boolean) => void;
  onCheckedApplyClick: () => void;
  onVideoAllApplyClick: (videoId: number) => void;
  onVideoRemoveClick: (videoId: number) => void;
  onFileAddClick: (e: ChangeEvent<HTMLInputElement>) => void;
  onFileUploadClick: () => void;
  onUrlUploadClick: () => void;
  onSearch: (searchValue: string) => void;
  myVideoCheckedList: string[];
};

const VideoModal = ({
  videoList,
  fields,
  role,
  loadingSpinner,
  setVideoUrl,
  setVideoUrlName,
  setCurrentModal,
  onCheckChange,
  onCheckedApplyClick,
  onVideoAllApplyClick,
  onVideoRemoveClick,
  onFileAddClick,
  onFileUploadClick,
  onUrlUploadClick,
  onSearch,
  myVideoCheckedList,
}: Props) => {
  return (
    <CModal
      visible={true}
      className='videoinput'
      onClose={() => setCurrentModal(null)}
      size='lg'
      backdrop='static'>
      <VideoModalSettingStyle>
        <CModalHeader className='bg-primary' closeButton color='white'>
          <CModalTitle>동영상 입력</CModalTitle>
        </CModalHeader>
        <CModalBody>
          <div className='videolist'>
            <div className='header'>
              <div className='Search'>
                <input
                  type='text'
                  placeholder='Search...'
                  onChange={(e) => {
                    onSearch(e.target.value);
                  }}
                />
                <CButton>
                  <FontAwesomeIcon icon={faSearch} size='1x' />
                </CButton>
              </div>
            </div>
            <div className='list'>
              <CTable>
                <CTableHead>
                  <tr>
                    {fields.map((head) => (
                      <th key={head.key} style={{ width: head._style.width }}>
                        {head.label}
                      </th>
                    ))}
                  </tr>
                </CTableHead>
                <CTableBody>
                  {_.uniq(videoList, 'original')
                    .sort((a, b) => (a.original > b.original ? 1 : -1))
                    .map((item) => {
                      return (
                        <tr key={item.id}>
                          <td>
                            <div className='videolist-td-checkbox'>
                              <input
                                type='checkbox'
                                id={`${item.id}`}
                                onChange={(e) => {
                                  onCheckChange(`${item.id}`, e.target.checked);
                                }}
                                checked={myVideoCheckedList.includes(
                                  String(item.id),
                                )}
                              />
                            </div>
                          </td>
                          <td title={item.path}>
                            <p>{item.original}</p>
                          </td>
                          <td>
                            <p>{item.type ? 'File' : 'URL'}</p>
                          </td>
                          {role === 'ROLE_ROOT' ? (
                            <>
                              <td>
                                <div className='btn-div'>
                                  <CButton
                                    onClick={() => {
                                      onVideoAllApplyClick(item.id);
                                    }}
                                    color='primary'>
                                    일괄적용
                                  </CButton>
                                </div>
                              </td>
                              <td>
                                <div className='btn-div'>
                                  <CButton
                                    onClick={() => {
                                      onVideoRemoveClick(item.id);
                                    }}
                                    color='danger'>
                                    삭제
                                  </CButton>
                                </div>
                              </td>
                            </>
                          ) : null}
                        </tr>
                      );
                    })}
                </CTableBody>
              </CTable>
            </div>
          </div>

          {role === 'ROLE_ROOT' ? (
            <CRow className='upload-container'>
              <CCol xs='12'>
                {loadingSpinner && (
                  <div className='upload-loading'>
                    <CSpinner color='primary' />
                  </div>
                )}
                <CRow className='mb-3'>
                  <CCol xs='12'>
                    <CFormLabel htmlFor='file'>
                      파일 등록
                      <span style={{ color: 'red' }}>
                        &nbsp;( mp4 파일만 업로드 할 수 있습니다. 이외 파일은 인코딩 이후 업로드 부탁드립니다. )
                      </span>
                    </CFormLabel>
                    <CRow>
                      <CCol xs='10'>
                        <input
                          type='file'
                          id='file'
                          name='videoFiles'
                          multiple
                          onChange={onFileAddClick}
                        />
                      </CCol>
                      <CCol xs='2'>
                        <CButton
                          id='uploadBtn'
                          color='info'
                          onClick={onFileUploadClick}>
                          등록
                        </CButton>
                      </CCol>
                    </CRow>
                  </CCol>
                </CRow>
                <CRow>
                  <CCol xs='12'>
                    <CFormLabel htmlFor='url'>URL 등록</CFormLabel>
                    <CRow>
                      <CCol xs='5'>
                        <CFormInput
                          id='url'
                          placeholder='https://www.youtube.com'
                          onChange={(e) => {
                            setVideoUrl(e.target.value);
                          }}
                        />
                      </CCol>
                      <CCol xs='5'>
                        <CFormInput
                          id='urlName'
                          placeholder='URL 영상이름'
                          onChange={(e) => {
                            setVideoUrlName(e.target.value);
                          }}
                        />
                      </CCol>
                      <CCol xs='2'>
                        <CButton
                          id='uploadBtn'
                          color='info'
                          onClick={onUrlUploadClick}>
                          등록
                        </CButton>
                      </CCol>
                    </CRow>
                  </CCol>
                </CRow>
              </CCol>
            </CRow>
          ) : null}
        </CModalBody>
        <CModalFooter>
          <CButton color='primary' onClick={onCheckedApplyClick}>
            적용
          </CButton>
          <CButton color='secondary' onClick={() => setCurrentModal(null)}>
            닫기
          </CButton>
        </CModalFooter>
      </VideoModalSettingStyle>
    </CModal>
  );
};

export default VideoModal;
