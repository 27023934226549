import styled from 'styled-components';

const MaskingSettingStyle = styled.div`
  .card {
    height: 18.5vh;
    .card-header {
      h5 {
        font-size: 1rem;
      }
    }
    .card-body {
      padding: 0.25em;
      .masking-position {
        margin-bottom: 0.5em;
      }
      p {
        font-size: 0.9rem;
        margin: 0 auto;
        width: 95%;
      }
      .form-control {
        border-radius: 15px;
        width: 95%;
        margin: 0 auto;
        height: 3vh;
      }
    }
  }

  @media (max-width: 1280px) {
    .card {
      height: 18vh;
      .card-body {
        .form-control {
          padding: 0.25em 0.75em;
          height: 3.5vh;
        }
      }
    }
  }
`;

export default MaskingSettingStyle;
