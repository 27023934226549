import styled from 'styled-components';

export const OutPharmacyWaitingStyle = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;

  .subtitle {
    height: 9vh;
    line-height: 9vh;
    background-color: #5a5490;
    color: #fff;
    font-size: 7vh;
    text-align: center;
  }
`;

const space = '0.5vw';

export const OutTable = styled.section`
  flex: 1;
  width: 100vw;
  display: flex;
  flex-direction: column;
  padding: ${space};
`;

export const Thead = styled.section`
  display: flex;
  flex-direction: row;
  margin-bottom: ${space};
`;

export const Tr = styled.div`
  flex: 1;
  height: 9vh;
  display: flex;
  flex-direction: row;
  align-items: center;
  border: 5px solid #000655;
  border-radius: 1vw;

  p {
    width: 35%;
    text-align: center;
    font-size: 5vh;
  }
  .name {
    width: 65%;
  }

  &:last-child {
    margin-left: ${space};
  }
`;

export const Tbody = styled.section`
  height: 100%;
  display: flex;
  flex-direction: row;
`;

export const Split = styled.section`
  width: 50%;
  height: 100%;
  display: flex;
  flex-direction: column;
  &:last-child {
    margin-left: ${space};
  }
`;

export const Patient = styled.div`
  flex: 1;
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: ${space};
  background-color: lightgray;
  border-radius: 1vw;
  position: relative;

  .name {
    width: 65%;
    border-left: 5px solid #000655;
  }

  p {
    text-align: center;
    color: #000;
    font-size: 9.5vh;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  &:nth-child(2n) {
    background-color: #88a2bc;
  }
  &:last-child {
    margin-bottom: 0;
  }
`;

export const Td = styled.div`
  width: 35%;
  height: 100%;
  position: relative;
`;
