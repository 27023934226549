import { useState, useEffect, useCallback, ChangeEvent } from 'react';
import { apiRoute, requestSecureGet, requestSecurePost, requestSecurePut } from '@libs/api';
import useLogined from '@hooks/store/useLogined';
import BoardManage from '../components/BoardManage';
import {
  AdminBoardInputTypes,
  AdminBoardTypes,
  AdminBoardModalItemTypes,
} from '@typedef/components/Admin/Board/admin.board.types';
import { AdminCenterTypes } from '@typedef/components/Admin/Clinic/components/admin.center.types';
import { AdminAccountRoleTypes } from '@typedef/components/Admin/Account/admin.account.role.types';
import {
  HEALTH_ROLE_KEY,
  LAB_ROLE_KEY,
  OUT_ROLE_KEY,
  PMY_ROLE_KEY,
  SUR_ROLE_KEY,
} from '@routes/config/admin.nav.confg';
import useGNB from '@hooks/store/useGNB';

const roles: AdminAccountRoleTypes[] = [
  {
    name: '검진',
    value: HEALTH_ROLE_KEY,
  },
  {
    name: '검사',
    value: LAB_ROLE_KEY,
  },
  {
    name: '외래',
    value: OUT_ROLE_KEY,
  },
  {
    name: '약국',
    value: PMY_ROLE_KEY,
  },
  {
    name: '수술',
    value: SUR_ROLE_KEY,
  },
];

type Props = {
  id: number | null;
  setCurrentModal: React.Dispatch<React.SetStateAction<AdminBoardModalItemTypes>>;
  reload: number;
  setReload: React.Dispatch<React.SetStateAction<number>>;
};

const BoardManageContainer = ({ id, setCurrentModal, reload, setReload }: Props) => {
  const { myBoardReload, __updateGNBMyBoardReloadFromHooks } = useGNB();

  const { token } = useLogined();

  const [isUpdate, setIsUpdate] = useState<boolean>(false);

  const [inputs, setInputs] = useState<AdminBoardInputTypes>({
    id: 0, // board id
    boardCode: '', // 전광판명
    mac: '', // pc mac address
    ip: '', // ipv4
    deptKor: '', // 진료과
    location: '', // 위치
    belong: OUT_ROLE_KEY, // 소속
    btypeName: '', // 유형
    deptId: '',
  });
  // 진료과 리스트
  const [departmentList, setDepartmentList] = useState<AdminCenterTypes[]>([]);
  const [vaildIp, setVaildIp] = useState<string>('');

  const onChangeHandler = (e: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    // 과 선택
    if (name === 'deptId') {
      if (!!!value) {
        setInputs((inputs) => ({
          ...inputs,
          // deptId: null,
          deptKor: null,
        }));
        return;
      }
      const filtered = departmentList.filter((dept) => dept.deptHngNm === value)[0];
      setInputs((inputs) => ({
        ...inputs,
        // deptId: filtered.DEPTCD,
        deptKor: filtered.deptHngNm,
      }));
    } else {
      if (name === 'belong') {
        // 소속이 외래인경우엔 과 선택 안함
        // 선택한 과가 있다면 초기화
        if (value === OUT_ROLE_KEY) {
          setInputs((inputs) => ({
            ...inputs,
            // deptId: null,
            deptKor: null,
          }));
        }
      }
      if (name === 'ip') {
        setVaildIp('');
      }
      setInputs((inputs) => {
        return {
          ...inputs,
          [name]: value,
        };
      });
    }
  };

  // 중복 체크
  const checkDupleIp = useCallback(async () => {
    if (!token) return;

    const { config, data } = await requestSecureGet<AdminBoardTypes>(
      apiRoute.admin.board.checkDupleIp + inputs.ip,
      {},
      token,
    );
    if (config.status >= 200 && config.status < 400) {
      // data === true ? 중복있음 : 중복없음
      if (data) {
        alert('이미 존재하는 아이피입니다.');
      } else {
        setVaildIp(inputs.ip);
        alert('사용 가능한 아이피입니다.');
      }
    }
  }, [inputs.ip, token]);

  const findMacAddress = useCallback(async () => {
    if (inputs.ip === '' || inputs.ip === undefined) {
      alert('ip를 입력해주세요.');
      return;
    }

    if (!token) return;

    const { data, config } = await requestSecureGet<string>(
      apiRoute.admin.board.findMacAddress + inputs.ip,
      {},
      token,
    );

    if (config.status === 200) {
      if (data.includes('접근')) {
        alert(data);
      } else {
        setInputs({
          ...inputs,
          mac: data,
        });
      }
    }
  }, [inputs, token]);

  // 전광판 추가
  const insert = useCallback(async () => {
    if (
      !!!inputs.boardCode ||
      !!!inputs.mac ||
      !!!inputs.ip ||
      !!!inputs.belong ||
      !!!inputs.btypeName
    ) {
      alert('필수 입력란이 누락되었습니다.');
      return;
    } else if (!!!vaildIp) {
      alert('아이피 중복검사를 진행해 주세요.');
      return;
    }

    const data = {
      ...inputs,
      ip: vaildIp,
    };

    if (!token) return;

    const { config } = await requestSecurePost(apiRoute.admin.board.postBoard, {}, data, token);
    if (config.status >= 200 && config.status < 400) {
      setCurrentModal(null);
      setReload(reload + 1);
    }
  }, [inputs, vaildIp, setCurrentModal, token, reload]);

  // 전광판 수정
  const update = useCallback(async () => {
    if (
      !!!inputs.boardCode ||
      !!!inputs.mac ||
      !!!inputs.ip ||
      !!!inputs.belong ||
      !!!inputs.btypeName
    ) {
      alert('필수 입력란이 누락되었습니다.');
      return;
    } else if (!!!vaildIp) {
      alert('아이피 중복검사를 진행해 주세요.');
      return;
    }

    if (!token) return;

    const { config } = await requestSecurePut(
      apiRoute.admin.board.putBoard,
      {},
      {
        ...inputs,
        ip: vaildIp,
        id: id,
      },
      token,
    );
    if (config.status >= 200 && config.status < 400) {
      setCurrentModal(null);
      setReload(reload + 1);
      __updateGNBMyBoardReloadFromHooks(myBoardReload + 1);
    }
  }, [id, inputs, vaildIp, setCurrentModal, token, reload, myBoardReload]);

  // 아이디 있을 경우(수정) 조회
  const getBoardInfo = useCallback(async () => {
    if (!token) return;

    const { config, data } = await requestSecureGet<AdminBoardTypes>(
      apiRoute.admin.board.getBoard + `${id}`,
      {},
      token,
    );
    if (config.status >= 200 && config.status < 400) {
      setInputs(data);
      setVaildIp(data.ip);
    }
  }, [id, token]);

  // 진료실 리스트
  const getDepartmentList = useCallback(async () => {
    if (!token) return;

    const { config, data } = await requestSecureGet<AdminCenterTypes[]>(
      apiRoute.admin.room.getDepartments,
      {},
      token,
    );

    if (config.status >= 200 && config.status < 400) {
      // 가져오기
      setDepartmentList(data);
    }
  }, [token]);

  useEffect(() => {
    if (id) {
      setIsUpdate(true);
      getBoardInfo();
    }
    getDepartmentList();
  }, []);

  return (
    <BoardManage
      type={isUpdate ? '수정' : '추가'}
      setCurrentModal={setCurrentModal}
      inputs={inputs}
      onChangeHandler={onChangeHandler}
      checkDupleIp={checkDupleIp}
      departmentList={departmentList}
      roles={roles}
      save={isUpdate ? update : insert}
      findMacAddress={findMacAddress}
    />
  );
};

export default BoardManageContainer;
