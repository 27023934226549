import images from '@assets/images';
import {
  CButton,
  CCard,
  CCardBody,
  CCardHeader,
  CCol,
  CContainer,
  CFormInput,
  CFormLabel,
  CFormSelect,
  CInputGroup,
} from '@coreui/react';
import {
  HEALTH_ROLE_KEY,
  LAB_ROLE_KEY,
  OUT_ROLE_KEY,
  PMY_ROLE_KEY,
  SUR_ROLE_KEY,
} from '@routes/config/admin.nav.confg';
import React from 'react';
import LoginStyle from './styles/LoginStyle';

interface KeyboardEvent {
  key: string;
}

// TODO : Login Screen Publishing
type Props = {
  setId: React.Dispatch<React.SetStateAction<string>>;
  setPw: React.Dispatch<React.SetStateAction<string>>;
  role: string;
  setRole: React.Dispatch<React.SetStateAction<string>>;
  staffLoginFlag: boolean;
  setStaffLoginFlag: React.Dispatch<React.SetStateAction<boolean>>;
  onLoginClicked: () => void;
  onkeyPressEnterInput: (e: KeyboardEvent) => void;
};
const Login = ({
  setId,
  setPw,
  role,
  setRole,
  staffLoginFlag,
  setStaffLoginFlag,
  onLoginClicked,
  onkeyPressEnterInput,
}: Props) => {
  return (
    <LoginStyle>
      <CContainer>
        <CCard>
          <CCardHeader className='bg-secondary banner'>
            <div>
              {window.location.hostname.includes('031') ? (
                <img src={images.loginBackground} alt='LoginBanner' />
              ) : window.location.hostname.includes('032') ? (
                <img src={images.loginBackgroundChilgok} alt='LoginBanner' />
              ) : (
                <img src={images.loginBackground} alt='LoginBanner' />
              )}
            </div>
          </CCardHeader>
          <CCardBody>
            <h1 className='mb-3'>병원 전광판 관리자 로그인</h1>
            <CInputGroup className='mb-3'>
              <CCol xs='8' className='pl-0'>
                <CInputGroup className='w-100'>
                  <CFormInput
                    type='text'
                    placeholder='아이디'
                    onChange={(e) => setId(e.target.value)}
                    id='id'
                    autoComplete='off'
                    onKeyPress={onkeyPressEnterInput}
                  />
                </CInputGroup>
              </CCol>
              <CCol xs='4' className='staff-login-switch'>
                <div className='staff-switch-content'>
                  <label className='staff-switch'>
                    <input
                      className='staff-login-flag-switch'
                      type='checkbox'
                      onChange={() => {
                        setStaffLoginFlag(!staffLoginFlag);
                      }}
                      defaultChecked={staffLoginFlag}
                      id='staffLogin'
                    />
                    <span className='slider'></span>
                  </label>
                  <div className='staff-login-switch-label'>
                    <CFormLabel htmlFor='staffLogin'>사번 로그인</CFormLabel>
                  </div>
                </div>
              </CCol>
            </CInputGroup>
            {/* 사번 로그인 활성화시 비번필요X */}
            {staffLoginFlag ? (
              <CInputGroup>
                <CCol xs='12' className='mb-4 pl-0 pr-0'>
                  <CFormSelect
                    id='role'
                    onChange={(e) => {
                      setRole(e.target.value);
                    }}
                    value={role ?? ''}
                  >
                    <option value=''>소속 선택</option>
                    <option value={OUT_ROLE_KEY}>외래</option>
                    <option value={LAB_ROLE_KEY}>검사</option>
                    <option value={SUR_ROLE_KEY}>수술</option>
                    <option value={PMY_ROLE_KEY}>약국</option>
                    <option value={HEALTH_ROLE_KEY}>검진</option>
                  </CFormSelect>
                </CCol>
              </CInputGroup>
            ) : (
              <CInputGroup className='mb-4'>
                <CFormInput
                  type='password'
                  placeholder='비밀번호'
                  onChange={(e) => setPw(e.target.value)}
                  id='password'
                  onKeyPress={onkeyPressEnterInput}
                />
              </CInputGroup>
            )}

            <CInputGroup>
              <CButton color='primary' className='px-4' onClick={onLoginClicked}>
                로그인
              </CButton>
            </CInputGroup>
          </CCardBody>
        </CCard>
      </CContainer>
    </LoginStyle>
  );
};

export default Login;
