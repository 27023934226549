import {
  CButton,
  CCard,
  CCardBody,
  CCardHeader,
  CCol,
  CFormInput,
  CFormSelect,
  CRow,
} from "@coreui/react";
import { faTimesCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  RoomListBoardRoomListItemTypes,
  RoomListItemTypes,
} from "@typedef/components/Admin/BoardSetting/components/RoomList/admin.board.setting.type.room.list.types";
import RoomListStyle from "../../styles/RoomList/RoomListStyle";

type Props = {
  roomList: RoomListItemTypes[];
  boardRoomList: RoomListBoardRoomListItemTypes[];
  onRoomListAdd: () => void;
  onRoomListRemove: (id: number) => void;
  onRoomListApply: () => void;
  onRoomListNumberChange: (id: number, value: string) => void;
  onRoomListCodeChange: (roomId: number, roomCode: string) => void;
};

const RoomListSetting = ({
  roomList,
  boardRoomList,
  onRoomListAdd,
  onRoomListRemove,
  onRoomListApply,
  onRoomListNumberChange,
  onRoomListCodeChange,
}: Props) => {
  return (
    <RoomListStyle>
      <CCard>
        <CCardHeader>
          <h5>멀티 진료실 설정</h5>
          <div className="btns">
            <CButton color="success" onClick={onRoomListAdd}>
              추가
            </CButton>
            <CButton color="info" onClick={onRoomListApply}>
              적용
            </CButton>
          </div>
        </CCardHeader>
        <CCardBody>
          <div className="roomlist">
            {roomList.map((room, index) => (
              <CRow key={room.id ?? index} id={`${room.id}`}>
                <CCol xs="3">
                  <CFormInput
                    autoComplete="off"
                    className="roomNumber"
                    placeholder="진료실번호"
                    id={`${room.id}`}
                    value={room.broomNumber || ""}
                    onChange={(e) => {
                      onRoomListNumberChange(room.id, e.target.value);
                    }}
                  />
                </CCol>
                <CCol xs="3">
                  <CFormSelect
                    className="roomCode"
                    id={`${room.id}`}
                    value={room.roomCode ?? ""}
                    onChange={(e) => {
                      onRoomListCodeChange(room.id, e.target.value);
                    }}
                  >
                    <option value="">진료실 선택</option>
                    {boardRoomList
                      .sort((a, b) => (a.roomCode > b.roomCode ? 1 : -1))
                      .map((data) => (
                        <option value={data.roomCode} key={data.id}>
                          {data.roomCode}
                        </option>
                      ))}
                  </CFormSelect>
                </CCol>
                <CCol xs="3">
                  <CFormInput
                    autoComplete="off"
                    className="roomDoctorKor"
                    placeholder="의사명"
                    value={room.doctorKor ?? ""}
                    disabled
                  />
                </CCol>
                <CCol xs="3">
                  <CButton
                    color="danger"
                    onClick={() => {
                      onRoomListRemove(room.id);
                    }}
                  >
                    <FontAwesomeIcon icon={faTimesCircle} color="white" />
                  </CButton>
                </CCol>
              </CRow>
            ))}
          </div>
        </CCardBody>
      </CCard>
    </RoomListStyle>
  );
};
export default RoomListSetting;
