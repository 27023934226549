import * as S from '../../styles/BoardSettingStyle';
import { CFormInput, CFormLabel, CFormSelect } from '@coreui/react';
import { AdminBoardSettingTypes } from '@typedef/components/Admin/BoardSetting/admin.board.setting.types';
import { AdminClinicTypes } from '@typedef/components/Admin/Clinic/admin.clinic.types';

type Props = {
  boardInfo: AdminBoardSettingTypes;
  boardList: AdminClinicTypes[];
  onChangeBoardInfo: (id: string, name: string, value: string) => void;
};

const ClinicHeader = ({ boardInfo, boardList, onChangeBoardInfo }: Props) => {
  return (
    <S.HeaderSetting>
      <S.Inputs className='manager-name' width='16%'>
        <CFormInput
          type='text'
          label='담당자'
          name='adminName'
          value={boardInfo.adminName === '-' ? '' : boardInfo.adminName ?? ''}
          onChange={(e) => {
            onChangeBoardInfo('adminName', 'adminName', e.target.value);
          }}
          placeholder='담당자'
        />
      </S.Inputs>
      <S.Inputs width='36%'>
        <CFormLabel>진료실 리스트[번호][진료실]</CFormLabel>
        <div className='clinic'>
          <CFormInput
            placeholder='번호'
            value={boardInfo.broomNumber ? boardInfo.broomNumber[0] : ''}
            onChange={(e) => {
              onChangeBoardInfo('broomNumber0', 'broomNumber', e.target.value);
            }}
          />
          <CFormSelect
            onChange={(e) => {
              onChangeBoardInfo('roomList0', 'roomList', e.target.value);
            }}
            value={boardInfo.roomList ? boardInfo.roomList[0] : ''}>
            <option value=''>진료실 선택</option>
            {boardList.map((board) => (
              <option key={board.id} value={board.id}>
                {board.roomCode}
              </option>
            ))}
          </CFormSelect>
        </div>
      </S.Inputs>
      <S.Inputs width='16%'>
        <CFormInput
          type='text'
          label='센터 명칭'
          value={boardInfo.subtitle === '-' ? '' : boardInfo.subtitle ?? ''}
          onChange={(e) => {
            onChangeBoardInfo('subtitle', 'subtitle', e.target.value);
          }}
          placeholder='센터 명칭'
        />
      </S.Inputs>
      <S.Inputs width='16%'>
        <CFormInput
          type='text'
          label='진료과 명칭'
          value={boardInfo.bname === '-' ? '' : boardInfo.bname}
          onChange={(e) => {
            onChangeBoardInfo('bname', 'bname', e.target.value);
          }}
          placeholder='진료과 명칭'
        />
      </S.Inputs>
      <S.Inputs width='16%'>
        <CFormInput
          type='text'
          label='전광판 영어명'
          value={boardInfo.bnameEng === '-' ? '' : boardInfo.bnameEng}
          onChange={(e) => {
            onChangeBoardInfo('bnameEng', 'bnameEng', e.target.value);
          }}
          placeholder='진료실 영어'
        />
      </S.Inputs>
    </S.HeaderSetting>
  );
};

export default ClinicHeader;
