import useViewer from '@hooks/store/useViewer';
import { alertMessage } from '@libs/alert';
import { apiRoute, requestPost } from '@libs/api';
import { getCurrentList } from '@libs/factory';
import { PharmacyPatientItemTypes } from '@typedef/components/PharmacyWaiting/pharmacy.waiting.types';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import OutPharmacyWaiting from '../components/OutPharmacyWaiting';

const OUT_PAGE_SIZE = 10;

const LOAD_PATIENT_LIST_INTERVAL = 5000;

const OutPharmacyWaitingContainer = () => {
  const { basic, visible } = useViewer();
  // const { basic } = useViewer();

  const [pharmacyList, setPharmacyList] = useState<PharmacyPatientItemTypes[]>(
    [],
  );

  const [intervalCounter, setIntervalCounter] = useState(0);

  const currentPharmacyList = useMemo(() => {
    return getCurrentList(pharmacyList, OUT_PAGE_SIZE, intervalCounter);
  }, [basic, pharmacyList, intervalCounter]);

  const loadOutPharmacyList = useCallback(async () => {
    if (!basic) {
      alertMessage('전광판 기본 정보를 불러오지 못했습니다!');
      return;
    }

    const { data, config } = await requestPost<PharmacyPatientItemTypes[]>(
      apiRoute.viewer.pharmacy.outPharmacy,
      {},
      {},
    );

    if (config.status === 200) {
      setPharmacyList(data);
    } else {
      alertMessage('외래환자 투약 리스트를 불러오지 못했습니다');
    }
  }, [basic]);

  useEffect(() => {
    loadOutPharmacyList();
  }, [basic, intervalCounter]);

  useEffect(() => {
    const interval = setInterval(
      () => setIntervalCounter((prev) => prev + 1),
      LOAD_PATIENT_LIST_INTERVAL,
    );

    return () => {
      clearInterval(interval);
    };
  }, []);

  return visible ? (
    <OutPharmacyWaiting
      visible={visible}
      currentPharmacyList={currentPharmacyList}
    />
  ) : null;
};

export default OutPharmacyWaitingContainer;

// const visible = {
//   delayTime: 20,
//   maskMark: '0',
//   position: 2,
//   vadmin: true,
//   vclock: true,
//   vdelay: true,
//   vdoctorEng: true,
//   vdoctorPhoto: true,
//   vlist: true,
//   vmasking: true,
//   vvideo: true,
// };
