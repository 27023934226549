import {
  CModal,
  CModalHeader,
  CButton,
  CModalBody,
  CModalFooter,
} from "@coreui/react";
import { AdminClinicModalItemTypes } from "@typedef/components/Admin/Clinic/admin.clinic.types";

type Props = {
  length: number;
  deleteClinic: () => void;
  setCurrentModal: React.Dispatch<
    React.SetStateAction<AdminClinicModalItemTypes>
  >;
};
const ClinicDelete = ({ length, deleteClinic, setCurrentModal }: Props) => {
  return (
    <CModal
      visible={true}
      onClose={() => setCurrentModal(null)}
      backdrop="static"
    >
      <CModalHeader>진료실 삭제</CModalHeader>
      <CModalBody>선택한 진료실 {length}개를 삭제하시겠습니까?</CModalBody>
      <CModalFooter>
        <CButton color="primary" onClick={deleteClinic}>
          삭제하기
        </CButton>
        <CButton color="secondary" onClick={() => setCurrentModal(null)}>
          취소
        </CButton>
      </CModalFooter>
    </CModal>
  );
};

export default ClinicDelete;
