import { CCol, CFormInput, CFormLabel, CFormSelect, CRow } from '@coreui/react';
import { fontFamilyOption } from '@libs/config/config';
import { FontItemTypes } from '@typedef/components/Admin/BoardSetting/components/Font/admin.board.setting.type.font.types';
import FontSettingItemStyle from '../../styles/Font/FontSettingItemStyle';

type Props = {
  content: string;
  fontId: string;
  font: FontItemTypes;
  onFamilyChange: (value: string) => void;
  onSizeChange: (value: number) => void;
  onBoldChange: (value: boolean) => void;
  onColorChange: (value: string) => void;
};

const FontSettingItem = ({
  content,
  fontId,
  font,
  onFamilyChange,
  onSizeChange,
  onBoldChange,
  onColorChange,
}: Props) => {
  return (
    <FontSettingItemStyle>
      <CRow>
        <CCol className='font-content'>
          <p>{content}</p>
        </CCol>
        <CCol xs='2' className='font-family'>
          <CFormSelect
            id='family'
            value={font?.family || ''}
            onChange={(e) => {
              onFamilyChange(e.target.value);
            }}
            style={{ fontFamily: `${font?.family}` }}>
            <option value=''>글꼴 설정</option>
            {fontFamilyOption.map((font) => (
              <option
                key={font.value}
                value={font?.value}
                style={{ fontFamily: `${font?.value}` }}>
                {font?.name}
              </option>
            ))}
          </CFormSelect>
        </CCol>

        <CCol xs='2' className='font-size'>
          <CFormInput
            value={font?.size !== undefined ? font?.size || 0 : 0}
            onChange={(e) => {
              onSizeChange(Number(e.target.value));
            }}
            min='0'
            type='number'
            id='size'
          />
        </CCol>

        <CCol className='font-bold'>
          <CRow>
            <input
              type='checkbox'
              id={`${fontId}bold`}
              onChange={(e) => {
                onBoldChange(e.target.checked);
              }}
              checked={font?.bold || false}
            />
            <CFormLabel
              htmlFor={`${fontId}bold`}
              style={{ fontWeight: font?.bold ? 'bold' : 'normal' }}>
              BOLD
            </CFormLabel>
          </CRow>
        </CCol>

        <CCol xs='5' className='font-color'>
          <CRow>
            <span>색상</span>
            <input
              type='color'
              id='color'
              onChange={(e) => {
                onColorChange(e.target.value);
              }}
              value={
                font?.color !== undefined ? font?.color ?? '#000000' : '#000000'
              }
            />
          </CRow>
        </CCol>
      </CRow>
    </FontSettingItemStyle>
  );
};

export default FontSettingItem;
