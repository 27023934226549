import useViewer from '@hooks/store/useViewer';
import { alertMessage } from '@libs/alert';
import React, { useEffect, useState } from 'react';
import PharmacyWaiting from '../PharmacyWaiting';

const EXIT_PHARMACY_BTYPENAME = '퇴원약국';
const OUT_PATIENT_PHARMACY_BTYPENAME = '외래약국';

const PharmacyWaitingContainer = () => {
  const { basic, visible } = useViewer();

  const [componentFlag, setComponentFlag] = useState<string>('');

  useEffect(() => {
    if (!basic) {
      alertMessage('전광판 기본 정보를 불러오지 못했습니다!');
      return;
    }
    if (basic?.btypeName === EXIT_PHARMACY_BTYPENAME) {
      setComponentFlag('EXIT');
    } else if (basic?.btypeName === OUT_PATIENT_PHARMACY_BTYPENAME) {
      setComponentFlag('OUT');
    }
  }, [basic]);

  return visible ? (
    <PharmacyWaiting visible={visible} componentFlag={componentFlag} />
  ) : null;
};

export default React.memo(PharmacyWaitingContainer);
