import styled from 'styled-components';

// Visible 설정 ( 표시 및 감추기 )
const VisibleSettingStyle = styled.div`
  .card {
    height: 32vh;
    .card-body {
      padding: 0.5em;
      ul {
        list-style: none;
        margin: 0;
        padding: 0;
        li {
          list-style: none;
          display: flex;
          padding: 0.15em 0;
          height: 3.1vh;
          p {
            margin: 0;
          }
          .setting {
            margin-left: auto;
            .form-check-input {
              cursor: pointer;
            }
            .form-label {
              margin: 0;
              margin-right: 0.5em;
              cursor: pointer;
            }
          }
        }
      }
    }
  }
  @media (max-width: 1280px) {
    .card {
      height: 32vh;
    }
  }
`;

export default VisibleSettingStyle;
