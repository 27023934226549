import { CTable } from '@coreui/react';
import { PatientListItemTypes } from '@typedef/components/Admin/Patient/admin.patient.types';
import { Droppable } from 'react-beautiful-dnd';
import DragRowContainer from '../containers/DragRowContainer';

type Props = {
  roomId: number | null;
  tableId: string;
  patientList: PatientListItemTypes[];
  canDrag: boolean;
  searchInput: string;
  searchOption: string;
  sortOption: string;
  sortOrderOption: number | null;
  setSortBy: (sortName: string) => void;
  sortingArg: (
    sortName: string,
    sortTarget: string,
    orderOption: number | null,
  ) => string | undefined;
  loadPatientList: (roomId: number) => void;
};

const DropTable = ({
  roomId,
  tableId,
  patientList,
  canDrag,
  searchInput,
  searchOption,
  sortOption,
  sortOrderOption,
  setSortBy,
  sortingArg,
  loadPatientList,
}: Props) => {
  return (
    <Droppable droppableId={tableId}>
      {(provided) => (
        <div className='drop-table' ref={provided.innerRef} {...provided.droppableProps}>
          <CTable striped bordered hover>
            <colgroup>
              <col width='6%'></col>
              {/* <col width='9%'></col> */}
              <col width='6%'></col>
              <col width='9%'></col>
              <col width='11%'></col>
              <col width='16%'></col>
              <col width='16%'></col>
              {/* <col width='7%'></col>
              <col width='8%'></col>
              <col width='5%'></col>
              <col width='5%'></col> */}
            </colgroup>
            <thead>
              <tr>
                <th>순서</th>
                <th
                  style={{ cursor: 'pointer' }}
                  onClick={() => {
                    setSortBy('ordtm');
                  }}
                >
                  예약{sortingArg(sortOption, 'ordtm', sortOrderOption)}
                </th>
                {/* <th>의뢰</th> */}
                <th style={{ cursor: 'pointer' }} onClick={() => setSortBy('nursacptdt')}>
                  도착시간
                  {sortingArg(sortOption, 'nursacptdt', sortOrderOption)}
                </th>
                <th style={{ cursor: 'pointer' }} onClick={() => setSortBy('pid')}>
                  등록번호{sortingArg(sortOption, 'pid', sortOrderOption)}
                </th>
                <th style={{ cursor: 'pointer' }} onClick={() => setSortBy('hngnm')}>
                  이름{sortingArg(sortOption, 'hngnm', sortOrderOption)}
                </th>
                <th style={{ cursor: 'pointer' }} onClick={() => setSortBy('age')}>
                  주민 (성)나이
                  {sortingArg(sortOption, 'age', sortOrderOption)}
                </th>
                {/* <th>초진</th>
                <th>구분</th>
                <th>진행</th>
                <th>심사</th> */}
              </tr>
            </thead>
            <tbody>
              {patientList?.map((patient, index) => {
                return (
                  <DragRowContainer
                    key={`key-${patient.pid}-${patient.cretno}`}
                    roomId={roomId}
                    tableId={tableId}
                    patient={patient}
                    patientIdx={index}
                    patientList={patientList}
                    cretNo={patient?.cretno}
                    searchInput={searchInput}
                    searchOption={searchOption}
                    canDrag={canDrag}
                    loadPatientList={loadPatientList}
                  />
                );
              })}
            </tbody>
          </CTable>
          {provided.placeholder}
        </div>
      )}
    </Droppable>
  );
};

export default DropTable;
