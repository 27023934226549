import BoardVariable from "../components/BoardVariable";
import { useCallback, useEffect, useState } from "react";
import {
  AdminBoardModalItemTypes,
  AdminBoardVariable,
} from "@typedef/components/Admin/Board/admin.board.types";
import { apiRoute, requestSecureGet, requestSecurePut } from "@libs/api";
import useLogined from "@hooks/store/useLogined";

type Props = {
  ids: number;
  setCurrentModal: React.Dispatch<
    React.SetStateAction<AdminBoardModalItemTypes>
  >;
  reload: number;
  setReload: React.Dispatch<React.SetStateAction<number>>;
};

const BoardVariableContainer = ({
  ids,
  setCurrentModal,
  reload,
  setReload,
}: Props) => {
  const [variableSet, setVariableSet] = useState<AdminBoardVariable>({});
  const { token } = useLogined();

  const loadVariable = useCallback(async () => {
    if (!token) return;

    const { data, config } = await requestSecureGet<AdminBoardVariable>(
      apiRoute.admin.board.getVariable + ids,
      {},
      token
    );

    if (config.status === 200) {
      if (data) {
        setVariableSet(data);
      }
    }
  }, [token, ids]);

  const updateVariable = useCallback(async () => {
    if (!token) return;

    const { data, config } = await requestSecurePut<AdminBoardVariable>(
      apiRoute.admin.board.putVariable,
      {},
      {
        id: ids,
        queryValue: JSON.stringify(variableSet),
      },
      token
    );

    if (config.status === 200 && data) {
      setCurrentModal(null);
    } else {
      alert("변수수정에 실패했습니다. 쿼리 또는 변수를 확인 해주세요.");
    }
  }, [token, variableSet]);

  // 추가 ( 기본값 ( "key" : "value" ))
  const onAddVaraible = useCallback(() => {
    setVariableSet((prev) => {
      const clone = { ...prev };

      clone["key"] = "value";

      return clone;
    });
  }, [variableSet]);

  // 삭제
  const onRemoveVariable = useCallback(
    (deleteKey: string) => {
      setVariableSet((prev) => {
        const clone = { ...prev };

        delete clone[deleteKey];

        return clone;
      });
    },
    [variableSet]
  );

  const onChangeKeyHandler = useCallback(
    (defaultKey: string, key: string) => {
      setVariableSet((prev) => {
        const clone = { ...prev };

        const defaultValue = clone[defaultKey];

        delete clone[defaultKey];

        clone[key] = defaultValue;

        // delete Object.assign(clone, { [key]: clone[defaultKey] })[defaultKey];
        return clone;
      });
    },
    [variableSet]
  );

  const onChangeValueHandler = useCallback(
    (key: string, value: string) => {
      setVariableSet((prev) => {
        const clone = { ...prev };

        clone[key] = value;

        return clone;
      });
    },
    [variableSet]
  );

  useEffect(() => {
    loadVariable();
  }, [loadVariable]);

  return (
    <BoardVariable
      updateVariable={updateVariable}
      setCurrentModal={setCurrentModal}
      variableSet={variableSet}
      onAddVaraible={onAddVaraible}
      onRemoveVariable={onRemoveVariable}
      onChangeKeyHandler={onChangeKeyHandler}
      onChangeValueHandler={onChangeValueHandler}
    />
  );
};

export default BoardVariableContainer;
