import styled from "styled-components";

export const ExaminationEndoscopePatientListWaitingStyle = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  background-color: #fff;
  border-radius: 1vw;

  .row {
    height: 100%;
    display: flex;
    flex-direction: row;
  }
`;

export const Thead = styled.section`
  height: 9vh;
  line-height: 9vh;
  display: flex;
  flex-direction: row;
  background-color: #5a5490;

  p {
    width: 16.66vw;
    text-align: center;
    color: #fff;
    font-size: 5vh;
    font-weight: 600;
  }
`;

export const Tbody = styled.section`
  height: 100%;
  display: flex;
  flex-direction: row;
`;

export const Patients = styled.ul`
  width: 16.66vw;
  display: flex;
  flex-direction: column;
  margin: 0;
  background-color: lightgray;
  list-style: none;
  border-left: 2px solid #5a5490;
`;

export const Patient = styled.li`
  flex: 1;
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 0.25vw;
  overflow: hidden;

  div {
    flex: 1;
    height: 100%;
    position: relative;
  }

  p {
    width: 100%;
    text-align: center;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  &:nth-child(2n) {
    background-color: #8ba5be;
  }
  &:nth-child(n + 11) {
    display: none;
  }
`;

export const CustomPTag = styled.p<{
  family?: string;
  fz?: number;
  fw?: boolean;
  color?: string;
  align?: string;
}>`
  font-family: ${(props) => props.family};
  font-size: calc(${(props) => props.fz}vh / 11);
  font-weight: ${(props) => (props.fw ? "bold" : "normal")};
  color: ${(props) => props.color};
  text-align: ${(props) => props.align ?? "left"};
`;
