import images from '@assets/images';
import {
  CButton,
  CCard,
  CCardBody,
  CCol,
  CFormInput,
  CFormSelect,
  CFormSwitch,
  CFormTextarea,
  CRow,
} from '@coreui/react';
import { parseCheckRoomorBoard } from '@libs/factory';
import {
  DoctorItemType,
  LoadIpMacItemType,
  LoadNoticeInfoResponseType,
  LoadSubTitleListResponseType,
} from '@typedef/components/AdminGNB/admin.gnb.types';
import { MyBoardListItemTypes } from '@typedef/components/Login/login.types';
import { VIEWER_USE_OPTIONS } from '@typedef/libs/config.types';
import React, { SetStateAction } from 'react';
import AdminGNBStyle from './styles/AdminGNBStyle';

type Props = {
  selected: MyBoardListItemTypes | null;
  myBoardList?: MyBoardListItemTypes[];
  onMyBoardItemClicked: (item: MyBoardListItemTypes) => void;
  subTitleInfo: LoadSubTitleListResponseType | null;
  subCheckboxList: number[];
  subtitleVisible: boolean;
  setSubtitleVisible: React.Dispatch<SetStateAction<boolean>>;
  onSubTitleListItemCheckClicked: (idx: number, check: boolean) => void;
  onSubTitleOptionApplyClicked: () => void;
  noticeInfo: LoadNoticeInfoResponseType | null;
  newNoticeContents: string | null;
  setNewNoticeContents: React.Dispatch<SetStateAction<string | null>>;
  noticeVisible: boolean;
  setNoticeVisible: React.Dispatch<SetStateAction<boolean>>;
  onNoticeOptionApplyClicked: () => void;
  doctorInfo: DoctorItemType[];
  currentDoctor: DoctorItemType | null;
  onDoctorOptionApplyClicked: () => void;
  newDelay: number | null;
  setNewDelay: React.Dispatch<SetStateAction<number | null>>;
  onDelayOptionApplyClicked: () => void;
  infectionFlag: boolean;
  onInfectionFlagClicked: (flag: boolean) => void;
  onDoctorItemClicked: (doctorId: string) => void;
  boardIpMacInfo?: LoadIpMacItemType;
  // onPowerAllChange: (value: string) => void;
  // onPowerRoomChange: (value: string) => void;
  visible: boolean;
  boardType: string;
  powerOnFlag: boolean;
};

const AdminGNB = ({
  selected,
  myBoardList,
  onMyBoardItemClicked,
  subTitleInfo,
  subCheckboxList,
  subtitleVisible,
  setSubtitleVisible,
  onSubTitleListItemCheckClicked,
  onSubTitleOptionApplyClicked,
  noticeInfo,
  newNoticeContents,
  setNewNoticeContents,
  noticeVisible,
  setNoticeVisible,
  onNoticeOptionApplyClicked,
  doctorInfo,
  currentDoctor,
  onDoctorOptionApplyClicked,
  newDelay,
  setNewDelay,
  onDelayOptionApplyClicked,
  infectionFlag,
  onInfectionFlagClicked,
  onDoctorItemClicked,
  boardIpMacInfo,
  // onPowerAllChange,
  // onPowerRoomChange,
  visible,
  boardType,
  powerOnFlag,
}: Props) => {
  return (
    <AdminGNBStyle visible={visible}>
      <div className='gnb-logo'>
        <div className='gnb-img'>
          <img src={images.jsolAdminLogo} alt='logo' />
        </div>
      </div>
      <div className='my-board'>
        <div className='my-board-list'>
          <ul>
            {myBoardList
              ? myBoardList.map((data) => (
                  <li
                    key={data.roomId ?? data.boardId}
                    onClick={() => {
                      onMyBoardItemClicked(data);
                    }}
                    id={data?.roomOrBoard ? `room-${data.roomId}` : `board-${data.boardId}`}
                    style={
                      parseCheckRoomorBoard(selected) === parseCheckRoomorBoard(data)
                        ? { backgroundColor: '#f9b115', borderRadius: '5px' }
                        : {}
                    }
                  >
                    <CRow>
                      <CCol className='my-board-list-name'>
                        <p>{data?.roomOrBoard ? data?.roomName : data?.boardName}</p>
                      </CCol>
                      <CCol xs='5' className='my-board-list-docname'>
                        <p>{data?.docname ?? ''}</p>
                      </CCol>
                    </CRow>
                  </li>
                ))
              : null}
          </ul>
        </div>
      </div>
      <CCard>
        <CCardBody>
          {VIEWER_USE_OPTIONS[boardType]?.option?.includes('Sub') && (
            <div className='sub-title'>
              <div className='title'>
                <p className='sidebar-card-header'>자막</p>
                <div className='checkbox'>
                  <input
                    type='checkbox'
                    checked={subtitleVisible}
                    onChange={(e) => {
                      setSubtitleVisible(e.target.checked);
                    }}
                    id='subTitleDisplay'
                  />
                  <label htmlFor='subTitleDisplay'>표시</label>
                </div>
              </div>
              <div className='content'>
                <div className='sub-title-list'>
                  <ul>
                    {subTitleInfo?.contents?.map((data) => (
                      <li key={data.idx}>
                        <CRow>
                          <CCol xs='1'>
                            <input
                              type='checkbox'
                              checked={subCheckboxList.includes(data.idx)}
                              onChange={(e) => {
                                onSubTitleListItemCheckClicked(data.idx, e.target.checked);
                              }}
                            />
                          </CCol>
                          <CCol xs='11'>
                            <label
                              style={
                                subCheckboxList.includes(data.idx)
                                  ? {
                                      backgroundColor: '#f9b115',
                                      color: '#fff',
                                    }
                                  : {}
                              }
                              title={data?.contents}
                            >
                              {data?.contents}
                            </label>
                          </CCol>
                        </CRow>
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
              <div className='option-btn'>
                <CButton color='warning' onClick={onSubTitleOptionApplyClicked}>
                  적용
                </CButton>
              </div>
            </div>
          )}

          {VIEWER_USE_OPTIONS[boardType]?.option?.includes('Notice') && (
            <div className='notice'>
              <div className='title'>
                <p className='sidebar-card-header'>공지사항</p>
                <div className='checkbox'>
                  <input
                    type='checkbox'
                    checked={noticeVisible}
                    id='noticeDisplay'
                    onChange={(e) => {
                      setNoticeVisible(e.target.checked);
                    }}
                  />
                  <label htmlFor='noticeDisplay'>표시</label>
                </div>
              </div>
              <div className='content'>
                <CFormTextarea
                  id='notice-content'
                  value={newNoticeContents ?? ''}
                  autoComplete='off'
                  style={{
                    textAlign: noticeInfo?.align,
                  }}
                  onChange={(e) => {
                    setNewNoticeContents(e.target.value);
                  }}
                />
              </div>
              <div className='option-btn'>
                <CButton color='warning' onClick={onNoticeOptionApplyClicked}>
                  적용
                </CButton>
              </div>
            </div>
          )}

          {selected?.roomOrBoard ? (
            <div className='doctor-list'>
              <div className='title'>
                <p className='sidebar-card-header'>의사 정보</p>
                <div className='select-box'>
                  <CFormSelect
                    id='doctorList'
                    value={currentDoctor?.USERID ?? '-'}
                    onChange={(e) => {
                      onDoctorItemClicked(e.target.value);
                    }}
                  >
                    <option value='-'>휴진</option>
                    {doctorInfo
                      .sort((a, b) => (a.USERNM > b.USERNM ? 1 : -1))
                      .map((data, index) => (
                        <option value={data.USERID} id={data.USERID} key={data.USERID + index}>
                          {data.USERNM}
                        </option>
                      ))}
                  </CFormSelect>
                </div>
              </div>
              <div className='option-btn'>
                <CButton color='warning' id='doctorListBtn' onClick={onDoctorOptionApplyClicked}>
                  적용
                </CButton>
              </div>
            </div>
          ) : null}

          {VIEWER_USE_OPTIONS[boardType]?.option?.includes('Delay') && (
            <div className='delay'>
              <div className='title'>
                <p className='sidebar-card-header'>지연시간&nbsp;(분)</p>

                <CFormInput
                  type='number'
                  id='delayTime'
                  value={newDelay ?? 0}
                  onChange={(e) => {
                    setNewDelay(Number(e.target.value));
                  }}
                />
              </div>
              <div className='option-btn'>
                <CButton color='warning' onClick={onDelayOptionApplyClicked}>
                  적용
                </CButton>
              </div>
            </div>
          )}
        </CCardBody>
      </CCard>
      <div className='power'>
        <div className='power-title'>
          <p className='title-content'>전원관리</p>
          <div className='content'>
            <div className='code'>
              <p>IP : {boardIpMacInfo ? boardIpMacInfo?.ip : ''}</p>
            </div>
            <div className='code'>
              <p>MAC : {boardIpMacInfo ? boardIpMacInfo?.mac : ''}</p>
            </div>
            <div className='all'>
              <p className='power-content-all-title'>전체</p>
              {powerOnFlag && (
                <CButton
                  color='success'
                  id='on'
                  onClick={() => {
                    // onPowerAllChange('on');
                  }}
                >
                  ON
                </CButton>
              )}
              <CButton
                color='danger'
                id='off'
                onClick={() => {
                  // onPowerAllChange('off');
                }}
              >
                OFF
              </CButton>
            </div>
            <div className='room'>
              <p className='power-content-room-title'>전광판</p>
              {powerOnFlag && (
                <CButton
                  color='success'
                  id='on'
                  onClick={() => {
                    // onPowerRoomChange('on');
                  }}
                >
                  ON
                </CButton>
              )}
              <CButton
                color='danger'
                id='off'
                onClick={() => {
                  // onPowerRoomChange('off');
                }}
              >
                OFF
              </CButton>
            </div>
          </div>
        </div>
      </div>
      {selected?.roomOrBoard && (
        <div className='infectious'>
          <div className='content'>
            <p className='infectious-content-title'>감염병조회</p>
            <label className='infectious-label' htmlFor='infectious-switch'>
              OFF
            </label>
            <CFormSwitch
              color='danger'
              id='infectious-switch'
              checked={infectionFlag}
              onChange={(e) => {
                onInfectionFlagClicked(e.target.checked);
              }}
            />
            <label className='infectious-label' htmlFor='infectious-switch'>
              ON
            </label>
          </div>
        </div>
      )}
    </AdminGNBStyle>
  );
};

export default AdminGNB;
