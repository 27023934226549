import ClinicUpdate from '../components/ClinicUpdate';
import React, { useState, useEffect, useCallback, SetStateAction } from 'react';
import {
  apiRoute,
  requestSecureGet,
  requestSecurePost,
  requestSecurePut,
} from '@libs/api';
import useLogined from '@hooks/store/useLogined';
import { AdminClinicInputsType } from '@typedef/components/Admin/Clinic/components/admin.clinic.inputs.type';
import { AdminDeptTypes } from '@typedef/components/Admin/Clinic/components/admin.dept.types';
import { AdminBoardTypes } from '@typedef/components/Admin/Clinic/components/admin.board.types';
import useGNB from '@hooks/store/useGNB';
import { AdminClinicModalItemTypes } from '@typedef/components/Admin/Clinic/admin.clinic.types';

type Props = {
  idx: number | null;
  setCurrentModal: React.Dispatch<
    React.SetStateAction<AdminClinicModalItemTypes>
  >;
  reload: number;
  setReload: React.Dispatch<SetStateAction<number>>;
};

const ClinicUpdateContainer = ({
  idx,
  setCurrentModal,
  reload,
  setReload,
}: Props) => {
  const {
    myBoardReload,
    __updateGNBMyBoardReloadFromHooks,
    __updateSelectedBoardFromHooks,
    selected,
  } = useGNB();

  const { token } = useLogined();

  const [inputs, setInputs] = useState<AdminClinicInputsType>({
    boardIdx: 0,
    boardCode: '',
    centerKor: '',
    customCenter: '',
    deptId: null,
    deptKor: '',
    roomCode: '',
  });

  const [deptList, setDeptList] = useState<AdminDeptTypes[] | null>(null);

  const [boardList, setBoardList] = useState<AdminBoardTypes[]>([]);

  const [validRoomCode, setValidRoomCode] = useState<string>('');

  // 진료실이름 입력 확인
  const onChangeRoomCode = useCallback((value: string) => {
    setValidRoomCode('');
    setInputs((inputs) => ({ ...inputs, roomCode: value }));
  }, []);

  const onChangeInputs = useCallback((name: string, value: any) => {
    setInputs((inputs) => ({ ...inputs, [name]: value }));
  }, []);

  // 진료과, 센터, 담당의사
  const onChangeSelect = useCallback(
    (e: React.ChangeEvent<HTMLSelectElement>) => {
      const { name, value } = e.target;

      // 진료과
      if (name === 'dept') {
        if (!!!value) {
          setInputs((inputs) => ({
            ...inputs,
            deptKor: '-',
            deptEng: '-',
          }));
          return;
        }
        deptList?.forEach((dept) => {
          if (dept.deptCd === value) {
            setInputs((inputs) => ({
              ...inputs,
              deptKor: dept.deptHngNm,
              deptEng: dept.deptEngNm,
            }));
          }
        });
      }
    },
    [deptList, inputs],
  );

  const roomCodeCheck = useCallback(async () => {
    if (!token) return;

    const { data, config } = await requestSecureGet<boolean>(
      apiRoute.admin.clinic.validClinic + inputs.roomCode,
      {},
      token,
    );

    if (config.status === 200) {
      //true : 중복데이터있음, false: 중복데이터없음
      if (data) {
        alert('이미 존재하는 진료실 이름입니다.');
      } else {
        setValidRoomCode(inputs.roomCode);
        alert('사용 가능한 진료실 이름입니다.');
      }
    }
  }, [inputs.roomCode, token]);

  // 전광판 검색, 선택
  const onChangeboard = useCallback(
    (value: string) => {
      boardList.forEach((board) => {
        if (board.boardCode === value) {
          setInputs({
            ...inputs,
            boardIdx: Number(board.id),
          });
        }
      });
    },
    [boardList, inputs],
  );

  // 진료실 수정
  const update = useCallback(async () => {
    if (!!!validRoomCode) {
      alert('진료실 중복검사를 해주세요.');
      return;
    }

    const data = {
      boardIdx: inputs.boardIdx ?? null,
      centerKor: inputs.centerKor ?? '-',
      customCenter: inputs.customCenter ?? '-',
      deptEng: inputs.deptEng ?? '-',
      deptKor: inputs.deptKor ?? '-',
      doctorId: null,
      doctorKor: inputs.doctorKor ?? '휴진',
      id: inputs.id,
      roomCode: validRoomCode,
    };

    if (!token) return;

    const { config } = await requestSecurePut(
      apiRoute.admin.clinic.patchClinic,
      {},
      data,
      token,
    );
    if (config.status === 200) {
      setCurrentModal(null);

      setReload(reload + 1);

      if (selected) {
        __updateSelectedBoardFromHooks({
          ...selected,
          docname: data.doctorKor,
        });
      }

      __updateGNBMyBoardReloadFromHooks(myBoardReload + 1);
    }
  }, [
    selected,
    inputs,
    validRoomCode,
    token,
    setCurrentModal,
    setReload,
    reload,
    __updateGNBMyBoardReloadFromHooks,
  ]);

  // 진료과 조회
  const getDeptData = useCallback(async () => {
    if (!token) return;

    const { config, data } = await requestSecureGet<AdminDeptTypes[]>(
      apiRoute.admin.clinic.getDepts,
      {},
      token,
    );
    if (config.status >= 200 && config.status < 400) {
      setDeptList(data);
    }
  }, [token]);

  // 전광판 조회
  const getBoardData = useCallback(async () => {
    if (!token) return;

    const { config, data } = await requestSecureGet<AdminBoardTypes[]>(
      apiRoute.admin.clinic.getBoards,
      {},
      token,
    );
    if (config.status >= 200 && config.status < 400) {
      setBoardList(data);
    }
  }, [token]);

  // 수정 데이터 상세 조회 (수정)
  const getClinicData = useCallback(async () => {
    if (!token) return;

    const { config, data } = await requestSecureGet<AdminClinicInputsType>(
      apiRoute.admin.clinic.getClinic + idx,
      {},
      token,
    );
    if (config.status >= 200 && config.status < 400) {
      setInputs(data);
      setValidRoomCode(data.roomCode);
    }
  }, [idx, token]);

  useEffect(() => {
    if (idx) {
      getClinicData();
    }
    getDeptData();
    getBoardData();
  }, []);

  return (
    <ClinicUpdate
      setCurrentModal={setCurrentModal}
      inputs={inputs}
      onChangeRoomCode={onChangeRoomCode}
      onChangeInputs={onChangeInputs}
      onChangeSelect={onChangeSelect}
      onChangeboard={onChangeboard}
      deptList={deptList}
      boardList={boardList}
      save={update}
      roomCodeCheck={roomCodeCheck}
    />
  );
};

export default ClinicUpdateContainer;
