import useGNB from '@hooks/store/useGNB';
import useLogined from '@hooks/store/useLogined';
import { apiRoute, requestSecureGet, requestSecurePut } from '@libs/api';
import { AdminBoardVisibleSettingTypes } from '@typedef/components/Admin/BoardSetting/admin.board.setting.types';
import { useCallback, useEffect, useState } from 'react';
import MaskingSetting from '../../components/Masking/MaskingSetting';

type Props = {
  boardId: number;
};
const MaskingSettingContainer = ({ boardId }: Props) => {
  const { token } = useLogined();

  const { selected } = useGNB();

  const [mark, setMark] = useState<string>('');

  const [position, setPosition] = useState<number>(0);

  // 마스킹 설정 적용
  const maskingSettingApply = useCallback(async () => {
    if (!token) return;

    if (boardId) {
      await requestSecurePut(
        apiRoute.admin.boardSet.visible + boardId,
        {},
        {
          id: boardId,
          maskMark: mark,
          position: position,
        },
        token,
      );
    }
  }, [boardId, token, mark, position]);

  // Visible 불러오기
  const loadVisible = useCallback(async () => {
    if (!token) return;

    if (boardId) {
      const { data, config } =
        await requestSecureGet<AdminBoardVisibleSettingTypes>(
          apiRoute.admin.boardSet.visible + boardId,
          {},
          token,
        );

      if (config.status === 200) {
        setMark(data.maskMark ?? '');
        setPosition(data.position ?? 0);
      }
    }
  }, [boardId, token]);

  useEffect(() => {
    loadVisible();
  }, [boardId, token, selected]);

  return (
    <MaskingSetting
      maskingSettingApply={maskingSettingApply}
      mark={mark}
      setMark={setMark}
      position={position}
      setPosition={setPosition}
    />
  );
};

export default MaskingSettingContainer;
