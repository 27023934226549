import HeaderSetting from '../../components/Header/HeaderSetting';
import { useState, useEffect, useCallback, useMemo } from 'react';
import useLogined from '@hooks/store/useLogined';
import useProfile from '@hooks/store/useProfile';
import { apiRoute, requestSecureGet, requestSecurePut } from '@libs/api';
import { AdminBoardSettingTypes } from '@typedef/components/Admin/BoardSetting/admin.board.setting.types';
import { AdminClinicTypes } from '@typedef/components/Admin/Clinic/admin.clinic.types';
import useGNB from '@hooks/store/useGNB';
import { alertMessage } from '@libs/alert';

type Props = {
  boardInfo: AdminBoardSettingTypes;
  setBoardInfo: React.Dispatch<React.SetStateAction<AdminBoardSettingTypes>>;
  setCurrentModal: React.Dispatch<React.SetStateAction<string | null>>;
};

const HeaderSettingContainer = ({ boardInfo, setBoardInfo, setCurrentModal }: Props) => {
  const { token } = useLogined();

  const { myBoard } = useProfile();

  const { selected } = useGNB();

  const [boardList, setBoardList] = useState<AdminClinicTypes[]>([]);

  const boardId = useMemo(() => myBoard?.id, [myBoard]);

  const miniTypePath: string = useMemo(
    () =>
      boardInfo.btypeName?.includes('검사검진')
        ? 'health/'
        : boardInfo.btypeName?.includes('외래대기')
        ? 'outpatient/'
        : '',
    [boardInfo],
  );

  // onChange(input)
  const onChangeBoardInfo = useCallback(
    (id: string, name: string, value: string) => {
      // broomNumber, roomList는 전광판 타입에 따라 최대 4개까지 받을수있음.
      if (id.includes('broomNumber') || id.includes('roomList')) {
        setBoardInfo((boardInfo) => ({
          ...boardInfo,
          [name]: [
            id.includes('0') ? value : (boardInfo[name] && boardInfo[name][0]) || '',
            id.includes('1') ? value : (boardInfo[name] && boardInfo[name][1]) || '',
            id.includes('2') ? value : (boardInfo[name] && boardInfo[name][2]) || '',
            id.includes('3') ? value : (boardInfo[name] && boardInfo[name][3]) || '',
          ],
        }));
      } else {
        setBoardInfo((boardInfo) => ({
          ...boardInfo,
          [name]: value.length > 0 ? value : '-',
        }));
      }
    },
    [setBoardInfo],
  );

  // 전광판 설정 적용
  const onClickApply = useCallback(async () => {
    if (!token) return;

    const patchData = {
      adminName: boardInfo.adminName ?? `-`,
      bcentName: boardInfo.bcentName ?? `-`,
      bname: boardInfo.bname ?? `-`,
      bnameEng: boardInfo.bnameEng ?? `-`,
      boardCode: boardInfo.boardCode ?? `-`,
      broomNumber: boardInfo.broomNumber.join() ?? `-`,
      btypeName: boardInfo.btypeName ?? `-`,
      id: boardInfo.id ?? `-`,
      ip: boardInfo.ip ?? `-`,
      mac: boardInfo.mac ?? `-`,
      roomList: boardInfo.roomList.join() ?? `-`,
      dscyn: boardInfo.dscyn ?? `-`,
      rosette: boardInfo.rosette ?? `-`,
      subtitle: boardInfo.subtitle ?? `-`,
    };

    await requestSecurePut(apiRoute.admin.boardSet.info + boardInfo.id, {}, patchData, token);
  }, [boardInfo, token]);

  // 진료실 리스트 조회
  const getBoardList = useCallback(async () => {
    if (!token) return;

    const { data, config } = await requestSecureGet<AdminClinicTypes[]>(
      apiRoute.admin.room.getRoomInfoBoard + selected?.boardId,
      {},
      token,
    );
    setBoardList([]);

    if (config.status === 200) {
      setBoardList(data);
    } else {
      alertMessage('진료실 리스트를 불러오지 못했습니다.');
    }
  }, [token, setBoardList, selected]);

  useEffect(() => {
    getBoardList();
  }, [boardId, getBoardList, selected, token]);

  return (
    <HeaderSetting
      boardInfo={boardInfo}
      btypeName={boardInfo.btypeName}
      boardList={boardList}
      onChangeBoardInfo={onChangeBoardInfo}
      onClickApply={onClickApply}
      setCurrentModal={setCurrentModal}
      miniTypePath={miniTypePath}
    />
  );
};

export default HeaderSettingContainer;
