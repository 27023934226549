import useGNB from '@hooks/store/useGNB';
import useLogined from '@hooks/store/useLogined';
import { apiRoute, requestSecureGet, requestSecurePut } from '@libs/api';
import {
  createFontSettingString,
  duplicateFontSettingComma,
} from '@libs/config/config';
import {
  FontDefaultResponseItemTypes,
  FontItemTypes,
  FontListItemTypes,
  FontResponseItemTypes,
} from '@typedef/components/Admin/BoardSetting/components/Font/admin.board.setting.type.font.types';
import { VIEWER_USE_OPTIONS } from '@typedef/libs/config.types';
import { useCallback, useEffect, useState } from 'react';
import FontSetting from '../../components/Font/FontSetting';

type Props = {
  boardId: number | null;
  btypeName: string;
};

const FontSettingContainer = ({ boardId, btypeName }: Props) => {
  const { token } = useLogined();

  const { selected } = useGNB();

  const [fontList, setFontList] = useState<FontListItemTypes>({});

  const [category, setCategory] = useState<string>('');

  // 폰트 데이터 조회
  const loadFontSetting = useCallback(async () => {
    if (!token) return;

    const { data, config } = await requestSecureGet<FontResponseItemTypes>(
      apiRoute.admin.boardSet.font + boardId,
      {},
      token,
    );

    if (config.status === 200) {
      setFontList({
        froomNumber: duplicateFontSettingComma(data.froomNumber),
        froomKor: duplicateFontSettingComma(data.froomKor),
        froomEng: duplicateFontSettingComma(data.froomEng),
        fcenter: duplicateFontSettingComma(data.fcenter),
        fdoctorKor: duplicateFontSettingComma(data.fdoctorKor),
        fdoctorEng: duplicateFontSettingComma(data.fdoctorEng),
        fadminKor: duplicateFontSettingComma(data.fadminKor),
        fpatientName: duplicateFontSettingComma(data.fpatientName),
        fnotice: duplicateFontSettingComma(data.fnotice),
        fsub: duplicateFontSettingComma(data.fsub),
      });
    }
  }, [boardId, token]);

  // 글꼴 적용
  const onFontSettingApplyClick = useCallback(async () => {
    if (!token) return;

    const { config } = await requestSecurePut<string>(
      apiRoute.admin.boardSet.font + boardId,
      {},
      {
        id: boardId,
        froomNumber: createFontSettingString(fontList?.froomNumber),
        froomKor: createFontSettingString(fontList?.froomKor),
        froomEng: createFontSettingString(fontList?.froomEng),
        fcenter: createFontSettingString(fontList?.fcenter),
        fdoctorKor: createFontSettingString(fontList?.fdoctorKor),
        fdoctorEng: createFontSettingString(fontList?.fdoctorEng),
        fadminKor: createFontSettingString(fontList?.fadminKor),
        fpatientName: createFontSettingString(fontList?.fpatientName),
        fnotice: createFontSettingString(fontList?.fnotice),
        fsub: createFontSettingString(fontList?.fsub),
      },
      token,
    );

    if (config.status === 200) {
      loadFontSetting();
    }
  }, [fontList, boardId, token]);

  const onFontSettingDefault = useCallback(async () => {
    if (!token) return;

    const { data, config } =
      await requestSecureGet<FontDefaultResponseItemTypes>(
        apiRoute.admin.boardSet.fontDefault + `?category=${category}`,
        {},
        token,
      );

    if (config.status === 200) {
      const { config: fontSettingConfig } = await requestSecurePut<string>(
        apiRoute.admin.boardSet.font + boardId,
        {},
        {
          id: boardId,
          froomNumber: createFontSettingString(data.fonts?.froomNumber),
          froomKor: createFontSettingString(data.fonts?.froomKor),
          froomEng: createFontSettingString(data.fonts?.froomEng),
          fcenter: createFontSettingString(data.fonts?.fcenter),
          fdoctorKor: createFontSettingString(data.fonts?.fdoctorKor),
          fdoctorEng: createFontSettingString(data.fonts?.fdoctorEng),
          fadminKor: createFontSettingString(data.fonts?.fadminKor),
          fpatientName: createFontSettingString(data.fonts?.fpatientName),
          fnotice: createFontSettingString(data.fonts?.fnotice),
          fsub: createFontSettingString(data.fonts?.fsub),
        },
        token,
      );

      if (fontSettingConfig.status === 200) {
        loadFontSetting();
      }
    }
  }, [token, category]);

  const onFontChange = useCallback(
    (fontId: string, fontValue: FontItemTypes) => {
      setFontList((fontList) => ({
        ...fontList,
        [fontId]: fontValue,
      }));
    },
    [fontList],
  );

  const loadFontCategory = useCallback(() => {
    setCategory(VIEWER_USE_OPTIONS[btypeName].font.default);
  }, [btypeName]);

  useEffect(() => {
    loadFontCategory();

    loadFontSetting();
  }, [boardId, token, selected]);

  return (
    <FontSetting
      btypeName={btypeName}
      fontList={fontList}
      onFontSettingApplyClick={onFontSettingApplyClick}
      onFontSettingResetClick={onFontSettingDefault}
      onFontChange={onFontChange}
    />
  );
};

export default FontSettingContainer;
