import styled from 'styled-components';

export const CTable = styled.div`
  table {
    width: 100%;
    border-collapse: collapse;
    tr {
      white-space: nowrap;
      .p-highlight {
        // background-color: #ced4da;
        color: #fff;
      }
    }
    th {
      border: 1px solid #fff;
      background-color: #636f83;
      color: #fff;
      font-size: 1rem;
      font-weight: 400;
      text-align: center;
      padding: 0;

      button {
        display: none;
      }

      .p-column-filter {
        width: 100%;
        line-height: 10px;
        padding: 5px;
        border: none;
        border-radius: 3px;

        input {
          height: 25px;
        }
      }
    }
    td {
      border: 1px solid lightgray;
      font-size: 0.9rem;
      font-weight: bold;
      text-align: center;
      button {
        margin: 3px;
        padding: 3px 10px;
        border: none;
        border-radius: 3px;
        font-size: 0.9rem;
        font-weight: 600;
      }
    }
  }

  .p-paginator-bottom {
    display: flex;
    align-items: center;
  }

  // 체크박스
  .p-checkbox-box {
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    width: 20px;
    height: 20px;
    margin: 0 auto;
    background: #ffffff;
    border: 2px solid #ced4da;
    border-radius: 3px;
    transition: background-color 0.2s, color 0.2s, border-color 0.2s,
      box-shadow 0.2s;

      .pi-check {
        color: #636f83;
        font-weight: bold;
      }
  }

  .p-datatable-emptymessage {
    line-height: 40px;
  }

  .p-paginator-bottom {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 1em;
  }

  .p-paginator-first,
  .p-paginator-prev,
  .p-paginator-next,
  .p-paginator-last {
    width: 30px;
    height: 30px;
    margin: 0 0.5rem;
    position: relative;
    background: none;
    border: 1px solid #ddd;
    border-radius: 5px;
    .p-button-label {
      display: none;
    }
  }

  .p-paginator-pages {
    .p-paginator-page {
      width: 30px;
      height: 30px;
      border: none;
      background: none;
      margin: 0 0.5rem;
    }
    .p-highlight {
      background-color: #ced4da;
      border-radius: 5px;
    }
  }
  @media (max-width: 1280px) {
    table {
      th {
        font-size: 0.9rem;
      }
      td {
        font-size: 0.7rem;
      }
    }
  }
`;
