import { CButton, CCard, CCardBody, CCardHeader, CTable } from '@coreui/react';
import { AdminBoardSettingVideoTypes } from '@typedef/components/Admin/BoardSetting/components/Video/admin.board.setting.type.video.types';
import VideoSettingStyle from '../../styles/Video/VideoSettingStyle';
import _ from 'lodash';

type Props = {
  videos: AdminBoardSettingVideoTypes[];
  setCurrentModal: React.Dispatch<React.SetStateAction<string | null>>;
};

const VideoSetting = ({ videos, setCurrentModal }: Props) => {
  return (
    <VideoSettingStyle>
      <CCard>
        <CCardHeader>
          <h5>동영상 설정</h5>
          <div className='btns'>
            <CButton color='info' onClick={() => setCurrentModal('video')}>
              입력
            </CButton>
          </div>
        </CCardHeader>
        <CCardBody>
          <CTable striped hover>
            <colgroup>
              <col width='15%' />
              <col width='85%' />
            </colgroup>
            <thead>
              <tr>
                <th>아이디</th>
                <th>파일명</th>
              </tr>
            </thead>
            <tbody>
              {_.uniq(videos, 'original').map((video) => (
                <tr key={video.id}>
                  <td>{video.id}</td>
                  <td>{video.original}</td>
                </tr>
              ))}
            </tbody>
          </CTable>
        </CCardBody>
      </CCard>
    </VideoSettingStyle>
  );
};

export default VideoSetting;
