import styled from 'styled-components';

type Props = {
  visible: boolean;
};

const PatientStyle = styled.div<Props>`
  width: 100%;
  padding: 0 1em;
  padding-top: 1em;
  .loading-spinner {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    position: fixed;
    top: 0;
    left: 13vw;
    right: 0;
    z-index: 10;
    .spinner-border {
      width: 5rem;
      height: 5rem;
    }
  }

  .patient-options {
    width: 25%;
    height: 4.5vh;
    position: absolute;
    top: 11px;
    right: 65px;
    z-index: 10;
    background-color: white;
    display: flex;
    justify-content: end;
    .total {
      width: 20%;
      font-size: 1rem;
      font-weight: bold;
      vertical-align: middle;
      margin: auto 0;
      background-color: white;
      margin-right: 0;
      text-align: center;
      span {
        color: #2eb85c;
      }
    }
    .search-option-select {
      width: 20%;
      height: 80%;
      margin: auto 0.25em;
      border-radius: 15px;
      font-weight: bold;
      font-size: 0.9rem;
      cursor: pointer;
    }
    .search-option-input {
      width: 40%;
      height: 80%;
      margin: auto 0.25em;
      display: flex;
      align-items: center;
      border: 1px solid #919191;
      border-radius: 15px;
      padding: 0 0.6em;

      &:active {
        border: 1px solid red;
      }
      input {
        width: 100%;
        outline: none;
        border: none;
        border-bottom: 1px solid black;
        padding: 0;
      }
      .btn {
        margin-left: auto;
        padding: 0;
        background-color: transparent;
        outline: none;
        border: none;
        svg {
          font-size: 1.4rem;
        }
      }
    }
    .search-result {
      background-color: #fff;
      position: fixed;
      top: 6vh;
      right: 0px;
      border: 1px solid black;
      ul {
        padding: 0 5px;
        list-style: none;
        margin-right: 10vw;
        margin-top: auto;
        margin-bottom: 0px;
        li {
          padding: 0;
          margin: 0;
          width: 100%;
          height: 100%;
        }
      }
    }
    .option-btns {
      margin: auto 0.25em;
      .btn {
        width: 40%;
        border: 1px solid transparent;
        text-align: center;
        vertical-align: middle;
        border-radius: 15px;
        color: black;
        font-size: 1rem;
        margin: auto 0.25em;
        padding: 0.25em 0.5em;
      }
    }
  }
  .single {
    padding: 0;
    z-index: 13;
    .single-col {
      padding: 0 0.25em;
    }
    .card {
      max-width: 100%;
      max-height: 100%;
      border-radius: 15px 15px 0 0;
      box-shadow: 2px 3px 8px 1px gray;
      margin-bottom: 0.35em;
      border: 1px solid gray;
      .card-header {
        padding: 0.25em;
        display: flex;
        align-items: center;
        border-radius: 15px 15px 0 0;
        background-color: white;
        .form-select {
          width: 15%;
          height: 3vh;
          margin-right: 0.5em;
          padding: 0 0.7em 0 0.7em;
          border-radius: 15px;
          font-size: 0.9rem;
          font-weight: bold;
          option {
            font-size: 0.8rem;
          }
        }
        .doctor-name,
        .admin-name,
        .patient-length {
          font-size: 1rem;
          margin-bottom: 0;
          margin: auto;
          font-weight: bold;
          span {
            color: #2eb85c;
          }
        }
        .refresh {
          border-radius: 15px;
          color: black;
          font-size: 1rem;
          margin-left: 0.5em;
          padding: 0.5em;
          display: flex;
          align-items: center;
          height: 3.5vh;
        }
      }
      .card-body {
        overflow-y: hidden;
        padding: 0;
        .drag-table-long {
          height: 86vh;
          overflow-y: auto;
        }
        .drag-table-shot {
          height: 20vh;
          overflow-y: auto;
        }
        .drag-table-done {
          height: 39vh;
          overflow-y: auto;
        }
        .patient-list-title {
          height: 3.3vh;
          border-radius: 14px 14px 0 0;
          font-size: 1rem;
          text-shadow: 0px 0px 18px gray;
          padding: 0.2em 0 0 0.5em;
          margin: 0;
          font-weight: bold;
          color: #fff;
          svg {
            margin-right: 0.5em;
          }
          span {
            float: right;
            padding-right: 1em;
          }
        }
        .drop-table {
          user-select: none;
          overflow-y: auto;
          height: 100%;
          font-family: 'Gothic A1';
          .table {
            margin-bottom: 0;
            border-collapse: collapse;
            tr {
              white-space: nowrap;
            }
            thead {
              position: sticky;
              top: 0;
              tr {
                background-color: #636f83;
                color: #fff;
                text-align: center;
                th {
                  border: 1px solid #fff;
                  border-collapse: collapse;
                  padding: 0.25em 0;
                  line-height: 1.5;
                  font-size: 0.8rem;
                  color: white;
                  font-weight: bold;
                }
              }
            }
            tbody {
              tr {
                cursor: pointer;
                width: 100%;
                td {
                  font-family: 'SCDream';
                  padding: 0;
                  vertical-align: middle;
                  font-size: 0.8rem;
                  font-weight: bold;
                  border: 1px solid lightgray;
                  text-align: center;
                  color: #000;
                  letter-spacing: 1px;
                  overflow: hidden;
                }
                &:hover {
                  background-color: lightgray;
                }
              }
            }
          }
        }
      }
    }
    .patient-list-absn {
      .patient-list-title {
        background-color: #f9b115;
      }
    }
    .patient-list-check {
      .patient-list-title {
        background-color: #3399ff;
      }
    }
    .patient-list-done {
      .patient-list-title {
        background-color: #2aa854;
      }
    }
  }
  .multi {
    max-width: 86.2vw;
    padding: 0;
    z-index: 13;
    overflow-x: auto;
    flex-wrap: nowrap;
    .multi-col {
      padding: 0 0.25em;
    }
  }
  @media (max-width: 1280px) {
    padding-top: 0.5em;
    .loading-spinner {
      left: 18vw;
    }
    .patient-options {
      width: 36.7%;
      .search-option-select {
        font-size: 0.8rem;
        padding: 0 0.3em;
      }
      .option-btns {
        width: 20%;
      }
    }
    .single {
      .card {
        .card-header {
          .form-select {
            width: 18%;
            padding: 0.15em 1em 0.15em 0.75em;
            font-size: 0.8rem;
          }
          .room-list-select {
            width: 25%;
          }
          .doctor-name,
          .admin-name,
          .patient-length {
            font-size: 0.8rem;
          }
          .refresh {
            font-size: 0.8rem;
            padding: 0.2em 0.5em;
            height: 3vh;
            margin: auto 0;
          }
        }
        .card-body {
          .drag-table-long {
            height: 87vh;
          }
          .drag-table-shot {
            height: 23vh;
          }
          .drag-table-done {
            height: 33.5vh;
          }
          .drop-table {
            .table {
              tbody {
                tr {
                  td {
                    font-size: 0.85rem;
                  }
                }
              }
            }
          }
        }
      }
    }
    .multi {
      max-width: ${(props) => (props.visible ? '83' : '100')}vw;
    }
  }
`;

export default PatientStyle;
