const DirectUrl = '/assets/images/';

const images = {
  doctorPlaceholder: `${DirectUrl}ic-doctor-profile-placeholder.jpg`,
  gnbTopLogo: `${DirectUrl}ic-gnb-top-logo.png`,
  loginBackground: `${DirectUrl}ic-login-bg.png`,
  loginBackgroundChilgok: `${DirectUrl}ic-login-bg-chilgok.png`,
  centerNamePlaceholder: `${DirectUrl}ic-monitor-center-name-placeholder.png`,
  viewerLogo: `${DirectUrl}ic-monitor-logo-name.png`,
  viewerLogoChilgok: `${DirectUrl}ic-monitor-logo-name-chilgok.png`,
  viewerBackground: `${DirectUrl}ic-monitor-bg.png`,
  iconPharmacy: `${DirectUrl}ic-monitor-pharmacy.png`,
  iconPharmacy2: `${DirectUrl}ic-monitor-pharmacy2.png`,
  iconSurgery: `${DirectUrl}ic-monitor-surgery.png`,
  iconSurgery2: `${DirectUrl}ic-monitor-surgery2.png`,
  iconHealthAll: `${DirectUrl}ic-monitor-health-all.png`,
  iconHealthLab: `${DirectUrl}ic-monitor-health-lab.png`,
  iconHealthOut: `${DirectUrl}ic-monitor-health-out.png`,
  iconLine1: `${DirectUrl}ic-monitor-line1.png`,
  iconLine2: `${DirectUrl}ic-monitor-line2.png`,
  iconLine3: `${DirectUrl}ic-monitor-line3.png`,
  iconLine4: `${DirectUrl}ic-monitor-line4.png`,
  iconList1Video: `${DirectUrl}ic-monitor-list1-video.png`,
  iconList2Video: `${DirectUrl}ic-monitor-list2-video.png`,
  iconListVideoList: `${DirectUrl}ic-monitor-list-video-list.png`,
  iconVideoList1: `${DirectUrl}ic-monitor-video-list1.png`,
  iconVideoList2: `${DirectUrl}ic-monitor-video-list2.png`,
  iconOutPatient: `${DirectUrl}ic-monitor-out-patient.png`,
  iconVideo: `${DirectUrl}ic-monitor-video.png`,
  iconVideoList3Endoscope: `${DirectUrl}ic-monitor-video-list3-endoscope.png`,
  iconQuad: `${DirectUrl}ic-monitor-quad.png`,
  jsolViewerLogo: `${DirectUrl}ic-monitor-logo-name-jsol.png`,
  jsolAdminLogo: `${DirectUrl}ic-monitor-center-name-placeholder-jsol-logo.png`,
};

export default images;
