import { CButton } from "@coreui/react";
import { TableButtonTypes } from "@typedef/components/Common/CTable/table.button.types";

type Props = {
  buttons: TableButtonTypes[];
};

const CTableButton = ({ buttons }: Props) => {
  return (
    <div>
      {buttons.map((button) => (
        <CButton
          id={String(button.id)}
          type="button"
          key={button.name}
          onClick={button.func}
          style={{ backgroundColor: button.color, margin: 0 }}
        >
          {button.name}
        </CButton>
      ))}
    </div>
  );
};

export default CTableButton;
