import * as Style from '../style/ExitPharmacyWaitingStyle';
import { PharmacyPatientItemTypes } from '@typedef/components/PharmacyWaiting/pharmacy.waiting.types';

type Props = {
  currentPharmacyList: PharmacyPatientItemTypes[];
};

const ExitPharmacyWaiting = ({ currentPharmacyList }: Props) => {
  return (
    <Style.ExitPharmacyWaitingStyle>
      <section className='subtitle'>퇴원환자 약 나왔습니다.</section>
      <Style.ExitTable>
        <Style.Column>
          {currentPharmacyList.map((data, index) =>
            index < 7 ? (
              <Style.Patient key={data?.drugno + '-' + index}>
                <p>{data.drugno}</p>
              </Style.Patient>
            ) : null,
          )}
        </Style.Column>
        <Style.Column>
          {currentPharmacyList.map((data, index) =>
            6 < index ? (
              <Style.Patient key={data?.drugno + '-' + index}>
                <p>{data.drugno}</p>
              </Style.Patient>
            ) : null,
          )}
        </Style.Column>
        <Style.Column>
          {currentPharmacyList.map((data, index) =>
            13 < index ? (
              <Style.Patient key={data?.drugno + '-' + index}>
                <p>{data.drugno}</p>
              </Style.Patient>
            ) : null,
          )}
        </Style.Column>
        <Style.Column>
          {currentPharmacyList.map((data, index) =>
            20 < index ? (
              <Style.Patient key={data?.drugno + '-' + index}>
                <p>{data.drugno}</p>
              </Style.Patient>
            ) : null,
          )}
        </Style.Column>
      </Style.ExitTable>
    </Style.ExitPharmacyWaitingStyle>
  );
};

export default ExitPharmacyWaiting;
