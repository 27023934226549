import styled from 'styled-components';

export const ExaminationPatientMiniWaitingStyle = styled.div`
  width: 100vw;
  height: 100vh;
  min-height: 120px;
  overflow: hidden;

  header {
    width: 100%;
    height: 8vh;
    line-height: 8vh;
    text-align: center;
    font-size: 1.2rem;
    font-weight: bold;
  }
`;

export const Main = styled.main`
  width: 100%;
  height: 92vh;
  display: flex;
  flex-direction: row;
`;

type Props ={
  width: 100 | 50
}

export const RoomList = styled.section<Props>`
  width: ${(props) => props.width}vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #0d0662;
  padding: 1vw;

  &:last-child {
    padding-left: 0;
  }
`;



export const PatientList = styled.ul`
  width: 100%;
  height: 100%;
  background-color: #fff;
  margin: 0;
  padding: 1vw;
  display: flex;
  flex-direction: column;
  border-radius: 3vh;
`

export const RoomInfo = styled.section`
flex: 1;
display: flex;
flex-direction: row;
justify-content: center;
align-items: center;
background-color: #0d0662;
margin-bottom: 1vw;
border-radius: 3vh;
p {
  margin: 0;
  color: red;
  font-size: 4vh;
  font-weight: bold;
}
`;

export const Patient = styled.li`
  flex: 1;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  background-color: lightgray;
  margin-bottom: 1vw;
  border-radius: 3vh;

  p {
    margin-bottom: 0;
    color: #000;
    font-size: 4vh;
    font-weight: bold;
  }

  .name {
    margin-left: 1vw;
  }

  &:nth-child(2n) {
    background-color: #88a2bc;
  }

  &:last-child {
    margin-bottom: 0
  }
`;

