import images from '@assets/images';
import styled from 'styled-components';

export const OutSinglePatientWaitingStyle = styled.div`
  width: 100%;
  height: 100%;
  flex: 1;
  display: flex;
  flex-direction: column;

  background: url(${images.viewerBackground}), linear-gradient(#231b7f, #530180);
  background-repeat: repeat-x;
  padding: 1px;
`;

type Props = {
  family?: string;
  fz?: number;
  fw?: boolean;
  color?: string;
  align?: string;
};

export const CustomPTag = styled.p<Props>`
  font-family: ${(props) => props.family};
  font-size: calc(${(props) => props.fz}vh / 20);
  font-weight: ${(props) => (props.fw ? 'bold' : 'normal')};
  color: ${(props) => props.color};
  text-align: ${(props) => props.align ?? 'left'};
`;

// 상단헤더(센터명, 진료실명, 룸번호)
export const Header = styled.header`
  width: 100%;
  height: 10vh;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 0.5vh 0.75vh;

  img {
    width: 12vh;
  }

  .left {
    display: flex;
    flex-direction: column;
  }
  .roomNum {
    width: calc(12vw - 4vh);
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    background-color: #fff;
    border-radius: 1vh;

    .clinicName {
      display: flex;
      align-items: center;
      justify-content: center;
      color: #000;
      font-weight: 600;
      font-size: 3vh;
      word-break: keep-all;
    }
  }
`;

// 본문 + 자막(subtitle)
export const Main = styled.section`
  flex: 1;
  display: flex;
  flex-direction: row;
  padding: 0 0.75vh;
`;

export const Clinic = styled.section`
  display: flex;
  background-color: #fff;
  border-radius: 1vh;
  padding: 1vh;
  flex-direction: column;
  margin-bottom: 0.5vh;

  .doctor {
    height: 17vh;
    display: flex;
    flex-direction: row;

    .imageBox {
      width: 6.5vw;
      height: 100%;
      z-index: 2;
      border-radius: 15px;
      box-shadow: 0 0 10px 5px gray;
      overflow: hidden;
      background-color: #fff;
      img {
        width: 100%;
        height: 100%;
        border-radius: 1vw;
        box-shadow: 0 0 10px 5px gray;
      }
    }

    .infoBox {
      width: 13.5vw;
      height: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      padding: 1vh 0;

      .doctorTitle {
        width: fit-content;
        background-color: #0d0662;
        padding: 0 1vw;
        width: 14vw;
        color: white;
        font-size: 2.5vh;
        font-weight: 600;
        border-radius: 0 1vh 1vh 0;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
  }

  .option {
    margin-top: 1vh;
    flex: 1;
    display: flex;
    flex-direction: row;

    .delayBox {
      width: 7.5vw;
      display: flex;
      flex-direction: column;
      margin-right: 0.5vw;
      background-color: #0d0662;
      border-radius: 1vh;
      font-weight: 600;

      span {
        color: white;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 0.5vh 0;
        font-size: 2.5vh;
      }

      p {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 100%;
        color: #fffca1;
        font-size: 3vh;
      }
    }

    .adminBox {
      width: 12vw;
      background-color: #0d0662;
      padding: 1vh;
      border-radius: 1vh;

      .admin {
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        background-color: #533d8a;
        border-radius: 1vh;

        span {
          color: white;
          font-size: 2vh;
          font-weight: 600;
        }
      }
    }
  }
`;

export const Patients = styled.section`
  flex: 1;
  padding-left: 1vh;
  display: flex;
  flex-direction: column;

  .selected {
    background-color: #3d428a;
    color: #fff;
  }

  .notArrive {
    background-color: #ddd;
    color: #444;
  }
`;

export const Patient = styled.div`
  flex: 1;
  height: 7vh;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 0.5vh;
  padding: 0 0.5vh;
  border-radius: 1vh;
  word-break: keep-all;

  .delayDescription {
    font-size: 2vh;
    font-weight: 600;
  }
`;
