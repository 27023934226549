import images from "@assets/images";
import styled from "styled-components";

const space = "2vw";

export const ExaminationEndoscopePatientWaitingStyle = styled.div`
  width: 100vw;
  height: 100vh;
  overflow: hidden;

  * {
    margin: 0;
  }

  .row {
    margin: 0;
  }
 
`;

type Props = {
  family?: string;
  fz?: number;
  fw?: boolean;
  color?: string;
  align?: string;
};

export const CustomPTag = styled.p<Props>`
  font-family: ${(props) => props.family};
  font-size: calc(${(props) => props.fz}vh / 11);
  font-weight: ${(props) => (props.fw ? "bold" : "normal")};
  color: ${(props) => props.color};
  text-align: ${(props) => props.align ?? "left"};
`;

// 상단헤더(로고, 진료실명)
export const Header = styled.header`
  width: 100%;
  height: 11vh;
  display: flex;
  flex-direction: row;
  overflow: hidden;

  // 로고, 삼각형
  .logoBox {
    height: 100%;
    display: flex;
    flex-direction: row;

    img {
      height: 100%;
    }

    .triangle {
      background-color: #fff;
      border-bottom: 11vh solid #000655;
      border-left: 11vh solid transparent;
    }
  }

  .titleBox {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 0 ${space};
    background: url(${images.viewerBackground}), #000655;
    background-repeat: repeat-x;

    .subtitle {
      font-size: 1vw;
      word-break: keep-all;
      text-align: left;
      color: white;
    }
  }
`;

export const Content = styled.section`
  height: calc(100vh - 11vh); // 100vh - 헤더
  display: flex;
  flex-direction: column;
`;

export const Row = styled.section`
  height: 100%;
  display: flex;
  flex-direction: row;
`;

// 비디오 또는 공지사항
export const VideoNotice = styled.section<{ bgc: string }>`
  width: 50vw;
  background-color: ${({ bgc }) => bgc};
`;

// 환자 리스트
export const List = styled.section<{ width?: number }>`
  width: ${({ width }) => (width ? width : 50)}vw;
  height: 100%;
  display: flex;
  background: linear-gradient(#000655, #530180);
`;

// 비디오
export const Video = styled.video`
  width: 100%;
  height: 100%;
  display: block;
  object-fit: fill;
  overflow: hidden;
  z-index: 5;
  box-shadow: 0 5px 15px gray;
`;

export const NoticePre = styled.div<{
  family?: string;
  fz?: number;
  fw?: boolean;
  color?: string;
  align?: string;
  valign?: string;
}>`
  font-family: ${(props) => props.family};
  font-size: calc(${(props) => props.fz}vh / 11);
  font-weight: ${(props) => (props.fw ? "bold" : "normal")};
  color: ${(props) => props.color};
  display: flex;
  height: 100%;
  justify-content: ${(props) =>
    props.align === "left"
      ? "start"
      : props.align === "right"
      ? "end"
      : props.align};
  align-items: ${(props) =>
    props.valign === "top"
      ? "start"
      : props.valign === "middle"
      ? "center"
      : "end"};
  overflow: hidden;
  word-break: break-all;
  white-space: pre-wrap;
`;
