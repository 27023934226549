import styled from 'styled-components';

export const Main = styled.main`
  flex: 1;
  padding: 1em;

  .card {
    box-shadow: 2px 3px 8px 1px gray;
    border-radius: 10px;
    margin-bottom: 0.5em;
  }

  .card-header {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    background-color: #5d5757;
    color: #fff;
    border-radius: 10px 10px 0 0;
    padding: 0.3em 1em;
    h5 {
      margin-bottom: 0;
    }
  }

  .btns {
    button {
      margin-left: 7px;
      color: white;
      a {
        color: white;
        text-decoration: none;
      }
    }
  }

  .header-body {
    display: flex;
    flex-direction: row;
  }

  .between {
    justify-content: space-between;
  }
  @media (max-width: 1280px) {
    .card-header {
      h5 {
        font-size: 1rem;
      }
    }
    .btns {
      button {
        padding: 0.15em 0.35em;
        font-size: 0.9rem;
      }
    }
  }
`;

// 헤더 설정 스타일
export const HeaderSetting = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  .manager-name {
    width: 100px;
  }

  .clinic {
    display: flex;
    flex-direction: row;
    .form-control {
      text-align: center;
      width: 40%;
    }
    .form-select {
      cursor: pointer;
    }
  }
`;

type Props = {
  width?: string;
};

export const Inputs = styled.div<Props>`
  width: ${(props) => props.width};
  display: flex;
  flex-direction: column;

  label {
    font-weight: 600;
    margin: 0;
  }
`;

// 전광판 타입 설정 스타일
export const BoardTypes = styled.section`
  margin-top: 1em;
  padding: 1em;
  border: 1px solid #ddd;
  border-radius: 10px;
  .title {
    color: #191919;
    font-size: 1.2rem;
    font-weight: 600;
  }

  .content {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 0.5em 1em;
  }
`;

export const BoardType = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  img {
    width: 130px;
    height: 125px;
    background-color: #4f5d73;
    border-radius: 5px;
    cursor: pointer;
  }
  .name {
    margin-top: 0.5em;
    color: #191919;
    font-size: 0.9rem;
    font-weight: 600;
  }
  .active {
    background-color: #321fdb;
  }
`;
