import useGNB from '@hooks/store/useGNB';
import useLogined from '@hooks/store/useLogined';
import { apiRoute, requestSecureGet } from '@libs/api';
import {
  AdminBoardSettingVideoTypes,
  VideoResponseItemTypes,
} from '@typedef/components/Admin/BoardSetting/components/Video/admin.board.setting.type.video.types';
import React, { useCallback, useEffect, useState, useContext } from 'react';
import VideoSetting from '../../components/Video/VideoSetting';
import { ReloadContext } from '../BoardSettingContainer';
type Props = {
  setCurrentModal: React.Dispatch<React.SetStateAction<string | null>>;
};

const VideoSettingContainer = ({ setCurrentModal }: Props) => {
  const { token } = useLogined();

  const { selected } = useGNB();

  const [videos, setVideos] = useState<AdminBoardSettingVideoTypes[]>([]);

  const reload = useContext(ReloadContext);

  const getVideos = useCallback(async () => {
    if (!token) return;

    const { data, config } = await requestSecureGet<VideoResponseItemTypes>(
      apiRoute.admin.boardSet.video + String(selected?.boardId),
      {},
      token,
    );

    if (config.status === 200) {
      setVideos(data.media);
    } else {
      setVideos([]);
    }
  }, [selected?.boardId]);

  useEffect(() => {
    getVideos();
  }, [reload, selected?.boardId]);

  return <VideoSetting videos={videos} setCurrentModal={setCurrentModal} />;
};

export default VideoSettingContainer;
