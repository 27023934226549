import images from '@assets/images';
import SubTitleContainer from '@components/SubTitle/containers/SubTitleContainer';
import { parseFontString } from '@libs/factory';
import {
  LoadBasicConfigResponseDataType,
  LoadFontConfigResponseDataType,
  LoadNoticeConfigResponseDataType,
  LoadVideoConfigItemTypes,
} from '@typedef/routes/viewer.navigation.types';
import ExaminationEndoscopePatientListWaitingContainer from './containers/ExaminationEndoscopePatientListWaitingContainer';

import * as S from './styles/ExaminationEndoscopePatientWaitingStyle';

type Props = {
  basic: LoadBasicConfigResponseDataType;
  font: LoadFontConfigResponseDataType;
  video: LoadVideoConfigItemTypes[] | null;
  notice: LoadNoticeConfigResponseDataType;
  videoIndex: number;
  setVideoIndex: React.Dispatch<React.SetStateAction<number>>;
};

const ExaminationEndoscopePatientWaiting = ({
  basic,
  font,
  video,
  notice,
  videoIndex,
  setVideoIndex,
}: Props) => {
  return (
    <S.ExaminationEndoscopePatientWaitingStyle>
      <S.Header>
        <section className='logoBox'>
          <img src={images.jsolViewerLogo} alt='logo' />
          <div className='triangle' />
        </section>
        <section className='titleBox'>
          <p className='subtitle'>
            &nbsp;지역과 함께한 백년, 세계로 나아갈 천년
            <br />
            환자중심의 병원으로 거듭나겠습니다.
          </p>
          <S.CustomPTag
            family={parseFontString(font.froomKor).family}
            fz={parseFontString(font.froomKor).size}
            fw={parseFontString(font.froomKor).bold}
            color={parseFontString(font.froomKor).color}
          >
            {basic?.bname} {/* 진료실이름 */}
          </S.CustomPTag>
        </section>
      </S.Header>
      <S.Content>
        <S.Row>
          <S.VideoNotice bgc={notice.display ? '#fff' : '#000'}>
            {notice.display ? (
              <S.NoticePre
                family={parseFontString(font.fnotice).family}
                fz={parseFontString(font.fnotice).size}
                fw={parseFontString(font.fnotice).bold}
                color={parseFontString(font.fnotice).color}
                align={notice.align ?? 'left'}
                valign={notice.valign ?? 'top'}
              >
                {notice?.contents}
              </S.NoticePre>
            ) : (
              <S.Video
                id='video'
                autoPlay
                muted
                loop={video ? video.length === 1 : false}
                src={video ? video[videoIndex]?.path : ''}
                onEnded={() => {
                  if (videoIndex === (video ? video.length : 0) - 1) {
                    setVideoIndex(0);
                  } else {
                    setVideoIndex(videoIndex + 1);
                  }
                }}
              />
            )}
          </S.VideoNotice>
          <S.List>
            <ExaminationEndoscopePatientListWaitingContainer />
          </S.List>
        </S.Row>
        <SubTitleContainer height='90vh' />
      </S.Content>
    </S.ExaminationEndoscopePatientWaitingStyle>
  );
};

export default ExaminationEndoscopePatientWaiting;
