import {
  CButton,
  CCard,
  CCardBody,
  CCardHeader,
  CCol,
  CRow,
} from '@coreui/react';
import { AdminBoardVisibleSettingTypes } from '@typedef/components/Admin/BoardSetting/admin.board.setting.types';
import { VIEWER_USE_OPTIONS } from '@typedef/libs/config.types';
import { visibleList } from '../../config/visible.setting.config';
import DelaySettingContainer from '../../containers/Delay/DelaySettingContainer';
import MaskingSettingContainer from '../../containers/Masking/MaskingSettingContainer';
import VisibleSettingStyle from '../../styles/Visible/VisibleSettingStyle';
import VisibleCheckBoxes from './VisibleSettingCheckBoxes';

type Props = {
  boardId: number;
  btypeName: string;
  visible: AdminBoardVisibleSettingTypes;
  visibleSettingApply: () => void;
  onVisibleCheckBoxHandler: (name: string) => void;
};

const VisibleSetting = ({
  boardId,
  btypeName,
  visible,
  visibleSettingApply,
  onVisibleCheckBoxHandler,
}: Props) => {
  return (
    <CRow>
      {VIEWER_USE_OPTIONS[btypeName]?.option?.includes('Visible') && (
        <CCol xs='7'>
          <VisibleSettingStyle>
            <CCard>
              <CCardHeader>
                <h5>Visible 설정</h5>
                <div className='btns'>
                  <CButton color='info' onClick={visibleSettingApply}>
                    적용
                  </CButton>
                </div>
              </CCardHeader>
              <CCardBody className='visiblesetting'>
                <ul>
                  {visibleList.map(
                    (visibles) =>
                      // 수술, 약국, 검진이 아닐때 보여주기.
                      // vmasking은 무조건 보여주기
                      (visibles.vistype === 'vmasking' ||
                        (!btypeName.includes('수술') &&
                          !btypeName.includes('약국') &&
                          !btypeName.includes('검진') &&
                          !(btypeName === '동영상 (대대합)'))) && (
                        // 예약환자 리스트는 동영상 컨텐츠가 visible일 때만 보임
                        <li id={visibles.vistype} key={visibles.vistype}>
                          <p>{visibles.content}</p>
                          <div className='setting'>
                            <VisibleCheckBoxes
                              id={visibles.vistype}
                              name={visibles.vistype}
                              checked={!!visible[visibles.vistype]}
                              onVisibleCheckBoxHandler={
                                onVisibleCheckBoxHandler
                              }
                            />
                          </div>
                        </li>
                      ),
                  )}
                </ul>
              </CCardBody>
            </CCard>
          </VisibleSettingStyle>
        </CCol>
      )}
      {(VIEWER_USE_OPTIONS[btypeName]?.option?.includes('Masking') ||
        VIEWER_USE_OPTIONS[btypeName]?.option?.includes('Delay')) && (
        <CCol xs='5'>
          {/* visible, masking, delay는 한 세트 */}
          {VIEWER_USE_OPTIONS[btypeName]?.option?.includes('Masking') && (
            <MaskingSettingContainer boardId={boardId} />
          )}
          {VIEWER_USE_OPTIONS[btypeName]?.option?.includes('Delay') && (
            <DelaySettingContainer boardId={boardId} />
          )}
        </CCol>
      )}
    </CRow>
  );
};

export default VisibleSetting;
