import { CFormCheck, CFormLabel } from '@coreui/react';

type Props = {
  id: string;
  name: string;
  checked: boolean;
  onVisibleCheckBoxHandler: (name) => void;
};

const VisibleCheckBoxes = ({
  id,
  name,
  onVisibleCheckBoxHandler,
  checked,
}: Props) => {
  return (
    <>
      <CFormCheck
        type='radio'
        id={`${id}_visible`}
        name={`${name}_visible`}
        checked={checked}
        onChange={(e) => {
          onVisibleCheckBoxHandler(e.target.name);
        }}
      />
      <CFormLabel htmlFor={`${id}_visible`}>보이기</CFormLabel>
      <CFormCheck
        type='radio'
        id={`${id}_invisible`}
        name={`${name}_invisible`}
        checked={!checked}
        onChange={(e) => {
          onVisibleCheckBoxHandler(e.target.name);
        }}
      />
      <CFormLabel htmlFor={`${id}_invisible`}>감추기</CFormLabel>
    </>
  );
};

export default VisibleCheckBoxes;
