import useViewer from '@hooks/store/useViewer';
import { alertMessage } from '@libs/alert';
import { apiRoute, requestPost } from '@libs/api';
import { getCurrentList } from '@libs/factory';
import { Surgery2PatientItemTypes } from '@typedef/components/SurgeryWaiting/surgery.waiting.types';
import { useCallback, useEffect, useMemo, useState } from 'react';
import SurgeryHorizontalWaiting from '../components/SurgeryHorizontalWaiting';

const TWO_PAGE_SIZE = 8;

const LOAD_PATIENT_LIST_INTERVAL = 5000;

// 수술실 타입2
const SurgeryHorizontalWaitingContainer = () => {
  const { basic, font, visible } = useViewer();

  // 대기중
  const [op30List, setOp30List] = useState<string[]>([]);

  // 수술중
  const [op40List, setOp40List] = useState<string[]>([]);

  // 퇴실
  const [op50List, setOp50List] = useState<string[]>([]);

  // 회복중
  const [op60List, setOp60List] = useState<string[]>([]);

  // 퇴실장소
  const [patposplcenmList, setPatposplcenmList] = useState<string[]>([]);

  const [intervalCounter, setIntervalCounter] = useState<number>(0);

  const [intervalCounterOp30, setIntervalCounterOp30] = useState<number>(0);

  const [intervalCounterOp40, setIntervalCounterOp40] = useState<number>(0);

  const [intervalCounterOp50, setIntervalCounterOp50] = useState<number>(0);

  const [intervalCounterOp60, setIntervalCounterOp60] = useState<number>(0);

  const [intervalCounterPat, setIntervalCounterPat] = useState<number>(0);

  // 대기중 환자들
  const surgery2WaitingPatient = useMemo(() => {
    return getCurrentList(op30List, TWO_PAGE_SIZE, intervalCounterOp30);
  }, [op30List, intervalCounterOp30]);

  // 수술중 환자들
  const surgery2OnGoingPatient = useMemo(() => {
    return getCurrentList(op40List, TWO_PAGE_SIZE, intervalCounterOp40);
  }, [op40List, intervalCounterOp40]);

  // 회복중 환자들
  const surgery2RecoveringPatient = useMemo(() => {
    return getCurrentList(op60List, TWO_PAGE_SIZE, intervalCounterOp50);
  }, [op50List, intervalCounterOp50]);

  // 퇴실 환자들
  const surgery2ExitPatient = useMemo(() => {
    return getCurrentList(op50List, TWO_PAGE_SIZE, intervalCounterOp60);
  }, [op60List, intervalCounterOp60]);

  // 퇴실 환자들 퇴실장소
  const surgery2ExitPlace = useMemo(() => {
    return getCurrentList(patposplcenmList, TWO_PAGE_SIZE, intervalCounterPat);
  }, [patposplcenmList, intervalCounterPat]);

  const loadSurgeryList = useCallback(async () => {
    if (!basic) {
      alertMessage('전광판 기본 정보를 불러오지 못했습니다!');
      return;
    }

    const { data, config } = await requestPost<Surgery2PatientItemTypes[]>(
      apiRoute.viewer.surgery.surgeryTwo,
      {},
      {
        boardId: basic.boardId,
        dscyn: basic.dscyn,
        roseete: basic.rosette,
      },
    );

    let loopOp30: string[] = [];
    let loopOp40: string[] = [];
    let loopOp50: string[] = [];
    let loopOp60: string[] = [];
    let loopPat: string[] = [];

    if (config.status === 200) {
      data.forEach((datas) => {
        // 대기중
        if (datas.op_30_hngnm) {
          loopOp30.push(datas?.op_30_hngnm ?? '');
        }
        // 수술중
        if (datas.op_40_hngnm) {
          loopOp40.push(datas?.op_40_hngnm ?? '');
        }
        // 회복중
        if (datas.op_60_hngnm) {
          loopOp60.push(datas?.op_60_hngnm ?? '');
        }
        // 퇴실
        if (datas.op_50_hngnm) {
          loopOp50.push(datas?.op_50_hngnm ?? '');
          // 퇴실장소
          if (datas.patposplcenm) {
            loopPat.push(datas?.patposplcenm ?? '');
          } else {
            loopPat.push('');
          }
        }
      });

      setOp30List(loopOp30);
      setOp40List(loopOp40);
      setOp50List(loopOp50);
      setOp60List(loopOp60);
      setPatposplcenmList(loopPat);
    } else {
      alertMessage('수술 환자 목록을 부르지 못했습니다.');
    }
  }, [basic]);

  useEffect(() => {
    loadSurgeryList();
  }, [basic, intervalCounter, loadSurgeryList]);

  useEffect(() => {
    const interval = setInterval(() => {
      setIntervalCounter((prev) => prev + 1);
      setIntervalCounterOp30((prev) => prev + 1);
      setIntervalCounterOp40((prev) => prev + 1);
      setIntervalCounterOp50((prev) => prev + 1);
      setIntervalCounterOp60((prev) => prev + 1);
      setIntervalCounterPat((prev) => prev + 1);
    }, LOAD_PATIENT_LIST_INTERVAL);

    return () => {
      clearInterval(interval);
    };
  }, []);

  return visible && font ? (
    <SurgeryHorizontalWaiting
      font={font}
      visible={visible}
      surgery2WaitingPatient={surgery2WaitingPatient}
      surgery2OnGoingPatient={surgery2OnGoingPatient}
      surgery2RecoveringPatient={surgery2RecoveringPatient}
      surgery2ExitPatient={surgery2ExitPatient}
      surgery2ExitPlace={surgery2ExitPlace}
    />
  ) : null;
};

export default SurgeryHorizontalWaitingContainer;
