import {
  PatientListItemTypes,
  DispFlagTypes,
} from './../typedef/components/Admin/Patient/admin.patient.types';
import {
  AMPMTypes,
  PatientTableTypes,
} from '@typedef/components/Admin/Patient/admin.patient.types';

import { MyBoardListItemTypes } from '@typedef/components/Login/login.types';
type FontDataType = {
  family: string;
  size: number | 0;
  bold: boolean;
  color: string;
};

export function parseFontString(fontString: string) {
  const fontArray = fontString.split(',');

  return {
    family: fontArray[0],
    size: parseInt(fontArray[1]),
    bold: fontArray[2] === 'true' ? true : false,
    color: fontArray[3],
  } as FontDataType;
}

export function getCurrentList<T>(list: T[], pageSize: number, intervalCounter: number) {
  if (pageSize <= 0) {
    return [] as T[];
  }

  const maxPage = Math.ceil(list.length / pageSize);

  const page = intervalCounter % maxPage;

  const start = pageSize * page;

  const end = start + pageSize;

  const result = list?.slice(start, end);

  const empty = Object.assign({}, result[0]);

  for (let key in empty) {
    delete empty[key];
  }

  while (result.length < pageSize) {
    result.push(empty);
  }

  return result;
}

export function getDroppableId(columnIdx: number, tableType: PatientTableTypes) {
  return `${columnIdx}-${tableType}`;
}

export function parseDroppableId(droppableId: string): [number, PatientTableTypes] {
  const res = droppableId.split('-');

  if (res.length !== 2) {
    throw new Error(
      'param droppableId of parseDroppableId function must have fotmat, `${columnIdx}-${tableType}`',
    );
  }

  return [parseInt(res[0]), res[1] as PatientTableTypes];
}

export function getDraggableId(
  columnIdx: number,
  tableType: PatientTableTypes,
  rowIdx: number,
  pid: string,
) {
  return `${columnIdx}-${tableType}-${rowIdx}-${pid}`;
}

export function parseDraggableId(draggableId: string): [number, PatientTableTypes, number, string] {
  const res = draggableId.split('-');

  if (res.length !== 4) {
    throw new Error(
      'param draggableId of parseDraggableId function must have fotmat, `${columnIdx}-${tableType}-${rowIdx}-${pid}`',
    );
  }

  return [parseInt(res[0]), res[1] as PatientTableTypes, parseInt(res[2]), res[3]];
}

export function getId(patient: string) {
  const [first, last] = patient.split('-');

  return [first, parseInt(last)];
}

export function reOrder(
  patientList: PatientListItemTypes[],
  startIndex: number,
  endIndex: number,
  dispflag: number,
  ampm: string,
) {
  let result = patientList
    .filter((patient) => patient.dispflag === dispflag)
    .filter((patient) => {
      if (patient.dispalltime === 'Y') return true;
      if (ampm === 'am') return parseInt(patient.ordtm) < 1230;
      if (ampm === 'pm') return parseInt(patient.ordtm) >= 1230;
      return true;
    })
    .map((patient) => patient.pid);

  const [pid] = result.splice(startIndex, 1);

  result.splice(endIndex, 0, pid);

  const List = patientList.map((patient) => {
    if (result.includes(patient.pid)) {
      patient.dispseq = result.findIndex((list) => patient.pid === list);
    }
    return patient;
  });

  return List;
}

export function move(
  patientList: PatientListItemTypes[],
  endIndex: number,
  startFlag: DispFlagTypes,
  endFlag: DispFlagTypes,
  ampm: string,
  patientPid: number,
) {
  // 부재, 검사에서 대기자로 옮길때 dispAllTime 할당
  const isDispAllTime = (startFlag === 8 || startFlag === 1) && endFlag === 0 && ampm !== 'all';

  //
  let startResult: PatientListItemTypes[] = patientList.filter(equalFlagFilter(startFlag));

  if (!isDispAllTime) {
    startResult = startResult.filter(ampmFilter(ampm));
  }

  let startResultPidList: number[] = startResult.map((patient) => patient.pid);

  let EndResult: PatientListItemTypes[] = patientList.filter(equalFlagFilter(endFlag));

  if (!isDispAllTime && endFlag === 0) {
    EndResult = EndResult.filter(ampmFilter(ampm));
  }

  let EndResultPidList: number[] = EndResult.map((patient) => {
    return patient.pid;
  });

  const putIndex = EndResultPidList[endIndex]
    ? EndResultPidList.findIndex((pid) => {
        return pid === EndResultPidList[endIndex];
      })
    : EndResultPidList.length;

  EndResultPidList.splice(putIndex, 0, patientPid);

  const List = patientList.map((patient) => {
    if (patient.pid === patientPid) {
      if (parseInt(patient.ordtm) < 1230 && ampm === 'am') {
        patient.dispalltime = 'N';
      } else if (parseInt(patient.ordtm) >= 1230 && ampm === 'pm') {
        patient.dispalltime = 'N';
      } else {
        patient.dispalltime = isDispAllTime ? 'Y' : 'N';
      }
    }

    if (startResultPidList.includes(patient.pid)) {
      patient.dispseq = startResultPidList.findIndex((pid) => patient.pid === pid);
    }
    if (EndResultPidList.includes(patient.pid)) {
      patient.dispseq = EndResultPidList.findIndex((pid) => patient.pid === pid);
    }
    if (patientPid === patient.pid) {
      patient.dispflag = endFlag;
    }
    return patient;
  });
  return List;
}

function equalFlagFilter(endFlag: number) {
  return (patient: PatientListItemTypes) => patient.dispflag === endFlag;
}

function ampmFilter(ampm: string) {
  return (patient: PatientListItemTypes) => {
    if (ampm === 'am') return parseInt(patient.ordtm) < 1230;
    if (ampm === 'pm') return parseInt(patient.ordtm) >= 1230;
    return true;
  };
}

export function getAddedList<T>(prev: T[], item: T, index?: number) {
  let clone = [...prev];

  if (index) {
    if (prev.length >= index) {
      clone.push(item);
    } else {
      clone = [...clone.slice(0, index), item, ...clone.slice(index)];
    }
  } else {
    clone.push(item);
  }

  return clone;
}

export function ordtmToAMPM(ordtm: string): AMPMTypes {
  const hour = parseInt(ordtm.slice(0, 2));

  return hour > 12 ? { label: '오후', type: 'pm' } : { label: '오전', type: 'am' };
}

export function parseCheckRoomorBoard(myBoardListItem: MyBoardListItemTypes | null) {
  if (myBoardListItem) {
    if (myBoardListItem.roomOrBoard) {
      return 'room-' + myBoardListItem.roomId;
    } else {
      return 'board-' + myBoardListItem.boardId;
    }
  } else {
    return;
  }
}
