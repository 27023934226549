import useBoardSet from '@hooks/store/useBoardSet';
import useGNB from '@hooks/store/useGNB';
import useLogined from '@hooks/store/useLogined';
import { apiRoute, requestSecureGet, requestSecurePut } from '@libs/api';
import { AdminBoardSettingNoticeTypes } from '@typedef/components/Admin/BoardSetting/admin.board.setting.types';
import { useCallback, useEffect, useState } from 'react';
import NoticeSetting from '../../components/Notice/NoticeSetting';

type Props = {
  boardId: number;
};

const NoticeSettingContainer = ({ boardId }: Props) => {
  // 로그인 토큰
  const { token } = useLogined();

  const { selected } = useGNB();

  const { noticeReload, __updateBoardSetNoticeFromHooks } = useBoardSet();

  // 전광판 공지 정보
  const [notice, setNotice] = useState<AdminBoardSettingNoticeTypes>({
    id: boardId,
    display: false,
    contents: '',
    align: 'center',
    valign: 'middle',
  });

  // 공지사항 적용
  const updateNotice = useCallback(async () => {
    if (!token) return;

    const { config } = await requestSecurePut(
      apiRoute.admin.boardSet.notice + boardId,
      {},
      {
        ...notice,
        id: boardId,
      },
      token,
    );

    if (config.status === 200) {
      __updateBoardSetNoticeFromHooks(noticeReload + 1);
    }
  }, [boardId, token, notice, noticeReload]);

  // 공지사항 데이터 조회
  const loadNotice = useCallback(async () => {
    if (!token) return;

    const { data, config } =
      await requestSecureGet<AdminBoardSettingNoticeTypes>(
        apiRoute.admin.boardSet.notice + boardId,
        {},
        token,
      );

    if (config.status === 200) {
      setNotice(data);
    }
  }, [boardId, token]);

  useEffect(() => {
    loadNotice();
  }, [boardId, token, selected, noticeReload]);

  return (
    <NoticeSetting
      notice={notice}
      setNotice={setNotice}
      updateNotice={updateNotice}
    />
  );
};

export default NoticeSettingContainer;
