import styled from 'styled-components';

export const ExitPharmacyWaitingStyle = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  .subtitle {
    height: 9vh;
    line-height: 9vh;
    background-color: #5a5490;
    color: #fff;
    font-size: 7vh;
    text-align: center;
  }
`;

export const ExitTable = styled.section`
  width: 100vw;
  height: 100%;
  display: flex;
  flex-direction: row;
`;

export const Column = styled.section`
  width: 25vw;
  height: 100%;
  background-color: yellow;
  display: flex;
  flex-direction: column;
  &:last-child {
    border-right: 2px solid black;
  }
`;

export const Patient = styled.div`
  flex: 1;
  align-items: center;
  justify-content: center;
  border-bottom: 2px solid black;
  border-left: 2px solid black;
  background-color: lightgray;
  position: relative;
  p {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: #000;
    font-size: 9vh;
  }

  &:nth-child(2n) {
    background-color: #88a2bc;
  }
  &:nth-child(n + 8) {
    // 한 줄에 7개까지만 보여줘야하기때문에 영역 제거
    display: none;
  }
`;
