import styled from 'styled-components';

const RoomListStyle = styled.div`
  .card {
    height: 26vh;
    .card-body {
      width: 100%;
      height: 100%;
      margin: 0 auto;
      overflow-y: auto;
      overflow-x: hidden;
      padding: 0 1em;

      /* 자막설정 내부 메인 */
      .roomlist {
        width: 100%;
        height: 100%;
        border-radius: 10px;

        .row {
          margin: 0;
          margin-top: 0.25em;

          .col-3 {
            padding: 0 0.5em;
            display: flex;
            align-items: center;
            justify-content: center;

            .btn {
              display: flex;
              align-items: center;
              justify-content: center;

              svg {
                font-size: 1.2rem;
              }
            }

            .form-control:disabled {
              background-color: #ddd;
            }
          }
        }
      }
    }
  }

  @media (min-width: 1280px) and (max-width: 1280px) {
    .card {
      height: 33vh;
      font-size: 0.8rem;
    }
  }
`;

export default RoomListStyle;
