import CTableContainer from '@components/common/CTable/containers/CTableContainer';
import { Main, Btns, Switch } from './styles/ClinicStyle';
import { CButton, CCard, CFormSelect, CFormSwitch, CCardBody, CFormLabel } from '@coreui/react';
import {
  AdminClinicModalItemTypes,
  AdminClinicTypes,
} from '@typedef/components/Admin/Clinic/admin.clinic.types';
import { TableColumnTypes } from '@typedef/components/Common/CTable/table.column.types';
import { RoleTypes } from '@typedef/common/common.types';

type Props = {
  columns: TableColumnTypes[];
  tableData: AdminClinicTypes[] | null;
  modal: {
    insert: JSX.Element;
    update: JSX.Element;
    delete: JSX.Element;
  };
  currentModal: AdminClinicModalItemTypes;
  onClickModalBtn: (type: AdminClinicModalItemTypes) => void;
  selectedRows: AdminClinicTypes[];
  setSelectedRows: React.Dispatch<React.SetStateAction<AdminClinicTypes[]>>;
  itemsPerPage: number;
  setItemsPerPage: React.Dispatch<React.SetStateAction<number>>;
  onChangeTab: (e: React.ChangeEvent<HTMLInputElement>) => void;
  isAll: boolean;
  role?: RoleTypes[];
};

const Clinic = ({
  columns,
  tableData,
  modal,
  currentModal,
  onClickModalBtn,
  selectedRows,
  setSelectedRows,
  itemsPerPage,
  setItemsPerPage,
  onChangeTab,
  isAll,
  role,
}: Props) => {
  return (
    <Main>
      <CCard>
        {currentModal && modal[currentModal]}
        <CCardBody>
          <Btns>
            <div className='left'>
              <CFormSelect
                name='row'
                onChange={(e) => setItemsPerPage(Number(e.target.value))}
                value={itemsPerPage}
              >
                <option value='15'>15개</option>
                <option value='30'>30개</option>
                <option value='45'>45개</option>
                <option value='100'>100개</option>
                <option value='99999'>All</option>
              </CFormSelect>
              {role && role.includes('ROLE_ROOT') ? (
                <>
                  <CButton type='button' color='success' onClick={() => onClickModalBtn('insert')}>
                    추가
                  </CButton>
                  <CButton type='button' color='danger' onClick={() => onClickModalBtn('delete')}>
                    삭제
                  </CButton>
                </>
              ) : null}
              <CButton type='button' color='info' onClick={() => onClickModalBtn('update')}>
                수정
              </CButton>
            </div>
            <Switch>
              <CFormLabel htmlFor='changeFilter'>마이 진료실</CFormLabel>
              <CFormSwitch color='dark' id='changeFilter' onChange={onChangeTab} checked={isAll} />
              <CFormLabel htmlFor='changeFilter'>전체 진료실</CFormLabel>
            </Switch>
          </Btns>
          <CTableContainer
            columns={columns}
            tableData={tableData}
            isCheckboxTable={true}
            selectedRows={selectedRows}
            setSelectedRows={setSelectedRows}
            itemsPerPage={itemsPerPage}
            isPaginator={true}
          />
        </CCardBody>
      </CCard>
    </Main>
  );
};

export default Clinic;
