import * as S from '../../styles/BoardSettingStyle';
import { AdminBoardSettingTypes } from '@typedef/components/Admin/BoardSetting/admin.board.setting.types';
import { CCol, CFormInput, CRow } from '@coreui/react';
import LabHeaderStyle from '../../styles/Header/LabHeaderStyle';

type Props = {
  boardInfo: AdminBoardSettingTypes;
  btypeName: string;
  onChangeBoardInfo: (id: string, name: string, value: string) => void;
};

const LabHeader = ({ boardInfo, btypeName, onChangeBoardInfo }: Props) => {
  return (
    <LabHeaderStyle>
      <CRow>
        <CCol xs='8'>
          <p>검사실 리스트[번호]|[검사실]</p>
          <CRow className='broomnumber-roomlist'>
            {(btypeName === '1열/영상/1열' || btypeName.includes('2열')) && (
              <>
                <S.Inputs width='49%'>
                  <CRow>
                    <CFormInput
                      placeholder='0'
                      value={
                        boardInfo.broomNumber ? boardInfo.broomNumber[0] : ''
                      }
                      onChange={(e) => {
                        onChangeBoardInfo(
                          'broomNumber0',
                          'broomNumber',
                          e.target.value,
                        );
                      }}
                    />
                    <CFormInput
                      placeholder='검사실 코드 입력'
                      value={
                        boardInfo.roomList ? boardInfo.roomList[0] ?? '' : ''
                      }
                      onChange={(e) => {
                        onChangeBoardInfo(
                          'roomList0',
                          'roomList',
                          e.target.value,
                        );
                      }}
                    />
                  </CRow>
                </S.Inputs>

                <S.Inputs width='49%'>
                  <CRow>
                    <CFormInput
                      placeholder='0'
                      value={
                        boardInfo.broomNumber ? boardInfo.broomNumber[1] : ''
                      }
                      onChange={(e) => {
                        onChangeBoardInfo(
                          'broomNumber1',
                          'broomNumber',
                          e.target.value,
                        );
                      }}
                    />
                    <CFormInput
                      placeholder='검사실 코드 입력'
                      value={
                        boardInfo.roomList ? boardInfo.roomList[1] ?? '' : ''
                      }
                      onChange={(e) => {
                        onChangeBoardInfo(
                          'roomList1',
                          'roomList',
                          e.target.value,
                        );
                      }}
                    />
                  </CRow>
                </S.Inputs>
              </>
            )}
            {(btypeName === '1열/영상' || btypeName === '영상/1열') && (
              <S.Inputs width='99%'>
                <CRow>
                  <CFormInput
                    placeholder='0'
                    value={
                      boardInfo.broomNumber ? boardInfo.broomNumber[0] : ''
                    }
                    onChange={(e) => {
                      onChangeBoardInfo(
                        'broomNumber0',
                        'broomNumber',
                        e.target.value,
                      );
                    }}
                  />

                  <CFormInput
                    placeholder='검사실 코드 입력'
                    value={
                      boardInfo.roomList ? boardInfo.roomList[0] ?? '' : ''
                    }
                    onChange={(e) => {
                      onChangeBoardInfo(
                        'roomList0',
                        'roomList',
                        e.target.value,
                      );
                    }}
                  />
                </CRow>
              </S.Inputs>
            )}
            {btypeName === '내시경' && (
              <S.Inputs width='99%'>
                <CRow>
                  <CFormInput
                    placeholder='검사실 코드 입력'
                    value={
                      boardInfo.roomList ? boardInfo.roomList[0] ?? '' : ''
                    }
                    onChange={(e) => {
                      onChangeBoardInfo(
                        'roomList0',
                        'roomList',
                        e.target.value,
                      );
                    }}
                  />
                </CRow>
              </S.Inputs>
            )}
          </CRow>
        </CCol>
        <CCol xs='2'>
          <p>검사실 명칭</p>
          <CFormInput
            placeholder='검사실 명칭 입력'
            value={boardInfo.bname === '-' ? '' : boardInfo.bname}
            onChange={(e) => {
              onChangeBoardInfo('bname', 'bname', e.target.value);
            }}
            id='bname'
          />
        </CCol>
      </CRow>
    </LabHeaderStyle>
  );
};

export default LabHeader;
