import { LoadVideoConfigItemTypes } from './../../typedef/routes/viewer.navigation.types';
import {
  LoadBasicConfigResponseDataType,
  LoadFontConfigResponseDataType,
  LoadNoticeConfigResponseDataType,
  LoadSubtitleConfigResponseDataType,
  LoadVisibleConfigResponseDataType,
} from '@typedef/routes/viewer.navigation.types';
import {
  UPDATE_BASIC_CONFIG_ACTION,
  UPDATE_FONT_CONFIG_ACTION,
  UPDATE_NOTICE_CONFIG_ACTION,
  UPDATE_SUBTITLE_CONFIG_ACTION,
  UPDATE_VIDEO_CONFIG_ACTION,
  UPDATE_VISIBLE_CONFIG_ACTION,
} from './modules/actionTypes';

export const updateBasicConfigAction = (
  diff: LoadBasicConfigResponseDataType,
) => ({
  type: UPDATE_BASIC_CONFIG_ACTION,
  payload: diff,
});

export const updateFontConfigAction = (
  diff: LoadFontConfigResponseDataType,
) => ({
  type: UPDATE_FONT_CONFIG_ACTION,
  payload: diff,
});

export const updateNoticeConfigAction = (
  diff: LoadNoticeConfigResponseDataType,
) => ({
  type: UPDATE_NOTICE_CONFIG_ACTION,
  payload: diff,
});

export const updateSubConfigAction = (
  diff: LoadSubtitleConfigResponseDataType,
) => ({
  type: UPDATE_SUBTITLE_CONFIG_ACTION,
  payload: diff,
});

export const updateVideoConfigAction = (
  diff: LoadVideoConfigItemTypes[],
) => ({
  type: UPDATE_VIDEO_CONFIG_ACTION,
  payload: diff,
});

export const updateVisibleConfigAction = (
  diff: LoadVisibleConfigResponseDataType,
) => ({
  type: UPDATE_VISIBLE_CONFIG_ACTION,
  payload: diff,
});

export type ViewerActionTypes =
  | ReturnType<typeof updateBasicConfigAction>
  | ReturnType<typeof updateFontConfigAction>
  | ReturnType<typeof updateNoticeConfigAction>
  | ReturnType<typeof updateSubConfigAction>
  | ReturnType<typeof updateVideoConfigAction>
  | ReturnType<typeof updateVisibleConfigAction>;
