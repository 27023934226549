import images from '@assets/images';
import SubTitleContainer from '@components/SubTitle/containers/SubTitleContainer';
import { CSpinner } from '@coreui/react';
import { parseFontString } from '@libs/factory';
import {
  LoadBasicConfigResponseDataType,
  LoadFontConfigResponseDataType,
} from '@typedef/routes/viewer.navigation.types';
import OutSinglePatientWaitingContainer from './containers/OutSinglePatientWaitingContainer';
import * as S from './styles/OutQuadPatientWaitingStyle';

type Props = {
  basic: LoadBasicConfigResponseDataType;
  font: LoadFontConfigResponseDataType;
  roomList: string[];
};

const OutQuadPatientWaiting = ({ basic, font, roomList }: Props) => {
  return (
    <S.OutQuadPatientWaitingStyle>
      <S.Header>
        <section className='logoBox'>
          <img src={images.jsolViewerLogo} alt='logo' />
          <div className='triangle' />
        </section>
        <section className='titleBox'>
          <p className='subtitle'>
            &nbsp;지역과 함께한 백년, 세계로 나아갈 천년
            <br />
            환자중심의 병원으로 거듭나겠습니다.
          </p>
          <S.CustomPTag
            family={parseFontString(font.froomKor).family}
            fz={parseFontString(font.froomKor).size}
            fw={parseFontString(font.froomKor).bold}
            color={parseFontString(font.froomKor).color}
          >
            {basic?.bname} {/* 진료실이름 */}
          </S.CustomPTag>
        </section>
      </S.Header>
      <S.Main>
        <S.Contents>
          {roomList.map((room, i) => (
            <S.Content>
              <OutSinglePatientWaitingContainer roomId={room ?? '0'} key={room ?? i} />
            </S.Content>
          ))}
        </S.Contents>
        <SubTitleContainer height='90vh' />
      </S.Main>
    </S.OutQuadPatientWaitingStyle>
  );
};

export default OutQuadPatientWaiting;
