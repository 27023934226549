import LargeModal from '@components/Admin/BoardSetting/components/Header/Modal/LargeModal';
import useGNB from '@hooks/store/useGNB';
import useLogined from '@hooks/store/useLogined';
import { apiRoute, requestSecureGet, requestSecurePut } from '@libs/api';
import {
  RoomListInfoDataItemTypes,
  RoomListItemTypes,
  RoomListResponseItemTypes,
} from '@typedef/components/Admin/BoardSetting/components/RoomList/admin.board.setting.type.room.list.types';
import { AdminClinicTypes } from '@typedef/components/Admin/Clinic/admin.clinic.types';
import { AdminDeptTypes } from '@typedef/components/Admin/Clinic/components/admin.dept.types';
import React, { useCallback, useEffect, useState } from 'react';

type Props = {
  boardId?: number | null;
  setCurrentModal: React.Dispatch<React.SetStateAction<string | null>>;
  setReload: React.Dispatch<React.SetStateAction<number>>;
};

const LargeModalContainer = ({
  boardId,
  setCurrentModal,
  setReload,
}: Props) => {
  const { token } = useLogined();

  const { selected } = useGNB();

  const [roomList, setRoomList] = useState<RoomListItemTypes[]>([]);

  const [clinicList, setClinicList] = useState<AdminClinicTypes[]>([]);

  const [deptList, setDeptList] = useState<AdminDeptTypes[]>([]);

  const [infoData, setInfoData] = useState<RoomListInfoDataItemTypes>({
    adminName: '',
    bname: '',
    bnameEng: '',
    boardCode: '',
    broomNumber: '',
    roomList: '',
    btypeName: '',
    bcentName: '',
    subtitle: '',
    id: 0,
    ip: '',
    mac: '',
    dscyn: '',
    rosette: '',
    topBoardIdx: '',
  });

  // 진료실 추가
  const onRoomListAdd = useCallback(() => {
    setRoomList([
      ...roomList,
      {
        id: roomList.length + 2,
        roomId: 0,
        broomNumber: null,
        roomCode: null,
        doctorKor: null,
      },
    ]);
  }, [roomList]);

  // 진료실 삭제
  const onRoomListRemove = useCallback(
    (id: number) => {
      setRoomList(roomList.filter((data) => data.id !== id));
    },
    [roomList],
  );

  // 진료실 설정 적용
  const onRoomListApply = useCallback(async () => {
    const roomNumberList = roomList.map((room) => {
      return room.broomNumber ?? '';
    });

    const roomCodeList = roomList.map((room) => {
      return room.roomId ?? '0';
    });

    if (!token) return;

    const { config } = await requestSecurePut<string>(
      apiRoute.admin.boardSet.info + boardId,
      {},
      {
        ...infoData[0],
        id: boardId,
        broomNumber: roomNumberList.join(),
        roomList: roomCodeList.join(),
      },
      token,
    );

    if (config.status === 200) {
      loadRoomList();

      loadBoardRoomList();

      loadInfoData();

      setCurrentModal(null);

      setReload((prev) => prev + 1);
    }
  }, [infoData, boardId, token, roomList]);

  // 룸번호 변경값 저장
  const onRoomListNumberChange = useCallback(
    (id: number, value: string) => {
      setRoomList(
        roomList.map((room) => {
          if (room.id === id) {
            return {
              ...room,
              broomNumber: value,
            };
          } else {
            return room;
          }
        }),
      );
    },
    [roomList],
  );

  // 룸리스트 코드 변경값 저장
  const onRoomListCodeChange = useCallback(
    (roomId: number, roomCode: string) => {
      clinicList.map((boardRoom) => {
        if (roomCode === '') {
          setRoomList(
            roomList.map((room) => {
              if (room.id === roomId) {
                return {
                  ...room,
                  roomId: 0,
                  roomCode: '',
                  doctorKor: '',
                };
              } else {
                return room;
              }
            }),
          );
        } else if (boardRoom.roomCode === roomCode) {
          setRoomList(
            roomList.map((room) => {
              if (room.id === roomId) {
                return {
                  ...room,
                  roomId: Number(boardRoom.id),
                  roomCode: boardRoom.roomCode,
                  doctorKor: boardRoom.doctorKor,
                };
              } else {
                return room;
              }
            }),
          );
        }
      });
    },
    [clinicList, roomList],
  );

  // 진료과 선택시 진료실 자동 추가
  const onDepartmentSelect = useCallback(
    (department: string) => {
      const departmentClinicList = clinicList
        .filter((clinic) => clinic.deptKor === department)
        .sort((a, b) => {
          if (b.roomCode > a.roomCode) return -1;
          else if (a.roomCode > b.roomCode) return 1;
          else return 0;
        });

      departmentClinicList.map((clinic, index) => {
        setRoomList((prev) => {
          const clone = [...prev];

          clone.push({
            id: roomList.length + 2 + index,
            roomId: Number(clinic.id),
            roomCode: clinic.roomCode,
            doctorKor: clinic.doctorKor,
            broomNumber: '',
          });

          return clone;
        });
      });
    },
    [clinicList, roomList],
  );

  // 진료실 정보불러오기
  const loadRoomList = useCallback(async () => {
    if (!token) {
      return;
    }

    const { data, config } = await requestSecureGet<
      RoomListResponseItemTypes[]
    >(apiRoute.admin.boardSet.getRoomList + boardId, {}, token);

    if (config.status === 200) {
      setRoomList([]);
      data.map((item, index) => {
        setRoomList((prev) => {
          const clone = [...prev];

          clone.push({
            id: index + 1,
            ...item,
          });

          return clone;
        });
      });
    }
  }, [boardId, token]);

  // 전광판에 할당된 진료실 불러오기
  const loadBoardRoomList = useCallback(async () => {
    if (!token) {
      return;
    }

    const { data, config } = await requestSecureGet<AdminClinicTypes[]>(
      apiRoute.admin.clinic.getClinics,
      {},
      token,
    );

    setClinicList([]);

    if (config.status === 200) {
      setClinicList(data);
    }
  }, [boardId, token]);

  // 진료과 불러오기
  const loadDepartment = useCallback(async () => {
    if (!token) return;

    const { data, config } = await requestSecureGet<AdminDeptTypes[]>(
      apiRoute.admin.clinic.getDepts,
      {},
      token,
    );
    if (config.status === 200) {
      setDeptList(data);
    }
  }, [token]);

  // 전광판 정보 불러오기
  const loadInfoData = useCallback(async () => {
    if (!token) {
      return;
    }

    const { data, config } = await requestSecureGet<RoomListInfoDataItemTypes>(
      apiRoute.admin.boardSet.info + boardId,
      {},
      token,
    );

    if (config.status === 200) {
      setInfoData(data);
    }
  }, [boardId, token]);

  useEffect(() => {
    loadRoomList();

    loadBoardRoomList();

    loadDepartment();

    loadInfoData();
  }, [boardId, token, selected]);

  return (
    <LargeModal
      setCurrentModal={setCurrentModal}
      roomList={roomList}
      clinicList={clinicList}
      deptList={deptList}
      onDepartmentSelect={onDepartmentSelect}
      onRoomListAdd={onRoomListAdd}
      onRoomListRemove={onRoomListRemove}
      onRoomListApply={onRoomListApply}
      onRoomListNumberChange={onRoomListNumberChange}
      onRoomListCodeChange={onRoomListCodeChange}
    />
  );
};

export default LargeModalContainer;
