import ClinicAdd from '../components/ClinicAdd';
import React, { useState, useEffect, useCallback, SetStateAction } from 'react';
import { apiRoute, requestSecureGet, requestSecurePost } from '@libs/api';
import useLogined from '@hooks/store/useLogined';
import { AdminClinicInputsType } from '@typedef/components/Admin/Clinic/components/admin.clinic.inputs.type';
import { AdminDeptTypes } from '@typedef/components/Admin/Clinic/components/admin.dept.types';
import { AdminCenterTypes } from '@typedef/components/Admin/Clinic/components/admin.center.types';
import { AdminDoctorTypes } from '@typedef/components/Admin/Clinic/components/admin.doctor.types';
import { AdminBoardTypes } from '@typedef/components/Admin/Clinic/components/admin.board.types';
import { AdminClinicModalItemTypes } from '@typedef/components/Admin/Clinic/admin.clinic.types';

type Props = {
  setCurrentModal: React.Dispatch<React.SetStateAction<AdminClinicModalItemTypes>>;
  reload: number;
  setReload: React.Dispatch<SetStateAction<number>>;
};

const ClinicAddContainer = ({ setCurrentModal, reload, setReload }: Props) => {
  const { token } = useLogined();

  const [inputs, setInputs] = useState<AdminClinicInputsType>({
    boardIdx: 0,
    boardCode: '',
    centerKor: '',
    customCenter: '',
    deptId: null,
    deptKor: '',
    roomCode: '',
  });

  const [deptList, setDeptList] = useState<AdminDeptTypes[] | null>(null);

  const [centerlist, setCenterList] = useState<AdminCenterTypes[] | null>(null);

  const [doctorList, setDoctorList] = useState<AdminDoctorTypes[] | null>(null);

  const [boardList, setBoardList] = useState<AdminBoardTypes[]>([]);

  const [searchBoardList, setSearchBoardList] = useState<AdminBoardTypes[] | null>(null);
  const [validRoomCode, setValidRoomCode] = useState<string>('');

  // 진료실이름 입력 확인
  const onChangeRoomCode = useCallback((value: string) => {
    setValidRoomCode('');
    setInputs((inputs) => ({ ...inputs, roomCode: value }));
  }, []);

  // 의사 조회
  // deptId 필수, centerId 없을 경우 '-'
  const getDoctorData = useCallback(
    async (deptId: string, centerId: string) => {
      if (!token) return;

      const { config, data } = await requestSecurePost<AdminDoctorTypes[]>(
        apiRoute.admin.clinic.postDoctors,
        {},
        {
          deptcd: deptId,
          // centcd : centerId 당장 필요없지만 추후 사용 가능성 있음.
          centcd: centerId,
        },
        token,
      );
      if (config.status >= 200 && config.status < 400) {
        setDoctorList(data);
      }
    },
    [token],
  );

  // 진료과, 센터, 담당의사
  const onChangeSelect = useCallback(
    (e: React.ChangeEvent<HTMLSelectElement>) => {
      const { name, value } = e.target;

      // 진료과
      if (name === 'dept') {
        if (!!!value) {
          setInputs((inputs) => ({
            ...inputs,
            deptKor: '-',
            deptEng: '-',
          }));
          setDoctorList(null);
          return;
        }
        deptList?.forEach((dept) => {
          if (dept.deptCd === value) {
            setInputs((inputs) => ({
              ...inputs,
              deptKor: dept.deptHngNm,
              deptEng: dept.deptEngNm,
            }));
          }
        });
      }
      // 센터
      if (name === 'center') {
        if (!!!value) {
          setInputs((inputs) => ({
            ...inputs,
            centerKor: '',
            centerEng: '',
          }));
          return;
        }
        centerlist?.forEach((center) => {
          if (center.deptCd === value) {
            setInputs((inputs) => ({
              ...inputs,
              centerKor: center.deptHngNm,
              centerEng: center.deptEngNm,
            }));
          }
        });
      }
      // 담당의사
      if (name === 'doctor') {
        if (value === '휴진') {
          setInputs((inputs) => ({
            ...inputs,
            doctorId: '-',
            doctorKor: '휴진',
          }));
        } else {
          doctorList?.forEach((doctor) => {
            if (doctor.userid === value) {
              setInputs((inputs) => ({
                ...inputs,
                doctorId: doctor.userid,
                doctorKor: doctor.usernm,
              }));
            }
          });
        }
      }
    },
    [deptList, getDoctorData, inputs, centerlist, doctorList],
  );

  const roomCodeCheck = useCallback(async () => {
    if (!token) return;

    const { data, config } = await requestSecureGet<boolean>(
      apiRoute.admin.clinic.validClinic + inputs.roomCode,
      {},
      token,
    );

    if (config.status === 200) {
      //true : 중복데이터있음, false: 중복데이터없음
      if (data) {
        alert('이미 존재하는 진료실 이름입니다.');
      } else {
        setValidRoomCode(inputs.roomCode);
        alert('사용 가능한 진료실 이름입니다.');
      }
    }
  }, [inputs.roomCode, token]);

  // 전광판 검색, 선택
  const onChangeboard = useCallback(
    (value: string) => {
      boardList.forEach((board) => {
        if (board.boardCode === value) {
          setInputs({
            ...inputs,
            boardIdx: Number(board.id),
          });
        }
      });
    },
    [boardList, inputs],
  );

  // 진료실 등록
  const insert = useCallback(async () => {
    if (!!!validRoomCode) {
      alert('진료실이름을 입력해 주세요.');
      return;
    }

    const data = {
      boardIdx: inputs.boardIdx ?? 0,
      centerKor: inputs.centerKor ?? '-',
      customCenter: inputs.customCenter ?? '-',
      deptEng: inputs.deptEng ?? '-',
      deptId: inputs.deptId ?? null,
      deptKor: inputs.deptKor ?? '-',
      doctorId: inputs.doctorId ?? null,
      doctorKor: inputs.doctorKor ?? '휴진',
      id: inputs.id,
      roomCode: validRoomCode,
    };

    if (!token) return;

    const { config } = await requestSecurePost(apiRoute.admin.clinic.postClinic, {}, data, token);

    if (config.status === 200) {
      setCurrentModal(null);

      setReload(reload + 1);
    }
  }, [inputs, validRoomCode, token, setCurrentModal, setReload, reload]);

  // 진료과 조회
  const getDeptData = useCallback(async () => {
    if (!token) return;

    const { config, data } = await requestSecureGet<AdminDeptTypes[]>(
      apiRoute.admin.clinic.getDepts,
      {},
      token,
    );
    if (config.status >= 200 && config.status < 400) {
      setDeptList(data);
    }
  }, [token]);

  // 센터 조회
  const getCenterData = useCallback(async () => {
    if (!token) return;

    const { config, data } = await requestSecureGet<AdminCenterTypes[]>(
      apiRoute.admin.clinic.getCenters,
      {},
      token,
    );
    if (config.status >= 200 && config.status < 400) {
      setCenterList(data);
    }
  }, [token]);

  // 전광판 조회
  const getBoardData = useCallback(async () => {
    if (!token) return;

    const { config, data } = await requestSecureGet<AdminBoardTypes[]>(
      apiRoute.admin.clinic.getBoards,
      {},
      token,
    );
    if (config.status >= 200 && config.status < 400) {
      setBoardList(data);
    }
  }, [token]);

  useEffect(() => {
    getDeptData();
    getCenterData();
    getBoardData();
  }, []);

  return (
    <ClinicAdd
      setCurrentModal={setCurrentModal}
      inputs={inputs}
      onChangeRoomCode={onChangeRoomCode}
      // onChangeCustomCenter={onChangeCustomCenter}
      onChangeSelect={onChangeSelect}
      onChangeboard={onChangeboard}
      deptList={deptList}
      centerlist={centerlist}
      doctorList={doctorList}
      boardList={boardList}
      searchBoardList={searchBoardList}
      save={insert}
      roomCodeCheck={roomCodeCheck}
    />
  );
};

export default ClinicAddContainer;
