import useViewer from "@hooks/store/useViewer";
import { alertMessage } from "@libs/alert";
import { apiRoute, requestGet } from "@libs/api";
import { getCurrentList } from "@libs/factory";
import { OutPatientItemTypes } from "@typedef/components/OutPatientWaiting/out.patient.types";
import { useCallback, useEffect, useMemo, useState } from "react";
import OutPatientMiniWaiting from "../components/OutPatientMiniWaiting";

const LOAD_PATIENT_LIST_INTERVAL = 3000;

const PAGE_SIZE = 10;

const OutPatientMiniWaitingContainer = () => {
  const { basic } = useViewer();

  const [outPatient, setOutPatient] = useState<OutPatientItemTypes[]>([]);

  const [intervalCounter, setIntervalCounter] = useState<number>(0);

  const currentOutPatient = useMemo(() => {
    return getCurrentList(outPatient ?? [], PAGE_SIZE, intervalCounter);
  }, [outPatient, intervalCounter]);

  const loadOutPatientList = useCallback(async () => {
    if (!basic?.roomList) {
      alertMessage("전광판 기본 정보를 불러오지 못했습니다!");
      return;
    }

    const { data, config } = await requestGet<OutPatientItemTypes[]>(
      apiRoute.viewer.outPatient.loadWaiterList + basic?.roomList[0],
      {}
    );

    if (config.status === 200) {
      if (data[basic.roomList[0]]) {
        setOutPatient(data[basic.roomList[0]]?.slice(0, 10));
      } else {
        setOutPatient([]);
      }
    } else {
      setOutPatient([]);
    }
  }, [basic]);

  useEffect(() => {
    loadOutPatientList();
  }, [intervalCounter, basic, loadOutPatientList]);

  useEffect(() => {
    const interval = setInterval(() => {
      setIntervalCounter((prev) => prev + 1);
    }, LOAD_PATIENT_LIST_INTERVAL);
    return () => {
      clearInterval(interval);
    };
  }, []);

  return basic ? (
    <OutPatientMiniWaiting
      basic={basic}
      currentOutPatient={currentOutPatient}
    />
  ) : null;
};

export default OutPatientMiniWaitingContainer;
