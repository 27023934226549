import styled from 'styled-components';

const VideoModalSettingStyle = styled.div`
  .modal-header {
    color: white;
  }
  .modal-body {
    padding: 0.25em 1em;
    .videolist {
      .header {
        .Search {
          margin: 0 auto;
          display: flex;
          align-items: center;
          border: 1px solid #919191;
          border-radius: 50px;
          padding: 0.5em 1em;
          width: 50%;
          input {
            width: 100%;
            outline: none;
            border: none;
            border-bottom: 1px solid black;
          }
          button {
            margin-left: auto;
            padding: 0;
            background-color: white;
            border: 0;
            svg {
              color: black;
              font-size: 1.2rem;
            }
          }
        }
      }
      .list {
        height: 50vh;
        overflow-y: auto;
        .table {
          height: 100%;
          border: 1px solid #ddd;
          margin: 0;
          thead {
            position: sticky;
            top: 0;
            tr {
              th {
                background-color: #636f83;
                color: white;
                font-size: 0.9rem;
                text-align: center;
              }
            }
          }
          tbody {
            tr {
              height: 5vh;
              td {
                text-align: center;
                word-break: break-all;
                .videolist-td-checkbox {
                  display: flex;
                  align-items: center;
                  width: 100%;
                  height: 100%;
                  justify-content: center;
                  input[type='checkbox'] {
                    cursor: pointer;
                    zoom: 1.5;
                  }
                }
                p {
                  margin: 0;
                  height: 100%;
                  display: flex;
                  align-items: center;
                  justify-content: center;
                }

                .btn-div {
                  display: flex;
                  align-items: center;
                  justify-content: center;
                  width: 100%;
                  height: 100%;
                  .btn {
                    vertical-align: middle;
                    color: white;
                  }
                }
              }
            }
          }
        }
      }
    }
    .upload-container {
      .upload-loading {
        position: absolute;
        display: flex;
        align-items: center;
        height: 25%;
        justify-content: center;
        width: 96%;
        z-index: 100;
        .spinner-border {
          width: 4em;
          height: 4em;
          font-size: 1.5rem;
        }
      }
      input[type='file'] {
        cursor: pointer;
      }
      .col-2 {
        display: flex;
        justify-content: center;
        .btn {
          color: white;
        }
      }
    }
  }
  .modal-footer {
    .btn {
      color: white;
    }
  }
`;

export default VideoModalSettingStyle;
