import styled from 'styled-components';

const HeaderSettingStyle = styled.div`
  .card {
    .card-header {
      a {
        text-decoration: none;
        height: 100%;
      }
    }
    .card-body {
      padding: 0.5em;
    }
  }
`;

export default HeaderSettingStyle;
