import useViewer from '@hooks/store/useViewer';
import { useEffect, useRef, useState } from 'react';
import SubTitle from '../SubTitle';

type Props = {
  height: string;
};

const SubTitleContainer = ({ height }: Props) => {
  const { font, sub } = useViewer();
  const subTitleRef = useRef<HTMLParagraphElement>(null);
  const [width, setWidth] = useState<number>(0);

  useEffect(() => {
    // 자막 width 값 가져오기
    // 애니메이션 left에 사용됨
    if (subTitleRef) {
      const subTitleWidth = subTitleRef.current?.clientWidth;
      setWidth(subTitleWidth ?? 0);
    }
  }, [sub]);

  return sub && font ? (
    <SubTitle
      sub={sub}
      font={font}
      subTitleRef={subTitleRef}
      height={height}
      width={width}
    />
  ) : null;
};

export default SubTitleContainer;
