import * as S from './styles/SurgeryWaitingStyle';
import images from '@assets/images';
import Clock from 'react-live-clock';
import SurgeryHorizontalWaitingContainer from './containers/SurgeryHorizontalWaitingContainer';
import SurgeryVerticalWaitingContainer from './containers/SurgeryVerticalWaitingContainer';
import { LoadVisibleConfigResponseDataType } from '@typedef/routes/viewer.navigation.types';
import SubTitleContainer from '@components/SubTitle/containers/SubTitleContainer';
import 'moment/locale/ko';

type Props = {
  visible: LoadVisibleConfigResponseDataType;
  componentFlag: string;
};

// 수술실 전체 ( 헤더, 리스트 ,자막 )
const SurgeryWaiting = ({ visible, componentFlag }: Props) => {
  return (
    <S.SurgeryWaitingStyle>
      <S.Header>
        <section className='logoBox'>
          <img src={images.jsolViewerLogo} alt='logo' />
          <div className='triangle' />
        </section>
        <section className='titleBox'>
          <p className='subtitle'>
            &nbsp;지역과 함께한 백년, 세계로 나아갈 천년
            <br />
            환자중심의 병원으로 거듭나겠습니다.
          </p>
          <div className='clock'>
            {visible.vclock ? (
              <Clock className='timeclock' format={'YYYY.MM.DD(dd) HH:mm'} ticking={true} />
            ) : null}
          </div>
        </section>
      </S.Header>
      <S.Content>
        {componentFlag === '수술실 타입1' ? (
          <SurgeryVerticalWaitingContainer />
        ) : (
          <SurgeryHorizontalWaitingContainer />
        )}
        <SubTitleContainer height='90vh' />
      </S.Content>
    </S.SurgeryWaitingStyle>
  );
};

export default SurgeryWaiting;
