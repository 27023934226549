import useGNB from '@hooks/store/useGNB';
import useLogined from '@hooks/store/useLogined';
import useProfile from '@hooks/store/useProfile';
import useBoardSet from '@hooks/store/useBoardSet';
import { alertMessage } from '@libs/alert';
import { apiRoute, requestSecureGet, requestSecurePut } from '@libs/api';
import {
  DoctorItemType,
  LoadDelayResponseTypes,
  LoadIpMacItemType,
  LoadNoticeInfoResponseType,
  LoadSubTitleListResponseType,
  RoomInfoTypes,
  SubTitleContentItemTypes,
} from '@typedef/components/AdminGNB/admin.gnb.types';
import { AdminBoardTypes } from '@typedef/components/Admin/Board/admin.board.types';
import { MyBoardItemTypes, MyBoardListItemTypes } from '@typedef/components/Login/login.types';
import { useCallback, useEffect, useMemo, useState } from 'react';
import AdminGNB from '../AdminGNB';
import { AdminBoardSettingResponseItemTypes } from '@typedef/components/Admin/BoardSetting/admin.board.setting.types';
import axios from 'axios';
import dev from '@libs/dev';

const AdminGNBContainer = () => {
  const { id, myBoard, account, deptId, roles, __updateProfileFromHooks } = useProfile();

  const {
    __updateSelectedBoardFromHooks,
    __updateInfectionFlagFromHooks,
    selected,
    visible,
    myBoardReload,
    infection,
  } = useGNB();

  const {
    subtitleReload,
    noticeReload,
    delayReload,
    __updateBoardSetSubtitleFromHooks,
    __updateBoardSetNoticeFromHooks,
    __updateBoardSetDelayFromHooks,
  } = useBoardSet();

  const { token } = useLogined();

  const [roomInfo, setRoomInfo] = useState<RoomInfoTypes | null>(null);

  const [boardIpMacInfo, setBoardIpMacInfo] = useState<LoadIpMacItemType>();

  const [boardType, setBoardType] = useState<string>('');

  const [boardPing, setBoardPing] = useState<boolean>(false);

  const [myBoardInfo, setMyBoardInfo] = useState<MyBoardItemTypes>();

  const [subTitleInfo, setSubTitleInfo] = useState<LoadSubTitleListResponseType | null>(null);

  const [doctorInfo, setDoctorInfo] = useState<DoctorItemType[]>([]);

  const [visibleInfo, setVisibleInfo] = useState<LoadDelayResponseTypes | null>(null);

  const [noticeInfo, setNoticeInfo] = useState<LoadNoticeInfoResponseType | null>(null);

  const [subTitleList, setSubTitleList] = useState<SubTitleContentItemTypes[]>([]);

  const [subCheckboxList, setSubCheckboxList] = useState<number[]>([]);

  const [subTitleVisible, setSubTitleVisible] = useState<boolean>(false);

  const [newNoticeContents, setNewNoticeContents] = useState<string | null>(null);

  const [noticeVisible, setNoticeVisible] = useState<boolean>(false);

  const [selectedDoctor, setSelectedDoctor] = useState<DoctorItemType | null>(null);

  const [newDelay, setNewDelay] = useState<number | null>(null);

  const [powerOnFlag, setPowerOnFlag] = useState<boolean>(false);

  const currentDoctor = useMemo(() => {
    return selectedDoctor === null && doctorInfo
      ? doctorInfo.find((item) => item.USERID === roomInfo?.doctorId) ?? null
      : selectedDoctor;
  }, [selectedDoctor, doctorInfo, roomInfo]);

  const loadBoardType = useCallback(async () => {
    if (!token) return;
    if (selected?.boardId) {
      const { config, data } = await requestSecureGet<AdminBoardSettingResponseItemTypes[]>(
        apiRoute.admin.boardSet.info + selected?.boardId,
        {},
        token,
      );

      if (config.status === 200) {
        if (!selected?.boardId) return;

        setBoardType(data[0].btypeName);
      }
    }
  }, [selected?.boardId, token]);

  // 현재 선택한 전광판 정보 불러오기
  const loadMyBoardInfo = useCallback(async () => {
    if (!token) return;

    const { data, config } = await requestSecureGet<MyBoardItemTypes>(
      apiRoute.admin.gnb.loadMyBoardInfo + myBoard?.id,
      {},
      token,
    );

    if (config.status === 200) {
      if (id && account && roles && token) {
        __updateProfileFromHooks({
          id: id,
          account: account,
          myBoard: data,
          deptId: deptId ?? null,
          roles: roles,
          token: token,
        });
      }
      setMyBoardInfo(data);
    }
  }, [myBoard, account, deptId, id, roles, token, __updateProfileFromHooks]);

  // 현재 선택한 진료실 정보 불러오기
  const loadRoomInfo = useCallback(async () => {
    if (!selected) {
      alertMessage('선택된 항목 없습니다');
      return;
    }

    if (!selected.roomId) {
      alertMessage('진료실이 아닙니다');
      return;
    }

    if (!token) {
      alertMessage('인증 토큰이 없습니다');
      return;
    }

    const { data, config } = await requestSecureGet<RoomInfoTypes>(
      apiRoute.admin.gnb.loadRoomInfo + selected.roomId,
      {},
      token,
    );

    if (config.status === 200) {
      setRoomInfo(data);
    } else {
      alertMessage('GNB 진료실 정보를 불러오지 못했습니다');
    }
  }, [selected, token]);

  // 마이전광판 리스트에서 전광판 선택 변경 감지
  const onMyBoardItemClicked = useCallback(
    (item: MyBoardListItemTypes) => {
      __updateSelectedBoardFromHooks(item);
    },
    [__updateSelectedBoardFromHooks],
  );

  // 의사 변경 감지
  const onDoctorItemClicked = useCallback(
    (doctorId: string) => {
      doctorInfo.forEach((doctor) => {
        if (doctor.USERID === doctorId) {
          setSelectedDoctor({
            USERNM: doctor?.USERNM ?? '',
            USERENGNM: doctor?.USERENGNM ?? '',
            USERID: doctor?.USERID ?? '',
            DEPTHNGNM: doctor?.DEPTHNGNM ?? '',
            SPECORDYN: doctor?.SPECORDYN,
            DEPTCD: doctor?.DEPTCD ?? '',
            JOBSEQ: doctor?.JOBSEQ ?? '',
          });
        } else if (doctorId === '-') {
          setSelectedDoctor({
            USERNM: '휴진',
            USERENGNM: '-',
            USERID: '-',
            DEPTHNGNM: '-',
            SPECORDYN: 'N',
            DEPTCD: '-',
            JOBSEQ: '-',
          });
        }
      });
    },
    [doctorInfo],
  );

  // 자막 불러오기
  const loadSubTitleList = useCallback(async () => {
    if (!selected) {
      alertMessage('선택된 항목 없습니다');
      return;
    }

    if (!token) return;

    if (selected.boardId) {
      const { data, config } = await requestSecureGet<LoadSubTitleListResponseType>(
        apiRoute.admin.boardSet.subtitle + String(selected.boardId),
        {},
        token,
      );

      if (config.status === 200) {
        setSubTitleInfo(data);

        setSubTitleList(data.contents.sort((prev, next) => next.idx - prev.idx));

        setSubTitleVisible(data.display);
        data.contents.forEach((sub) => {
          if (sub.checkFlag) {
            setSubCheckboxList((list) => [...list, sub.idx]);
          }
        });
      }
    }
  }, [selected, token]);

  // 공지사항 불러오기
  const loadNoticeList = useCallback(async () => {
    if (!selected) {
      alertMessage('선택된 항목 없습니다');
      return;
    }

    if (!token) {
      alertMessage('인증 토큰이 없습니다');
      return;
    }

    if (selected.boardId) {
      const { data, config } = await requestSecureGet<LoadNoticeInfoResponseType>(
        apiRoute.admin.boardSet.notice + selected.boardId,
        {},
        token,
      );

      if (config.status === 200) {
        setNoticeInfo(data);

        setNewNoticeContents(data.contents);

        setNoticeVisible(data.display);
      } else {
        alertMessage('GNB 공지 설정 정보를 불러오지 못했습니다');
      }
    }
  }, [selected, token]);

  // 의사 불러오기
  const loadDoctorList = useCallback(async () => {
    if (!selected) {
      alertMessage('선택된 항목 없습니다');
      return;
    }

    if (!token) {
      alertMessage('인증 토큰이 없습니다');
      return;
    }

    if (selected.roomId) {
      const { data, config } = await requestSecureGet<DoctorItemType[]>(
        apiRoute.admin.gnb.loadDoctorList + selected.roomId,
        {},
        token,
      );

      if (config.status === 200) {
        setDoctorInfo(data);
      } else {
        alertMessage('GNB 진료 정보를 불러오지 못했습니다');
      }
    }
  }, [selected, token]);

  // 지연시간 불러오기
  const loadDelayInfo = useCallback(async () => {
    if (!selected) {
      alertMessage('선택된 항목 없습니다');
      return;
    }

    if (!token) {
      alertMessage('인증 토큰이 없습니다');
      return;
    }
    if (selected.boardId) {
      const { data, config } = await requestSecureGet<LoadDelayResponseTypes>(
        apiRoute.admin.boardSet.visible + selected.boardId,
        {},
        token,
      );

      if (config.status === 200) {
        setVisibleInfo(data);

        setNewDelay(data.delayTime);
      } else {
        alertMessage('GNB 딜레이 정보를 불러오지 못했습니다');
      }
    }
  }, [selected, token]);

  // 전광판 파워 불러오기
  // const loadBoardPower = useCallback(
  //   async (ip: string) => {
  //     if (!token) return;

  //     const { data, config } = await requestSecureGet<boolean>(
  //       apiRoute.admin.gnb.loadPowerPing + ip,
  //       {},
  //       token,
  //     );

  //     if (config.status === 200) {
  //       setBoardPing(data);
  //     }
  //   },
  //   [token],
  // );

  // Ip, Mac 주소 불러오기
  const loadIpMacInfo = useCallback(async () => {
    if (selected) {
      if (!token) return;

      if (selected.boardId) {
        const { data, config } = await requestSecureGet<LoadIpMacItemType>(
          apiRoute.admin.gnb.loadIpMacInfo + selected.boardId,
          {},
          token,
        );

        if (config.status === 200) {
          setBoardIpMacInfo(data);

          // loadBoardPower(data.ip);
        }
      }
    }
  }, [selected, token]);

  // const loadCurrentPowerOnJclient = useCallback(async () => {
  //   setPowerOnFlag(false);

  //   await axios.get('http://localhost:5114/version', {}).then((response) => {
  //     if (response.status === 200) {
  //       setPowerOnFlag(true);
  //     }
  //   });
  // }, []);

  // const onPowerRoomByBoardIp = useCallback(async (ip: string, mac: string) => {
  //   await axios.get(`http://localhost:5114/turnon/${ip}/${mac}`, {});
  // }, []);

  // const offPowerRoomByBoardIp = useCallback(
  //   async (ip: string) => {
  //     if (!token) return;

  //     await requestSecureGet(apiRoute.admin.gnb.loadPowerOff + '/' + ip, {}, token);
  //   },
  //   [token],
  // );

  // // 전체 버튼 클릭시
  // const onPowerAllChange = useCallback(
  //   async (value: string) => {
  //     if (!roles) return;
  //     if (!token) return;
  //     if (!myBoard) return;

  //     const myBoardList = myBoard.myBoardList;

  //     // 전광판 리스트 가져오기
  //     const boardList = await requestSecureGet<AdminBoardTypes[]>(
  //       apiRoute.admin.board.getBoards + myBoard.id + '/' + roles[0],
  //       {},
  //       token,
  //     );

  //     if (boardList.config.status === 200) {
  //       if (boardList.data) {
  //         const boardIpObj = {};

  //         const boardMacObj = {};

  //         boardList.data.forEach((board) => {
  //           if (board.id && board.ip) {
  //             boardIpObj[board.id] = board.ip;
  //             boardMacObj[board.id] = board.mac;
  //           }
  //         });

  //         myBoardList.forEach(async (info) => {
  //           const { boardId } = info;
  //           if (!boardId) return;

  //           // 전체 on
  //           if (value === 'on') {
  //             onPowerRoomByBoardIp(boardIpObj[boardId], boardMacObj[boardId]);
  //           }

  //           // 전체 off
  //           if (value === 'off') {
  //             offPowerRoomByBoardIp(boardIpObj[boardId]);
  //           }
  //         });
  //         if (value === 'on') {
  //           alert('마이전광판에 있는 전광판들의 전원을 시작했습니다.');
  //         }
  //         if (value === 'off') {
  //           alert('마이전광판에 있는 전광판들의 전원을 종료했습니다.');
  //         }
  //       }
  //     }
  //   },
  //   [token, myBoard, offPowerRoomByBoardIp, onPowerRoomByBoardIp, roles],
  // );

  // // 룸 전원 클릭 변경값 ( 현재 off만 정상작동 )
  // const onPowerRoomChange = useCallback(
  //   (value: string) => {
  //     if (!boardIpMacInfo?.ip) return;

  //     if (value === 'on') {
  //       onPowerRoomByBoardIp(boardIpMacInfo.ip, boardIpMacInfo.mac);

  //       alert('선택하신 전광판의 전원을 시작했습니다');
  //     }

  //     if (value === 'off') {
  //       offPowerRoomByBoardIp(boardIpMacInfo.ip);

  //       alert('선택하신 전광판의 전원을 종료했습니다.');
  //     }
  //   },
  //   [boardIpMacInfo, boardPing, offPowerRoomByBoardIp, onPowerRoomByBoardIp],
  // );

  // 감염병 클릭 변경값 저장
  const onInfectionFlagClicked = useCallback(
    async (flag: boolean) => {
      __updateInfectionFlagFromHooks(flag);
    },
    [__updateInfectionFlagFromHooks],
  );

  // 자막 리스트 값 변경
  const onSubTitleListItemCheckClicked = useCallback(
    (idx: number, check: boolean) => {
      if (check) {
        setSubCheckboxList((list) => [...list, idx]);
      } else {
        setSubCheckboxList(subCheckboxList.filter((data) => data !== idx));
      }

      subTitleList.forEach((item) => {
        if (item.idx === idx) {
          item.checkFlag = check;
        }
      });

      setSubTitleList(subTitleList);
    },
    [subTitleList, subCheckboxList],
  );

  // 자막 적용
  const onSubTitleOptionApplyClicked = useCallback(async () => {
    if (!token) return;

    if (selected?.boardId) {
      const { config: subtitleUpdateResultConfig } = await requestSecurePut(
        apiRoute.admin.boardSet.subtitle + selected?.boardId,
        {},
        {
          display: subTitleVisible === null ? subTitleInfo?.display : subTitleVisible,
          id: selected?.boardId,
          speed: subTitleInfo?.speed,
        },
        token,
      );

      const { config: subtitleListUpdateResultConfig } = await requestSecurePut(
        apiRoute.admin.boardSet.subtitleContents,
        {},
        subTitleList.map((item) => ({ ...item, boardIdx: selected!.boardId })),
        token,
      );

      if (
        subtitleUpdateResultConfig.status === 200 &&
        subtitleListUpdateResultConfig.status === 200
      ) {
        __updateBoardSetSubtitleFromHooks(subtitleReload + 1);
      }
    }
  }, [selected, subTitleInfo, subTitleList, subTitleVisible, token, subtitleReload]);

  // 공지사항 적용
  const onNoticeOptionApplyClicked = useCallback(async () => {
    if (!token) return;

    const { config } = await requestSecurePut(
      apiRoute.admin.boardSet.notice + selected?.boardId,
      {},
      {
        align: noticeInfo?.align,
        contents: newNoticeContents === null ? noticeInfo?.contents : newNoticeContents,
        display: noticeVisible,
        id: noticeInfo?.id,
      },
      token,
    );

    if (config.status === 200) {
      __updateBoardSetNoticeFromHooks(noticeReload + 1);
    }
  }, [selected, noticeInfo, newNoticeContents, noticeVisible, token, noticeReload]);

  // 의사 적용
  const onDoctorOptionApplyClicked = useCallback(async () => {
    if (!token) return;

    const { config } = await requestSecurePut(
      apiRoute.admin.gnb.updateDoctor,
      {},
      {
        ...roomInfo,
        doctorId: selectedDoctor?.USERID,
        doctorKor: selectedDoctor?.USERNM,
      },
      token,
    );

    if (config.status === 200) {
      loadMyBoardInfo();
      __updateSelectedBoardFromHooks({
        boardId: selected!.boardId,
        roomId: selected!.roomId, // REF : roomId ? 진료실 : 전광판
        boardName: selected!.boardName,
        roomName: selected!.roomName,
        docname: selectedDoctor!.USERNM,
        roomOrBoard: selected!.roomOrBoard,
      });
    }
  }, [selected, token, selectedDoctor, roomInfo]);

  // 지연시간 적용
  const onDelayOptionApplyClicked = useCallback(async () => {
    if (!token) return;

    const { config } = await requestSecurePut(
      apiRoute.admin.boardSet.visible + selected?.boardId,
      {},
      {
        ...visibleInfo,
        delayTime: newDelay,
      },
      token,
    );

    if (config.status === 200) {
      __updateBoardSetDelayFromHooks(delayReload + 1);
    }
  }, [selected, visibleInfo, newDelay, token, delayReload]);

  // 마이전광판 전광판or진료실 선택시 실행 ( 모든 값들 초기화 )
  useEffect(() => {
    setNewNoticeContents(null);

    setSelectedDoctor(null);

    setNewDelay(null);

    loadBoardType();
    loadMyBoardInfo();
    loadSubTitleList();
    loadNoticeList();
    loadDelayInfo();
    loadIpMacInfo();

    if (selected?.roomId) {
      loadRoomInfo();
      loadDoctorList();
    }
  }, [selected, token, myBoardReload]);

  //선택한 마이전광판이 있는지 유무 검사
  useEffect(() => {
    if (!selected) {
      if (myBoard?.myBoardList.length) {
        onMyBoardItemClicked(myBoard?.myBoardList[0]);
      }
      return;
    } else {
      // 내가선택한 마이전광판이 삭제되었을때 자동으로 다시 0번째값 선택
      const filter = myBoard?.myBoardList.filter((data) => data.boardId === selected.boardId);

      if (myBoard?.myBoardList.length && !filter?.length) {
        onMyBoardItemClicked(myBoard?.myBoardList[0]);
      }
    }
  }, [selected, myBoard, token, myBoardReload]);

  // 공지사항, 지연시간, 자막 적용 클릭시 동기화
  useEffect(() => {
    setSubCheckboxList([]);

    loadSubTitleList();
  }, [subtitleReload]);

  useEffect(() => {
    loadNoticeList();
  }, [noticeReload]);

  useEffect(() => {
    loadDelayInfo();
  }, [delayReload]);

  // 전광판 관리자 PC에 jclient 유무 검사를 위한 최초 통신
  // useEffect(() => {
  //   loadCurrentPowerOnJclient();
  // }, [selected]);

  return (
    <AdminGNB
      selected={selected}
      myBoardList={myBoardInfo?.myBoardList}
      onMyBoardItemClicked={onMyBoardItemClicked}
      subTitleInfo={subTitleInfo}
      subCheckboxList={subCheckboxList}
      subtitleVisible={subTitleVisible}
      setSubtitleVisible={setSubTitleVisible}
      onSubTitleListItemCheckClicked={onSubTitleListItemCheckClicked}
      onSubTitleOptionApplyClicked={onSubTitleOptionApplyClicked}
      noticeInfo={noticeInfo}
      newNoticeContents={newNoticeContents}
      setNewNoticeContents={setNewNoticeContents}
      noticeVisible={noticeVisible}
      setNoticeVisible={setNoticeVisible}
      onNoticeOptionApplyClicked={onNoticeOptionApplyClicked}
      doctorInfo={doctorInfo}
      currentDoctor={currentDoctor}
      onDoctorOptionApplyClicked={onDoctorOptionApplyClicked}
      newDelay={newDelay}
      setNewDelay={setNewDelay}
      onDelayOptionApplyClicked={onDelayOptionApplyClicked}
      infectionFlag={infection}
      onInfectionFlagClicked={onInfectionFlagClicked}
      onDoctorItemClicked={onDoctorItemClicked}
      boardIpMacInfo={boardIpMacInfo}
      // onPowerAllChange={onPowerAllChange}
      // onPowerRoomChange={onPowerRoomChange}
      visible={visible}
      boardType={boardType}
      powerOnFlag={powerOnFlag}
    />
  );
};

export default AdminGNBContainer;
