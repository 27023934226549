import styled from 'styled-components';

export const SubTitleStyle = styled.div<{ height: string }>`
  width: 100vw;
  height: calc(${({ height }) => height} / 11);
  display: flex;
  align-items: center;
  background-color: #333;
  p {
    margin: 0;
    position: absolute;
    white-space: nowrap;
  }
`;

type Props = {
  width: number;
  family?: string;
  fz?: number;
  fw?: boolean;
  color?: string;
  align?: string;
  speed?: number;
};

export const CustomPTag = styled.p<Props>`
  font-family: ${(props) => props.family};
  font-size: calc(${(props) => props.fz}vh / 11);
  font-weight: ${(props) => (props.fw ? 'bold' : 'normal')};
  color: ${(props) => props.color};
  // speed === 0 일 때 테스트 중앙 정렬
  left: ${(props) => (props.speed === 0 ? '50%' : '')};
  transform: translate(${(props) => (props.speed === 0 ? '-50%' : 0)}, 0);
  // 애니메이션 : rightToLeft를 speed초만큼 일정하게 무한 재생
  animation: rightToLeft ${(props) => props.speed}s linear infinite;

  // 오른쪽에서부터 왼쪽으로 애니메이션
  // 자막 길이만큼 -left 해줌 (뚝뚝 끊기는 느낌 없애기 위해 + 50)
  @keyframes rightToLeft {
    0% {
      left: 100%;
    }
    to {
      left: -${(props) => props.width + 50}px;
    }
  }
`;
