import BoardDelete from "../components/BoardDelete";
import { useCallback } from "react";
import { apiRoute, requestSecureDelete } from "@libs/api";
import useLogined from "@hooks/store/useLogined";
import useGNB from "@hooks/store/useGNB";
import { AdminBoardModalItemTypes } from "@typedef/components/Admin/Board/admin.board.types";

type Props = {
  ids: number[];
  setCurrentModal: React.Dispatch<
    React.SetStateAction<AdminBoardModalItemTypes>
  >;
  reload: number;
  setReload: React.Dispatch<React.SetStateAction<number>>;
};

const BoardDeleteContainer = ({
  ids,
  setCurrentModal,
  reload,
  setReload,
}: Props) => {
  const { token } = useLogined();

  const { myBoardReload, __updateGNBMyBoardReloadFromHooks } = useGNB();

  // 전광판 삭제
  const deleteBoard = useCallback(async () => {
    if(!token) return;

    const { config } = await requestSecureDelete(
      apiRoute.admin.board.deleteBoard,
      {},
      ids,
      token
    );
    if (config.status >= 200 && config.status < 400) {
      setCurrentModal(null);
      setReload(reload + 1);
      __updateGNBMyBoardReloadFromHooks(myBoardReload + 1);
    }
  }, [ids, setCurrentModal, token, reload, myBoardReload]);

  return (
    <BoardDelete
      setCurrentModal={setCurrentModal}
      deleteBoard={deleteBoard}
      ids={ids}
    />
  );
};

export default BoardDeleteContainer;
