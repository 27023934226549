import {
  CModal,
  CModalHeader,
  CButton,
  CModalBody,
  CModalFooter,
} from '@coreui/react';

type Props = {
  deleteAccount: () => Promise<void>;
  setCurrentModal: React.Dispatch<
    React.SetStateAction<'update' | 'delete' | null>
  >;
};

const AccountDelete = ({ deleteAccount, setCurrentModal }: Props) => {
  return (
    <CModal visible={true} onClose={() => setCurrentModal(null)}>
      <CModalHeader>계정 삭제</CModalHeader>
      <CModalBody>정말로 삭제하시겠습니까?</CModalBody>
      <CModalFooter>
        <CButton color='primary' onClick={deleteAccount}>
          삭제하기
        </CButton>
        <CButton color='secondary' onClick={() => setCurrentModal(null)}>
          취소
        </CButton>
      </CModalFooter>
    </CModal>
  );
};

export default AccountDelete;
