import * as S from './styles/BoardSettingStyle';
import HeaderSettingContainer from './containers/Header/HeaderSettingContainer';
import { AdminBoardSettingTypes } from '@typedef/components/Admin/BoardSetting/admin.board.setting.types';
import React from 'react';
import { CCol, CRow } from '@coreui/react';
import FontSettingContainer from './containers/Font/FontSettingContainer';
import VisibleSettingContainer from './containers/Visible/VisibleSettingContainer';
import VideoSettingContainer from './containers/Video/VideoSettingContainer';
import SubtitleSettingContainer from './containers/Subtitle/SubtitleSettingContainer';
import NoticeSettingContainer from './containers/Notice/NoticeSettingContainer';
import RoomListSettingContainer from './containers/RoomList/RoomListSettingContainer';
import { VIEWER_USE_OPTIONS } from '@typedef/libs/config.types';

type Props = {
  boardInfo: AdminBoardSettingTypes;
  setBoardInfo: React.Dispatch<React.SetStateAction<AdminBoardSettingTypes>>;
  modal: {
    type: JSX.Element;
  };
  currentModal: string | null;
  setCurrentModal: React.Dispatch<React.SetStateAction<string | null>>;
  setReload: React.Dispatch<React.SetStateAction<number>>;
};

const BoardSetting = ({
  boardInfo,
  setBoardInfo,
  modal,
  currentModal,
  setCurrentModal,
  setReload,
}: Props) => {
  return (
    <S.Main>
      {currentModal && modal[currentModal]}
      {/* 전광판 세팅 상단(헤더) 버튼, 설정 등 */}
      <HeaderSettingContainer
        boardInfo={boardInfo}
        setBoardInfo={setBoardInfo}
        setCurrentModal={setCurrentModal}
      />
      {/* 전광판 타입에 따른 전광판세팅 설정 화면이 달라짐 */}
      {/* 컬렉션에서 판단 후 설정화면 보여줌 */}
      <CRow>
        <CCol xl='6' sm='12' xs='12'>
          {VIEWER_USE_OPTIONS[boardInfo.btypeName]?.option?.includes(
            'Font',
          ) && (
            <FontSettingContainer
              boardId={boardInfo.id}
              btypeName={boardInfo.btypeName}
            />
          )}

          {VIEWER_USE_OPTIONS[boardInfo.btypeName]?.option?.includes(
            'Visible',
          ) && (
            <VisibleSettingContainer
              boardId={boardInfo.id}
              btypeName={boardInfo.btypeName}
            />
          )}
        </CCol>
        <CCol xl='6' sm='12' xs='12'>
          {VIEWER_USE_OPTIONS[boardInfo.btypeName]?.option?.includes(
            'MultiClinic',
          ) && (
            <RoomListSettingContainer
              boardId={boardInfo.id}
              setReload={setReload}
            />
          )}

          {VIEWER_USE_OPTIONS[boardInfo.btypeName]?.option?.includes(
            'Video',
          ) && <VideoSettingContainer setCurrentModal={setCurrentModal} />}

          {VIEWER_USE_OPTIONS[boardInfo.btypeName]?.option?.includes('Sub') && (
            <SubtitleSettingContainer boardId={boardInfo.id} />
          )}

          {VIEWER_USE_OPTIONS[boardInfo.btypeName]?.option?.includes(
            'Notice',
          ) && <NoticeSettingContainer boardId={boardInfo.id} />}
        </CCol>
      </CRow>
    </S.Main>
  );
};

export default BoardSetting;
