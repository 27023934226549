import { maskingName } from '@libs/monitor';
import { PharmacyPatientItemTypes } from '@typedef/components/PharmacyWaiting/pharmacy.waiting.types';
import { LoadVisibleConfigResponseDataType } from '@typedef/routes/viewer.navigation.types';
import * as Style from '../style/OutPharmacyWaitingStyle';

type Props = {
  visible: LoadVisibleConfigResponseDataType;
  currentPharmacyList: PharmacyPatientItemTypes[];
};

const OutPharmacyWaiting = ({ visible, currentPharmacyList }: Props) => {
  return (
    <Style.OutPharmacyWaitingStyle>
      <section className='subtitle'>외래환자 약 나왔습니다.</section>
      <Style.OutTable>
        <Style.Thead>
          <Style.Tr>
            <p>투약번호</p>
            <p className='name'>이&nbsp;&nbsp;름</p>
          </Style.Tr>
          <Style.Tr>
            <p>투약번호</p>
            <p className='name'>이&nbsp;&nbsp;름</p>
          </Style.Tr>
        </Style.Thead>
        <Style.Tbody>
          <Style.Split>
            {currentPharmacyList.map((data, index) =>
              index < 5 ? (
                <Style.Patient key={data?.drugno + '-' + index}>
                  <Style.Td>
                    <p>{data?.drugno}</p>
                  </Style.Td>
                  <Style.Td className='name'>
                    <p>
                      {maskingName(data?.hngnm, {
                        vmasking: visible.vmasking,
                        position: visible.position,
                        maskMark: visible.maskMark,
                      })}
                    </p>
                  </Style.Td>
                </Style.Patient>
              ) : null,
            )}
          </Style.Split>
          <Style.Split>
            {currentPharmacyList.map((data, index) =>
              4 < index ? (
                <Style.Patient key={data?.drugno + '-' + index}>
                  <Style.Td>
                    <p>{data?.drugno}</p>
                  </Style.Td>
                  <Style.Td className='name'>
                    <p>
                      {maskingName(data?.hngnm, {
                        vmasking: visible.vmasking,
                        position: visible.position,
                        maskMark: visible.maskMark,
                      })}
                    </p>
                  </Style.Td>
                </Style.Patient>
              ) : null,
            )}
          </Style.Split>
        </Style.Tbody>
      </Style.OutTable>
    </Style.OutPharmacyWaitingStyle>
  );
};

export default OutPharmacyWaiting;
