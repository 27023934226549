import styled from 'styled-components';

const FontSettingStyle = styled.div`
  .card {
    height: 42vh;
    .card-header {
      .btns {
        .btn {
          color: white;
        }
      }
    }
    .card-body {
      padding: 0.25em;
      .row {
        width: 100%;
        margin: 0;
        ul {
          list-style: none;
          padding: 0;
          margin: 0;
        }
      }
    }
  }
  @media (max-width: 1280px) {
    .card {
      height: 44vh;
    }
  }
`;

export default FontSettingStyle;
