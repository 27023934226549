import * as S from '../../styles/BoardSettingStyle';
import { CFormInput } from '@coreui/react';
import { AdminBoardSettingTypes } from '@typedef/components/Admin/BoardSetting/admin.board.setting.types';

type Props = {
  boardInfo: AdminBoardSettingTypes;
  onChangeBoardInfo: (id: string, name: string, value: string) => void;
};

const SurgeryHeader = ({ boardInfo, onChangeBoardInfo }: Props) => {
  return (
    <div className='header-body between'>
      <S.Inputs width='49%'>
        <CFormInput
          type='text'
          label='dscyn'
          value={boardInfo.dscyn === '-' ? '' : boardInfo.dscyn}
          onChange={(e) => {
            onChangeBoardInfo('dscyn', 'dscyn', e.target.value);
          }}
          placeholder='dscyn'
        />
      </S.Inputs>
      <S.Inputs width='49%'>
        <CFormInput
          type='text'
          label='rosette'
          value={boardInfo.rosette === '-' ? '' : boardInfo.rosette}
          onChange={(e) => {
            onChangeBoardInfo('rosette', 'rosette', e.target.value);
          }}
          placeholder='rosette'
        />
      </S.Inputs>
    </div>
  );
};

export default SurgeryHeader;
