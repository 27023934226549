import styled from "styled-components";

type Props = {
  visible: boolean;
};

const AdminGNBStyle = styled.div<Props>`
  width: 13.35vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #5d5757;
  position: fixed;
  left: ${(props) => (props.visible ? 0 : -256)}px;
  transition: left 0.3s ease-in-out;
  .gnb-logo {
    width: 100%;
    background-color: #4a464a;
    padding-bottom: 0.5em;
    .gnb-img {
      width: auto;
      display: flex;
      justify-content: center;
      align-items: center;
      img {
        height: 3em;
      }
    }
  }

  .my-board {
    width: 95%;
    font-size: 0.9rem;
    font-weight: bold;
    overflow: hidden;
    box-shadow: 2px 3px 8px 1px gray;
    margin-bottom: 0.5em;
    border: 2px solid #ced2d8;
    border-radius: 10px;
    padding: 0.1vw;
    background-color: transparent;
    color: white;
    .my-board-list {
      width: 100%;
      height: 18vh;
      overflow-y: auto;
      overflow-x: hidden;
      border-radius: 5px 5px 0 0;
      ul {
        list-style: none;
        padding: 0;
        margin: 0;
        li {
          width: 100%;
          cursor: pointer;
          .my-board-list-name {
            text-align: left;
          }
          .my-board-list-docname {
            text-align: right;
          }
          p {
            margin: 0;
            white-space: nowrap;
          }
        }
      }
    }
  }
  .card {
    width: 95%;
    font-size: 0.9rem;
    font-weight: bold;
    overflow: hidden;
    border-radius: 15px;
    box-shadow: 2px 3px 8px 1px gray;
    border: 2px solid #fff;
    .card-body {
      border: none;
      margin: 0;
      padding: 0;
      background-color: transparent;
      .title {
        display: flex;
        padding: 0.25em 0;
        border-radius: 15px 15px 0 0;
        background-color: #636f83;
        .sidebar-card-header {
          margin: auto 0;
          width: 50%;
          font-weight: bold;
          text-align: center;
          color: white;
        }
        .checkbox {
          margin-right: 0.25em;
          width: 50%;
          background-color: #fff;
          border-radius: 20px;
          border: 2px groove #0d86ff;
          padding: 0 1.5em;
          display: flex;
          align-items: center;
          input[type="checkbox"] {
            margin: 0;
            zoom: 1.3;
            cursor: pointer;
            position: relative;
            margin-left: 0.05em;
          }
          label {
            cursor: pointer;
            color: #768192;
            margin: auto;
            display: flex;
            align-items: center;
          }
        }
      }
      .option-btn {
        .btn {
          color: white;
          width: 100%;
          border-radius: 0 0 5px 5px;
        }
      }
      .sub-title {
        border: 1px solid #000;
        border-radius: 16px 16px 5px 5px;
        .content {
          .sub-title-list {
            height: 10vh;
            ul {
              overflow-y: auto;
              height: 100%;
              list-style: none;
              .row {
                margin: 0;
                border-right: 1px solid #808080;
                border-bottom: 1px solid #808080;
                .col-1,
                .col-11 {
                  padding: 0;
                  display: flex;
                  align-items: center;
                  input[type="checkbox"] {
                    position: relative;
                    margin: 0;
                    zoom: 1.5;
                    cursor: pointer;
                  }
                  label {
                    padding: 0;
                    margin: 0;
                    width: 100%;
                    height: 100%;
                    border: none;
                    background-color: #fff;
                    border-radius: unset;
                    padding-left: 0.25em;
                    font-size: 0.8rem;
                    line-height: 1.5vh;
                    color: #222;
                    display: flex;
                    align-items: center;
                  }
                }
              }
            }
          }
        }
      }
      .notice {
        border: 1px solid #000;
        border-radius: 5px;
        .title {
          border-radius: 0;
        }
        .content {
          height: 10vh;
          .form-control {
            border-radius: 0;
            resize: none;
            height: 100%;
          }
        }
      }
      .doctor-list {
        border: 1px solid #000;
        border-radius: 5px;
        .title {
          border-radius: 0;
          .select-box {
            width: 50%;
            margin: 0 0.25em;
            .form-select {
              border-radius: 25px;
              font-size: 0.9rem;
              font-weight: bold;
              height: 3vh;
              padding: 0.25em 0.75em;
            }
          }
        }
      }
      .delay {
        border: 1px solid #000;
        border-radius: 5px 5px 16px 16px;
        .title {
          border-radius: 0;
          input[type="number"] {
            margin: 0 0.25em;
            border-radius: 25px;
            width: 50%;
            padding: 0.25em 0.75em;
          }
        }
        .option-btn {
          .btn {
            border-radius: 0 0 15px 15px;
          }
        }
      }
    }
  }

  .power {
    border-radius: 15px;
    box-shadow: 2px 3px 8px 1px gray;
    margin-top: 0.5em;
    background-color: #636f83;
    width: 95%;
    border: 2px solid #ffffff;
    .power-title {
      padding: 0.5em 0;
      display: flex;
      border-radius: 15px 15px 0 0;
      width: 100%;
      .title-content {
        font-weight: bold;
        text-align: center;
        margin: auto 0;
        width: 10%;
        height: 100%;
        margin-left: 0.25em;
        border-radius: 15px;
        color: white;
      }
      .content {
        width: 100%;
        .code {
          border: 1px solid #cccccc;
          border-radius: 5px;
          width: 98%;
          margin: 0 auto;
          margin-bottom: 0.25em;
          p {
            color: white;
            width: 100%;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            text-align: left;
            margin: auto 0;
            padding: 0.25em;
            line-height: 15px;
            display: flex;
            align-items: center;
            font-weight: bold;
          }
        }
        .all,
        .room {
          display: flex;
          justify-content: center;
          .btn {
            width: 3.7em;
            margin: 0 0.2em;
            padding: 0 0.5em;
            height: 26px;
            color: white;
            font-weight: bold;
          }
          .power-content-all-title,
          .power-content-room-title {
            width: 45%;
            height: 100%;
            margin: auto 0.25em;
            text-align: left;
            display: flex;
            align-items: center;
            color: white;
            font-weight: bold;
            justify-content: start;
          }
        }
        .all {
          margin-bottom: 0.25em;
        }
        .room {
          label {
            color: white;
            margin: 0;
            display: flex;
            align-items: center;
            justify-content: center;
            padding: 0 0.25em;
            cursor: pointer;
          }
          .form-switch {
            margin: 0;
            padding: 0;
            display: flex;
            align-items: center;
            .form-check-input {
              cursor: pointer;
              width: 3.2em;
              height: 100%;
              margin: 0;
            }
          }
        }
      }
    }
  }
  .infectious {
    border: 1px solid #ffffff;
    border-radius: 15px;
    box-shadow: 2px 3px 8px 1px gray;
    margin: 0.5em 0;
    background-color: #636f83;
    width: 95%;
    .content {
      display: flex;
      padding: 0.25em 0;
      justify-content: center;
      width: 100%;
      .infectious-content-title {
        width: 48%;
        margin: 0;
        text-align: center;
        color: white;
      }
      .infectious-label {
        padding: 0 0.25em;
        color: red;
        margin: 0;
        display: flex;
        align-items: center;
        cursor: pointer;
        font-weight: bold;
      }
      .form-switch {
        margin: 0;
        padding: 0;
        display: flex;
        align-items: center;
        .form-check-input {
          cursor: pointer;
          width: 3.2em;
          height: 100%;
          margin: 0;
        }
      }
    }
  }
  @media (max-width: 1280px) {
    width: 16.45vw;
    .my-board {
      .my-board-list {
        ul {
          li {
            font-size: 0.85rem;
          }
        }
      }
    }
    .card {
      .card-body {
        .notice {
          .content {
            .form-control {
              font-size: 0.9rem;
            }
          }
        }
        .doctor-list {
          .title {
            .select-box {
              .form-select {
                font-size: 0.8rem;
              }
            }
          }
        }
        .delay {
          .title {
            input[type="number"] {
              font-size: 0.9rem;
            }
          }
          .option-btn {
            .btn {
              padding: 0;
            }
          }
        }
      }
    }
  }
`;

export default AdminGNBStyle;
