import { CommonActionTypes } from '@store/actions';
import { STORE_INIT_ACTION_TYPES } from '@store/modules/actionTypes';
import { LoginedActionTypes } from './actions';
import { UPDATE_LOGINED_ACTION_TYPE } from './modules/actionTypes';

export type LoginedStateTypes = {
  token: string | null;
};

const init: LoginedStateTypes = {
  token: null,
};

const TOKEN_SESSION_KEY_VALUE = '@token';

const getToken = () => sessionStorage.getItem(TOKEN_SESSION_KEY_VALUE);

const setToken = (token: string) =>
  sessionStorage.setItem(TOKEN_SESSION_KEY_VALUE, token);

const loginedReducer = (
  prev: LoginedStateTypes = init,
  { type, payload }: LoginedActionTypes | CommonActionTypes,
): LoginedStateTypes => {
  switch (type) {
    case UPDATE_LOGINED_ACTION_TYPE: {
      setToken(payload);

      return {
        token: payload,
      };
    }

    case STORE_INIT_ACTION_TYPES:
      return {
        token: getToken(),
      };

    default:
      return prev;
  }
};

export default loginedReducer;
