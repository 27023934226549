import { Main, Btns, Switch } from '../Clinic/styles/ClinicStyle';
import CTableContainer from '@components/common/CTable/containers/CTableContainer';
import { TableColumnTypes } from '@typedef/components/Common/CTable/table.column.types';
import {
  CButton,
  CCard,
  CCardBody,
  CFormLabel,
  CFormSelect,
  CFormSwitch,
  CSpinner,
} from '@coreui/react';
import React from 'react';
import {
  AdminBoardModalItemTypes,
  AdminBoardTypes,
} from '@typedef/components/Admin/Board/admin.board.types';

type Props = {
  columns: TableColumnTypes[];
  tableData: any;
  checkBoard: AdminBoardTypes[];
  setCheckBoard: React.Dispatch<React.SetStateAction<AdminBoardTypes[]>>;
  modal: {
    insert: JSX.Element;
    update: JSX.Element;
    delete: JSX.Element;
  };
  currentModal: AdminBoardModalItemTypes;
  onClickButton: (type: AdminBoardModalItemTypes) => void;
  boardChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  isAll: boolean;
  itemsPerPage: number;
  setItemsPerPage: React.Dispatch<React.SetStateAction<number>>;
};

const Board = ({
  columns,
  tableData,
  checkBoard,
  setCheckBoard,
  modal,
  currentModal,
  onClickButton,
  boardChange,
  isAll,
  itemsPerPage,
  setItemsPerPage,
}: Props) => {
  return (
    <Main>
      <CCard>
        {currentModal && modal[currentModal]}
        <CCardBody>
          <Btns>
            <div className='left'>
              <CFormSelect
                name='row'
                onChange={(e) => setItemsPerPage(Number(e.target.value))}
                value={itemsPerPage}
              >
                <option value='15'>15개</option>
                <option value='30'>30개</option>
                <option value='45'>45개</option>
                <option value='100'>100개</option>
                <option value='99999'>All</option>
              </CFormSelect>
              <CButton type='button' color='success mr-1' onClick={() => onClickButton('insert')}>
                추가
              </CButton>
              <CButton type='button' color='info mr-1' onClick={() => onClickButton('update')}>
                수정
              </CButton>
              <CButton type='button' color='danger mr-1' onClick={() => onClickButton('delete')}>
                삭제
              </CButton>
            </div>
            <Switch>
              <CFormLabel htmlFor='changeFilter'>마이 전광판</CFormLabel>
              <CFormSwitch color='dark' id='changeFilter' onChange={boardChange} checked={isAll} />
              <CFormLabel htmlFor='changeFilter'>전체 전광판</CFormLabel>
            </Switch>
          </Btns>
          <CTableContainer
            columns={columns}
            tableData={tableData}
            isCheckboxTable={true}
            selectedRows={checkBoard}
            itemsPerPage={itemsPerPage}
            setSelectedRows={setCheckBoard}
            isPaginator={true}
          />
        </CCardBody>
      </CCard>
    </Main>
  );
};

export default Board;
