import images from '@assets/images';
import styled from 'styled-components';

const space = '2vw';

export const SurgeryWaitingStyle = styled.div`
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  .row {
    margin: 0;
  }
`;

// 상단헤더(로고, 진료실명)
export const Header = styled.header`
  width: 100%;
  height: 11vh;
  display: flex;
  flex-direction: row;
  overflow: hidden;

  // 로고, 삼각형
  .logoBox {
    height: 100%;
    display: flex;
    flex-direction: row;

    img {
      height: 100%;
    }

    .triangle {
      background-color: #fff;
      border-bottom: 11vh solid #000655;
      border-left: 11vh solid transparent;
    }
  }

  .titleBox {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 0 ${space};
    background: url(${images.viewerBackground}), #000655;
    background-repeat: repeat-x;
    border: 1px solid #000655;

    .subtitle {
      font-size: 1vw;
      word-break: keep-all;
      text-align: left;
      color: white;
    }

    .clock {
      font-size: 5vh;
      font-weight: 600;
      color: white;
    }
  }
`;

export const Content = styled.section`
  height: calc(100vh - 11vh); // 100vh - 헤더
  display: flex;
  flex-direction: column;
`;
