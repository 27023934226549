import { MyBoardListItemTypes } from "@typedef/components/Login/login.types";
import {
  UPDATE_GNB_VISIBLE_ACITON_TYPE,
  UPDATE_SELECTED_BOARD_ACTION_TYPE,
  UPDATE_INFECTION_FLAG_ACTION_TYPE,
  UPDATE_GNB_MYBOARD_RELOAD_ACTION_TYPE,
} from "./modules/actionTypes";

export const updateGNBVisibleAction = (visible: boolean) => ({
  type: UPDATE_GNB_VISIBLE_ACITON_TYPE,
  payload: visible,
});

export const updateSelectedBoardAction = (board: MyBoardListItemTypes) => ({
  type: UPDATE_SELECTED_BOARD_ACTION_TYPE,
  payload: board,
});

export const updateInfectionFlagAction = (infection: boolean) => ({
  type: UPDATE_INFECTION_FLAG_ACTION_TYPE,
  payload: infection,
});

export const updateGNBMyBoardReloadAction = (myBoardReload: number) => ({
  type: UPDATE_GNB_MYBOARD_RELOAD_ACTION_TYPE,
  payload: myBoardReload,
});

export type GNBActionTypes =
  | ReturnType<typeof updateGNBVisibleAction>
  | ReturnType<typeof updateSelectedBoardAction>
  | ReturnType<typeof updateInfectionFlagAction>
  | ReturnType<typeof updateGNBMyBoardReloadAction>;
