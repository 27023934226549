import useViewer from '@hooks/store/useViewer';
import { alertMessage } from '@libs/alert';
import { apiRoute, requestGet } from '@libs/api';
import { getCurrentList } from '@libs/factory';
import { OutPatientItemTypes } from '@typedef/components/OutPatientWaiting/out.patient.types';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import OutPatient from '../OutPatientWaiting';
import EXIF from 'exif-js';

const LOAD_PATIENT_LIST_INTERVAL = 5000;

const PAGE_SIZE = 10;

const OutPatientWaitingContainer = () => {
  const { basic, font, video, visible, notice } = useViewer();

  const [videoIndex, setVideoIndex] = useState<number>(0);

  const [waitingPatitent, setWaitingPatient] = useState<OutPatientItemTypes[]>([]);

  const [reservePatient, setReservePatient] = useState<OutPatientItemTypes[]>([]);

  const [intervalCounter, setIntervalCounter] = useState<number>(0);

  const [imgLoad, setImgLoad] = useState<boolean>(false);

  const imgRef = useRef<HTMLImageElement>(null);

  const currentReservePatient = useMemo(() => {
    return getCurrentList(reservePatient, PAGE_SIZE, intervalCounter);
  }, [reservePatient, intervalCounter]);

  const loadWaitingPatient = useCallback(async () => {
    if (!basic?.roomList) {
      alertMessage('전광판 기본 정보를 불러오지 못했습니다!');
      return;
    }

    const { data, config } = await requestGet<{
      [key: string]: OutPatientItemTypes[];
    }>(apiRoute.viewer.outPatient.loadWaiterList + basic?.roomList[0], {});

    if (config.status === 200) {
      if (Object.keys(data).length) {
        if (basic.roomList) {
          setWaitingPatient([
            data[basic.roomList[0]][0] ?? {},
            data[basic.roomList[0]][1] ?? {},
            data[basic.roomList[0]][2] ?? {},
          ]);
        }
      } else {
        setWaitingPatient([]);
      }
    } else {
      setWaitingPatient([]);
    }
  }, [basic?.roomList]);

  const loadReservePatient = useCallback(async () => {
    if (!basic) {
      alertMessage('전광판 기본 정보를 불러오지 못했습니다!');
      return;
    }

    const { data, config } = await requestGet<{
      [key: string]: OutPatientItemTypes[];
    }>(apiRoute.viewer.outPatient.loadReserveList + basic.roomList, {});

    if (config.status === 200) {
      if (Object.keys(data).length) {
        if (basic.roomList) {
          setReservePatient(data[basic.roomList[0]] ?? []);
        }
      } else {
        setReservePatient([]);
      }
    } else {
      setReservePatient([]);
    }
  }, [basic]);

  // 의사 사진 회전
  const setOrientation = () => {
    const { current } = imgRef;

    if (!!!current) {
      return;
    }

    // EXIF.getData()는 이미지가 완전히 로드되지 않으면 호출 안됨
    EXIF.getData(current.src, function () {
      let orientation = EXIF.getTag(current, 'Orientation');

      switch (orientation) {
        case 6:
          // 이미지 90도 회전 (오른쪽으로 270도 회전)
          current.style.transform = 'rotate( 270deg )';
          // current.style.width = '130%';
          break;

        case 3:
          // 이미지 180도 회전 (오른쪽으로 180도 회전)
          current.style.transform = 'rotate( 180deg )';
          // current.style.width = '130%';
          break;

        case 8:
          // 이미지 270도 회전 (오른쪽으로 90도 회전)
          current.style.transform = 'rotate( 90deg )';
          // current.style.width = '130%';
          break;
      }
    });
  };

  useEffect(() => {
    if (imgLoad) {
      setOrientation();
    }
  }, [imgLoad]);

  useEffect(() => {
    loadWaitingPatient();
  }, [intervalCounter, loadWaitingPatient]);

  useEffect(() => {
    if (visible?.vlist) {
      loadReservePatient();
    }
  }, [basic, loadReservePatient, intervalCounter, visible]);

  useEffect(() => {
    const interval = setInterval(() => {
      setIntervalCounter((prev) => prev + 1);
    }, LOAD_PATIENT_LIST_INTERVAL);

    return () => {
      clearInterval(interval);
    };
  }, []);

  return basic && font && visible && notice ? (
    <OutPatient
      basic={basic}
      font={font}
      video={video}
      visible={visible}
      notice={notice}
      waitingPatitent={waitingPatitent}
      currentReservePatient={currentReservePatient}
      videoIndex={videoIndex}
      setVideoIndex={setVideoIndex}
      imgRef={imgRef}
      onLoadFunc={() => setImgLoad(true)}
    />
  ) : null;
};

export default React.memo(OutPatientWaitingContainer);
