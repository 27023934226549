import { parseFontString } from '@libs/factory';
import { maskingName } from '@libs/monitor';
import { ExaminationEndoscopePatientItemTypes } from '@typedef/components/ExaminationEndoscopePatientWaiting/examination.patient.waiting.types';
import {
  LoadFontConfigResponseDataType,
  LoadVisibleConfigResponseDataType,
} from '@typedef/routes/viewer.navigation.types';
import * as Style from '../styles/ExaminationEndoscopePatientListWaitingStyle';

type Props = {
  font: LoadFontConfigResponseDataType;
  visible: LoadVisibleConfigResponseDataType;
  currentWaitingPatient: ExaminationEndoscopePatientItemTypes[];
  currentExaminationPatient: ExaminationEndoscopePatientItemTypes[];
  currentHealingPatient: ExaminationEndoscopePatientItemTypes[];
};
const ExaminationEndoscopePatientListWaiting = ({
  font,
  visible,
  currentWaitingPatient,
  currentExaminationPatient,
  currentHealingPatient,
}: Props) => {
  return (
    <Style.ExaminationEndoscopePatientListWaitingStyle>
      <Style.Thead>
        <p>대기중</p>
        <p>검사중</p>
        <p>회복중</p>
      </Style.Thead>
      <Style.Tbody>
        <Style.Patients>
          {currentWaitingPatient.map((data, index) => (
            <Style.Patient key={index + '-' + data.acpttm}>
              <div>
                <Style.CustomPTag
                  family={parseFontString(font.fpatientName).family}
                  fz={parseFontString(font.fpatientName).size}
                  fw={parseFontString(font.fpatientName).bold}
                  color={parseFontString(font.fpatientName).color}
                >
                  {maskingName(data.hngnm, {
                    vmasking: visible.vmasking,
                    position: visible.position,
                    maskMark: visible.maskMark,
                  })}
                </Style.CustomPTag>
              </div>
            </Style.Patient>
          ))}
        </Style.Patients>
        <Style.Patients>
          {currentExaminationPatient.map((data, index) => (
            <Style.Patient key={index + '-' + data.acpttm}>
              <div>
                <Style.CustomPTag
                  family={parseFontString(font.fpatientName).family}
                  fz={parseFontString(font.fpatientName).size}
                  fw={parseFontString(font.fpatientName).bold}
                  color={parseFontString(font.fpatientName).color}
                >
                  {maskingName(data.hngnm, {
                    vmasking: visible.vmasking,
                    position: visible.position,
                    maskMark: visible.maskMark,
                  })}
                </Style.CustomPTag>
              </div>
            </Style.Patient>
          ))}
        </Style.Patients>
        <Style.Patients>
          {currentHealingPatient.map((data, index) => (
            <Style.Patient key={index + '-' + data.acpttm}>
              <div>
                <Style.CustomPTag
                  family={parseFontString(font.fpatientName).family}
                  fz={parseFontString(font.fpatientName).size}
                  fw={parseFontString(font.fpatientName).bold}
                  color={parseFontString(font.fpatientName).color}
                >
                  {maskingName(data.hngnm, {
                    vmasking: visible.vmasking,
                    position: visible.position,
                    maskMark: visible.maskMark,
                  })}
                </Style.CustomPTag>
              </div>
            </Style.Patient>
          ))}
        </Style.Patients>
      </Style.Tbody>
    </Style.ExaminationEndoscopePatientListWaitingStyle>
  );
};

export default ExaminationEndoscopePatientListWaiting;
