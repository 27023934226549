import { timeAddColons } from '@libs/monitor';
import { PatientListItemTypes } from '@typedef/components/Admin/Patient/admin.patient.types';
import { CSSProperties } from 'react';
import { Draggable } from 'react-beautiful-dnd';

type Props = {
  patientIdx: number;
  patient: PatientListItemTypes;
  tableId: string;
  cretNo: number;
  borderFlag: boolean;
  canDrag: boolean;
  arriveTime: string;
  // arriveText: string;
  onArriveDoubleClick: () => void;
  getPatientRowStyle: (
    isDragging: boolean,
    draggableStyle: CSSProperties | undefined,
  ) => CSSProperties;
  getPatientColumnStyle: (
    isDragging: boolean,
    bgColor: string | undefined,
    color: string | undefined,
    maxWidth: number,
    other: CSSProperties | undefined,
  ) => CSSProperties;
};

const DragRow = ({
  patientIdx,
  patient,
  tableId,
  cretNo,
  canDrag,
  arriveTime,
  // arriveText,
  onArriveDoubleClick,
  getPatientRowStyle,
  getPatientColumnStyle,
}: Props) => {
  return (
    <Draggable
      draggableId={`dragID-${patient?.pid}-${tableId}-${cretNo}`}
      index={patientIdx}
      isDragDisabled={!canDrag}
    >
      {(provided, snapshot) => (
        <tr
          ref={provided.innerRef}
          {...provided.draggableProps}
          {...provided.dragHandleProps}
          style={getPatientRowStyle(snapshot.isDragging, provided.draggableProps.style)}
        >
          <td
            style={getPatientColumnStyle(
              snapshot.isDragging,
              patient['dispseqbgcolor'],
              patient['dispseqcharactercolor'],
              1,
              {},
            )}
            // {
            //   backgroundColor: patient['DISPSEQBGCOLOR'],
            //   color: patient['DISPSEQCHARACTERCOLOR'],
            //   maxWidth: '1vw',
            // }
            title={`${patientIdx + 1}`}
          >
            {patientIdx + 1}
          </td>
          <td
            style={getPatientColumnStyle(
              snapshot.isDragging,
              patient['ordtmbgcolor'],
              patient['ordtmcharactercolor'],
              4,
              {},
            )}
            // {
            //   backgroundColor: patient['ORDTMBGCOLOR'],
            //   color: patient['ORDTMCHARACTERCOLOR'],
            // }
            title={patient.ordtm}
          >
            {timeAddColons(patient.ordtm)}
          </td>
          {/* <td
            style={getPatientColumnStyle(
              snapshot.isDragging,
              patient['consultbgcolor'],
              patient['consultcharactercolor'],
              1,
              {},
            )}
            // {
            //   backgroundColor: patient['CONSULTBGCOLOR'],
            //   color: patient['CONSULTCHARACTERCOLOR'],
            //   maxWidth: '1vw',
            // }
            title={`${patient.consult}`}
          >
            {patient.consult}
          </td> */}
          <td
            id={`${patient.pid}`}
            onDoubleClick={onArriveDoubleClick}
            title={timeAddColons(arriveTime)}
            style={getPatientColumnStyle(
              snapshot.isDragging,
              patient['nursacptdtbgcolor'],
              '',
              4,
              {},
            )}
            // {
            //     backgroundColor: patient['NURSACPTDTBGCOLOR'],
            //     cursor: 'pointer',
            //   }
          >
            {timeAddColons(arriveTime)}
          </td>
          <td
            style={getPatientColumnStyle(
              snapshot.isDragging,
              patient['pidbgcolor'],
              patient['pidcharactercolor'],
              6,
              {},
            )}
            // {
            //   backgroundColor: patient['PIDBGCOLOR'],
            //   color: patient['PIDCHARACTERCOLOR'],
            //   maxWidth: '6vw',
            // }
            title={`${patient.pid}`}
          >
            {patient.pid}
          </td>
          <td
            style={getPatientColumnStyle(
              snapshot.isDragging,
              patient['namecolor'],
              patient['namecharactercolor'] ?? '#222',
              4,
              {},
            )}
            // {
            //   backgroundColor: patient['NAMEBGCOLOR'],
            //   color: patient['NAMECHARACTERCOLOR'] ?? '#222',
            //   maxWidth: '4vw',
            // }
            title={patient.hngnm}
          >
            {patient.hngnm}
          </td>
          <td
            style={getPatientColumnStyle(
              snapshot.isDragging,
              patient['agesexbgcolor'],
              patient['agesexcharactercolor'],
              9,
              {
                textAlign: 'left',
              },
            )}
            // {
            //   backgroundColor: patient['AGESEXBGCOLOR'],
            //   color: patient['AGESEXCHARACTERCOLOR'],
            //   textAlign: 'left',
            //   maxWidth: '9vw',
            // }
            title={`${patient.birth} (${patient.sex}) ${patient.age}`}
          >
            <div className='rrgstno1-sex-age'>
              &nbsp;
              {`${patient.birth} (${patient.sex === 'F' ? '여' : '남'})${patient.age}`}
              &nbsp;
            </div>
          </td>
          {/* <td
            style={getPatientColumnStyle(
              snapshot.isDragging,
              patient['FSEXAMFLAGNMBGCOLOR'],
              patient['FSEXAMFLAGNMCHARACTERCOLOR'],
              2,
              {},
            )}
            // {
            //   backgroundColor: patient['FSEXAMFLAGNMBGCOLOR'],
            //   color: patient['FSEXAMFLAGNMCHARACTERCOLOR'],
            //   maxWidth: '2vw',
            // }
            title={patient.FSEXAMFLAGNM}>
            {patient.FSEXAMFLAGNM}
          </td>
          <td
            style={getPatientColumnStyle(
              snapshot.isDragging,
              patient['RSRVFLAGNMBGCOLOR'],
              patient['RSRVFLAGNMCHARACTERCOLOR'],
              4,
              {},
            )}
            // {
            //   backgroundColor: patient['RSRVFLAGNMBGCOLOR'],
            //   color: patient['RSRVFLAGNMCHARACTERCOLOR'],
            //   maxWidth: '4vw',
            // }
            title={patient.RSRVFLAGNM}>
            {patient.RSRVFLAGNM}
          </td>
          <td
            id={`${patient?.PID}ELBULBODSTATNM`}
            style={getPatientColumnStyle(
              snapshot.isDragging,
              patient['ELBULBODSTATNMBGCOLOR'],
              patient['ELBULBODSTATNMCHARACTERCOLOR'],
              3,
              {},
            )}
            // {
            //   backgroundColor: patient['ELBULBODSTATNMBGCOLOR'],
            //   color: patient['ELBULBODSTATNMCHARACTERCOLOR'],
            //   maxWidth: '3vw',
            // }
            title={arriveText}>
            {arriveText}
          </td>
          <td
            style={getPatientColumnStyle(
              snapshot.isDragging,
              patient['BFJUDGBGCOLOR'],
              patient['BFJUDGCHARACTERCOLOR'],
              3,
              {},
            )}
            // {
            //   backgroundColor: patient['BFJUDGBGCOLOR'],
            //   color: patient['BFJUDGCHARACTERCOLOR'],
            //   maxWidth: '3vw',
            // }
            title={`${patient.BFJUDGVALUE}`}>
            {patient.BFJUDGVALUE}
          </td> */}
        </tr>
      )}
    </Draggable>
  );
};

export default DragRow;
