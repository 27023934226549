import useGNB from "@hooks/store/useGNB";
import useLogined from "@hooks/store/useLogined";
import useProfile from "@hooks/store/useProfile";
import { useCallback, useEffect, useState } from "react";
import AdminNav from "../AdminNav";

const AdminNavContainer = () => {
  const { account, roles, __updateProfileFromHooks } = useProfile();

  const { visible, __updateGNBVisibleFromHooks } = useGNB();

  const { __updateTokenFromHooks } = useLogined();

  const [path, setPath] = useState<string>("");

  const visibleHandler = useCallback(() => {
    __updateGNBVisibleFromHooks(!visible);
  }, [visible]);

  const onLogoutClicked = useCallback(() => {
    __updateTokenFromHooks("");
    __updateProfileFromHooks({
      id: 0,
      account: "",
      myBoard: {
        id: 0,
        roomList: "",
        boardList: "",
        myBoardList: [],
      },
      deptId: null,
      roles: [],
      token: "",
    });
    sessionStorage.clear();
    window.location.href = "/";
  }, []);

  const onLinkClicked = useCallback(
    (link: string) => {
      setPath(link);
    },
    [path]
  );

  useEffect(() => {
    setPath(window.location.pathname.split("/")[1]);
  }, []);

  return (
    <AdminNav
      userRole={roles ? roles[0] : ""}
      account={account}
      path={path}
      onLinkClicked={onLinkClicked}
      onLogoutClicked={onLogoutClicked}
      visibleHandler={visibleHandler}
    />
  );
};

export default AdminNavContainer;
