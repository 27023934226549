import AccountContainer from '@components/Admin/Account/containers/AccountContainer';
import BoardContainer from '@components/Admin/Board/containers/BoardContainer';
import BoardSettingContainer from '@components/Admin/BoardSetting/containers/BoardSettingContainer';
import ClinicContainer from '@components/Admin/Clinic/containers/ClinicContainer';
import PatientContainer from '@components/Admin/Patient/OutPatient/containers/PatientContainer';
import AdminGNBContainer from '@components/AdminGNB/containers/AdminGNBContainer';
import AdminNavContainer from '@components/AdminNav/containers/AdminNavContainer';
import NotFoundContainer from '@components/common/NotFound/containers/NotFoundContainer';
import LoginContainer from '@components/Login/containers/LoginContainer';
import { AdminNavigationStyle, AdminRoutes } from '@routes/styles/AdminNavigationStyle';
import { Route, Routes } from 'react-router-dom';

type Props = {
  visible: boolean;
  token: string | null;
};

const AdminNavigation = ({ visible, token }: Props) => {
  return token ? (
    <AdminNavigationStyle>
      <AdminGNBContainer />
      <AdminRoutes className='content-root' visible={visible}>
        <AdminNavContainer />
        <Routes>
          <Route path='/patient' element={<PatientContainer />} />
          <Route path='/clinics' element={<ClinicContainer />} />
          <Route path='/boards' element={<BoardContainer />} />
          <Route path='/board-setting' element={<BoardSettingContainer />} />
          <Route path='/accounts' element={<AccountContainer />} />
          <Route path='/*' element={<NotFoundContainer msg='Admin Navigation Not Found' />} />
        </Routes>
      </AdminRoutes>
    </AdminNavigationStyle>
  ) : (
    <LoginContainer />
  );
};

export default AdminNavigation;
