import styled from 'styled-components';

export const SurgeryHorizontalWaitingStyle = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  p {
    margin: 0;
  }
`;

export const Thead = styled.section`
  height: 9vh;
  line-height: 9vh;
  display: flex;
  flex-direction: row;
  background-color: #5a5490;

  p {
    width: 20vw;
    text-align: center;
    color: #fff;
    font-size: 5vh;
    font-weight: 600;
  }

  .wide {
    width: 40vw;
  }
`;

export const Tbody = styled.section`
  height: 100%;
  display: flex;
  flex-direction: row;

  .wide {
    width: 40vw;
  }
`;

export const Patients = styled.ul`
  width: 20vw;
  display: flex;
  flex-direction: column;
  margin: 0;
  background-color: lightgray;
  list-style: none;
  border-left: 2px solid #000;

  &:last-child {
    border-right: 2px solid #000;
  }
`;

export const Patient = styled.li`
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;

  p {
    text-align: center;
    color: #000;
    font-size: 5.5vh;
    font-weight: 600;
  }
`;

export const CustomPTag = styled.p<{
  family?: string;
  fz?: number;
  fw?: boolean;
  color?: string;
  align?: string;
}>`
  font-family: ${(props) => props.family};
  font-size: calc(${(props) => props.fz}vh / 11);
  font-weight: ${(props) => (props.fw ? 'bold' : 'normal')};
  color: ${(props) => props.color};
  text-align: ${(props) => props.align ?? 'left'};
`;
