import {
  CModal,
  CModalHeader,
  CModalBody,
  CModalFooter,
  CFormLabel,
  CFormInput,
  CButton,
  CFormSelect,
  CCol,
  CRow,
} from '@coreui/react';
import { AdminClinicInputsType } from '@typedef/components/Admin/Clinic/components/admin.clinic.inputs.type';
import { AdminDeptTypes } from '@typedef/components/Admin/Clinic/components/admin.dept.types';
import { AdminCenterTypes } from '@typedef/components/Admin/Clinic/components/admin.center.types';
import { AdminDoctorTypes } from '@typedef/components/Admin/Clinic/components/admin.doctor.types';
import { AdminBoardTypes } from '@typedef/components/Admin/Clinic/components/admin.board.types';
import { AdminClinicModalItemTypes } from '@typedef/components/Admin/Clinic/admin.clinic.types';

type Props = {
  setCurrentModal: React.Dispatch<React.SetStateAction<AdminClinicModalItemTypes>>;
  inputs: AdminClinicInputsType;
  onChangeRoomCode: (value: string) => void;
  onChangeSelect: (e: React.ChangeEvent<HTMLSelectElement>) => void;
  onChangeboard: (value: string) => void;
  deptList: AdminDeptTypes[] | null;
  centerlist: AdminCenterTypes[] | null;
  doctorList: AdminDoctorTypes[] | null;
  boardList: AdminBoardTypes[] | null;
  searchBoardList: AdminBoardTypes[] | null;
  save: () => Promise<void>;
  roomCodeCheck: () => void;
};

const ClinicAdd = ({
  setCurrentModal,
  inputs,
  onChangeRoomCode,
  onChangeSelect,
  onChangeboard,
  deptList,
  centerlist,
  doctorList,
  boardList,
  searchBoardList,
  save,
  roomCodeCheck,
}: Props) => {
  return (
    <CModal visible={true} onClose={() => setCurrentModal(null)} backdrop='static'>
      <CModalHeader>진료실 추가</CModalHeader>
      <CModalBody>
        <CCol>
          <CFormLabel>
            진료실이름 <span style={{ color: 'red' }}>*</span>
          </CFormLabel>
          <CRow>
            <CCol xs='9' style={{ paddingRight: '0' }}>
              <CFormInput
                style={{ borderRadius: '0.25em 0 0 0.25em' }}
                name='roomCode'
                onChange={(e) => onChangeRoomCode(e.target.value)}
                placeholder='진료실이름'
              />
            </CCol>
            <CCol style={{ paddingLeft: '0' }}>
              <CButton
                onClick={roomCodeCheck}
                style={{ width: '100%', borderRadius: '0 .25em .25em 0' }}
              >
                중복검사
              </CButton>
            </CCol>
          </CRow>
        </CCol>
        <CCol>
          <CFormLabel>진료과</CFormLabel>
          <CFormSelect name='dept' onChange={onChangeSelect}>
            <option value=''>진료과 선택</option>
            {deptList
              ? deptList
                  .sort((a, b) => (a.deptHngNm > b.deptHngNm ? 1 : -1))
                  .map((dept) => (
                    <option key={dept.deptCd} value={dept.deptCd ?? ''}>
                      {dept.deptHngNm}
                    </option>
                  ))
              : null}
          </CFormSelect>
        </CCol>
        <CCol>
          <CFormLabel>센터</CFormLabel>
          <CFormSelect name='center' onChange={onChangeSelect}>
            <option value=''>센터 선택</option>
            {centerlist
              ? centerlist
                  .sort((a, b) => (a.deptHngNm > b.deptHngNm ? 1 : -1))
                  .map((center, index) => (
                    <option key={center.deptCd + index} value={center.deptCd}>
                      {center.deptHngNm}
                    </option>
                  ))
              : null}
          </CFormSelect>
        </CCol>

        <CCol>
          <CFormLabel>담당의사</CFormLabel>
          <CFormSelect name='doctor' onChange={onChangeSelect} value={inputs.doctorId ?? '휴진'}>
            {doctorList
              ? doctorList
                  .sort((a, b) => (a.usernm > b.usernm ? 1 : -1))
                  .map((doctor, index) => (
                    <option key={doctor.userid + index} value={doctor.userid}>
                      {doctor.usernm}
                    </option>
                  ))
              : null}
            <option value='휴진'>휴진</option>
          </CFormSelect>
        </CCol>
        <CFormInput
          label='전광판 검색'
          name='board'
          onChange={(e) => onChangeboard(e.target.value)}
          placeholder='전광판'
          list='boardList'
        />
        <datalist id='boardList'>
          {boardList
            ?.sort((a, b) => (a.boardCode > b.boardCode ? 1 : -1))
            .map((board) => (
              <option value={board.boardCode} key={board.id} />
            ))}
        </datalist>
      </CModalBody>
      <CModalFooter>
        <CButton color='primary' onClick={save}>
          추가하기
        </CButton>
        <CButton color='secondary' onClick={() => setCurrentModal(null)}>
          취소
        </CButton>
      </CModalFooter>
    </CModal>
  );
};

export default ClinicAdd;
