import useGNB from '@hooks/store/useGNB';
import useLogined from '@hooks/store/useLogined';
import { alertMessage } from '@libs/alert';
import { apiRoute, requestSecureGet, requestSecurePost, requestSecurePut } from '@libs/api';
import {
  PatientAlertTypes,
  PatientArriveInfoTypes,
  PatientListItemTypes,
} from '@typedef/components/Admin/Patient/admin.patient.types';
import { useCallback, useEffect, useState } from 'react';
import { CSSProperties } from 'styled-components';
import DragRow from '../components/DragRow';

type Props = {
  roomId: number | null;
  tableId: string;
  patient: PatientListItemTypes;
  patientIdx: number;
  patientList: PatientListItemTypes[];
  cretNo: number;
  searchInput: string;
  searchOption: string;
  canDrag: boolean;
  loadPatientList: (roomId: number) => void;
};
const DragRowContainer = ({
  roomId,
  tableId,
  patient,
  patientIdx,
  cretNo,
  searchInput,
  searchOption,
  canDrag,
  loadPatientList,
}: Props) => {
  const { token } = useLogined();

  const { infection } = useGNB();

  const [borderFlag, setBorderFlag] = useState<boolean>(false);

  const [arriveTime, setArriveTime] = useState<string>('');

  // const [arriveText, setArriveText] = useState<string>('');

  // 도착시간 더블클릭시 실행
  const onArriveDoubleClick = useCallback(async () => {
    // 로그인이 되어있지 않으면 하지 않음.
    if (!token) {
      alertMessage('인증 토큰이 없습니다.');
      return;
    }
    if (
      // 선택된 진료실 번호가 없으면 하지 않음.
      roomId == null ||
      // patient가 완료자 명단에에 위치하면 하지 않음. ( 대기자, 부재자, 검사자 명단에서는 도착시간 찍을수 있음. )
      patient.dispflag === 9
    )
      return;

    //환자가 도착 했을 시 환자의 도착여부, 진행여부를 업데이트 함.
    patient.nursacptyn = patient.arrflag === '1' ? 'N' : 'Y';
    patient.elbulbodstat = patient.arrflag === '1' ? '0' : '1';

    //Patient 객체의 key들을 소문자로 변경 ( 서버 데이터 규격 )
    const patientKeyLowerCase = Object.entries(patient).reduce((b, [key, value]) => {
      b[key.toLowerCase()] = `'${value}'`;
      return b;
    }, {});

    // 환자 도착시간 설정에 대한 alert 정보 가져옴
    const alertResult = await requestSecurePost<PatientAlertTypes>(
      apiRoute.admin.patient.loadPatientAlert,
      {},
      patientKeyLowerCase,
      token,
    );

    // 감염정보 조회
    if (infection && patient.arrflag === '0') {
      const { data, config } = await requestSecureGet<string | null>(
        apiRoute.admin.patient.checkInfection + patient.pid,
        {},
        token,
      );

      if (config.status === 200) {
        if (data) {
          // 감염 정보가 있다면 띄워준다.
          alert(data);
        }
      }
    }

    // 도착시간 data response에 따른 행위 처리 방식은 alertResult.data의 interface를 참고

    // 도착시간 더블클릭시 도착메세지 값에 따라 표시 방법이 다름.
    if (alertResult.config.status === 200) {
      if (alertResult.data === undefined || alertResult.data === null) {
        arriveUpdate();
      } else if (
        // 도착을 하였고 arrivealert이 없다면
        patient.nursacptyn === 'Y' &&
        alertResult.data?.arrivealert === null
      ) {
        // arrivealarm이 없다면 도착 업데이트
        if (!alertResult.data?.arrivealarm) arriveUpdate();
        // arrivealarm이 있다면 alert
        else if (alertResult.data?.arrivealarm) {
          alert(alertResult.data?.arrivealarm);
        }
      } else if (
        // 도착을 하지않았고 취소관련 메세지가 없다면
        patient.nursacptyn === 'N' &&
        !alertResult.data?.cancelarrivealert &&
        !alertResult.data?.cancelarrivealarm
      ) {
        // canclearrveialarm이 없다면 도착 업데이트
        if (!alertResult.data.cancelarrivealert) arriveUpdate();
        // canclearrivealarm 표시
        else if (alertResult.data.cancelarrivealert) {
          alert(alertResult.data.cancelarrivealert);
        }
      } else {
        // 도착을 한 상태
        if (patient.nursacptyn === 'Y') {
          // arrivealarm 표시
          if (alertResult.data?.arrivealarm) {
            alert(alertResult.data?.arrivealarm);
          } else if (alertResult.data?.arrivealert) {
            // arrivealert 표시 및 도착 업데이트
            alert(alertResult.data?.arrivealert);
            arriveUpdate();
          }
        } else {
          // 도착을 안한 상태
          // 완료 후에는 도착시간을 설정할 수 없음.
          if (alertResult.data?.cancelarrivealarm)
            // canclearrivealarm 표시
            alert(alertResult.data.cancelarrivealarm);
          else if (alertResult.data?.arrivealert) {
            // arrivealert 표시 및 도착 업데이트
            alert(alertResult.data?.arrivealert);
            arriveUpdate();
          } else {
            arriveUpdate();
          }
        }
      }
    }
  }, [infection, token, patient]);

  // 도착시간 업데이트
  const arriveUpdate = useCallback(async () => {
    //Patient 객체의 key를 소문자로 만듦
    const patientKeyLowerCase = Object.entries(patient).reduce((b, [key, value]) => {
      b[key.toLowerCase()] = `'${value}'`;
      return b;
    }, {});

    if (!token) return;

    // 1이면 도착 0 이면 도착해제 및 도착X
    const flag: boolean = patient.arrflag === '0';

    // const nowHours = (
    //   new Date().getHours() < 10
    //     ? "0" + new Date().getHours()
    //     : new Date().getHours()
    // ).toString();

    // const nowMinutes = (
    //   new Date().getMinutes() < 10
    //     ? "0" + new Date().getMinutes()
    //     : new Date().getMinutes()
    // ).toString();

    // // 도착설정한 환자의 도착시간 설정
    // setArriveTime(flag ? nowHours + nowMinutes : "");

    // 도착설정한 환자의 진행 컬럼 값 수정
    // setArriveText(flag ? "대기" : "");

    // 도착 설정.
    const { data, config } = await requestSecurePut<PatientArriveInfoTypes>(
      apiRoute.admin.patient.updatePatientArrive + roomId,
      {},
      patientKeyLowerCase,
      token,
    );

    if (config.status === 200) {
      // 도착설정한 환자의 도착시간 설정
      setArriveTime(flag ? data?.nursacptdt : '');

      // 도착설정한 환자의 진행 컬럼 값 수정
      // setArriveText(flag ? '대기' : '');

      // 도착안했을때 도착시간을 더블클릭했으니 도착값을 다 넣어줌
      // 그 반대일땐 다 빼버림
      // 값 출력은 arriveTime, arriveTest status로 하지만
      // 값 비교는 patient.속성 으로 하기 때문에 아래 3줄을 지우면 정상적으로 돌아가지 않음.
      // 삭제 하려면 리팩토링 진행.
      patient.arrflag = flag ? '1' : '0';
      patient.nursacptyn = flag ? 'Y' : 'N';
      patient.nursacptdt = flag ? data.nursacptdt : '';

      if (roomId) {
        // 환자 리스트 업데이트
        loadPatientList(roomId);
      }
    }
  }, [token, patient, roomId, loadPatientList]);

  //환자 검색시 환자에게 씌여줄 border의 boolean을 결정
  const loadBorderFlag = useCallback(() => {
    if (searchOption === 'hngnm' || searchOption === 'pid' || searchOption === 'rrgstno1') {
      if (patient[searchOption].includes(searchInput) && searchInput?.length !== 0)
        setBorderFlag(true);
      else setBorderFlag(false);
    }
  }, [searchOption, searchInput, patient]);

  // tr Element Style 지정
  const getPatientRowStyle = useCallback(
    (isDragging: boolean, draggableStyle: CSSProperties | undefined) => ({
      backgroundColor: patient['bgcolor'],
      border: borderFlag ? '2px solid red' : isDragging ? '1px solid blue' : 'none',

      ...draggableStyle,
    }),
    [borderFlag, patient],
  );

  // td Element Style 지정
  const getPatientColumnStyle = useCallback(
    (
      isDragging: boolean,
      bgColor: string | undefined,
      color: string | undefined,
      maxWidth: number,
      other: CSSProperties | undefined,
    ) => ({
      backgroundColor: bgColor,
      color: color,
      maxWidth: !isDragging ? `${maxWidth}vw` : '',
      width: isDragging ? '4vw' : '',
      ...other,
    }),
    [borderFlag, patient],
  );

  // 환자 검색시 border 처리
  useEffect(() => {
    loadBorderFlag();
  }, [searchOption, searchInput, patient]);

  // 환자 도착시간, 진행 테스트 표기
  useEffect(() => {
    setArriveTime(patient.nursacptdt ?? '');
    // setArriveText(patient.elbulbodstatnm ?? '');
  }, [patient]);

  return (
    <DragRow
      patientIdx={patientIdx}
      patient={patient}
      tableId={tableId}
      cretNo={cretNo}
      borderFlag={borderFlag}
      canDrag={canDrag}
      arriveTime={arriveTime}
      // arriveText={arriveText}
      onArriveDoubleClick={onArriveDoubleClick}
      getPatientRowStyle={getPatientRowStyle}
      getPatientColumnStyle={getPatientColumnStyle}
    />
  );
};

export default DragRowContainer;
