import {
  CModal,
  CModalHeader,
  CButton,
  CModalBody,
  CModalFooter,
  CFormInput,
  CRow,
  CCol,
} from "@coreui/react";
import { faTimesCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  AdminBoardModalItemTypes,
  AdminBoardVariable,
} from "@typedef/components/Admin/Board/admin.board.types";
import BoardVariableStyle from "../styles/BoardVariableStyle";

type Props = {
  updateVariable: () => void;
  setCurrentModal: React.Dispatch<
    React.SetStateAction<AdminBoardModalItemTypes>
  >;
  variableSet: AdminBoardVariable;
  onAddVaraible: () => void;
  onRemoveVariable: (deleteKey: string) => void;
  onChangeKeyHandler: (key: string, value: string) => void;
  onChangeValueHandler: (key: string, value: string) => void;
};
const BoardVariable = ({
  updateVariable,
  setCurrentModal,
  variableSet,
  onAddVaraible,
  onRemoveVariable,
  onChangeKeyHandler,
  onChangeValueHandler,
}: Props) => {
  return (
    <CModal
      visible={true}
      onClose={() => setCurrentModal(null)}
      backdrop="static"
    >
      <BoardVariableStyle>
        <CModalHeader>
          <h5>전광판 변수수정</h5>
        </CModalHeader>
        <CModalBody>
          <div className="variable-list">
            {Object.entries(variableSet).length > 0 && (
              <CRow>
                <CCol xs="4">Key</CCol>
                <CCol xs="4">Value</CCol>
                <CCol xs="4">삭제</CCol>
              </CRow>
            )}
            {Object.entries(variableSet).map((param, index) => (
              <CRow key={param[0] + index}>
                <CCol xs="4">
                  <CFormInput
                    defaultValue={param[0]}
                    onBlur={(e) => {
                      onChangeKeyHandler(param[0], e.target.value);
                    }}
                    placeholder="key"
                  />
                </CCol>
                <CCol xs="4">
                  <CFormInput
                    onBlur={(e) => {
                      onChangeValueHandler(param[0], e.target.value);
                    }}
                    defaultValue={param[1]}
                    placeholder="value"
                  />
                </CCol>
                <CCol xs="4">
                  <CButton
                    color="danger"
                    onClick={() => {
                      onRemoveVariable(param[0]);
                    }}
                  >
                    <FontAwesomeIcon icon={faTimesCircle} />
                  </CButton>
                </CCol>
              </CRow>
            ))}
          </div>
        </CModalBody>
      </BoardVariableStyle>
      <CModalFooter>
        <CButton
          color="success"
          onClick={onAddVaraible}
          style={{ color: "white" }}
        >
          추가
        </CButton>
        <CButton color="primary" onClick={updateVariable}>
          수정하기
        </CButton>
        <CButton color="secondary" onClick={() => setCurrentModal(null)}>
          취소
        </CButton>
      </CModalFooter>
    </CModal>
  );
};

export default BoardVariable;
