import React from 'react';
import * as S from './styles/OutPatientWaitingStyle';
import images from '@assets/images';
import Clock from 'react-live-clock';
import 'moment/locale/ko';
import { maskingName, timeAddColons } from '@libs/monitor';
import {
  LoadBasicConfigResponseDataType,
  LoadFontConfigResponseDataType,
  LoadNoticeConfigResponseDataType,
  LoadVideoConfigItemTypes,
  LoadVisibleConfigResponseDataType,
} from '@typedef/routes/viewer.navigation.types';
import { OutPatientItemTypes } from '@typedef/components/OutPatientWaiting/out.patient.types';
import SubTitleContainer from '@components/SubTitle/containers/SubTitleContainer';
import { parseFontString } from '@libs/factory';
import { apiRoute } from '@libs/api';

type Props = {
  basic: LoadBasicConfigResponseDataType;
  font: LoadFontConfigResponseDataType;
  video: LoadVideoConfigItemTypes[] | null;
  visible: LoadVisibleConfigResponseDataType;
  notice: LoadNoticeConfigResponseDataType;
  waitingPatitent: OutPatientItemTypes[];
  currentReservePatient: OutPatientItemTypes[];
  videoIndex: number;
  setVideoIndex: React.Dispatch<React.SetStateAction<number>>;
  imgRef: React.RefObject<HTMLImageElement>;
  onLoadFunc: () => void;
};

const OutPatientWaiting = ({
  basic,
  font,
  video,
  visible,
  notice,
  waitingPatitent,
  currentReservePatient,
  videoIndex,
  setVideoIndex,
  imgRef,
  onLoadFunc,
}: Props) => {
  return (
    <S.OutPatientWaitingStyle>
      <S.Header>
        <section className='left'>
          {!!!basic?.subtitle ? ( // 전광판 설정의 센터 명칭 입력 확인
            <img src={images.jsolViewerLogo} alt='logoImage' />
          ) : (
            <S.CustomPTag
              family={parseFontString(font.fcenter).family}
              fz={parseFontString(font.fcenter).size}
              fw={parseFontString(font.fcenter).bold}
              color={parseFontString(font.fcenter).color}
            >
              {basic?.subtitle ? basic.subtitle : basic.bcentName}
              {/* 센터이름 */}
            </S.CustomPTag>
          )}
          <S.CustomPTag
            family={parseFontString(font.froomKor).family}
            fz={parseFontString(font.froomKor).size}
            fw={parseFontString(font.froomKor).bold}
            color={parseFontString(font.froomKor).color}
          >
            {basic?.bname} {/* 진료실이름 */}
          </S.CustomPTag>
          <S.CustomPTag
            family={parseFontString(font.froomEng).family}
            fz={parseFontString(font.froomEng).size}
            fw={parseFontString(font.froomEng).bold}
            color={parseFontString(font.froomEng).color}
          >
            {basic?.bnameEng} {/* 진료실이름(영어) */}
          </S.CustomPTag>
        </section>
        <section className='roomNum'>
          <S.CustomPTag
            family={parseFontString(font.froomNumber).family}
            fz={parseFontString(font.froomNumber).size}
            fw={parseFontString(font.froomNumber).bold}
            color={parseFontString(font.froomNumber).color}
          >
            {basic.broomNumber ? basic.broomNumber[0] : null}
          </S.CustomPTag>
          <span style={{ fontFamily: parseFontString(font.froomNumber).family }}>진료실</span>
        </section>
      </S.Header>
      <S.Main>
        <S.Content>
          <S.ContentHeader>
            <section className='doctor'>
              <div className='imageBox'>
                {visible.vdoctorPhoto && basic?.docId ? (
                  <img
                    src={`${apiRoute.viewer.loadDoctorImage + basic?.docId}`}
                    alt='의사사진'
                    onError={(event: React.SyntheticEvent<HTMLImageElement, Event>) => {
                      event.currentTarget.src = images.doctorPlaceholder;
                    }}
                    ref={imgRef}
                    onLoad={onLoadFunc}
                  />
                ) : (
                  <img src={images.doctorPlaceholder} alt='의사사진' />
                )}
              </div>
              <div className='infoBox'>
                <span>진료의사</span>
                <S.CustomPTag
                  family={parseFontString(font.fdoctorKor).family}
                  fz={parseFontString(font.fdoctorKor).size}
                  fw={parseFontString(font.fdoctorKor).bold}
                  color={parseFontString(font.fdoctorKor).color}
                >
                  {basic.docKor === '' ? '휴진' : basic.docKor}
                </S.CustomPTag>
                <S.CustomPTag
                  family={parseFontString(font.fdoctorEng).family}
                  fz={parseFontString(font.fdoctorEng).size}
                  fw={parseFontString(font.fdoctorEng).bold}
                  color={parseFontString(font.fdoctorEng).color}
                >
                  {visible.vdoctorEng ? basic.docEng : ' '}
                </S.CustomPTag>
              </div>
            </section>
            <section className='adminBox'>
              <div className='admin'>
                <span>담당직원</span>
                <S.CustomPTag
                  family={parseFontString(font.fadminKor).family}
                  fz={parseFontString(font.fadminKor).size}
                  fw={parseFontString(font.fadminKor).bold}
                  color={parseFontString(font.fadminKor).color}
                >
                  {visible.vadmin && basic.adminName}
                </S.CustomPTag>
              </div>
            </section>
          </S.ContentHeader>
          <S.Times>
            <div className='clock'>
              {visible.vclock && <Clock className='timeclock' format={'A HH:mm'} ticking={true} />}
            </div>
            <div className='delay'>
              <span>지연시간</span>
              <p>{visible?.vdelay && visible.delayTime ? visible.delayTime + ' 분' : ' '}</p>
            </div>
          </S.Times>
          <S.Patients>
            {waitingPatitent.length > 0
              ? waitingPatitent.map((patient, index) =>
                  index < 3 ? (
                    <S.Patient
                      className={patient?.arrflag === '1' ? 'selected' : 'notArrive'}
                      key={patient?.pid + '-' + index}
                    >
                      <S.CustomPTag
                        family={parseFontString(font.fpatientName).family}
                        fz={parseFontString(font.fpatientName).size}
                        fw={parseFontString(font.fpatientName).bold}
                        color={parseFontString(font.fpatientName).color}
                      >
                        {maskingName(patient?.hngnm, {
                          vmasking: visible?.vmasking,
                          position: visible?.position,
                          maskMark: visible?.maskMark,
                        })?.slice(0, 5)}
                      </S.CustomPTag>
                      <p
                        className='delayDescription'
                        style={{
                          fontFamily: parseFontString(font.fpatientName).family,
                        }}
                      >
                        {index === 0
                          ? '호명하면 들어오십시오'
                          : index === 1
                          ? '다음 순서입니다.'
                          : '잠시만 기다려주세요.'}
                      </p>
                    </S.Patient>
                  ) : null,
                )
              : // 환자리스트가 없을때 빈박스 보여주기(3개)
                ['호명하면 들어오십시오.', '다음 순서입니다.', '잠시만 기다려주세요.'].map(
                  (data, index) => (
                    <S.Patient className='notArrive' key={index}>
                      <p className='name'></p>
                      <p className='delayDescription'>{data}</p>
                    </S.Patient>
                  ),
                )}
          </S.Patients>
          {notice.display ? (
            <S.Notice>
              <S.NoticePre
                family={parseFontString(font.fnotice).family}
                fz={parseFontString(font.fnotice).size}
                fw={parseFontString(font.fnotice).bold}
                color={parseFontString(font.fnotice).color}
                align={notice.align ?? 'left'}
                valign={notice.valign ?? 'top'}
              >
                {notice?.contents}
              </S.NoticePre>
            </S.Notice>
          ) : visible.vvideo ? (
            <S.Video>
              <iframe
                src='https://www.youtube.com/embed/Ngzr42mz57Y?autoplay=1&mute=1&showinfo=0&modestbranding=0&controls=0&loop=1&platlist=Ngzr42mz57Y'
                title='promotion'
                allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share'
                allowFullScreen
              />
              {/* <video
                src={video ? video[videoIndex]?.path : '/assets/videos/pormotion.mp4'}
                autoPlay
                muted
                loop={video ? video.length === 1 : false}
                // loop={video ? (video.length === 1 ? true : false) : false}
                onEnded={() => {
                  if (videoIndex === (video ? video.length : 0) - 1) {
                    setVideoIndex(0);
                  } else {
                    setVideoIndex(videoIndex + 1);
                  }
                }}
             
              /> */}
            </S.Video>
          ) : visible.vlist ? (
            <S.MoreList>
              <header>
                <p>진료 대기자 명단</p>
              </header>
              <ul>
                {currentReservePatient.map((patient, index) => (
                  <li key={patient?.pid + '-' + index}>
                    <p>{patient?.rn ?? ''}</p>
                    <p>{patient?.ordtm ? timeAddColons(patient?.ordtm) : null}</p>
                    <p className='name'>
                      {maskingName(patient?.hngnm, {
                        vmasking: visible?.vmasking,
                        position: visible?.position,
                        maskMark: visible?.maskMark,
                      })}
                    </p>
                  </li>
                ))}
              </ul>
            </S.MoreList>
          ) : null}
        </S.Content>
        <SubTitleContainer height='150vw' />
      </S.Main>
    </S.OutPatientWaitingStyle>
  );
};

export default OutPatientWaiting;
