export const UPDATE_BASIC_CONFIG_ACTION = 'UPDATE_BASIC_CONFIG_ACTION' as const;

export const UPDATE_FONT_CONFIG_ACTION = 'UPDATE_FONT_CONFIG_ACTION' as const;

export const UPDATE_NOTICE_CONFIG_ACTION =
  'UPDATE_NOTICE_CONFIG_ACTION' as const;

export const UPDATE_SUBTITLE_CONFIG_ACTION =
  'UPDATE_SUBTITLE_CONFIG_ACTION' as const;

export const UPDATE_VIDEO_CONFIG_ACTION = 'UPDATE_VIDEO_CONFIG_ACTION' as const;

export const UPDATE_VISIBLE_CONFIG_ACTION =
  'UPDATE_VISIBLE_CONFIG_ACTION' as const;
