import Account from '../Account';
import { useState, useEffect, useCallback } from 'react';
import { apiRoute, requestSecureGet } from '@libs/api';
import useLogined from '@hooks/store/useLogined';
import { AdminAccountLogTypes } from '@typedef/components/Admin/Account/admin.account.log.types';
import { AdminAccountTypes } from '@typedef/components/Admin/Account/admin.account.types';
import { RoleTypes } from '@typedef/common/common.types';
import { TableColumnTypes } from '@typedef/components/Common/CTable/table.column.types';
import CTableButton from '@components/common/CTable/components/CTableButton';
import AccountDeleteContainer from './AccountDeleteContainer';
import AccountUpdateContainer from './AccountUpdateContainer';
import {
  HEALTH_ROLE_KEY,
  LAB_ROLE_KEY,
  OUT_ROLE_KEY,
  PMY_ROLE_KEY,
  ROOT_ROLE_KEY,
  SUR_ROLE_KEY,
} from '@routes/config/admin.nav.confg';

// 접속기록 컬럼
const logsColumns: TableColumnTypes[] = [
  {
    header: '인덱스',
    field: 'index',
  },
  {
    header: '아이디',
    field: 'account',
  },
  {
    header: '소속',
    field: 'role',
  },
  {
    header: '마지막접속',
    field: 'accessTime',
  },
];

// 계정목록 컬럼
const accountColumns: TableColumnTypes[] = [
  {
    header: '인덱스',
    field: 'index',
  },
  {
    header: '아이디',
    field: 'account',
  },
  {
    header: '소속',
    field: 'role',
  },
  {
    header: '액션',
    field: 'action',
    filter: false,
  },
];

const AccountContainer = () => {
  const { token } = useLogined();
  const [AccountLogs, setAccountLogs] = useState<AdminAccountLogTypes[] | null>(null);
  const [accounts, setAccounts] = useState<AdminAccountTypes[] | null>(null);
  const [currentTab, setCurrentTab] = useState<string>('접속기록');
  const [currentModal, setCurrentModal] = useState<'update' | 'delete' | null>(null);
  const [selectedId, setSelectedId] = useState<string | null>(null);

  const [reload, setReload] = useState<number>(0);

  const modal = {
    update: (
      <AccountUpdateContainer
        idx={selectedId}
        setCurrentModal={setCurrentModal}
        reload={reload}
        setReload={setReload}
      />
    ),
    delete: (
      <AccountDeleteContainer
        id={selectedId}
        setCurrentModal={setCurrentModal}
        reload={reload}
        setReload={setReload}
      />
    ),
  };

  // 탭 클릭
  const onClickTab = useCallback((tab: string) => {
    setCurrentTab(tab);
  }, []);

  // 모달 클릭
  const onClickButton = useCallback((type: 'update' | 'delete', id: string) => {
    setCurrentModal(type);
    setSelectedId(id);
  }, []);

  // roles 번역
  const translationRole = useCallback((role: RoleTypes) => {
    if (role === HEALTH_ROLE_KEY) {
      return '검진';
    } else if (role === LAB_ROLE_KEY) {
      return '검사';
    } else if (role === OUT_ROLE_KEY) {
      return '외래';
    } else if (role === PMY_ROLE_KEY) {
      return '약국';
    } else if (role === SUR_ROLE_KEY) {
      return '수술';
    } else {
      return '관리자';
    }
  }, []);

  // 접속 기록 조회
  const getAccountLogs = useCallback(async () => {
    if (!token) return;

    const { config, data } = await requestSecureGet<AdminAccountLogTypes[]>(
      apiRoute.admin.account.getAccountLogs,
      {},
      token,
    );
    if (config.status >= 200 && config.status < 400) {
      const tableData = data.map((data, index) => ({
        ...data,
        index: index + 1,
        role: translationRole(data.roles),
      }));
      setAccountLogs(tableData);
    }
  }, [token, translationRole]);

  // 계정 목록 조회
  const getAccounts = useCallback(async () => {
    if (!token) return;

    const { config, data } = await requestSecureGet<AdminAccountTypes[]>(
      apiRoute.admin.account.getAccounts,
      {},
      token,
    );
    if (config.status >= 200 && config.status < 400) {
      const tableData = data.map((data, index) => ({
        ...data,
        index: index + 1,
        role: translationRole(data.roles),
        action: (
          <CTableButton
            buttons={
              data.roles === ROOT_ROLE_KEY
                ? [
                    {
                      id: data.account,
                      name: '수정',
                      color: '#f9b115',
                      func: () => onClickButton('update', data.account),
                    },
                    {
                      id: data.account,
                      name: '삭제',
                      color: '#e55353',
                      func: () => onClickButton('delete', data.account),
                    },
                  ]
                : [
                    {
                      id: data.account,
                      name: '삭제',
                      color: '#e55353',
                      func: () => onClickButton('delete', data.account),
                    },
                  ]
            }
          />
        ),
      }));
      setAccounts(tableData);
    }
  }, [token, translationRole, onClickButton]);

  useEffect(() => {
    getAccountLogs();
    getAccounts();
  }, [reload]);

  return (
    <Account
      columns={currentTab === '접속기록' ? logsColumns : accountColumns}
      tableData={currentTab === '접속기록' ? AccountLogs : accounts}
      currentTab={currentTab}
      onClickTab={onClickTab}
      modal={modal}
      currentModal={currentModal}
      onClickButton={onClickButton}
    />
  );
};

export default AccountContainer;
