import useGNB from '@hooks/store/useGNB';
import useLogined from '@hooks/store/useLogined';
import {
  apiRoute,
  requestPut,
  requestSecureGet,
  requestSecurePut,
} from '@libs/api';
import { AdminBoardVisibleSettingTypes } from '@typedef/components/Admin/BoardSetting/admin.board.setting.types';
import { ChangeEvent, useCallback, useEffect, useState } from 'react';
import VisibleSetting from '../../components/Visible/VisibleSetting';

type Props = {
  boardId: number;
  btypeName: string;
};
/**
 *  visible, masking, delay는 세트
 * @returns
 */
const VisibleSettingContainer = ({ boardId, btypeName }: Props) => {
  const { token } = useLogined();

  const { selected } = useGNB();

  // visible 설정에서 선택한 값 저장
  const [visible, setVisible] = useState<AdminBoardVisibleSettingTypes>({
    id: boardId,
    vadmin: null,
    vclock: null,
    vdoctorEng: null,
    vdoctorPhoto: null,
    vlist: null,
    vdelay: null,
    vmasking: null,
    vvideo: null,
    delayTime: null,
    position: null,
    maskMark: null,
  });

  // Visible 설정 적용
  const visibleSettingApply = useCallback(async () => {
    if (!token) return;
    if (boardId) {
      await requestSecurePut<AdminBoardVisibleSettingTypes>(
        apiRoute.admin.boardSet.visible + boardId,
        {},
        {
          id: boardId,
          vadmin: visible.vadmin,
          vclock: visible.vclock,
          vdoctorEng: visible.vdoctorEng,
          vdoctorPhoto: visible.vdoctorPhoto,
          vlist: visible.vlist,
          vdelay: visible.vdelay,
          vmasking: visible.vmasking,
          vvideo: visible.vvideo,
        },
        token,
      );
    }
  }, [boardId, token, visible]);

  // 값 변경 핸들러
  const onVisibleCheckBoxHandler = useCallback(
    (name: string) => {
      const checked = !!name.split('_')[1] && name.split('_')[1] === 'visible';

      name = name.split('_')[0];

      if (name === 'vvideo') {
        setVisible((i) => ({
          ...i,
          vvideo: checked,
          vlist: !checked,
        }));
      } else if (name === 'vlist') {
        setVisible((i) => ({
          ...i,
          vvideo: !checked,
          vlist: checked,
        }));
      } else {
        setVisible((i) => ({
          ...i,
          [name]: checked,
        }));
      }
    },
    [visible],
  );

  // VISIBLE 조회
  const loadVisible = useCallback(async () => {
    if (!token) return;
    if (boardId) {
      const { data, config } =
        await requestSecureGet<AdminBoardVisibleSettingTypes>(
          apiRoute.admin.boardSet.visible + boardId,
          {},
          token,
        );

      if (config.status === 200) {
        setVisible({ ...data, id: boardId });
      }
    }
  }, [boardId, token]);

  useEffect(() => {
    loadVisible();
  }, [boardId, selected, token]);

  return (
    <VisibleSetting
      boardId={boardId}
      btypeName={btypeName}
      visible={visible}
      visibleSettingApply={visibleSettingApply}
      onVisibleCheckBoxHandler={onVisibleCheckBoxHandler}
    />
  );
};

export default VisibleSettingContainer;
