import useBoardSet from '@hooks/store/useBoardSet';
import useGNB from '@hooks/store/useGNB';
import useLogined from '@hooks/store/useLogined';
import { alertMessage } from '@libs/alert';
import {
  apiRoute,
  requestSecureDelete,
  requestSecureGet,
  requestSecurePost,
  requestSecurePut,
} from '@libs/api';
import { AdminBoardSettingSubtitleTypes } from '@typedef/components/Admin/BoardSetting/admin.board.setting.types';
import { useCallback, useEffect, useState } from 'react';
import SubtitleSetting from '../../components/Subtitle/SubtitleSetting';

type Props = {
  boardId: number;
};
const SubtitleSettingContainer = ({ boardId }: Props) => {
  const { token } = useLogined();

  const { selected } = useGNB();

  const { subtitleReload, __updateBoardSetSubtitleFromHooks } = useBoardSet();

  // 전광판 자막 정보
  const [subtitle, setSubtitle] = useState<AdminBoardSettingSubtitleTypes>({
    id: boardId,
    speed: 0,
    display: false,
    contents: [],
  });

  // 적용
  const onSubtitleApplyClick = useCallback(async () => {
    if (!token) return;

    const subtitleUpdate = await requestSecurePut<any>(
      apiRoute.admin.boardSet.subtitle + boardId,
      {},
      {
        display: subtitle.display,
        id: subtitle.id,
        speed: subtitle.speed,
      },
      token,
    );

    const subtitleContentsUpdate = await requestSecurePut<any>(
      apiRoute.admin.boardSet.subtitleContents,
      {},
      subtitle.contents,
      token,
    );

    if (
      subtitleUpdate.config.status === 200 &&
      subtitleContentsUpdate.config.status === 200
    ) {
      __updateBoardSetSubtitleFromHooks(subtitleReload + 1);
    }
  }, [boardId, token, subtitle, subtitle.contents, subtitleReload]);

  // 추가
  const onSubtitleAddClick = useCallback(async () => {
    if (!token) return;

    const { config } = await requestSecurePost<any>(
      apiRoute.admin.boardSet.subtitleContents,
      {},
      {
        boardIdx: boardId,
      },
      token,
    );

    if (config.status === 200) {
      __updateBoardSetSubtitleFromHooks(subtitleReload + 1);
    }
  }, [boardId, token, subtitleReload]);

  // 삭제
  const onSubtitleRemoveClick = useCallback(
    async (contentId: number) => {
      if (!token) return;

      const { config } = await requestSecureDelete<any>(
        apiRoute.admin.boardSet.subtitleContents,
        {},
        [contentId],
        token,
      );

      if (config.status === 200) {
        __updateBoardSetSubtitleFromHooks(subtitleReload + 1);
      }
    },
    [token, subtitleReload],
  );

  // 표시여부, 스피드 값 변경
  const onSubtitleChange = useCallback(
    (display: boolean, speed: number) => {
      setSubtitle({
        ...subtitle,
        display: display,
        speed: speed,
      });
    },
    [subtitle],
  );

  // 자막 체크박스, 내용 값 변경
  const onContentsChange = useCallback(
    (contentId: number, content: string, checkFlag: boolean) => {
      subtitle.contents.map((item) => {
        if (item.idx === contentId) {
          item.contents = content;
          item.checkFlag = checkFlag;
        }
      });

      setSubtitle({
        ...subtitle,
        contents: subtitle.contents,
      });
    },
    [subtitle],
  );

  // 자막 불러오기
  const loadSubtitle = useCallback(async () => {
    if (!token) return;

    const { data, config } =
      await requestSecureGet<AdminBoardSettingSubtitleTypes>(
        apiRoute.admin.boardSet.subtitle + boardId,
        {},
        token,
      );
    if (config.status === 200) {
      setSubtitle({
        ...data,
        contents: data.contents.sort((prev, next) => next.idx - prev.idx),
      });
    }
  }, [boardId, token]);

  useEffect(() => {
    loadSubtitle();
  }, [boardId, token, selected, subtitleReload]);

  return (
    <SubtitleSetting
      subtitle={subtitle}
      contents={subtitle.contents}
      onSubtitleAddClick={onSubtitleAddClick}
      onSubtitleApplyClick={onSubtitleApplyClick}
      onSubtitleRemoveClick={onSubtitleRemoveClick}
      onSubtitleChange={onSubtitleChange}
      onContentsChange={onContentsChange}
    />
  );
};

export default SubtitleSettingContainer;
