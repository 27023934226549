import { useState, useMemo, useCallback, useEffect } from 'react';
import ExaminationPatientListWaiting from '../components/ExaminationPatientListWaiting';
import useViewer from '@hooks/store/useViewer';
import { getCurrentList } from '@libs/factory';
import { alertMessage } from '@libs/alert';
import { apiRoute, requestGet } from '@libs/api';
import { ExaminationPatientItemTypes } from '@typedef/components/ExaminationPatientWaiting/examination.patient.waiting.types';

type Props = {
  index: number;
  isDoubleList?: boolean;
};

const LOAD_PATIENT_LIST_INTERVAL = 5000;

const ExaminationPatientListWaitingContainer = ({
  index,
  isDoubleList,
}: Props) => {
  const pageSize = isDoubleList ? 20 : 10;
  const counterAdder = isDoubleList ? 2 : 1;

  const { basic, font, visible } = useViewer();
  // const { basic, font } = useViewer();

  const [examinationPatient, setExaminationPatient] = useState<
    ExaminationPatientItemTypes[]
  >([]);

  const [intervalCounter, setIntervalCounter] = useState<number>(0);

  const currentExaminationPatient = useMemo(() => {
    return getCurrentList(
      examinationPatient ?? [],
      pageSize,
      intervalCounter,
    ) as ExaminationPatientItemTypes[];
  }, [examinationPatient, intervalCounter, pageSize]);

  const loadExaminationPatient = useCallback(async () => {
    if (!basic) {
      alertMessage('전광판 기본 정보를 불러오지 못했습니다!');
      return;
    }

    const { data, config } = await requestGet<ExaminationPatientItemTypes[]>(
      apiRoute.viewer.examination.loadByRooms +
        (basic.roomList ? basic.roomList[index] : ''),
      {},
    );

    if (config.status === 200) {
      if (basic.roomList) {
        if (data[basic.roomList[index]]) {
          const newArray: ExaminationPatientItemTypes[] = data[
            basic.roomList[index]
          ].map((patient, index) => {
            return {
              ...patient,
              CNT: index + 1,
            };
          });
          setExaminationPatient(newArray);
        } else {
          setExaminationPatient([]);
        }
      } else {
        setExaminationPatient([]);
      }
    } else {
      setExaminationPatient([]);
      alertMessage('검사실 환자 정보를 불러오지 못했습니다');
    }
  }, [basic, index]);

  useEffect(() => {
    loadExaminationPatient();
  }, [basic, intervalCounter, loadExaminationPatient]);

  useEffect(() => {
    const interval = setInterval(() => {
      setIntervalCounter((prev) => prev + counterAdder);
    }, LOAD_PATIENT_LIST_INTERVAL);

    return () => {
      clearInterval(interval);
    };
  }, [counterAdder]);

  return basic && font && visible ? (
    <ExaminationPatientListWaiting
      isDoubleList={isDoubleList}
      font={font}
      visible={visible}
      broomNumber={basic.broomNumber ? basic.broomNumber[index] : null}
      currentExaminationPatient={currentExaminationPatient}
      currentExaminationPatient2={
        isDoubleList ? currentExaminationPatient.slice(pageSize / 2) : []
      }
    />
  ) : null;
};
export default ExaminationPatientListWaitingContainer;
