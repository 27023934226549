import { AdminBoardSettingTypeSettingTypes } from '@typedef/components/Admin/BoardSetting/components/Header/admin.board.setting.type.setting.types';

export const TypeSettingData: AdminBoardSettingTypeSettingTypes = {
  ROLE_OUT: [
    {
      name: '외래대기전광판',
      src: 'iconOutPatient',
    },
    {
      name: '외래4열대기전광판',
      src: 'iconQuad',
    },
    // {
    //   name: '1열/영상/1열 (외래)',
    //   src: 'iconListVideoList',
    // },
    // {
    //   name: '2열/영상 (외래)',
    //   src: 'iconList2Video',
    // },
    // {
    //   name: '영상/2열 (외래)',
    //   src: 'iconVideoList2',
    // },
    // {
    //   name: '1열/영상 (외래)',
    //   src: 'iconList1Video',
    // },
    // {
    //   name: '영상/1열 (외래)',
    //   src: 'iconVideoList1',
    // },
    {
      name: '1열/영상/1열 (대대합)',
      src: 'iconListVideoList',
    },
    {
      name: '2열/영상 (대대합)',
      src: 'iconList2Video',
    },
    {
      name: '영상/2열 (대대합)',
      src: 'iconVideoList2',
    },
    {
      name: '열',
      src: 'iconLine4',
    },
    {
      name: '동영상 (대대합)',
      src: 'iconVideo',
    },
    {
      name: '항암주사실',
      src: 'iconVideoList1',
    },
  ],
  ROLE_LAB: [
    {
      name: '2열/영상',
      src: 'iconList2Video',
    },
    {
      name: '영상/2열',
      src: 'iconVideoList2',
    },
    {
      name: '1열/영상/1열',
      src: 'iconListVideoList',
    },
    {
      name: '1열/영상',
      src: 'iconList1Video',
    },
    {
      name: '영상/1열',
      src: 'iconVideoList1',
    },
    {
      name: '내시경',
      src: 'iconVideoList3Endoscope',
    },
    // {
    //   name: "4열",
    //   src: "iconLine4",
    // },
    // {
    //   name: "3열",
    //   src: "iconLine3",
    // },
    // {
    //   name: "2열",
    //   src: "iconLine2",
    // },
    // {
    //   name: "1열",
    //   src: "iconLine1",
    // },
  ],
  ROLE_SUR: [
    {
      name: '수술실 타입1',
      src: 'iconSurgery',
    },
    {
      name: '수술실 타입2',
      src: 'iconSurgery2',
    },
  ],
  ROLE_PMY: [
    {
      name: '외래약국',
      src: 'iconPharmacy2',
    },
    {
      name: '퇴원약국',
      src: 'iconPharmacy',
    },
  ],
  ROLE_HEALTH: [
    {
      name: '외래검진',
      src: 'iconOutPatient',
    },
    {
      name: '검사검진',
      src: 'iconHealthLab',
    },
    {
      name: '종합현황검진',
      src: 'iconHealthAll',
    },
  ],
};
