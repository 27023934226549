import useViewer from '@hooks/store/useViewer';
import { alertMessage } from '@libs/alert';
import { apiRoute, requestGet } from '@libs/api';
import { getCurrentList } from '@libs/factory';
import {
  OutLabRoomItemTypes,
  OutLabPatientItemTypes,
} from '@typedef/components/OutLabPatient/outlabpatient.waiting.types';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import OutLabLargePatientListWaiting from '../components/OutLabLargePatientListWaiting';

type Props = {
  roomIdx: string;
  roomNum: string;
};

const LOAD_PATIENT_LIST_INTERVAL = 5000;

const PAGE_SIZE = 10;

const OutLabLargePatientListWaitingContainer = ({ roomIdx, roomNum }: Props) => {
  const { basic, font, visible } = useViewer();
  const [reservePatient, setReservePatient] = useState<OutLabPatientItemTypes[]>([]);

  const [intervalCounter, setIntervalCounter] = useState<number>(0);

  const [room, setRoom] = useState<OutLabRoomItemTypes | null>(null);

  const currentReservePatient = useMemo(() => {
    return getCurrentList(reservePatient ?? [], PAGE_SIZE, intervalCounter);
  }, [reservePatient, intervalCounter]);

  const loadDoctor = useCallback(async () => {
    if (!basic) {
      alertMessage('전광판 기본 정보를 불러오지 못했습니다!');
      return;
    }

    if (!roomIdx) {
      return;
    }

    const { data, config } = await requestGet<OutLabRoomItemTypes>(
      apiRoute.viewer.outlabPatient.loadClinic + roomIdx ?? 0,
      {},
    );
    if (config.status === 200) {
      setRoom(data);
    } else {
      setRoom(null);
    }
  }, [basic, roomIdx]);

  const loadReservePatient = useCallback(async () => {
    if (!basic) {
      alertMessage('전광판 기본 정보를 불러오지 못했습니다!');
      return;
    }

    if (!roomIdx) {
      return;
    }

    const { data, config } = await requestGet<{
      [key: string]: OutLabPatientItemTypes[];
    }>(apiRoute.viewer.outlabPatient.loadWaiterList + roomIdx ?? 0, {});

    if (config.status === 200) {
      if (Object.keys(data).length) {
        if (basic.roomList) {
          setReservePatient(data[roomIdx ?? 0] ?? []);
        }
      } else {
        setReservePatient([]);
      }
    } else {
      setReservePatient([]);
    }
  }, [basic, roomIdx]);

  useEffect(() => {
    loadReservePatient();

    loadDoctor();
  }, [basic, loadDoctor, intervalCounter, loadReservePatient]);

  useEffect(() => {
    const interval = setInterval(() => {
      setIntervalCounter((prev) => prev + 1);
    }, LOAD_PATIENT_LIST_INTERVAL);

    return () => {
      clearInterval(interval);
    };
  }, []);

  return font && visible ? (
    <OutLabLargePatientListWaiting
      broomNumber={roomNum}
      room={room ?? null}
      font={font}
      visible={visible}
      subtitle={basic?.subtitle ? basic?.subtitle : null}
      currentReservePatient={currentReservePatient}
    />
  ) : null;
};

export default OutLabLargePatientListWaitingContainer;
