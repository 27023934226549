import useViewer from "@hooks/store/useViewer";
import { alertMessage } from "@libs/alert";
import React, { useEffect, useState } from "react";
import SurgeryWaiting from "../SurgeryWaiting";

const SURGERY_ONE_BTYPENAME = "수술실 타입1";
const SURGERY_TWO_BTYPENAME = "수술실 타입2";

// 수술실 전체
const SurgeryWaitingContainer = () => {
  const { basic, visible } = useViewer();

  const [componentFlag, setComponentFlag] = useState<string>("");

  useEffect(() => {
    if (!basic) {
      alertMessage("전광판 기본 정보가 없습니다");
      return;
    }

    if (basic.btypeName === SURGERY_ONE_BTYPENAME) {
      setComponentFlag("수술실 타입1");
    } else if (basic.btypeName === SURGERY_TWO_BTYPENAME) {
      setComponentFlag("수술실 타입2");
    }
  }, [basic]);

  return visible ? (
    <SurgeryWaiting visible={visible} componentFlag={componentFlag} />
  ) : null;
};

export default React.memo(SurgeryWaitingContainer);
