import { maskingName } from '@libs/monitor';
import {
  LoadFontConfigResponseDataType,
  LoadVisibleConfigResponseDataType,
} from '@typedef/routes/viewer.navigation.types';
import * as S from '../styles/SurgeryHorizontalWaitingStyle';
import { parseFontString } from '@libs/factory';

type Props = {
  font: LoadFontConfigResponseDataType;
  visible: LoadVisibleConfigResponseDataType;
  surgery2WaitingPatient: string[];
  surgery2OnGoingPatient: string[];
  surgery2RecoveringPatient: string[];
  surgery2ExitPatient: string[];
  surgery2ExitPlace: string[];
};

const SurgeryHorizontalWaiting = ({
  font,
  visible,
  surgery2WaitingPatient,
  surgery2OnGoingPatient,
  surgery2RecoveringPatient,
  surgery2ExitPatient,
  surgery2ExitPlace,
}: Props) => {
  return (
    <S.SurgeryHorizontalWaitingStyle>
      <S.Thead>
        <p>대기중</p>
        <p>수술중</p>
        <p>회복중</p>
        <p>퇴&nbsp;실</p>
        <p className='wide'>퇴실장소</p>
      </S.Thead>
      <S.Tbody>
        <S.Patients>
          {surgery2WaitingPatient.map((data, index) => (
            <S.Patient key={index}>
              <S.CustomPTag
                family={parseFontString(font.fpatientName).family}
                fz={parseFontString(font.fpatientName).size}
                fw={parseFontString(font.fpatientName).bold}
                color={parseFontString(font.fpatientName).color}>
                {Object.keys(data).length === 0
                  ? ''
                  : maskingName(data, {
                      vmasking: visible.vmasking,
                      position: visible.position,
                      maskMark: visible.maskMark,
                    })}
              </S.CustomPTag>
            </S.Patient>
          ))}
        </S.Patients>
        <S.Patients style={{ backgroundColor: '#88a2bc' }}>
          {surgery2OnGoingPatient.map((data, index) => (
            <S.Patient key={index}>
              <S.CustomPTag
                family={parseFontString(font.fpatientName).family}
                fz={parseFontString(font.fpatientName).size}
                fw={parseFontString(font.fpatientName).bold}
                color={parseFontString(font.fpatientName).color}>
                {Object.keys(data).length === 0
                  ? ''
                  : maskingName(data, {
                      vmasking: visible.vmasking,
                      position: visible.position,
                      maskMark: visible.maskMark,
                    })}
              </S.CustomPTag>
            </S.Patient>
          ))}
        </S.Patients>
        <S.Patients>
          {surgery2RecoveringPatient.map((data, index) => (
            <S.Patient key={index}>
              <S.CustomPTag
                family={parseFontString(font.fpatientName).family}
                fz={parseFontString(font.fpatientName).size}
                fw={parseFontString(font.fpatientName).bold}
                color={parseFontString(font.fpatientName).color}>
                {Object.keys(data).length === 0
                  ? ''
                  : maskingName(data, {
                      vmasking: visible.vmasking,
                      position: visible.position,
                      maskMark: visible.maskMark,
                    })}
              </S.CustomPTag>
            </S.Patient>
          ))}
        </S.Patients>
        <S.Patients style={{ backgroundColor: '#88a2bc' }}>
          {surgery2ExitPatient.map((data, index) => (
            <S.Patient key={index}>
              <S.CustomPTag
                family={parseFontString(font.fpatientName).family}
                fz={parseFontString(font.fpatientName).size}
                fw={parseFontString(font.fpatientName).bold}
                color={parseFontString(font.fpatientName).color}>
                {Object.keys(data).length === 0
                  ? ''
                  : maskingName(data, {
                      vmasking: visible.vmasking,
                      position: visible.position,
                      maskMark: visible.maskMark,
                    })}
              </S.CustomPTag>
            </S.Patient>
          ))}
        </S.Patients>
        <S.Patients className='wide' style={{ backgroundColor: '#88a2bc' }}>
          {surgery2ExitPlace.map((data, index) => (
            <S.Patient key={index}>
              <S.CustomPTag
                family={parseFontString(font.fpatientName).family}
                fz={parseFontString(font.fpatientName).size}
                fw={parseFontString(font.fpatientName).bold}
                color={parseFontString(font.fpatientName).color}>
                {Object.keys(data).length === 0 ? '' : data}
              </S.CustomPTag>
            </S.Patient>
          ))}
        </S.Patients>
      </S.Tbody>
    </S.SurgeryHorizontalWaitingStyle>
  );
};

export default SurgeryHorizontalWaiting;
