import { CButton, CCard, CCardBody, CCardHeader, CFormSelect } from '@coreui/react';
import { faMinusCircle, faRedo } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  AMPMTypes,
  PatientListItemTypes,
} from '@typedef/components/Admin/Patient/admin.patient.types';
import { MyBoardListItemTypes } from '@typedef/components/Login/login.types';
import DropTableContainer from '../containers/DropTableContainer';
import MultiRoomItemStyle from '../styles/MultiRoomItemStyle';

type Props = {
  room: MyBoardListItemTypes;
  roomIdx: number;
  selectRoomList: number[];
  myRoomList: MyBoardListItemTypes[];
  resvList: PatientListItemTypes[];
  absnList: PatientListItemTypes[];
  checkList: PatientListItemTypes[];
  doneList: PatientListItemTypes[];
  ampm: AMPMTypes;
  delayBtn: boolean;
  searchInput: string;
  searchOption: string;
  onDeleteClick: () => void;
  onAmPmSelect: (ampm: AMPMTypes) => void;
  onRoomSelected: (roomId: number) => void;
  onRefreshClicked: () => void;
  loadPatientList: (roomId: number) => void;
};
const MultiRoomItem = ({
  room,
  roomIdx,
  selectRoomList,
  myRoomList,
  resvList,
  absnList,
  checkList,
  doneList,
  ampm,
  delayBtn,
  searchInput,
  searchOption,
  onDeleteClick,
  onAmPmSelect,
  onRoomSelected,
  onRefreshClicked,
  loadPatientList,
}: Props) => {
  return (
    <MultiRoomItemStyle>
      <CCard>
        <CCardHeader>
          <CFormSelect
            className='room-list-select'
            value={Number(room?.roomId)}
            onChange={(e) => {
              onRoomSelected(Number(e.target.value));
            }}
          >
            <option value={0}>진료실 선택</option>

            {myRoomList.map((roomData) =>
              !selectRoomList.includes(roomData?.roomId ?? 0) ? (
                <option key={roomData.roomId} value={String(roomData.roomId)}>
                  {roomData.roomName}
                </option>
              ) : roomData.roomId === room.roomId ? (
                <option key={roomData.roomId} value={String(room.roomId)}>
                  {room.roomName}
                </option>
              ) : null,
            )}
          </CFormSelect>
          <CFormSelect
            className='ampm-select'
            onChange={(e) => {
              if (e.target.value === 'am') onAmPmSelect({ label: '오전', type: 'am' });
              if (e.target.value === 'pm') onAmPmSelect({ label: '오후', type: 'pm' });
            }}
            value={ampm?.type}
          >
            <option value='am'>오전</option>
            <option value='pm'>오후</option>
          </CFormSelect>
          <p className='doctor-name'>
            교수 : <span>&nbsp;{room?.docname}</span>
          </p>
          <p className='patient-length'>
            <span>
              {`${resvList?.length + absnList?.length + checkList?.length + doneList?.length}`}
            </span>
            명
          </p>
          <div className='refresh'>
            <CButton color='dark' onClick={onRefreshClicked} disabled={delayBtn ? true : false}>
              <FontAwesomeIcon icon={faRedo} color='white' />
            </CButton>
          </div>
          <CButton color='white' className='remove' onClick={onDeleteClick}>
            <FontAwesomeIcon icon={faMinusCircle} color='red' />
          </CButton>
        </CCardHeader>
        <CCardBody>
          <div className='drag-table-long'>
            <DropTableContainer
              roomId={room?.roomId}
              tableId={`resv-${roomIdx}`}
              patientList={resvList}
              searchInput={searchInput}
              searchOption={searchOption}
              loadPatientList={loadPatientList}
            />
          </div>
          <div className='drag-table-shot'>
            <DropTableContainer
              roomId={room?.roomId}
              tableId={`absn-${roomIdx}`}
              patientList={absnList}
              searchInput={searchInput}
              searchOption={searchOption}
              loadPatientList={loadPatientList}
            />
          </div>
          <div className='drag-table-shot'>
            <DropTableContainer
              roomId={room?.roomId}
              tableId={`check-${roomIdx}`}
              patientList={checkList}
              searchInput={searchInput}
              searchOption={searchOption}
              loadPatientList={loadPatientList}
            />
          </div>
          <div className='drag-table-shot'>
            <DropTableContainer
              roomId={room?.roomId}
              tableId={`done-${roomIdx}`}
              patientList={doneList}
              searchInput={searchInput}
              searchOption={searchOption}
              loadPatientList={loadPatientList}
            />
          </div>
        </CCardBody>
      </CCard>
    </MultiRoomItemStyle>
  );
};

export default MultiRoomItem;
