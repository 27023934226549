import {
  AMPMTypes,
  dispFlag,
  PatientListItemTypes,
} from '@typedef/components/Admin/Patient/admin.patient.types';
import { MyBoardListItemTypes } from '@typedef/components/Login/login.types';
import { useCallback, useMemo, useState } from 'react';
import MultiRoomItem from '../components/MultiRoomItem';

type Props = {
  room: MyBoardListItemTypes;
  roomIdx: number;
  activeRoomList: MyBoardListItemTypes[];
  myRoomList: MyBoardListItemTypes[];
  patientList: PatientListItemTypes[];
  ampmList: AMPMTypes;
  searchInput: string;
  searchOption: string;
  onRoomSelected: (roomId: number, roomIdx: number) => void;
  onDeleteClicked: (roomId: number, roomIdx: number) => void;
  onRefreshClicked: (index: number) => void;
  onAmPmSelected: (roomIdx: number, ampm: AMPMTypes) => void;
  loadPatientList: (roomId: number) => void;
};

const MultiRoomItemContainer = ({
  room,
  roomIdx,
  activeRoomList,
  myRoomList,
  patientList,
  ampmList,
  searchInput,
  searchOption,
  onRoomSelected: _onRoomSelected,
  onDeleteClicked: _onDeleteClicked,
  onRefreshClicked: _onRefreshClicked,
  onAmPmSelected,
  loadPatientList,
}: Props) => {
  const [delayBtn, setDelayBtn] = useState<boolean>(false);

  // 대기자명단
  const resvList = useMemo(
    () =>
      patientList?.length === 0
        ? []
        : patientList
            ?.filter((item) => item.dispflag === dispFlag.RESV)
            .filter((item) => {
              if (item.dispalltime === 'Y') return true;
              if (ampmList?.type === 'am') return parseInt(item.ordtm) < 1230;
              if (ampmList?.type === 'pm') return parseInt(item.ordtm) >= 1230;
            }),
    [patientList, ampmList],
  );

  // 부재자명단
  const absnList = useMemo(
    () =>
      patientList?.length === 0
        ? []
        : patientList?.filter((item) => item.dispflag === dispFlag.ABSN),
    [patientList],
  );

  // 검사자명단
  const checkList = useMemo(
    () =>
      patientList?.length === 0
        ? []
        : patientList?.filter((item) => item.dispflag === dispFlag.CHECK),
    [patientList],
  );

  // 완료자명단
  const doneList = useMemo(
    () =>
      patientList?.length === 0
        ? []
        : patientList
            ?.filter((item) => item?.dispflag === dispFlag?.DONE)
            .filter((item) => {
              if (ampmList?.type === 'am') return parseInt(item.ordtm) < 1230;
              if (ampmList?.type === 'pm') return parseInt(item.ordtm) >= 1230;
            }),
    [patientList, ampmList],
  );

  // 현재 선택한 진료실
  const selectRoomList: number[] = useMemo(
    () => activeRoomList.map((item) => item.roomId ?? 0),
    [activeRoomList],
  );

  // 진료실 선택
  const onRoomSelected = useCallback(
    (roomId: number) => {
      _onRoomSelected(roomId, roomIdx);
    },
    [_onRoomSelected, roomIdx],
  );

  // 멀티진료실 삭제
  const onDeleteClick = useCallback(() => {
    _onDeleteClicked(room?.roomId ?? 0, roomIdx);
  }, [_onDeleteClicked, room, roomIdx]);

  // 진료실 새로고침
  const onRefreshClicked = useCallback(() => {
    _onRefreshClicked(roomIdx);
    setDelayBtn(true);

    setTimeout(() => {
      setDelayBtn(false);
    }, 1500);
  }, [_onRefreshClicked, roomIdx]);

  // 오전,오후 선택
  const onAmPmSelect = useCallback(
    (ampm: AMPMTypes) => {
      onAmPmSelected(roomIdx, ampm);
    },
    [roomIdx],
  );

  return (
    <MultiRoomItem
      room={room}
      roomIdx={roomIdx}
      selectRoomList={selectRoomList}
      myRoomList={myRoomList}
      resvList={resvList}
      absnList={absnList}
      checkList={checkList}
      doneList={doneList}
      ampm={ampmList}
      delayBtn={delayBtn}
      searchInput={searchInput}
      searchOption={searchOption}
      onDeleteClick={onDeleteClick}
      onAmPmSelect={onAmPmSelect}
      onRoomSelected={onRoomSelected}
      onRefreshClicked={onRefreshClicked}
      loadPatientList={loadPatientList}
    />
  );
};

export default MultiRoomItemContainer;
