import useViewer from '@hooks/store/useViewer';
import { alertMessage } from '@libs/alert';
import { apiRoute, requestGet } from '@libs/api';
import { getCurrentList } from '@libs/factory';
import { ExaminationEndoscopePatientItemTypes } from '@typedef/components/ExaminationEndoscopePatientWaiting/examination.patient.waiting.types';
import { useCallback, useEffect, useMemo, useState } from 'react';
import ExaminationEndoscopePatientListWaiting from '../components/ExaminationEndoscopePatientListWaiting';

const LOAD_PATIENT_LIST_INTERVAL = 5000;
const ExaminationEndoscopePatientListWaitingContainer = () => {
  const { basic, font, visible } = useViewer();

  const [waitingPatient, setWaitingPatient] = useState<ExaminationEndoscopePatientItemTypes[]>([]);

  const [examinationPatient, setExaminationPatient] = useState<
    ExaminationEndoscopePatientItemTypes[]
  >([]);

  const [healingPatient, setHealingPatient] = useState<ExaminationEndoscopePatientItemTypes[]>([]);

  const [intervalCounter, setIntervalCounter] = useState<number>(0);

  const currentWaitingPatient = useMemo(() => {
    return getCurrentList(
      waitingPatient ?? [],
      10,
      intervalCounter,
    ) as ExaminationEndoscopePatientItemTypes[];
  }, [waitingPatient, intervalCounter]);

  const currentExaminationPatient = useMemo(() => {
    return getCurrentList(
      examinationPatient ?? [],
      10,
      intervalCounter,
    ) as ExaminationEndoscopePatientItemTypes[];
  }, [examinationPatient, intervalCounter]);

  const currentHealingPatient = useMemo(() => {
    return getCurrentList(
      healingPatient ?? [],
      10,
      intervalCounter,
    ) as ExaminationEndoscopePatientItemTypes[];
  }, [healingPatient, intervalCounter]);

  const loadPatient = useCallback(async () => {
    if (!basic) {
      alertMessage('전광판 기본 정보를 불러오지 못했습니다!');
      return;
    }

    const { data, config } = await requestGet<ExaminationEndoscopePatientItemTypes[]>(
      apiRoute.viewer.examination.loadEndoscope + (basic.roomList ? basic.roomList[0] : ''),
      {},
    );

    if (config.status === 200) {
      if (basic.roomList) {
        setWaitingPatient([]);
        setExaminationPatient([]);
        setHealingPatient([]);
        if (data[basic.roomList[0]]) {
          const patientsData: ExaminationEndoscopePatientItemTypes[] = data[basic.roomList[0]];

          const waitingPatientsData = patientsData.filter(
            (patient: ExaminationEndoscopePatientItemTypes) => {
              if (patient.execprcpstatnm === '대기중') return true;
            },
          );

          const examinationPatientsData = patientsData.filter(
            (patient: ExaminationEndoscopePatientItemTypes) => {
              if (patient.execprcpstatnm === '검사중') return true;
            },
          );

          const healingPatientsData = patientsData.filter(
            (patient: ExaminationEndoscopePatientItemTypes) => {
              if (patient.execprcpstatnm === '회복중') return true;
            },
          );

          setWaitingPatient(waitingPatientsData);
          setExaminationPatient(examinationPatientsData);
          setHealingPatient(healingPatientsData);
        } else {
          setWaitingPatient([]);
          setExaminationPatient([]);
          setHealingPatient([]);
        }
      } else {
        setWaitingPatient([]);
        setExaminationPatient([]);
        setHealingPatient([]);
      }
    } else {
      setWaitingPatient([]);
      setExaminationPatient([]);
      setHealingPatient([]);
    }
  }, [basic]);

  useEffect(() => {
    loadPatient();
  }, [basic, intervalCounter, loadPatient]);

  useEffect(() => {
    const interval = setInterval(() => {
      setIntervalCounter((prev) => prev + 1);
    }, LOAD_PATIENT_LIST_INTERVAL);

    return () => {
      clearInterval(interval);
    };
  }, []);

  return basic && font && visible ? (
    <ExaminationEndoscopePatientListWaiting
      font={font}
      visible={visible}
      currentWaitingPatient={currentWaitingPatient}
      currentExaminationPatient={currentExaminationPatient}
      currentHealingPatient={currentHealingPatient}
    />
  ) : null;
};

export default ExaminationEndoscopePatientListWaitingContainer;
