import { OutPatientItemTypes } from '@typedef/components/OutPatientWaiting/out.patient.types';
import { LoadBasicConfigResponseDataType } from '@typedef/routes/viewer.navigation.types';
import * as Style from '../styles/OutPatientMiniWaitingStyle';

type Props = {
  basic: LoadBasicConfigResponseDataType;
  currentOutPatient: OutPatientItemTypes[];
};

const OutPatientMiniWaiting = ({ basic, currentOutPatient }: Props) => {
  return (
    <Style.OutPatientMiniWaitingStyle>
      <header>{basic?.bname}</header>
      <Style.Main>
        <Style.RoomList width={100}>
          <Style.PatientList>
            <Style.RoomInfo>
              <p>{basic?.broomNumber ? basic?.broomNumber[0] : ''}</p>
              <p>{basic?.subtitle}</p>
            </Style.RoomInfo>
            {currentOutPatient.map((data) => (
              <Style.Patient key={data?.pid}>
                <p className='mini'>{data?.hngnm?.slice(0, 5)}</p>
              </Style.Patient>
            ))}
          </Style.PatientList>
        </Style.RoomList>
      </Style.Main>
    </Style.OutPatientMiniWaitingStyle>
  );
};

export default OutPatientMiniWaiting;
