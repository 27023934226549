import { RootState } from '@store/rootReducer';
import {
  updateBasicConfigAction,
  updateFontConfigAction,
  updateNoticeConfigAction,
  updateSubConfigAction,
  updateVideoConfigAction,
  updateVisibleConfigAction,
} from '@store/viewer/actions';
import {
  LoadBasicConfigResponseDataType,
  LoadFontConfigResponseDataType,
  LoadNoticeConfigResponseDataType,
  LoadSubtitleConfigResponseDataType,
  LoadVideoConfigItemTypes,
  LoadVisibleConfigResponseDataType,
} from '@typedef/routes/viewer.navigation.types';
import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';

export default function useViewer() {
  const viewer = useSelector((root: RootState) => root.viewerReducer);

  const dispatch = useDispatch();

  const __updateBasicConfigFromHooks = useCallback(
    (diff: LoadBasicConfigResponseDataType) =>
      dispatch(updateBasicConfigAction(diff)),
    [dispatch],
  );

  const __updateFontConfigFromHooks = useCallback(
    (diff: LoadFontConfigResponseDataType) =>
      dispatch(updateFontConfigAction(diff)),
    [dispatch],
  );

  const __updateNoticeConfigFromHooks = useCallback(
    (diff: LoadNoticeConfigResponseDataType) =>
      dispatch(updateNoticeConfigAction(diff)),
    [dispatch],
  );

  const __updateSubConfigFromHooks = useCallback(
    (diff: LoadSubtitleConfigResponseDataType) =>
      dispatch(updateSubConfigAction(diff)),
    [dispatch],
  );

  const __updateVideoConfigFromHooks = useCallback(
    (diff: LoadVideoConfigItemTypes[]) =>
      dispatch(updateVideoConfigAction(diff)),
    [dispatch],
  );

  const __updateVisibleConfigFromHooks = useCallback(
    (diff: LoadVisibleConfigResponseDataType) =>
      dispatch(updateVisibleConfigAction(diff)),
    [dispatch],
  );

  return {
    ...viewer,
    __updateBasicConfigFromHooks,
    __updateFontConfigFromHooks,
    __updateNoticeConfigFromHooks,
    __updateSubConfigFromHooks,
    __updateVideoConfigFromHooks,
    __updateVisibleConfigFromHooks,
  };
}
