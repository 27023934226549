import styled from 'styled-components';

const FontSettingItemStyle = styled.li`
  list-style: none;
  padding: 0.22em 0;
  height: 3.6vh;
  .font-content {
    p {
      width: 100%;
      height: 100%;
      margin: 0;
      display: flex;
      align-items: center;
      justify-content: start;
      text-align: center;
      font-weight: bold;
      font-size: 1rem;
    }
  }

  .font-family {
    padding: 0;
    .form-select {
      width: 100%;
      height: 100%;
      padding: 0;
      padding-right: 0.75em;
      cursor: pointer;
    }
  }

  .font-size {
    padding: 0 0.5em;
    .form-control {
      height: 100%;
      padding: 0 0.5em;
    }
  }

  .font-bold {
    .row {
      height: 100%;
      align-items: center;
      justify-content: center;
      input[type='checkbox'] {
        width: auto;
        zoom: 1.5;
        cursor: pointer;
      }
      .form-label {
        width: auto;
        height: 100%;
        margin: 0;
        padding: 0;
        font-size: 1rem;
        text-align: center;
        margin-left: 0.25em;
        cursor: pointer;
      }
    }
  }

  .font-color {
    .row {
      justify-content: center;
      span {
        width: auto;
        margin: 0;
        font-weight: bold;
      }
      input[type='color'] {
        width: 80%;
        cursor: pointer;
        padding: 0.25em;
        background-color: transparent;
      }
    }
  }

  @media (max-width: 1280px) {
    height: 3.85vh;
    .font-content {
      padding: 0;
      p {
        font-size: 1rem;
      }
    }

    .font-family {
      .form-select {
        padding-right: 2em;
        font-size: 0.8rem;
      }
    }

    .font-bold {
      padding: 0;
    }

    .font-color {
      padding: 0;
      width: 30%;
      .row {
        span {
          padding: 0;
        }
        input[type='color'] {
          width: 8vw;
        }
      }
    }
  }
`;

export default FontSettingItemStyle;
