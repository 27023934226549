import { FontItemTypes } from "@typedef/components/Admin/BoardSetting/components/Font/admin.board.setting.type.font.types";
import { useCallback } from "react";
import FontSettingItemBackground from "../../components/Font/FontSettingItemBackground";

type Props = {
  content: string;
  fontId: string;
  font: FontItemTypes;
  onFontChange: (fontId: string, fontValue: FontItemTypes) => void;
};

const FontSettingItemBackgroundContainer = ({
  content,
  fontId,
  font,
  onFontChange,
}: Props) => {
  const onColorChange = useCallback(
    (value: string) => {
      onFontChange(fontId, {
        id: undefined,
        family: font.family,
        size: font.size,
        bold: font.bold,
        color: value,
      });
    },
    [font?.bold, font?.family, font?.size, fontId, onFontChange]
  );

  return (
    <FontSettingItemBackground
      content={content}
      font={font}
      onColorChange={onColorChange}
    />
  );
};

export default FontSettingItemBackgroundContainer;
