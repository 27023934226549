import { combineReducers } from 'redux';
import viewerReducer from './viewer/reducer';
import loginedReducer from './logined/reducer';
import gnbReducer from './gnb/reducer';
import profileReducer from './profile/reducer';
import multiRoomReducer from './multiRoom/reducer';
import boardSetReducer from './boardset/reducer';

const rootReducer = combineReducers({
  viewerReducer,
  loginedReducer,
  gnbReducer,
  profileReducer,
  multiRoomReducer,
  boardSetReducer,
});

export type RootState = ReturnType<typeof rootReducer>;

export default rootReducer;
