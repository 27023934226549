import Board from '../Board';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { apiRoute, requestSecureGet, requestSecurePut } from '@libs/api';
import useLogined from '@hooks/store/useLogined';
import useProfile from '@hooks/store/useProfile';
import {
  AdminBoardTypes,
  AdminBoardModalItemTypes,
} from '@typedef/components/Admin/Board/admin.board.types';
import { TableColumnTypes } from '@typedef/components/Common/CTable/table.column.types';
import BoardDeleteContainer from './BoardDeleteContainer';
import BoardManageContainer from './BoardManageContainer';
import CTableButton from '@components/common/CTable/components/CTableButton';
import { AdminMyBoardType } from '@typedef/components/Admin/Clinic/admin.myboard.types';
import useGNB from '@hooks/store/useGNB';
import BoardVariableContainer from './BoardVariableContainer';

// 접속기록 컬럼
const columns: TableColumnTypes[] = [
  {
    header: '전광판명',
    field: 'boardCode',
  },
  {
    header: 'Ip',
    field: 'ip',
  },
  {
    header: 'Mac',
    field: 'mac',
  },
  {
    header: '유형',
    field: 'btypeName',
  },
  {
    header: '진료과',
    field: 'deptKor',
  },
  {
    header: '위치',
    field: 'location',
  },
  {
    header: '소속',
    field: 'belong',
  },
  {
    header: '마이전광판 여부',
    field: 'isMyBoard',
    filter: false,
  },
];

const BoardContainer = () => {
  const { myBoardReload, __updateGNBMyBoardReloadFromHooks } = useGNB();
  const { token } = useLogined();
  const { myBoard, roles } = useProfile();
  // 진료실 관리 데이터 재조회
  const [reload, setReload] = useState<number>(0);
  // Switch : true: 전체전광판, false: 마이전광판
  const [isAll, setIsAll] = useState(true);
  // 전체전광판
  const [boards, setBoards] = useState<AdminBoardTypes[]>([]);
  // 마이전광판
  const [myBoards, setMyBoards] = useState<AdminBoardTypes[]>([]);
  const [currentModal, setCurrentModal] = useState<AdminBoardModalItemTypes>(null);
  // 전광판 리스트에서 선택한 전광판 id 저장 변수 ( 삭제에서는 여러개 가능 , 수정에선 1개만 )
  const [checkBoard, setCheckBoard] = useState<AdminBoardTypes[]>([]);
  // 테이블에서 한번에 보여줄 갯수 정하기
  const [itemsPerPage, setItemsPerPage] = useState<number>(15);

  const boardId = useMemo(() => myBoard?.id, [myBoard]);

  const modal = {
    insert: (
      <BoardManageContainer
        id={null}
        setCurrentModal={setCurrentModal}
        reload={reload}
        setReload={setReload}
      />
    ),
    update: (
      <BoardManageContainer
        id={checkBoard.map((row) => row.id)[0]}
        setCurrentModal={setCurrentModal}
        reload={reload}
        setReload={setReload}
      />
    ),
    delete: (
      <BoardDeleteContainer
        ids={checkBoard.map((row) => row.id)}
        setCurrentModal={setCurrentModal}
        reload={reload}
        setReload={setReload}
      />
    ),
    variable: (
      <BoardVariableContainer
        ids={checkBoard.map((row) => row.id)[0]}
        setCurrentModal={setCurrentModal}
        reload={reload}
        setReload={setReload}
      />
    ),
  };

  // 추가, 수정, 삭제 버튼 클릭 (모달 오픈)
  const onClickButton = useCallback(
    (type: AdminBoardModalItemTypes) => {
      if (type === 'update' || type === 'variable') {
        if (checkBoard.length > 1) {
          alert('수정할 전광판을 하나만 선택해 주세요.');
          return;
        } else if (checkBoard.length === 0) {
          alert('수정할 전광판을 선택해 주세요.');
          return;
        }
      } else if (type === 'delete') {
        if (checkBoard.length === 0) {
          alert('삭제할 전광판을 선택해 주세요.');
          return;
        }
      }
      setCurrentModal(type);
    },
    [checkBoard],
  );

  // 마이진료실 <--> 전체진료실
  const boardChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    // true: 전체 , false: 마이
    setIsAll(e.target.checked);
  };

  // 마이 전광판 목록 조회
  const loadMyBoards = useCallback(async () => {
    if (!token) return;

    const { data, config } = await requestSecureGet<AdminMyBoardType>(
      apiRoute.admin.board.getMyBoards + myBoard?.id,
      {},
      token,
    );

    if (config.status === 200) {
      return data;
    }
  }, [myBoard]);

  // 마이전광판에 추가
  const addMyBoard = useCallback(
    async (idx: number) => {
      const myBoards = await loadMyBoards();

      const putData = {
        id: boardId,
        boardList: myBoards?.boardList ? myBoards?.boardList.concat(',', String(idx)) : idx,
      };

      if (!token) return;

      const { config } = await requestSecurePut(
        apiRoute.admin.board.putMyBoard,
        {},
        putData,
        token,
      );
      if (config.status === 200) {
        setReload(reload + 1);
        __updateGNBMyBoardReloadFromHooks(myBoardReload + 1);
      }
    },
    [boardId, token, reload, myBoardReload],
  );

  // 마이전광판에 삭제
  const removeMyBoard = useCallback(
    async (idx: number) => {
      const myBoards = await loadMyBoards();

      const split = myBoards?.boardList.split(',');

      const filtered = split?.filter((data) => data !== `${idx}`);

      if (!token) return;

      const { config } = await requestSecurePut(
        apiRoute.admin.board.putMyBoard,
        {},
        {
          id: boardId,
          boardList: filtered?.toString(),
        },
        token,
      );
      if (config.status === 200) {
        setReload(reload + 1);
        __updateGNBMyBoardReloadFromHooks(myBoardReload + 1);
      }
    },
    [boardId, token, reload, myBoardReload],
  );

  // 전광판 목록 조회
  const getBoards = useCallback(async () => {
    if (!roles) return;
    if (!myBoard) return;
    if (!token) return;

    const { config, data } = await requestSecureGet<AdminBoardTypes[]>(
      apiRoute.admin.board.getBoards + myBoard.id + '/' + roles[0],
      {},
      token,
    );
    if (config.status >= 200 && config.status < 400) {
      const boardsData = data.map((data) => ({
        ...data,
        isMyBoard: (
          <CTableButton
            buttons={
              data.flag
                ? [
                    {
                      id: data.id,
                      name: '삭제',
                      color: '#e55353',
                      func: () => removeMyBoard(data.id),
                    },
                  ]
                : [
                    {
                      id: data.id,
                      name: '추가',
                      color: '#2eb85c',
                      func: () => addMyBoard(data.id),
                    },
                  ]
            }
          />
        ),
      }));
      // 마이전광판
      const filtered = boardsData.filter((data) => data.flag);
      setBoards(boardsData);
      setMyBoards(filtered);
    }
  }, [addMyBoard, myBoard, removeMyBoard, roles, token]);

  useEffect(() => {
    getBoards();
    setCheckBoard([]);
  }, [reload]);

  return (
    <Board
      columns={columns}
      tableData={isAll ? boards : myBoards}
      checkBoard={checkBoard}
      setCheckBoard={setCheckBoard}
      modal={modal}
      currentModal={currentModal}
      onClickButton={onClickButton}
      boardChange={boardChange}
      isAll={isAll}
      itemsPerPage={itemsPerPage}
      setItemsPerPage={setItemsPerPage}
    />
  );
};

export default BoardContainer;
