export const dispFlag = {
  RESV: 0 as const,
  CHECK: 1 as const,
  ABSN: 8 as const,
  DONE: 9 as const,
};

export type DispFlagTypes = 0 | 1 | 8 | 9;

export type PatientListItemTypes = {
  dispflag: DispFlagTypes;
  dispseq: number;
  ordtm: string;
  // consult: string | null;
  arrflag: '0' | '1';
  nursacptdt?: string;
  nursacptyn: 'Y' | 'N';
  pid: number;
  hngnm: string;
  birth: string;
  sex: 'M' | 'F';
  age: string;
  // FSEXAMFLAGNM: string; // 초진
  // RSRVFLAGNM: string; // 구분
  // ELBULBODSTATNM: string | null; // 진행
  elbulbodstat: '0' | '1'; // 도착 설정
  bgcolor?: string;
  dispseqbgcolor?: string;
  dispseqcharactercolor?: string;
  ordtmbgcolor?: string;
  ordtmcharactercolor?: string;
  // consultbgcolor?: string;
  // consultcharactercolor?: string;
  nursacptdtbgcolor?: string;
  nursacptdtcharactercolor?: string;
  pidbgcolor?: string;
  pidcharactercolor?: string;
  namecolor?: string;
  namecharactercolor?: string;
  agesexbgcolor?: string;
  agesexcharactercolor?: string;
  // FSEXAMFLAGNMBGCOLOR?: string; // 초진 배경 색상
  // FSEXAMFLAGNMCHARACTERCOLOR?: string; // 초진 글자 색상
  // RSRVFLAGNMBGCOLOR?: string; // 구분 배경 색상
  // RSRVFLAGNMCHARACTERCOLOR?: string; // 구분 글자 색상
  // ELBULBODSTATNMBGCOLOR?: string; // 진행 배경 색상
  // ELBULBODSTATNMCHARACTERCOLOR?: string; // 진행 글자 색상
  // BFJUDGBGCOLOR?: string; // 심사 배경 색상
  // BFJUDGCHARACTERCOLOR?: string; // 심사 글자 색상
  orddd: string;
  cretno: number;
  acptseqno: number;
  orddeptcd: string;
  orddrid: string;
  insukind: string;
  etcordflag: string;
  acpttime: string | null;
  prcptdayaftrcptyn: 'N' | 'Y';
  calcyn: string | null;
  aftcalcyn: 'Y' | 'N';
  acptmsg: string | null;
  // BFJUDGVALUE: string | null; // 심사 값
  dispalltime?: 'Y' | 'N';
};

export type MyRoomListItemTypes = {
  deptKor: string | null;
  centerId: string | null;
  hardcdno: string | null;
  doctorKor: string;
  trgtcd: string | null;
  deptId: string | null;
  centerKor: string | null;
  roomCode: string;
  deptEng: string | null;
  doctorId: string | null;
  boardIdx: number | null;
  centerEng: string | null;
  id: number;
  customCenter: string | null;
  flag: boolean;
  admin: string | null;
};

export type PatientTableTypes = 'RESV' | 'CHECK' | 'ABSN' | 'DONE';

export type PatientSearchType = 'name' | 'registerNumber';

export type AMPMTypes = { label: '오전'; type: 'am' } | { label: '오후'; type: 'pm' };

export type PatientArriveInfoTypes = {
  nursacptdt: string;
  orddrid: string;
  instcd: string;
  orddeptcd: string;
  cretno: number;
  pid: string;
  orddd: string;
};

/**
 * ALERT 와 ALARM의 차이는
 * ALERT : 행위를 하고 행위에 대한 알림을 준다.
 * ALARM : 행위를 막고 행위가 막힌 이유에 대한 알림을 준다.
 */
export type PatientAlertTypes = {
  arrivealert: string | null;
  arrivealarm: string | null;
  cancelarrivealarm: string | null;
  cancelarrivealert: string | null;
};

export type PatientVersionItemTypes = {
  [key: string]: string;
};

export type PatientVersionResponseTypes = {
  version: string;
};
