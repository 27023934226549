import { CButton, CCard, CCardBody, CCardHeader } from '@coreui/react';
import {
  FontItemTypes,
  FontListItemTypes,
} from '@typedef/components/Admin/BoardSetting/components/Font/admin.board.setting.type.font.types';
import { VIEWER_USE_OPTIONS } from '@typedef/libs/config.types';
import FontSettingItemBackgroundContainer from '../../containers/Font/FontSettingItemBackgroundContainer';
import FontSettingItemContainer from '../../containers/Font/FontSettingItemContainer';
import FontSettingStyle from '../../styles/Font/FontSettingStyle';

type Props = {
  btypeName: string;
  fontList: FontListItemTypes;
  onFontSettingApplyClick: () => void;
  onFontSettingResetClick: () => void;
  onFontChange: (fontId: string, fontValue: FontItemTypes) => void;
};

const FontSetting = ({
  btypeName,
  fontList,
  onFontSettingApplyClick,
  onFontSettingResetClick,
  onFontChange,
}: Props) => {
  return (
    <FontSettingStyle>
      <CCard>
        <CCardHeader>
          <h5>글꼴 설정</h5>
          <div className='btns'>
            <CButton color='warning' onClick={onFontSettingResetClick}>
              기본값
            </CButton>
            <CButton color='info' onClick={onFontSettingApplyClick}>
              적용
            </CButton>
          </div>
        </CCardHeader>
        <CCardBody>
          <ul>
            {/* 항암주사실 짝수,홀수 배경 색상을 지정하기 위해 사용하지 않는 폰트 값을 이용. */}
            {VIEWER_USE_OPTIONS[btypeName].font.fontId.map((fontId, index) =>
              btypeName === '항암주사실' &&
              (fontId === 'fadminKor' || fontId === 'fcenter') ? (
                <FontSettingItemBackgroundContainer
                  key={fontId}
                  content={VIEWER_USE_OPTIONS[btypeName].font.content[index]}
                  fontId={fontId}
                  font={fontList[fontId]}
                  onFontChange={onFontChange}
                />
              ) : (
                <FontSettingItemContainer
                  key={fontId}
                  content={VIEWER_USE_OPTIONS[btypeName].font.content[index]}
                  fontId={fontId}
                  font={fontList[fontId]}
                  onFontChange={onFontChange}
                />
              ),
            )}
          </ul>
        </CCardBody>
      </CCard>
    </FontSettingStyle>
  );
};

export default FontSetting;
