import { CButton, CCard, CCardBody, CCardHeader, CFormInput } from '@coreui/react';
import ClinicHeader from './ClinicHeader';
import HealthHeader from './HealthHeader';
import InjectionHeader from './InjectionHeader';
import SurgeryHeader from './SurgeryHeader';
import { AdminBoardSettingTypes } from '@typedef/components/Admin/BoardSetting/admin.board.setting.types';
import { AdminClinicTypes } from '@typedef/components/Admin/Clinic/admin.clinic.types';
import { Inputs } from '../../styles/BoardSettingStyle';
import { Link } from 'react-router-dom';
import HeaderSettingStyle from '../../styles/Header/HeaderSettingStyle';
import LabHeader from './LabHeader';
import { BoardNames } from '../../config/board.setting.config';

type Props = {
  boardInfo: AdminBoardSettingTypes;
  btypeName: string;
  boardList: AdminClinicTypes[];
  onChangeBoardInfo: (id: string, name: string, value: string) => void;
  onClickApply: () => Promise<void>;
  setCurrentModal: React.Dispatch<React.SetStateAction<string | null>>;
  miniTypePath: string;
};

const HeaderSetting = ({
  boardInfo,
  btypeName,
  boardList,
  onChangeBoardInfo,
  onClickApply,
  setCurrentModal,
  miniTypePath,
}: Props) => {
  return (
    <HeaderSettingStyle>
      <CCard>
        <CCardHeader>
          <h5>전광판 설정</h5>
          <div className='btns'>
            <CButton color='dark' onClick={() => setCurrentModal('type')}>
              전광판 타입
            </CButton>
            <Link to={`/monitor/${boardInfo.ip}`} target='_blank'>
              <CButton color='info'>전광판 보기</CButton>
            </Link>
            <CButton
              onClick={() => {
                window
                  .open(
                    `${window.location.origin}/monitor/mini/${miniTypePath + boardInfo.ip}`,
                    '',
                    'width=400px, height=400px',
                  )
                  ?.focus();
              }}
              color='info'
            >
              전광판 미니
            </CButton>
            {btypeName.includes('대대합') && (
              <CButton color='info' onClick={() => setCurrentModal('large')}>
                진료실 설정
              </CButton>
            )}
            <CButton color='info' onClick={onClickApply}>
              적용
            </CButton>
          </div>
        </CCardHeader>
        <CCardBody>
          {/* 수술실 */}
          {BoardNames.map((board) =>
            board.role === 'ROLE_SUR' && board.label === btypeName ? (
              <SurgeryHeader boardInfo={boardInfo} onChangeBoardInfo={onChangeBoardInfo} />
            ) : null,
          )}

          {/* 약국 */}
          {BoardNames.map((board) =>
            board.role === 'ROLE_PMY' && board.label === btypeName ? (
              <div style={{ textAlign: 'center', fontWeight: 'bold' }}>설정할 내용이 없습니다.</div>
            ) : null,
          )}

          {/* 외래대기전광판 */}
          {btypeName === '외래대기전광판' && (
            <ClinicHeader
              boardInfo={boardInfo}
              boardList={boardList}
              onChangeBoardInfo={onChangeBoardInfo}
            />
          )}

          {/* 항암주사실 */}
          {btypeName === '항암주사실' && (
            <InjectionHeader boardInfo={boardInfo} onChangeBoardInfo={onChangeBoardInfo} />
          )}

          {/* 외래검진, 검사검진 */}
          {BoardNames.map((board) =>
            board.role === 'ROLE_HEALTH' &&
            board.label === btypeName &&
            btypeName !== '종합현황검진' ? (
              <HealthHeader boardInfo={boardInfo} onChangeBoardInfo={onChangeBoardInfo} />
            ) : null,
          )}

          {/* 열, 종합현황검진, 대대합 */}
          {(btypeName === '열' ||
            btypeName === '종합현황검진' ||
            btypeName.includes('대대합') ||
            btypeName === '외래4열대기전광판') && (
            <Inputs>
              <CFormInput
                type='text'
                label='전광판 이름'
                value={boardInfo.bname === '-' ? '' : boardInfo.bname}
                onChange={(e) => {
                  onChangeBoardInfo('bname', 'bname', e.target.value);
                }}
                placeholder='전광판 이름'
              />
            </Inputs>
          )}

          {/* 검사실 */}
          {BoardNames.map((board) =>
            board.role === 'ROLE_LAB' && board.label === btypeName ? (
              <LabHeader
                boardInfo={boardInfo}
                btypeName={btypeName}
                onChangeBoardInfo={onChangeBoardInfo}
              />
            ) : null,
          )}
        </CCardBody>
      </CCard>
    </HeaderSettingStyle>
  );
};

export default HeaderSetting;
