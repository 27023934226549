import VideoModal from '@components/Admin/BoardSetting/components/Video/Modal/VideoModal';
import {
  userRoleOtherFields,
  userRoleRootFields,
} from '@components/Admin/BoardSetting/config/video.modal.config';
import useGNB from '@hooks/store/useGNB';
import useLogined from '@hooks/store/useLogined';
import useProfile from '@hooks/store/useProfile';
import {
  apiRoute,
  requestSecureDelete,
  requestSecureGet,
  requestSecurePost,
  requestSecurePut,
} from '@libs/api';
import {
  MyVideoResponseItemTypes,
  VideoListItemTypes,
} from '@typedef/components/Admin/BoardSetting/components/Video/admin.board.setting.type.video.types';
import React, { ChangeEvent, useCallback, useEffect, useState } from 'react';

type Props = {
  boardId: number;
  setCurrentModal: React.Dispatch<React.SetStateAction<string | null>>;
  setReload: React.Dispatch<React.SetStateAction<number>>;
};

const VideoModalContainer = ({ boardId, setCurrentModal, setReload }: Props) => {
  const { token } = useLogined();

  const { roles } = useProfile();

  const { selected } = useGNB();

  const [loadingSpinner, setLoadingSpinner] = useState<boolean>(false);

  const [videoUrl, setVideoUrl] = useState<string>('');

  const [videoUrlName, setVideoUrlName] = useState<string>('');

  const [myVideoCheckedList, setMyVideoCheckedList] = useState<string[]>([]);

  const [videoList, setvideoList] = useState<VideoListItemTypes[]>([]);

  const [searchItemList, setSearchItemList] = useState<VideoListItemTypes[]>([]);

  const [formData, setFormData] = useState(new FormData());

  const onCheckChange = useCallback(
    (videoId: string, check: boolean) => {
      if (check) {
        setMyVideoCheckedList((prev) => {
          const clone = [...prev];

          clone.push(videoId);

          return clone;
        });
      } else {
        setMyVideoCheckedList(myVideoCheckedList.filter((list) => list !== videoId));
      }
    },
    [myVideoCheckedList],
  );

  // 적용
  const onCheckedApplyClick = useCallback(async () => {
    if (!token) return;

    const { config } = await requestSecurePut<string>(
      apiRoute.admin.boardSet.video + boardId,
      {},
      {
        id: boardId,
        media: myVideoCheckedList.toString(),
      },
      token,
    );

    if (config.status === 200) {
      setReload((prev) => prev + 1);
      setCurrentModal(null);
    }
  }, [boardId, token, myVideoCheckedList, setReload, setCurrentModal]);

  // 영상 일괄적용
  const onVideoAllApplyClick = useCallback(
    async (videoId: number) => {
      if (!token) return;

      const { config } = await requestSecureGet<string>(
        apiRoute.admin.boardSet.getVideoEvery + videoId,
        {},
        token,
      );

      if (config.status === 200) {
        setReload((prev) => prev + 1);

        setCurrentModal(null);
      }
    },
    [token, setReload, setCurrentModal],
  );

  // 영상 리스트 불러오기
  const loadVideoData = useCallback(async () => {
    if (!token) return;

    const { data, config } = await requestSecureGet<VideoListItemTypes[]>(
      apiRoute.admin.boardSet.getMedias,
      {},
      token,
    );

    if (config.status === 200) {
      setvideoList(data.sort((a, b) => b.id - a.id));
      setSearchItemList(data.sort((a, b) => b.id - a.id));
    }
  }, [token]);

  // 전광판에 할당된 영상 불러오기
  const loadMyVideoData = useCallback(async () => {
    if (!token) return;

    const { data, config } = await requestSecureGet<MyVideoResponseItemTypes>(
      apiRoute.admin.boardSet.getMyVideo + boardId,
      {},
      token,
    );

    if (config.status === 200) {
      setMyVideoCheckedList(data.media.split(','));
    }
  }, [boardId, token]);

  // 영상 삭제
  const onVideoRemoveClick = useCallback(
    async (videoId: number) => {
      if (!token) return;

      const { config } = await requestSecureDelete<string>(
        apiRoute.admin.boardSet.media,
        {},
        [videoId],
        token,
      );
      if (config.status === 200) {
        loadVideoData();

        loadMyVideoData();
      }
    },
    [token, loadVideoData, loadMyVideoData],
  );

  // 파일 추가
  const onFileAddClick = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      const { files } = e.target;
      if (files) {
        for (let i: number = 0; i < files?.length; i++) {
          formData?.append('files', files[i]);
        }
      }
    },
    [formData],
  );

  // 파일 영상 입력
  const onFileUploadClick = useCallback(async () => {
    if (!token) return;

    setLoadingSpinner(true);

    const { config } = await requestSecurePost<string>(
      apiRoute.admin.boardSet.media,
      {
        Accept: '*/*',
        Cache: 'no-cache',
        'Content-Type': 'multipart/form-data',
      },
      formData,
      token,
    );

    if (config.status === 200) {
      setFormData(new FormData());

      setLoadingSpinner(false);

      alert('영상 추가가 완료되었습니다.');

      setCurrentModal(null);
    } else {
      alert('파일을 선택해주세요');

      setLoadingSpinner(false);
    }
  }, [formData, token]);

  // URL 영상 입력
  const onUrlUploadClick = useCallback(async () => {
    if (!token) return;

    setLoadingSpinner(true);

    const { data, config } = await requestSecurePost<string>(
      apiRoute.admin.boardSet.mediaUrl,
      {},
      {
        url: videoUrl,
        original: videoUrlName,
      },
      token,
    );

    if (config.status === 200) {
      setVideoUrl('');

      setVideoUrlName('');

      loadVideoData();

      loadMyVideoData();

      setLoadingSpinner(false);
    } else {
      alert('URL을 입력해주세요');

      setLoadingSpinner(false);
    }
  }, [token, videoUrl, videoUrlName]);

  // 영상 검색 결과
  const onSearch = useCallback(
    (searchValue: string) => {
      setSearchItemList(videoList.filter((data) => data?.original?.includes(searchValue)));
    },
    [videoList],
  );

  useEffect(() => {
    if (!boardId) {
      return;
    }

    loadVideoData();

    loadMyVideoData();
  }, [boardId, token, selected]);

  return roles ? (
    <VideoModal
      videoList={searchItemList}
      fields={roles[0] === 'ROLE_ROOT' ? userRoleRootFields : userRoleOtherFields}
      role={roles[0]}
      loadingSpinner={loadingSpinner}
      setVideoUrl={setVideoUrl}
      setVideoUrlName={setVideoUrlName}
      setCurrentModal={setCurrentModal}
      onCheckChange={onCheckChange}
      onCheckedApplyClick={onCheckedApplyClick}
      onVideoAllApplyClick={onVideoAllApplyClick}
      onVideoRemoveClick={onVideoRemoveClick}
      onFileAddClick={onFileAddClick}
      onFileUploadClick={onFileUploadClick}
      onUrlUploadClick={onUrlUploadClick}
      onSearch={onSearch}
      myVideoCheckedList={myVideoCheckedList}
    />
  ) : null;
};

export default VideoModalContainer;
