import NotFoundContainer from '@components/common/NotFound/containers/NotFoundContainer';
import OutPatientWaitingContainer from '@components/view/OutPatientWaiting/containers/OutPatientWaitingContainer';
import PharmacyWaitingContainer from '@components/view/PharmacyWaiting/containers/PharmacyWaitingContainer';
import SurgeryWaitingContainer from '@components/view/SurgeryWaiting/containers/SurgeryWaitingContainer';
import ExaminationPatientWaitingContainer from '@components/view/ExaminationPatientWaiting/containers/ExaminationPatientWaitingContainer';
import ViewerNavigationStyle from '@routes/styles/ViewerNavigationStyle';
import { Route, Routes } from 'react-router-dom';
import ExaminationPatientMiniWaitingContainer from '@components/view/ExaminationPatientWaiting/containers/ExaminationPatientMiniWaitingContainer';
import { LoadBasicConfigResponseDataType } from '@typedef/routes/viewer.navigation.types';
import RoomListWaitingContainer from '@components/view/RoomListWaiting/container/RoomListWaitingContainer';
import { CSpinner } from '@coreui/react';
import OutLabLargePatientWaitingContainer from '@components/view/OutLabLargePatientWaiting/containers/OutLabLargePatientWaitingContainer';
import VideoWaitingContainer from '@components/view/VideoWaiting/containers/VideoWaitingContainer';
import ExaminationEndoscopePatientWaitingContainer from '@components/view/ExaminationEndoscopePatientWaiting/containers/ExaminationEndoscopePatientWaitingContainer';
import OutPatientMiniWaitingContainer from '@components/view/OutPatientWaiting/containers/OutPatientMiniWaitingContainer';
import OutQuadPatientWaitingContainer from '@components/view/OutQuadPatientWaiting/containers/OutQuadPatientWaitingContainer';

type Props = {
  basicConfig: LoadBasicConfigResponseDataType;
  loadingSpinner: boolean;
};

const ViewerNavigation = ({ basicConfig, loadingSpinner }: Props) => {
  return (
    <ViewerNavigationStyle>
      <div className='content-root'>
        {loadingSpinner && (
          <div className='viewer-loading-spinner'>
            <CSpinner color='primary' />
            <p>Loading...</p>
          </div>
        )}

        <Routes>
          {basicConfig.btypeName === '외래대기전광판' && (
            <Route path=':ip' element={<OutPatientWaitingContainer />} />
          )}

          {basicConfig.btypeName === '외래4열대기전광판' && (
            <Route path=':ip' element={<OutQuadPatientWaitingContainer />} />
          )}

          {(basicConfig.btypeName === '영상/1열' ||
            basicConfig.btypeName === '영상/2열' ||
            basicConfig.btypeName === '1열/영상/1열' ||
            basicConfig.btypeName === '1열/영상' ||
            basicConfig.btypeName === '2열/영상') && (
            <Route path=':ip' element={<ExaminationPatientWaitingContainer />} />
          )}

          {/* 영상의학과 간호사 전용 미니 뷰어 */}
          <Route path='/mini/:ip' element={<ExaminationPatientMiniWaitingContainer />} />

          {/* 외래대기전광판 진료실 내부 전용 미니 뷰어 */}
          <Route path='/mini/outpatient/:ip' element={<OutPatientMiniWaitingContainer />} />

          {basicConfig.btypeName === '동영상 (대대합)' && (
            <Route path=':ip' element={<VideoWaitingContainer />} />
          )}

          {basicConfig.btypeName.includes('(대대합)') &&
            basicConfig.btypeName !== '동영상 (대대합)' && (
              <Route path=':ip' element={<OutLabLargePatientWaitingContainer />} />
            )}

          {basicConfig.btypeName.includes('수술') && (
            <Route path=':ip' element={<SurgeryWaitingContainer />} />
          )}

          {basicConfig.btypeName.includes('약국') && (
            <Route path=':ip' element={<PharmacyWaitingContainer />} />
          )}

          {basicConfig.btypeName === '열' && (
            <Route path=':ip' element={<RoomListWaitingContainer />} />
          )}

          {basicConfig.btypeName === '내시경' && (
            <Route path=':ip' element={<ExaminationEndoscopePatientWaitingContainer />} />
          )}

          <Route path='*' element={<NotFoundContainer />} />
        </Routes>
      </div>
    </ViewerNavigationStyle>
  );
};

export default ViewerNavigation;
