import RoomListWaiting from '../RoomListWaiting';
import { useState, useEffect, useMemo } from 'react';
import useViewer from '@hooks/store/useViewer';
import { getCurrentList } from '@libs/factory';

// 초기화 딜레이
const ROOM_LIST_INTERVAL = 29500;

const PAGE_SIZE = 4;

const RoomListWaitingContainer = () => {
  const { basic, font, visible } = useViewer();

  const [intervalCounter, setIntervalCounter] = useState<number>(0);

  // 진료실 roomList 4개씩 분할
  const currentReserveClinic = useMemo(() => {
    if (basic?.roomList) {
      return getCurrentList(basic?.roomList, PAGE_SIZE, intervalCounter);
    } else {
      return getCurrentList([], PAGE_SIZE, intervalCounter);
    }
  }, [basic?.roomList, intervalCounter]);

  // 진료실 broomNumber 4개씩 분할
  const currentReserveNumber = useMemo(() => {
    if (basic?.broomNumber) {
      return getCurrentList(basic?.broomNumber, PAGE_SIZE, intervalCounter);
    } else {
      return getCurrentList([], PAGE_SIZE, intervalCounter);
    }
  }, [basic?.broomNumber, intervalCounter]);

  useEffect(() => {
    const interval = setInterval(() => {
      setIntervalCounter((prev) => prev + 1);
    }, ROOM_LIST_INTERVAL);

    return () => {
      clearInterval(interval);
    };
  }, []);

  return basic && font && visible ? (
    <RoomListWaiting
      basic={basic}
      font={font}
      visible={visible}
      roomList={currentReserveClinic}
      roomNums={currentReserveNumber}
    />
  ) : null;
};

export default RoomListWaitingContainer;
