import {
  CModal,
  CModalHeader,
  CButton,
  CModalBody,
  CModalFooter,
  CForm,
  CFormInput,
  CFormLabel,
  CFormSelect,
  CCol,
} from '@coreui/react';
import { AdminAccountRoleTypes } from '@typedef/components/Admin/Account/admin.account.role.types';
import {
  AdminBoardInputTypes,
  BoardTypes,
  AdminBoardModalItemTypes,
} from '@typedef/components/Admin/Board/admin.board.types';
import { AdminCenterTypes } from '@typedef/components/Admin/Clinic/components/admin.center.types';

type Props = {
  type: '추가' | '수정';
  setCurrentModal: React.Dispatch<
    React.SetStateAction<AdminBoardModalItemTypes>
  >;
  inputs: AdminBoardInputTypes;
  onChangeHandler: React.ChangeEventHandler<HTMLElement>;
  checkDupleIp: () => void;
  departmentList: AdminCenterTypes[];
  roles: AdminAccountRoleTypes[];
  save: () => Promise<void>;
  findMacAddress: () => void;
};

// 계정관리 > 등록 / 수정 모달
const BoardManage = ({
  type,
  setCurrentModal,
  inputs,
  onChangeHandler,
  checkDupleIp,
  departmentList,
  roles,
  save,
  findMacAddress,
}: Props) => {
  return (
    <CModal
      visible={true}
      onClose={() => setCurrentModal(null)}
      backdrop='static'
    >
      <CModalHeader>전광판 {type}</CModalHeader>
      <CModalBody>
        <CForm>
          <CCol>
            <CFormLabel>
              전광판 이름 <span style={{ color: 'red' }}>*</span>
            </CFormLabel>
            <CFormInput
              id='boardCode'
              key='boardCodeInput'
              name='boardCode'
              value={inputs.boardCode ?? ''}
              placeholder='전광판 이름 입력'
              onChange={onChangeHandler}
              autoComplete='off'
            />
          </CCol>
          <CCol>
            <CFormLabel>
              ip 주소 <span style={{ color: 'red' }}>*</span>
            </CFormLabel>
            <div style={{ display: 'flex' }}>
              <CFormInput
                id='ip'
                key='ipInput'
                name='ip'
                value={inputs.ip ?? ''}
                placeholder='전광판 ip 주소 입력'
                onChange={onChangeHandler}
                autoComplete='off'
                style={{ flex: 1 }}
              />
              <CButton color='info' onClick={checkDupleIp}>
                중복검사
              </CButton>
            </div>
          </CCol>
          <CCol>
            <CFormLabel>
              Mac 주소 <span style={{ color: 'red' }}>*</span>
            </CFormLabel>
            <div style={{ display: 'flex' }}>
              <CFormInput
                id='mac'
                key='macInput'
                name='mac'
                value={inputs.mac ?? ''}
                placeholder='Mac 주소 입력'
                onChange={onChangeHandler}
                autoComplete='off'
                style={{ flex: 1 }}
              />
              <CButton color='info' onClick={findMacAddress}>
                맥 주소 찾기
              </CButton>
            </div>
          </CCol>

          <CCol>
            <CFormLabel>
              전광판 유형 <span style={{ color: 'red' }}>*</span>
            </CFormLabel>
            <CFormSelect
              value={inputs.btypeName ?? ''}
              id='btypeName'
              name='btypeName'
              onChange={onChangeHandler}
            >
              <option value=''>전광판 유형</option>
              {BoardTypes.reverse().map((data) => (
                <option value={data.label} key={data.label}>
                  {data.label}
                </option>
              ))}
            </CFormSelect>
          </CCol>
          <CCol>
            <CFormLabel>
              소속 선택 <span style={{ color: 'red' }}>*</span>
            </CFormLabel>
            <CFormSelect
              id='belong'
              name='belong'
              onChange={onChangeHandler}
              value={inputs.belong ?? ''}
            >
              <option value=''>소속 선택</option>
              {roles.map((role) => (
                <option key={role.value} value={role.value}>
                  {role.name}
                </option>
              ))}
            </CFormSelect>
          </CCol>
          <CFormInput
            label='설치 위치'
            id='location'
            key='locationInput'
            name='location'
            value={inputs.location ?? undefined}
            placeholder='설치 위치 입력'
            onChange={onChangeHandler}
            autoComplete='off'
          />

          <CCol>
            <CFormLabel htmlFor='deptId'>과선택</CFormLabel>
            <CFormSelect
              id='deptId'
              name='deptId'
              onChange={onChangeHandler}
              value={String(inputs.deptKor) ?? ''}
            >
              <option value=''>과선택</option>
              {departmentList
                .sort((a, b) => (a.deptHngNm > b.deptHngNm ? 1 : -1))
                .map((data) => (
                  <option value={data.deptHngNm} key={data.deptHngNm}>
                    {data.deptHngNm}
                  </option>
                ))}
            </CFormSelect>
          </CCol>
        </CForm>
      </CModalBody>
      <CModalFooter>
        <CButton color='primary' onClick={save}>
          {type}하기
        </CButton>
        <CButton color='secondary' onClick={() => setCurrentModal(null)}>
          취소
        </CButton>
      </CModalFooter>
    </CModal>
  );
};

export default BoardManage;
