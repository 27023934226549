import { CommonActionTypes } from "@store/actions";
import { STORE_INIT_ACTION_TYPES } from "@store/modules/actionTypes";
import {
  UPDATE_BOARDSET_SUBTITLE_ACTION_TYPE,
  UPDATE_BOARDSET_NOTICE_ACTION_TYPE,
  UPDATE_BOARDSET_DELAY_ACTION_TYPE,
} from "./modules/actionTypes";
import { BoardSetActionTypes } from "./actions";

export type BoardSetStateTypes = {
  subtitleReload: number;
  noticeReload: number;
  delayReload: number;
};

const init: BoardSetStateTypes = {
  subtitleReload: 0,
  noticeReload: 0,
  delayReload: 0,
};

const boardSetReducer = (
  prev: BoardSetStateTypes = init,
  { type, payload }: BoardSetActionTypes | CommonActionTypes
): BoardSetStateTypes => {
  switch (type) {
    case UPDATE_BOARDSET_SUBTITLE_ACTION_TYPE: {
      return {
        ...prev,
        subtitleReload: payload,
      };
    }

    case UPDATE_BOARDSET_NOTICE_ACTION_TYPE: {
      return {
        ...prev,
        noticeReload: payload,
      };
    }

    case UPDATE_BOARDSET_DELAY_ACTION_TYPE: {
      return {
        ...prev,
        delayReload: payload,
      };
    }

    case STORE_INIT_ACTION_TYPES:
      return {
        ...prev,
      };

    default:
      return prev;
  }
};

export default boardSetReducer;
