import TypeSetting from '@components/Admin/BoardSetting/components/Header/Modal/TypeSetting';
import { useState, useCallback, useMemo } from 'react';
import useLogined from '@hooks/store/useLogined';
import useProfile from '@hooks/store/useProfile';
import { AdminBoardSettingTypes } from '@typedef/components/Admin/BoardSetting/admin.board.setting.types';
import { apiRoute, requestSecurePut } from '@libs/api';

type Props = {
  idx?: number | null;
  boardInfo: AdminBoardSettingTypes;
  setCurrentModal: React.Dispatch<React.SetStateAction<string | null>>;
  setReload: React.Dispatch<React.SetStateAction<number>>;
};

const TypeSettingContainer = ({
  idx,
  boardInfo,
  setCurrentModal,
  setReload,
}: Props) => {
  const { token } = useLogined();
  const { roles } = useProfile();
  const [selectedType, setSelectedType] = useState<string>(boardInfo.btypeName);

  const role = useMemo(() => roles, [roles]);

  const onClickApply = useCallback(async () => {
    if (!token) return;

    const { config } = await requestSecurePut(
      apiRoute.admin.boardSet.info + idx,
      {},
      {
        id: idx,
        btypeName: selectedType,
      },
      token,
    );
    if (config.status >= 200 && config.status < 400) {
      setCurrentModal(null);
      setReload((prev) => prev + 1);
    }
  }, [idx, selectedType, setCurrentModal, token, setReload]);

  return (
    <TypeSetting
      setCurrentModal={setCurrentModal}
      role={role ? role[0] : null}
      selectedType={selectedType}
      setSelectedType={setSelectedType}
      onClickApply={onClickApply}
    />
  );
};

export default TypeSettingContainer;
