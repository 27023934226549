import styled from 'styled-components';

const LargeModalStyle = styled.div`
  .modal-header {
    h5 {
      font-weight: bold;
    }
    .large-btn {
      margin-left: 65%;
      .btn {
        margin: 0 0.25vw;
        color: white;
      }
    }
  }
  .modal-body {
    .department {
      margin-bottom: 1vh;
    }
    .roomlist {
      .row {
        margin: 0.25vh 0;
        .btn {
          width: 100%;
        }
      }
    }
  }
`;

export default LargeModalStyle;
