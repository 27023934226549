import {
  CButton,
  CCard,
  CCardBody,
  CCardHeader,
  CFormInput,
  CFormLabel,
  CTable,
} from "@coreui/react";
import {
  AdminBoardSettingSubtitleContentTypes,
  AdminBoardSettingSubtitleTypes,
} from "@typedef/components/Admin/BoardSetting/admin.board.setting.types";
import SubtitleSettingStyle from "../../styles/Sub/SubtitleSettingStyle";

type Props = {
  subtitle: AdminBoardSettingSubtitleTypes;
  contents: AdminBoardSettingSubtitleContentTypes[];
  onSubtitleAddClick: () => void;
  onSubtitleApplyClick: () => void;
  onSubtitleRemoveClick: (contentId: number) => void;
  onSubtitleChange: (display: boolean, speed: number) => void;
  onContentsChange: (
    contentId: number,
    content: string,
    checkFlag: boolean
  ) => void;
};

const SubtitleSetting = ({
  subtitle,
  contents,
  onSubtitleAddClick,
  onSubtitleApplyClick,
  onSubtitleRemoveClick,
  onSubtitleChange,
  onContentsChange,
}: Props) => {
  return (
    <SubtitleSettingStyle>
      <CCard>
        <CCardHeader>
          <h5>자막설정</h5>
          <div className="btns">
            <CButton color="success" onClick={onSubtitleAddClick}>
              추가
            </CButton>
            <CButton color="info" onClick={onSubtitleApplyClick}>
              적용
            </CButton>
          </div>
        </CCardHeader>
        <CCardBody>
          <div className="menus">
            <div className="visible">
              <input
                type="checkbox"
                id="subDisplay"
                checked={subtitle.display}
                onChange={(e) => {
                  onSubtitleChange(e.target.checked, subtitle.speed);
                }}
              />
              <CFormLabel htmlFor="subDisplay">표시여부</CFormLabel>
            </div>
            <div className="speed">
              <CFormLabel htmlFor="subtitlerange">스피드</CFormLabel>
              <input
                id="speed"
                type="range"
                min="0"
                step={10}
                max="100"
                value={subtitle.speed}
                onChange={(e) => {
                  onSubtitleChange(subtitle.display, Number(e.target.value));
                }}
              />
            </div>
          </div>
          <div className="subtitletables">
            <CTable striped>
              <colgroup>
                <col width="5%"></col>
                <col width="80%"></col>
                <col width="15%"></col>
              </colgroup>
              <tbody>
                {contents.map((content) => (
                  <tr id={`${content?.idx}`} key={content?.idx}>
                    <td>
                      <input
                        type="checkbox"
                        checked={content?.checkFlag}
                        id={`${content?.idx}`}
                        onChange={(e) => {
                          onContentsChange(
                            content?.idx,
                            content?.contents,
                            e.target.checked
                          );
                        }}
                      />
                    </td>
                    <td>
                      <CFormInput
                        type="text"
                        id={`${content?.idx}`}
                        title={content?.contents}
                        autoComplete="off"
                        value={content?.contents ?? ""}
                        onChange={(e) => {
                          onContentsChange(
                            content?.idx,
                            e.target.value,
                            content?.checkFlag
                          );
                        }}
                      />
                    </td>
                    <td>
                      <CButton
                        color="danger"
                        id={`${content?.idx}`}
                        onClick={(e) => {
                          onSubtitleRemoveClick(content?.idx);
                        }}
                      >
                        삭제
                      </CButton>
                    </td>
                  </tr>
                ))}
              </tbody>
            </CTable>
          </div>
        </CCardBody>
      </CCard>
    </SubtitleSettingStyle>
  );
};

export default SubtitleSetting;
