import LargeModalStyle from '@components/Admin/BoardSetting/styles/Header/LargeModalStyle';
import {
  CButton,
  CCol,
  CFormInput,
  CFormSelect,
  CModal,
  CModalBody,
  CModalHeader,
  CRow,
} from '@coreui/react';
import { faTimesCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { RoomListItemTypes } from '@typedef/components/Admin/BoardSetting/components/RoomList/admin.board.setting.type.room.list.types';
import { AdminClinicTypes } from '@typedef/components/Admin/Clinic/admin.clinic.types';
import { AdminDeptTypes } from '@typedef/components/Admin/Clinic/components/admin.dept.types';
import React from 'react';

type Props = {
  setCurrentModal: React.Dispatch<React.SetStateAction<string | null>>;
  roomList: RoomListItemTypes[];
  clinicList: AdminClinicTypes[];
  deptList: AdminDeptTypes[];
  onDepartmentSelect: (department: string) => void;
  onRoomListAdd: () => void;
  onRoomListRemove: (id: number) => void;
  onRoomListApply: () => void;
  onRoomListNumberChange: (id: number, value: string) => void;
  onRoomListCodeChange: (roomId: number, roomCode: string) => void;
};

const LargeModal = ({
  setCurrentModal,
  roomList,
  clinicList,
  deptList,
  onDepartmentSelect,
  onRoomListAdd,
  onRoomListRemove,
  onRoomListApply,
  onRoomListNumberChange,
  onRoomListCodeChange,
}: Props) => {
  return (
    <CModal
      visible={true}
      className='largeinput'
      onClose={() => setCurrentModal(null)}
      size='lg'
      backdrop='static'
    >
      <LargeModalStyle>
        <CModalHeader>
          <h5>진료실 설정</h5>
          <div className='large-btn'>
            <CButton color='success' onClick={onRoomListAdd}>
              추가
            </CButton>
            <CButton color='info' onClick={onRoomListApply}>
              적용
            </CButton>
          </div>
        </CModalHeader>
        <CModalBody>
          <div className='department'>
            <CFormSelect
              className='department-select'
              onChange={(e) => {
                onDepartmentSelect(e.target.value);
              }}
            >
              <option value=''>진료과 선택</option>
              {deptList
                .sort((a, b) => (a.deptHngNm > b.deptHngNm ? 1 : -1))
                .map((data) => (
                  <option value={data.deptHngNm} key={data.deptCd}>
                    {data.deptHngNm}
                  </option>
                ))}
            </CFormSelect>
          </div>
          <div className='roomlist'>
            {roomList.map((room, index) => (
              <CRow key={room.id ?? index} id={`${room.id}`}>
                <CCol xs='3'>
                  <CFormInput
                    autoComplete='off'
                    className='roomNumber'
                    placeholder='진료실번호'
                    id={`${room.id}`}
                    value={room.broomNumber || ''}
                    onChange={(e) => {
                      onRoomListNumberChange(room.id, e.target.value);
                    }}
                  />
                </CCol>
                <CCol xs='3'>
                  <CFormSelect
                    className='roomCode'
                    id={`${room.id}`}
                    value={room.roomCode ?? ''}
                    onChange={(e) => {
                      onRoomListCodeChange(room.id, e.target.value);
                    }}
                  >
                    <option value=''>진료실 선택</option>
                    {clinicList
                      .sort((a, b) => (a.roomCode > b.roomCode ? 1 : -1))
                      .map((data) => (
                        <option value={data.roomCode} key={data.id}>
                          {data.roomCode}
                        </option>
                      ))}
                  </CFormSelect>
                </CCol>
                <CCol xs='3'>
                  <CFormInput
                    autoComplete='off'
                    className='roomDoctorKor'
                    placeholder='의사명'
                    value={room.doctorKor ?? ''}
                    disabled
                  />
                </CCol>
                <CCol xs='3'>
                  <CButton
                    color='danger'
                    onClick={() => {
                      onRoomListRemove(room.id);
                    }}
                  >
                    <FontAwesomeIcon icon={faTimesCircle} color='white' />
                  </CButton>
                </CCol>
              </CRow>
            ))}
          </div>
        </CModalBody>
      </LargeModalStyle>
    </CModal>
  );
};

export default LargeModal;
