import styled from 'styled-components';

const space = '0.5vw';

export const OutLabPatientListWaitingStyle = styled.div`
  width: 100%;
  flex: 1;
  display: flex;
  flex-direction: column;
  background-color: #fff;
  border-radius: 1vw;
  padding: ${space};

  * {
    margin: 0;
  }
`;

type Props = {
  family?: string;
  fz?: number;
  fw?: boolean;
  color?: string;
  align?: string;
};

export const CustomPTag = styled.p<Props>`
  font-family: ${(props) => props.family};
  font-size: calc(${(props) => props.fz}vh / 20);
  font-weight: ${(props) => (props.fw ? 'bold' : 'normal')};
  color: ${(props) => props.color};
  text-align: ${(props) => props.align ?? 'left'};
`;

export const Header = styled.header`
  width: 100%;
  height: 15vh;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 1vw;

  .doctor {
    display: flex;
    flex-direction: row;

    .imageBox {
      width: 7vw;
      img {
        width: 100%;
        height: 100%;
        object-fit: fill;
        border-radius: 1vw;
        box-shadow: 0 0 10px 5px gray;
      }
    }

    .infoBox {
      display: flex;
      flex-direction: column;
      padding: ${space} 0;

      span {
        width: fit-content;
        padding: 0 1.5vw;
        background-color: #0d0662;
        color: white;
        font-size: 1.5vw;
        font-weight: 600;
        border-radius: 0 1vh 1vh 0;
      }

      p {
        text-align: center;
      }
    }
  }

  .roomNum {
    width: 14vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: #0d0662;
    border-radius: 1vh;

    span {
      color: #fff;
      font-size: 1vw;
      font-weight: 600;
    }
  }
`;

export const Patients = styled.ul`
  flex: 1;
  display: flex;
  flex-direction: column;
  list-style: none;
  li {
    margin: 0.1vw 0;
  }
`;

type PatientProps = {
  arrive: boolean;
};

export const Patient = styled.li<PatientProps>`
  flex: 1;
  display: flex;
  flex-direction: row;
  align-items: center;
  background-color: ${(PatientProps) =>
    PatientProps.arrive ? '#3d428a' : 'lightgray'};
  border-radius: 0.7vw;

  div {
    width: 50%;
    position: relative;
  }

  p {
    width: 100%;
    text-align: center;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  &:nth-child(n + 11) {
    display: none;
  }
`;
