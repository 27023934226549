import styled from "styled-components";

const ViewerNavigationStyle = styled.div`
  display: flex;
  cursor: none;
  flex-direction: column;
  overflow-y: hidden;
  .content-root {
    display: flex;
    flex: 1;
    .viewer-loading-spinner {
      position: absolute;
      display: flex;
      align-items: center;
      height: 100%;
      width: 100%;
      justify-content: center;
      z-index: 100;
      flex-direction: column;
      .spinner-border {
        width: 6rem;
        height: 6rem;
        border: 0.35em solid currentColor;
        border-right-color: transparent;
        border-radius: 50%;
      }
      p {
        width: 6rem;
        margin: 0.5em 0;
        text-align: center;
      }
    }
  }
`;

export default ViewerNavigationStyle;
