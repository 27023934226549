import styled from 'styled-components';

type Props = {
  height: number;
};

export const Main = styled.main<Props>`
  flex: 1;
  padding: 1em;

  .card {
    box-shadow: 2px 3px 8px 1px gray;
    border-radius: 0 10px 10px 10px;
    .card-body {
      height: 85vh;
      overflow-y: auto;
    }
  }
`;

export const Tabs = styled.section`
  display: flex;
  flex-direction: row;
  width: 100%;
  .active {
    background-color: #3498ff;
    color: #fff;
    font-weight: 600;
  }
`;

export const Tab = styled.div`
  cursor: pointer;
  background-color: lightgray;
  border-radius: 5px 5px 0 0;
  padding: 5px 10px;
  font-size: 0.9rem;
  &:first-child {
    margin-right: 5px;
  }
`;

export const Btns = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 1em;

  .left {
    display: flex;
    flex-direction: row;
  }

  button {
    width: fit-content;
    height: 35px;
    padding: 0 10px;
    margin-right: 10px;
    color: #fff;
    font-size: 0.9rem;
    font-weight: 600;
  }
`;
