import {
  FontFamilyOptionItemTypes,
  VIEWER_USE_OPTIONS,
} from './../../typedef/libs/config.types';
import { FontItemTypes } from './../../typedef/components/Admin/BoardSetting/components/Font/admin.board.setting.type.font.types';
export const fontFamilyOption: FontFamilyOptionItemTypes[] = [
  {
    name: '고딕 A1',
    value: 'Gothic A1',
  },
  {
    name: '나눔 고딕',
    value: 'Nanum Gothic',
  },
  {
    name: 'NotoSans',
    value: 'NotoSans',
  },
  {
    name: '에스코어드림',
    value: 'SCDream',
  },
];

// 폰트 데이터 String으로 변환
export function createFontSettingString(font?: FontItemTypes) {
  if (font) {
    const data = {
      family: font?.family,
      size: font?.size,
      bold: font?.bold,
      color: font?.color,
    };
    return Object.values(data).join();
  }
}

// 폰트 데이터 Object로 변환
export function duplicateFontSettingComma(font: string) {
  let fontArray: string[] = font.split(',');
  return {
    id: undefined,
    family: fontArray[0],
    size: Number(fontArray[1]),
    bold: fontArray[2] === 'true' ? true : false,
    color: fontArray[3],
  } as FontItemTypes;
}

// 전광판 타입별 어떤 API 사용하는지 체크
export function checkViewerUseApi(viewName: string, useApi: string) {
  return VIEWER_USE_OPTIONS[viewName]?.option?.includes(useApi);
}
