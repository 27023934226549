import useLogined from '@hooks/store/useLogined';
import useProfile from '@hooks/store/useProfile';
import { apiRoute, requestGet } from '@libs/api';
import { ROOT_ROLE_KEY } from '@routes/config/admin.nav.confg';
import { LoginRepsonseTypes } from '@typedef/components/Login/login.types';
import { useCallback, useState } from 'react';
import Login from '../Login';

interface KeyboardEvent {
  key: string;
}

const LoginContainer = () => {
  const { __updateTokenFromHooks } = useLogined();
  const { __updateProfileFromHooks } = useProfile();

  const [id, setId] = useState<string>('');

  const [pw, setPw] = useState<string>('');

  const [role, setRole] = useState<string>('');

  const [staffLoginFlag, setStaffLoginFlag] = useState<boolean>(true);

  const onLoginClicked = useCallback(async () => {
    if (staffLoginFlag) {
      // 사번 로그인
      if (id === '' || role === '') {
        alert('아이디 또는 소속이 없습니다.');
      } else {
        const { data, config } = await requestGet<LoginRepsonseTypes>(
          apiRoute.admin.login + `?account=${id}&password=knu.ac.kr&roles=${role}`,
          {},
        );

        if (config.status === 200) {
          __updateTokenFromHooks(data.token);
          __updateProfileFromHooks(data);
          if (data.roles[0] === 'ROLE_OUT') {
            window.location.href = '/patient';
          } else {
            window.location.href = '/board';
          }
        } else {
          alert(config.msg ? config.msg : '로그인에 실패했습니다');
        }
      }
    } else {
      // 관리자 로그인
      if (id === '' || pw === '') {
        alert('아이디 또는 비밀번호가 없습니다.');
      } else {
        const { data, config } = await requestGet<LoginRepsonseTypes>(
          apiRoute.admin.login + `?account=${id}&password=${pw}&roles=${ROOT_ROLE_KEY}`,
          {},
        );

        if (config.status === 200) {
          __updateTokenFromHooks(data?.token);
          __updateProfileFromHooks(data);
          window.location.href = '/patient';
        } else {
          alert(config.msg ? config.msg : '로그인에 실패했습니다');
        }
      }
    }
  }, [id, pw, role, staffLoginFlag]);

  const onkeyPressEnterInput = (e: KeyboardEvent) => {
    if (e.key === 'Enter') {
      onLoginClicked();
    }
  };

  return (
    <Login
      setId={setId}
      setPw={setPw}
      role={role}
      setRole={setRole}
      staffLoginFlag={staffLoginFlag}
      setStaffLoginFlag={setStaffLoginFlag}
      onLoginClicked={onLoginClicked}
      onkeyPressEnterInput={onkeyPressEnterInput}
    />
  );
};

export default LoginContainer;
