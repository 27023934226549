import ClinicDelete from "../components/ClinicDelete";
import { useCallback } from "react";
import { apiRoute, requestSecureDelete } from "@libs/api";
import useLogined from "@hooks/store/useLogined";
import useGNB from "@hooks/store/useGNB";
import { AdminClinicModalItemTypes } from "@typedef/components/Admin/Clinic/admin.clinic.types";

type Props = {
  idxes: number[];
  setCurrentModal: React.Dispatch<
    React.SetStateAction<AdminClinicModalItemTypes>
  >;
  reload: number;
  setReload: React.Dispatch<React.SetStateAction<number>>;
};

const ClinicDeleteContainer = ({
  idxes,
  setCurrentModal,
  reload,
  setReload,
}: Props) => {
  const { token } = useLogined();

  const { myBoardReload, __updateGNBMyBoardReloadFromHooks } = useGNB();

  const deleteClinic = useCallback(async () => {
    if(!token) return;

    const { config } = await requestSecureDelete(
      apiRoute.admin.clinic.deleteClinic,
      {},
      [...idxes],
      token
    );
    if (config.status >= 200 && config.status < 400) {
      setCurrentModal(null);

      setReload(reload + 1);

      __updateGNBMyBoardReloadFromHooks(myBoardReload + 1);
    }
  }, [idxes, setCurrentModal, token, reload, myBoardReload]);

  return (
    <ClinicDelete
      length={idxes.length}
      deleteClinic={deleteClinic}
      setCurrentModal={setCurrentModal}
    />
  );
};

export default ClinicDeleteContainer;
