import useViewer from '@hooks/store/useViewer';
import React, { useState } from 'react';
import VideoWaiting from '../VideoWaiting';

const VideoWaitingContainer = () => {
  const { basic, font, video, notice } = useViewer();

  const [videoIndex, setVideoIndex] = useState<number>(0);
  return basic && font && notice ? (
    <VideoWaiting
      basic={basic}
      font={font}
      video={video}
      notice={notice}
      videoIndex={videoIndex}
      setVideoIndex={setVideoIndex}
    />
  ) : null;
};

export default React.memo(VideoWaitingContainer);
