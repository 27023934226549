import styled from 'styled-components';

const LabHeaderStyle = styled.div`
  width: 100%;
  p {
    margin: auto 0;
    text-align: left;
    font-weight: bold;
    font-size: 0.9rem;
  }
  .broomnumber-roomlist {
    margin: 0;
    .row {
      margin: 0;
      .form-control {
        text-align: center;
        vertical-align: middle;
        width: 50%;
      }
    }
  }
`;

export default LabHeaderStyle;
