import styled from "styled-components";

const space = "0.5vw";

export const ExaminationPatientListWaitingStyle = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  background-color: #fff;
  border-radius: 1vw;
  padding: ${space};

  .row {
    height: 100%;
    display: flex;
    flex-direction: row;
  }
`;

export const CustomPTag = styled.p<{
  family?: string;
  fz?: number;
  fw?: boolean;
  color?: string;
  align?: string;
}>`
  font-family: ${(props) => props.family};
  font-size: calc(${(props) => props.fz}vh / 11);
  font-weight: ${(props) => (props.fw ? "bold" : "normal")};
  color: ${(props) => props.color};
  text-align: ${(props) => props.align ?? "left"};
  white-space: nowrap;
`;

export const Thead = styled.section`
  width: 100%;
  height: 9vh;
  position: relative;
  background-color: #0d0662;
  border-radius: 1vw;

  p {
    text-align: center;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
  }
`;

export const Patients = styled.ul<{ isDouble: boolean }>`
  width: ${({ isDouble }) => (isDouble ? 50 : 100)}%;
  height: 100%;
  display: flex;
  flex-direction: column;
  list-style: none;
  margin: 0;
  padding: 0;

  &:last-child {
    padding-left: ${({ isDouble }) => isDouble && "0.25vw"};
  }
`;

export const Patinet = styled.li`
  flex: 1;
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 0.25vw;
  background-color: lightgray;
  border-radius: 0.7vw;

  div {
    width: 30%;
    height: 100%;
    position: relative;
  }

  .wide {
    width: 70%;
  }

  p {
    width: 100%;
    text-align: center;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  &:nth-child(2n) {
    background-color: #8ba5be;
  }
  &:nth-child(n + 11) {
    display: none;
  }
`;

export default ExaminationPatientListWaitingStyle;
