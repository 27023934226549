export function alertMessage(
  message: string,
  noticeUser?: boolean,
  fileName?: string,
  lineNumber?: number,
) {
  if (noticeUser) {
    alert(message);
  }
}
