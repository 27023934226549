import { LoadVideoConfigItemTypes } from "./../../typedef/routes/viewer.navigation.types";
import {
  LoadBasicConfigResponseDataType,
  LoadFontConfigResponseDataType,
  LoadNoticeConfigResponseDataType,
  LoadSubtitleConfigResponseDataType,
  LoadVisibleConfigResponseDataType,
} from "@typedef/routes/viewer.navigation.types";
import { ViewerActionTypes } from "./actions";
import {
  UPDATE_BASIC_CONFIG_ACTION,
  UPDATE_FONT_CONFIG_ACTION,
  UPDATE_NOTICE_CONFIG_ACTION,
  UPDATE_SUBTITLE_CONFIG_ACTION,
  UPDATE_VIDEO_CONFIG_ACTION,
  UPDATE_VISIBLE_CONFIG_ACTION,
} from "./modules/actionTypes";

export type ViewerStateTypes = {
  basic: LoadBasicConfigResponseDataType | null;
  font: LoadFontConfigResponseDataType | null;
  notice: LoadNoticeConfigResponseDataType | null;
  sub: LoadSubtitleConfigResponseDataType | null;
  video: LoadVideoConfigItemTypes[] | null;
  visible: LoadVisibleConfigResponseDataType | null;
};

const init: ViewerStateTypes = {
  basic: null,
  font: null,
  notice: null,
  sub: null,
  video: null,
  visible: null,
};

const reducerEquals = (prev: any, payload: any) => {
  if (JSON.stringify(prev) === JSON.stringify(payload)) {
    return true;
  } else {
    return false;
  }
};

const viewerReducer = (
  prev: ViewerStateTypes = init,
  { type, payload }: ViewerActionTypes
): ViewerStateTypes => {
  switch (type) {
    case UPDATE_BASIC_CONFIG_ACTION:
      if (reducerEquals(prev.basic, payload)) {
        return prev;
      } else {
        return { ...prev, basic: payload };
      }

    case UPDATE_FONT_CONFIG_ACTION:
      if (reducerEquals(prev.font, payload)) {
        return prev;
      } else {
        return {
          ...prev,
          font: payload,
        };
      }

    case UPDATE_NOTICE_CONFIG_ACTION:
      if (reducerEquals(prev.notice, payload)) {
        return prev;
      } else {
        return {
          ...prev,
          notice: payload,
        };
      }

    case UPDATE_SUBTITLE_CONFIG_ACTION:
      if (reducerEquals(prev.sub, payload)) {
        return prev;
      } else {
        return {
          ...prev,
          sub: payload,
        };
      }

    case UPDATE_VIDEO_CONFIG_ACTION:
      if (reducerEquals(prev.video, payload)) {
        return prev;
      } else {
        return {
          ...prev,
          video: payload,
        };
      }

    case UPDATE_VISIBLE_CONFIG_ACTION:
      if (reducerEquals(prev.visible, payload)) {
        return prev;
      } else {
        return {
          ...prev,
          visible: payload,
        };
      }

    default:
      return prev;
  }
};

export default viewerReducer;
