import {
  CModal,
  CModalHeader,
  CButton,
  CModalBody,
  CModalFooter,
  CFormInput,
  CFormLabel,
  CCol,
  CFormSelect,
} from "@coreui/react";
import { AdminAccountInputsTypes } from "@typedef/components/Admin/Account/components/admin.account.inputs.types";

type Props = {
  type: "추가" | "수정";
  setCurrentModal: React.Dispatch<
    React.SetStateAction<"update" | "delete" | null>
  >;
  inputs: AdminAccountInputsTypes;
  onChangeId: (value: string) => void;
  roles: {
    name: string;
    value: string;
  }[];
  onClickSelect: (value: string) => void;
  save: () => Promise<void>;
};

const AccoutUpdate = ({
  setCurrentModal,
  type,
  inputs,
  onChangeId,
  roles,
  onClickSelect,
  save,
}: Props) => {
  return (
    <CModal
      backdrop="static"
      visible={true}
      onClose={() => setCurrentModal(null)}
    >
      <CModalHeader>계정 {type}</CModalHeader>
      <CModalBody>
        <CCol>
          <CFormInput
            label="ID"
            onChange={(e) => onChangeId(e.target.value)}
            value={inputs.account}
            placeholder="ID 입력"
          />
        </CCol>
        <CCol>
          <CFormLabel>권한 선택</CFormLabel>
          <CFormSelect
            value={inputs.role_kor ? inputs.role_kor : ""}
            onChange={(e) => {
              onClickSelect(e.target.value);
            }}
          >
            <option value="">권한선택</option>
            {roles.map((role) => (
              <option value={role.name} key={role.value}>
                {role.name}
              </option>
            ))}
          </CFormSelect>
        </CCol>
      </CModalBody>
      <CModalFooter>
        <CButton color="primary" onClick={save}>
          {type}하기
        </CButton>
        <CButton color="secondary" onClick={() => setCurrentModal(null)}>
          취소
        </CButton>
      </CModalFooter>
    </CModal>
  );
};

export default AccoutUpdate;
