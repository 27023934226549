import { parseFontString } from '@libs/factory';
import {
  LoadFontConfigResponseDataType,
  LoadSubtitleConfigResponseDataType,
} from '@typedef/routes/viewer.navigation.types';
import { RefObject } from 'react';
import { SubTitleStyle, CustomPTag } from './styles/SubTitleStyle';

type Props = {
  sub: LoadSubtitleConfigResponseDataType;
  font: LoadFontConfigResponseDataType;
  subTitleRef: RefObject<HTMLParagraphElement>;
  height: string;
  width: number;
};

const SubTitle = ({ sub, font, subTitleRef, height, width }: Props) => {
  return (
    <SubTitleStyle height={height}>
      {sub.display ? (
        <CustomPTag
          ref={subTitleRef}
          width={width}
          family={parseFontString(font.fsub).family}
          fz={parseFontString(font.fsub).size}
          fw={parseFontString(font.fsub).bold}
          color={parseFontString(font.fsub).color}
          speed={sub.speed === 0 ? 0 : 110 - sub.speed}>
          {sub.contents}
        </CustomPTag>
      ) : null}
    </SubTitleStyle>
  );
};

export default SubTitle;
