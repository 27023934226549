import {
  CDropdown,
  CDropdownItem,
  CDropdownMenu,
  CDropdownToggle,
  CHeader,
  CHeaderNav,
  CHeaderToggler,
  CNavItem,
} from "@coreui/react";
import {
  faBars,
  faSignOutAlt,
  faUserCircle,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { USER_ROUTE } from "@routes/config/admin.nav.confg";
import { Link } from "react-router-dom";
import AdminNavStyle from "./styles/AdminNavStyle";

type Props = {
  userRole: string;
  account?: string;
  path: string;
  onLinkClicked: (link: string) => void;
  onLogoutClicked: () => void;
  visibleHandler: () => void;
};

const AdminNav = ({
  userRole,
  account,
  path,
  onLinkClicked,
  onLogoutClicked,
  visibleHandler,
}: Props) => {
  return (
    <AdminNavStyle>
      <CHeader>
        <CHeaderToggler onClick={visibleHandler}>
          <FontAwesomeIcon icon={faBars} />
        </CHeaderToggler>
        <CHeaderNav>
          {USER_ROUTE[userRole].map((item) => (
            <CNavItem key={item.link}>
              <Link
                to={`/${item.link}`}
                style={{
                  color: item.link === path ? "#000" : "",
                }}
                onClick={() => {
                  onLinkClicked(item.link);
                }}
                className="nav-link"
              >
                {item.label}
              </Link>
            </CNavItem>
          ))}
        </CHeaderNav>
        <CDropdown className="profile">
          <CDropdownToggle color="white">
            <div className="avatar">
              <FontAwesomeIcon icon={faUserCircle} />
            </div>
          </CDropdownToggle>
          <CDropdownMenu placeholder="bottom-start">
            <CDropdownItem color="light">
              <strong>사번 : {account} </strong>
            </CDropdownItem>
            <CDropdownItem className="logout" onClick={onLogoutClicked}>
              <p>
                <FontAwesomeIcon icon={faSignOutAlt} />
                <span>로그아웃</span>
              </p>
            </CDropdownItem>
          </CDropdownMenu>
        </CDropdown>
      </CHeader>
    </AdminNavStyle>
  );
};

export default AdminNav;
