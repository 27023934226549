import { CommonActionTypes } from '@store/actions';
import { STORE_INIT_ACTION_TYPES } from '@store/modules/actionTypes';
import { MyBoardListItemTypes } from '@typedef/components/Login/login.types';
import { MultiRoomActionTypes } from './actions';
import { SET_ROOM_ACTION_TYPE } from './modules/actionTypes';

export type MultiRoomStateType = {
  multiRoomList: MyBoardListItemTypes[];
};

const init: MultiRoomStateType = {
  multiRoomList: [],
};

const MULTI_ROOM_SESSION_KEY = '@multiRoom';

const getMultiRoom = () =>
  JSON.parse(
    sessionStorage.getItem(MULTI_ROOM_SESSION_KEY) || '[]',
  ) as MyBoardListItemTypes[];

const setMultiRoom = (multiRoomList: MyBoardListItemTypes[]) =>
  sessionStorage.setItem(MULTI_ROOM_SESSION_KEY, JSON.stringify(multiRoomList));

const multiRoomReducer = (
  prev: MultiRoomStateType = init,
  { type, payload }: MultiRoomActionTypes | CommonActionTypes,
): MultiRoomStateType => {
  switch (type) {
    case STORE_INIT_ACTION_TYPES: {
      const sessionValue = getMultiRoom();

      return {
        multiRoomList: sessionValue,
      };
    }

    case SET_ROOM_ACTION_TYPE: {
      setMultiRoom(payload);

      return {
        multiRoomList: payload,
      };
    }

    default:
      return prev;
  }
};

export default multiRoomReducer;
