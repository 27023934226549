import * as S from '../../styles/BoardSettingStyle';
import { CFormInput } from '@coreui/react';
import { AdminBoardSettingTypes } from '@typedef/components/Admin/BoardSetting/admin.board.setting.types';

type Props = {
  boardInfo: AdminBoardSettingTypes;
  onChangeBoardInfo: (id: string, name: string, value: string) => void;
};

const HealthHeader = ({ boardInfo, onChangeBoardInfo }: Props) => {
  return (
    <div className='header-body between'>
      <S.Inputs width='23%'>
        <CFormInput
          type='text'
          label='검사실 번호'
          name='broomNumber1'
          value={boardInfo.broomNumber ? boardInfo.broomNumber[0] : 0}
          onChange={(e) => {
            onChangeBoardInfo('broomNumber0', 'broomNumber', e.target.value);
          }}
          placeholder='0'
        />
      </S.Inputs>

      <S.Inputs width='23%'>
        <CFormInput
          type='text'
          label='검사실 명칭'
          value={boardInfo.bname === '-' ? '' : boardInfo.bname ?? ''}
          onChange={(e) => {
            onChangeBoardInfo('bname', 'bname', e.target.value);
          }}
          placeholder='검사실 명칭 입력'
        />
      </S.Inputs>

      <S.Inputs width='23%'>
        <CFormInput
          type='text'
          label='검사실 영어명'
          value={boardInfo.bnameEng === '-' ? '' : boardInfo.bnameEng ?? ''}
          onChange={(e) => {
            onChangeBoardInfo('bnameEng', 'bnameEng', e.target.value);
          }}
          placeholder='검사실 영어명 입력'
        />
      </S.Inputs>

      <S.Inputs width='23%'>
        <CFormInput
          type='text'
          label='담당자명'
          value={boardInfo.adminName === '-' ? '' : boardInfo.adminName ?? ''}
          onChange={(e) => {
            onChangeBoardInfo('adminName', 'adminName', e.target.value);
          }}
          placeholder='담당자명 입력'
        />
      </S.Inputs>
    </div>
  );
};

export default HealthHeader;
