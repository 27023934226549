import RootNavigation from '@routes/RootNavigation';
import { useEffect } from 'react';

const RootNavigationContainer = () => {
  // 입력한 주소에 따라 title 변경
  useEffect(() => {
    const htmlTitle = document.querySelector('title');

    if (htmlTitle) {
      htmlTitle.innerHTML = '전광판 솔루션';
    }
  }, []);

  return <RootNavigation />;
};

export default RootNavigationContainer;
