import { CCol, CRow } from '@coreui/react';
import { FontItemTypes } from '@typedef/components/Admin/BoardSetting/components/Font/admin.board.setting.type.font.types';
import FontSettingItemBackgroundStyle from '../../styles/Font/FontSettingItemBackgroundStyle';

type Props = {
  content: string;
  font: FontItemTypes;
  onColorChange: (value: string) => void;
};

const FontSettingItemBackground = ({ content, font, onColorChange }: Props) => {
  return (
    <FontSettingItemBackgroundStyle>
      <CRow>
        <CCol className='font-content'>
          <p>{content}</p>
        </CCol>
        <CCol xs='12' className='font-color'>
          <CRow>
            <span>색상</span>
            <input
              type='color'
              id='color'
              onChange={(e) => {
                onColorChange(e.target.value);
              }}
              value={font?.color || '#000000'}
            />
          </CRow>
        </CCol>
      </CRow>
    </FontSettingItemBackgroundStyle>
  );
};

export default FontSettingItemBackground;
