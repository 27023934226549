import styled from 'styled-components';

const LoginStyle = styled.div`
  width: 100vw;
  height: 100vh;
  background-color: lightgray;
  .container {
    width: 100%;
    height: 90%;
    padding-top: 1em;
    .card {
      border-radius: 25px;
      box-shadow: 2px 3px 10px 1px white;
      .card-header {
        border-radius: 25px;
        z-index: 2;
        padding-top: 1em;
        img {
          border-radius: 25px;
          width: 100%;
          height: 90vh;
        }
      }
      .card-body {
        background-color: rgb(255, 255, 255, 1);
        position: absolute;
        z-index: 3;
        bottom: 0;
        width: 100%;
        border-radius: 0 0 25px 25px;
        text-align: center;
        /* 각 input들 ( id, password, btn ) */
        .input-group {
          width: 50%;
          margin: 0 auto;
        }
        /* 로그인버튼 */
        .btn {
          width: 100%;
        }
        .staff-login-switch {
          .staff-switch-content {
            display: flex;
            height: 100%;
            padding-left: 2.5em;
            .staff-switch {
              position: relative;
              display: inline-block;
              width: 50px;
              height: 30px;
              .staff-login-flag-switch {
                opacity: 0;
                width: 0;
                height: 0;
                &:checked + .slider {
                  background-color: #321fdb;
                }
                &:focus + .slider {
                  box-shadow: 0 0 1px #321fdb;
                }
              }
              .slider {
                border-radius: 15px;
                position: absolute;
                cursor: pointer;
                top: 4px;
                left: 0;
                right: 0;
                bottom: -4px;
                background-color: #ccc;
                -webkit-transition: 0.4s;
                transition: 0.4s;
                &::before {
                  position: absolute;
                  content: '';
                  height: 22px;
                  width: 22px;
                  left: 4px;
                  bottom: 4px;
                  background-color: white;
                  border-radius: 15px;
                  -webkit-transition: 0.4s;
                  transition: 0.4s;
                }
              }

              .staff-login-flag-switch:checked + .slider::before {
                -webkit-transform: translateX(22px);
                -ms-transform: translateX(22px);
                transform: translateX(20px);
              }
            }
            .staff-login-switch-label {
              padding-left: 0.25em;
              margin: 0;
              height: 100%;
              display: flex;
              align-items: center;
              .form-label {
                cursor: pointer;
                margin: 0;
              }
            }
          }
        }
      }
    }
  }
`;

export default LoginStyle;
