import {
  CButton,
  CCard,
  CCardBody,
  CCardHeader,
  CFormInput,
} from '@coreui/react';
import { SetStateAction } from 'react';
import DelaySettingStyle from '../../styles/Delay/DelaySettingStyle';

type Props = {
  delaySettingApply: () => void;
  delay: number;
  setDelay: React.Dispatch<SetStateAction<number>>;
};

const DelaySetting = ({ delaySettingApply, delay, setDelay }: Props) => {
  return (
    <DelaySettingStyle>
      <CCard>
        <CCardHeader>
          <h5>지연시간 설정</h5>
          <div className='btns'>
            <CButton color='info' onClick={delaySettingApply}>
              적용
            </CButton>
          </div>
        </CCardHeader>
        <CCardBody>
          <div>
            <p>지연시간 (분)</p>
            <CFormInput
              type='number'
              // delay 값이 0 이라면 Blank 출력
              value={delay === 0 ? '' : delay ?? ''}
              onChange={(e) => {
                setDelay(Number(e.target.value));
              }}
            />
          </div>
        </CCardBody>
      </CCard>
    </DelaySettingStyle>
  );
};

export default DelaySetting;
