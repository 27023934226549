import * as S from '../styles/SurgeryVerticalWaitingStyle';
import { Surgery1PatientItemTypes } from '@typedef/components/SurgeryWaiting/surgery.waiting.types';
import { maskingName } from '@libs/monitor';
import {
  LoadFontConfigResponseDataType,
  LoadVisibleConfigResponseDataType,
} from '@typedef/routes/viewer.navigation.types';
import { parseFontString } from '@libs/factory';

type Props = {
  font: LoadFontConfigResponseDataType;
  currentSurgeryList: Surgery1PatientItemTypes[];
  visible: LoadVisibleConfigResponseDataType;
};

const SurgeryVerticalWaiting = ({ font, currentSurgeryList, visible }: Props) => {
  return (
    <S.SurgeryVerticalWaitingStyle>
      <S.Thead>
        <p>환자명</p>
        <p className='wide'>진료과</p>
        <p>성별/나이</p>
        <p>진행현황</p>
        <p className='wide'>퇴실장소</p>
      </S.Thead>
      <S.Patients>
        {currentSurgeryList.map((data, index) => (
          <S.Patient key={data?.pid + '-' + index}>
            <S.CustomPTag
              family={parseFontString(font.fpatientName).family}
              fz={parseFontString(font.fpatientName).size}
              fw={parseFontString(font.fpatientName).bold}
              color={parseFontString(font.fpatientName).color}
            >
              {maskingName(data?.hngnm, {
                vmasking: visible.vmasking,
                position: visible.position,
                maskMark: visible.maskMark,
              })}
            </S.CustomPTag>
            <S.CustomPTag
              family={parseFontString(font.fpatientName).family}
              fz={parseFontString(font.fpatientName).size}
              fw={parseFontString(font.fpatientName).bold}
              color={parseFontString(font.fpatientName).color}
              className='wide'
            >
              {data?.orddeptnm ?? ''}
            </S.CustomPTag>
            <S.CustomPTag
              family={parseFontString(font.fpatientName).family}
              fz={parseFontString(font.fpatientName).size}
              fw={parseFontString(font.fpatientName).bold}
              color={parseFontString(font.fpatientName).color}
            >
              {data?.sex && data?.age ? `${data?.sex} / ${data?.age}` : ''}
            </S.CustomPTag>
            <S.CustomPTag
              family={parseFontString(font.fpatientName).family}
              fz={parseFontString(font.fpatientName).size}
              fw={parseFontString(font.fpatientName).bold}
              color={parseFontString(font.fpatientName).color}
            >
              {data?.opstatnm ?? ''}
            </S.CustomPTag>
            <S.CustomPTag
              family={parseFontString(font.fpatientName).family}
              fz={parseFontString(font.fpatientName).size}
              fw={parseFontString(font.fpatientName).bold}
              color={parseFontString(font.fpatientName).color}
              className='wide'
            >
              {data?.patposplcenm ?? ''}
            </S.CustomPTag>
          </S.Patient>
        ))}
      </S.Patients>
    </S.SurgeryVerticalWaitingStyle>
  );
};

export default SurgeryVerticalWaiting;
