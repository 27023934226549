import {
  CModal,
  CModalHeader,
  CModalBody,
  CModalFooter,
  CFormLabel,
  CFormInput,
  CButton,
  CFormSelect,
  CCol,
  CRow,
} from '@coreui/react';
import { AdminClinicInputsType } from '@typedef/components/Admin/Clinic/components/admin.clinic.inputs.type';
import { AdminDeptTypes } from '@typedef/components/Admin/Clinic/components/admin.dept.types';
import { AdminCenterTypes } from '@typedef/components/Admin/Clinic/components/admin.center.types';
import { AdminDoctorTypes } from '@typedef/components/Admin/Clinic/components/admin.doctor.types';
import { AdminBoardTypes } from '@typedef/components/Admin/Clinic/components/admin.board.types';
import { AdminClinicModalItemTypes } from '@typedef/components/Admin/Clinic/admin.clinic.types';

type Props = {
  setCurrentModal: React.Dispatch<
    React.SetStateAction<AdminClinicModalItemTypes>
  >;
  inputs: AdminClinicInputsType;
  onChangeRoomCode: (value: string) => void;
  onChangeInputs: (name: string, value: any) => void;
  onChangeSelect: (e: React.ChangeEvent<HTMLSelectElement>) => void;
  onChangeboard: (value: string) => void;
  deptList: AdminDeptTypes[] | null;
  boardList: AdminBoardTypes[] | null;
  save: () => Promise<void>;
  roomCodeCheck: () => void;
};

const ClinicUpdate = ({
  setCurrentModal,
  inputs,
  onChangeRoomCode,
  onChangeInputs,
  onChangeSelect,
  onChangeboard,
  deptList,
  boardList,
  save,
  roomCodeCheck,
}: Props) => {
  return (
    <CModal
      visible={true}
      onClose={() => setCurrentModal(null)}
      backdrop='static'
    >
      <CModalHeader>진료실 수정</CModalHeader>
      <CModalBody>
        <CCol>
          <CFormLabel>
            진료실이름 <span style={{ color: 'red' }}>*</span>
          </CFormLabel>
          <CRow>
            <CCol xs='9' style={{ paddingRight: '0' }}>
              <CFormInput
                style={{ borderRadius: '0.25em 0 0 0.25em' }}
                name='roomCode'
                onChange={(e) => onChangeRoomCode(e.target.value)}
                value={
                  inputs?.roomCode !== undefined ? inputs?.roomCode || '' : ''
                }
                placeholder='진료실이름'
              />
            </CCol>
            <CCol style={{ paddingLeft: '0' }}>
              <CButton
                onClick={roomCodeCheck}
                style={{ width: '100%', borderRadius: '0 .25em .25em 0' }}
              >
                중복검사
              </CButton>
            </CCol>
          </CRow>
        </CCol>
        <CCol>
          <CFormLabel>진료과</CFormLabel>
          <CFormSelect
            name='dept'
            onChange={onChangeSelect}
            value={inputs?.deptId !== undefined ? inputs?.deptId || '' : ''}
          >
            <option value=''>진료과 선택</option>
            {deptList
              ? deptList
                  .sort((a, b) => (a.deptHngNm > b.deptHngNm ? 1 : -1))
                  .map((dept) => (
                    <option key={dept.deptCd} value={dept.deptCd ?? ''}>
                      {dept.deptHngNm}
                    </option>
                  ))
              : null}
          </CFormSelect>
        </CCol>
        <CCol>
          <CFormLabel>센터</CFormLabel>
          <CCol style={{ paddingRight: '0' }}>
            <CFormInput
              style={{ borderRadius: '0.25em 0 0 0.25em' }}
              name='centerKor'
              onChange={(e) => onChangeInputs(e.target.name, e.target.value)}
              value={
                inputs?.centerKor !== undefined ? inputs?.centerKor || '' : ''
              }
              placeholder='센터명'
            />
          </CCol>
        </CCol>
        <CCol>
          <CFormLabel>담당의사</CFormLabel>
          <CCol style={{ paddingRight: '0' }}>
            <CFormInput
              style={{ borderRadius: '0.25em 0 0 0.25em' }}
              name='doctorKor'
              onChange={(e) => onChangeInputs(e.target.name, e.target.value)}
              value={
                inputs?.doctorKor !== undefined ? inputs?.doctorKor || '' : ''
              }
              placeholder='의사이름'
            />
          </CCol>
        </CCol>
        <CFormInput
          label='전광판 검색'
          name='board'
          onChange={(e) => onChangeboard(e.target.value)}
          defaultValue={
            inputs.boardCode ??
            boardList?.filter((data) => Number(data.id) === inputs.boardIdx)[0]
              ?.boardCode
          }
          placeholder='전광판'
          list='boardList'
        />
        <datalist id='boardList'>
          {boardList
            ?.sort((a, b) => (a.boardCode > b.boardCode ? 1 : -1))
            .map((board) => (
              <option value={board.boardCode} key={board.id} />
            ))}
        </datalist>
      </CModalBody>
      <CModalFooter>
        <CButton color='primary' onClick={save}>
          수정하기
        </CButton>
        <CButton color='secondary' onClick={() => setCurrentModal(null)}>
          취소
        </CButton>
      </CModalFooter>
    </CModal>
  );
};

export default ClinicUpdate;
