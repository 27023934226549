import useViewer from '@hooks/store/useViewer';
import { getCurrentList } from '@libs/factory';
import React, { useEffect, useMemo, useState } from 'react';
import OutLabLargePatientWaiting from '../OutLabLargePatientWaiting';

const PAGE_SIZE = 2;

const ROOM_LIST_INTERVAL = 5000;

const OutLabLargePatientWaitingContainer = () => {
  const { basic, font, video, notice } = useViewer();

  const [videoIndex, setVideoIndex] = useState<number>(0);

  const [intervalCounter, setIntervalCounter] = useState<number>(0);

  const currentReserveClinic = useMemo(() => {
    if (basic?.roomList) {
      return getCurrentList<string>(basic.roomList, PAGE_SIZE, intervalCounter);
    } else {
      return getCurrentList<any>([], PAGE_SIZE, intervalCounter);
    }
  }, [basic?.roomList, intervalCounter]);

  const currentReserveNumber = useMemo(() => {
    if (basic?.broomNumber) {
      return getCurrentList<string>(basic.broomNumber, PAGE_SIZE, intervalCounter);
    } else {
      return getCurrentList<any>([], PAGE_SIZE, intervalCounter);
    }
  }, [basic?.broomNumber, intervalCounter]);

  useEffect(() => {
    const interval = setInterval(() => {
      setIntervalCounter((prev) => prev + 1);
    }, ROOM_LIST_INTERVAL);

    return () => {
      clearInterval(interval);
    };
  }, []);

  return basic && font && notice ? (
    <OutLabLargePatientWaiting
      basic={basic}
      font={font}
      video={video}
      notice={notice}
      videoIndex={videoIndex}
      setVideoIndex={setVideoIndex}
      roomList={currentReserveClinic}
      roomNums={currentReserveNumber}
    />
  ) : null;
};

export default React.memo(OutLabLargePatientWaitingContainer);
