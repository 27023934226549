import AccoutUpdate from "../components/AccoutUpdate";
import { useState, useEffect, useCallback } from "react";
import {
  apiRoute,
  requestSecureGet,
  requestSecurePost,
  requestSecurePut,
} from "@libs/api";
import useLogined from "@hooks/store/useLogined";
import { AdminAccountRoleTypes } from "@typedef/components/Admin/Account/admin.account.role.types";
import { AdminAccountInputsTypes } from "@typedef/components/Admin/Account/components/admin.account.inputs.types";
import { RoleTypes } from "@typedef/common/common.types";
import {
  HEALTH_ROLE_KEY,
  LAB_ROLE_KEY,
  OUT_ROLE_KEY,
  PMY_ROLE_KEY,
  ROOT_ROLE_KEY,
  SUR_ROLE_KEY,
} from "@routes/config/admin.nav.confg";
import { LoginRepsonseTypes } from "@typedef/components/Login/login.types";

type Props = {
  idx: string | null;
  setCurrentModal: React.Dispatch<
    React.SetStateAction<"update" | "delete" | null>
  >;
  reload: number;
  setReload: React.Dispatch<React.SetStateAction<number>>;
};

const roles: AdminAccountRoleTypes[] = [
  {
    name: "검진",
    value: HEALTH_ROLE_KEY,
  },
  {
    name: "검사",
    value: LAB_ROLE_KEY,
  },
  {
    name: "외래",
    value: OUT_ROLE_KEY,
  },
  {
    name: "약국",
    value: PMY_ROLE_KEY,
  },
  {
    name: "수술",
    value: SUR_ROLE_KEY,
  },
  {
    name: "관리자",
    value: ROOT_ROLE_KEY,
  },
];

const AccountUpdateContainer = ({
  idx,
  setCurrentModal,
  reload,
  setReload,
}: Props) => {
  const { token } = useLogined();
  const [isUpdate, setIsUpdate] = useState<boolean>(false);
  const [inputs, setInputs] = useState<AdminAccountInputsTypes>({
    id: "",
    account: "",
    role_kor: "",
    role_eng: "",
  });

  // 아이디 변경
  const onChangeId = useCallback((value: string) => {
    setInputs((inputs) => ({
      ...inputs,
      account: value,
    }));
  }, []);

  // 권한 선택
  const onClickSelect = useCallback(
    (value: string) => {
      roles.map((role) => {
        if (role.name === value) {
          setInputs((inputs) => ({
            ...inputs,
            role_kor: role.name,
            role_eng: role.value,
          }));
        }
      });
    },
    [roles]
  );

  const allInputs = useCallback(() => {
    let message = "";
    if (!!!inputs.account) message = "아이디를 입력해 주세요.";
    else if (inputs.account.length < 4)
      message = "4자리 이상 아이디만 가능합니다.";
    else if (!!!inputs.role_eng) message = "권한을 선택해 주세요.";
    return message;
  }, [inputs]);

  // 계정 추가
  const insert = useCallback(async () => {
    const message = allInputs();

    if (!!message) {
      alert(message);
      return;
    }

    if (!token) return;

    const { config } = await requestSecurePost(
      apiRoute.admin.account.postAccount,
      {},
      {
        account: inputs.account,
        password: "knu.ac.kr",
        role: inputs.role_eng,
      },
      token
    );
    if (config.status >= 200 && config.status < 400) {
      setReload(reload + 1);
      setCurrentModal(null);
    }
  }, [allInputs, inputs, setCurrentModal, token, reload]);

  // 계정 수정
  const update = useCallback(async () => {
    const message = allInputs();

    if (!!message) {
      alert(message);
      return;
    }

    if (!token) return;

    const { config } = await requestSecurePut(
      apiRoute.admin.account.putAccount +
        `?id=${inputs.id}&account=${inputs.account}&roles=${inputs.role_eng}`,
      {},
      {},
      token
    );
    if (config.status >= 200 && config.status < 400) {
      setReload(reload + 1);
      setCurrentModal(null);
    }
  }, [allInputs, inputs, token, setCurrentModal, reload, setReload]);

  // roles 번역
  const translationRole = useCallback((value: RoleTypes) => {
    const filter = roles.filter((role) => role.value === value);
    return filter[0].name;
  }, []);

  const getAccountData = useCallback(async () => {
    if (!token) return;

    setIsUpdate(true);

    const { config, data } = await requestSecureGet<LoginRepsonseTypes[]>(
      apiRoute.admin.account.getAccount,
      {},
      token
    );
    if (config.status >= 200 && config.status < 400) {
      data.map((account) => {
        if (account.roles && account.account === idx) {
          setInputs({
            account: account.account,
            id: account.id + "",
            role_kor: translationRole(account.roles[0]),
            role_eng: account.roles[0],
          });
        }
      });
    }
  }, [idx, token, translationRole]);

  useEffect(() => {
    if (idx) {
      getAccountData();
    }
  }, []);

  return (
    <AccoutUpdate
      type={isUpdate ? "수정" : "추가"}
      setCurrentModal={setCurrentModal}
      inputs={inputs}
      onChangeId={onChangeId}
      roles={roles}
      onClickSelect={onClickSelect}
      save={isUpdate ? update : insert}
    />
  );
};

export default AccountUpdateContainer;
