import CTable from '../CTable';
import { useState, useEffect, useCallback, useMemo } from 'react';
import { FilterMatchMode } from 'primereact/api';
import { TableColumnTypes } from '@typedef/components/Common/CTable/table.column.types';
import { DataTableSelectionChangeParams } from 'primereact/datatable';
import { ColumnEditorType, ColumnEventParams } from 'primereact/column';

type Props = {
  columns: TableColumnTypes[]; // 테이블 헤더
  tableData: any; // 테이블 데이터
  isCheckboxTable: boolean; // true: 체크박스가 포함된 테이블, false: 체크박스 없는 테이블
  selectedRows?: any; // 체크박스 체크된 값
  setSelectedRows?: any; // 체크박스 체크된 값 Setter
  itemsPerPage?: number; // 몇 개의 데이터를 보여줄 것인지 (default : 15)
  editMode?: 'cell' | 'row'; // cell: td에서 수정, row: 수정 버튼 생성
  onCellEdit?(e: ColumnEventParams): void; // 수정 완료 시 사용하는 함수(cell모드)
  editor?: ColumnEditorType; // 데이터 수정 JSX, (options: any) => JSX.Element;
  isPaginator: boolean;
};

const CTableContainer = ({
  columns,
  tableData,
  isCheckboxTable,
  selectedRows,
  setSelectedRows,
  itemsPerPage,
  editMode,
  editor,
  onCellEdit,
  isPaginator,
}: Props) => {
  const [filters, setFilters] = useState();

  // 테이블 사용하는 컴포넌트에서 아이템 갯수를 99999 ( 무제한 )으로 정할 시 paginator 끄기
  const currentIsPaginator = useMemo(() => {
    if (itemsPerPage === 99999) {
      return false;
    } else {
      return isPaginator;
    }
  }, [isPaginator, itemsPerPage]);

  // 검색
  const setFilterData = useCallback(() => {
    let temp: any = {};
    columns.forEach((column: TableColumnTypes) => {
      if (column.field) {
        temp[column.field] = {
          value: null,
          matchMode: FilterMatchMode.CONTAINS,
        };
      }
    });
    setFilters(temp);
  }, [columns]);

  // 체크박스 클릭
  const onChangeCheckBox = useCallback(
    (e: DataTableSelectionChangeParams) => {
      const value = e.value;
      setSelectedRows(value);
    },
    [setSelectedRows],
  );

  useEffect(() => {
    setFilterData();
  }, [setFilterData]);

  return (
    <CTable
      columns={columns}
      tableData={tableData}
      filters={filters}
      isCheckboxTable={isCheckboxTable}
      selectedRows={selectedRows}
      onChangeCheckBox={onChangeCheckBox}
      itemsPerPage={itemsPerPage}
      editMode={editMode}
      editor={editor}
      onCellEdit={onCellEdit}
      currentIsPaginator={currentIsPaginator}
    />
  );
};

export default CTableContainer;
