export type FontFamilyOptionItemTypes = {
  name: string;
  value: string;
};

type ViewerTypeOptions = {
  [key: string]: {
    option: string[];
    font: {
      default: string;
      content: string[];
      fontId: string[];
    };
  };
};

// 전광판 타입별 사용하는 설정들 ( Font, Notice, Sub, Video, Visible, Masking, Delay, MultiClinic )
export const VIEWER_USE_OPTIONS: ViewerTypeOptions = {
  외래대기전광판: {
    option: ['Font', 'Notice', 'Sub', 'Video', 'Visible', 'Masking', 'Delay'],
    font: {
      default: 'OUT_WAITING',
      content: [
        '과 룸번호',
        '과 한글명',
        '과 영문명',
        '센터명',
        '의사한글명',
        '의사영문명',
        '담당이름',
        '환자이름',
        '공지사항',
        '자막',
      ],
      fontId: [
        'froomNumber',
        'froomKor',
        'froomEng',
        'fcenter',
        'fdoctorKor',
        'fdoctorEng',
        'fadminKor',
        'fpatientName',
        'fnotice',
        'fsub',
      ],
    },
  },
  외래4열대기전광판: {
    option: ['Font', 'Sub', 'MultiClinic'],
    font: {
      default: 'OUT_QUAD_WAITING',
      content: [
        '과 룸번호',
        '과 한글명',
        '과 영문명',
        '센터명',
        '의사한글명',
        '의사영문명',
        '담당이름',
        '환자이름',
        '자막',
      ],
      fontId: [
        'froomNumber',
        'froomKor',
        'froomEng',
        'fcenter',
        'fdoctorKor',
        'fdoctorEng',
        'fadminKor',
        'fpatientName',
        'fsub',
      ],
    },
  },
  '1열/영상/1열 (대대합)': {
    option: ['Font', 'Notice', 'Sub', 'Video', 'Visible', 'Masking'],
    font: {
      default: 'LAB_PATIENT',
      content: [
        '과 룸번호',
        '과 한글명',
        '의사한글명',
        '의사영문명',
        '환자이름',
        '공지사항',
        '자막',
      ],
      fontId: [
        'froomNumber',
        'froomKor',
        'fdoctorKor',
        'fdoctorEng',
        'fpatientName',
        'fnotice',
        'fsub',
      ],
    },
  },
  '2열/영상 (대대합)': {
    option: ['Font', 'Notice', 'Sub', 'Video', 'Visible', 'Masking'],
    font: {
      default: 'LAB_PATIENT',
      content: [
        '과 룸번호',
        '과 한글명',
        '의사한글명',
        '의사영문명',
        '환자이름',
        '공지사항',
        '자막',
      ],
      fontId: [
        'froomNumber',
        'froomKor',
        'fdoctorKor',
        'fdoctorEng',
        'fpatientName',
        'fnotice',
        'fsub',
      ],
    },
  },
  '영상/2열 (대대합)': {
    option: ['Font', 'Notice', 'Sub', 'Video', 'Visible', 'Masking'],
    font: {
      default: 'LAB_PATIENT',
      content: [
        '과 룸번호',
        '과 한글명',
        '의사한글명',
        '의사영문명',
        '환자이름',
        '공지사항',
        '자막',
      ],
      fontId: [
        'froomNumber',
        'froomKor',
        'fdoctorKor',
        'fdoctorEng',
        'fpatientName',
        'fnotice',
        'fsub',
      ],
    },
  },
  열: {
    option: ['Font', 'Sub', 'Visible', 'Masking', 'MultiClinic'],
    font: {
      default: 'ROOM_LIST',
      content: [
        '과 룸번호',
        '과 한글명',
        '의사한글명',
        '환자이름',
        '공지사항',
        '자막',
      ],
      fontId: [
        'froomNumber',
        'froomKor',
        'fdoctorKor',
        'fpatientName',
        'fnotice',
        'fsub',
      ],
    },
  },
  '동영상 (대대합)': {
    option: ['Font', 'Notice', 'Sub', 'Video'],
    font: {
      default: 'LAB_PATIENT',
      content: ['과 한글명', '공지사항', '자막'],
      fontId: ['froomKor', 'fnotice', 'fsub'],
    },
  },
  항암주사실: {
    option: ['Font', 'Notice', 'Sub', 'Video', 'Visible', 'Masking'],
    font: {
      default: 'INJECTION',
      content: [
        '과 한글명',
        '순번',
        '이름',
        '시간',
        '공지사항',
        '자막',
        '홀수배경',
        '짝수배경',
      ],
      fontId: [
        'froomKor',
        'froomNumber',
        'froomEng',
        'fdoctorKor',
        'fnotice',
        'fsub',
        'fadminKor',
        'fcenter',
      ],
    },
  },
  '2열/영상': {
    option: ['Font', 'Notice', 'Sub', 'Video', 'Visible', 'Masking'],
    font: {
      default: 'LAB',
      content: ['과 룸번호', '과 한글명', '환자이름', '공지사항', '자막'],
      fontId: ['froomNumber', 'froomKor', 'fpatientName', 'fnotice', 'fsub'],
    },
  },
  '영상/2열': {
    option: ['Font', 'Notice', 'Sub', 'Video', 'Visible', 'Masking'],
    font: {
      default: 'LAB',
      content: ['과 룸번호', '과 한글명', '환자이름', '공지사항', '자막'],
      fontId: ['froomNumber', 'froomKor', 'fpatientName', 'fnotice', 'fsub'],
    },
  },
  '1열/영상/1열': {
    option: ['Font', 'Notice', 'Sub', 'Video', 'Visible', 'Masking'],
    font: {
      default: 'LAB',
      content: ['과 룸번호', '과 한글명', '환자이름', '공지사항', '자막'],
      fontId: ['froomNumber', 'froomKor', 'fpatientName', 'fnotice', 'fsub'],
    },
  },
  '1열/영상': {
    option: ['Font', 'Notice', 'Sub', 'Video', 'Visible', 'Masking'],
    font: {
      default: 'LAB',
      content: ['과 룸번호', '과 한글명', '환자이름', '공지사항', '자막'],
      fontId: ['froomNumber', 'froomKor', 'fpatientName', 'fnotice', 'fsub'],
    },
  },
  '영상/1열': {
    option: ['Font', 'Notice', 'Sub', 'Video', 'Visible', 'Masking'],
    font: {
      default: 'LAB',
      content: ['과 룸번호', '과 한글명', '환자이름', '공지사항', '자막'],
      fontId: ['froomNumber', 'froomKor', 'fpatientName', 'fnotice', 'fsub'],
    },
  },
  내시경: {
    option: ['Font', 'Notice', 'Sub', 'Video', 'Visible', 'Masking'],
    font: {
      default: 'LAB',
      content: ['과 한글명', '환자이름', '공지사항', '자막'],
      fontId: ['froomKor', 'fpatientName', 'fnotice', 'fsub'],
    },
  },
  '수술실 타입1': {
    option: ['Font', 'Sub', 'Visible', 'Masking'],
    font: {
      default: 'SURGERY',
      content: ['환자이름', '자막'],
      fontId: ['fpatientName', 'fsub'],
    },
  },
  '수술실 타입2': {
    option: ['Font', 'Sub', 'Visible', 'Masking'],
    font: {
      default: 'SURGERY',
      content: ['환자이름', '자막'],
      fontId: ['fpatientName', 'fsub'],
    },
  },
  외래약국: {
    option: ['Sub', 'Visible', 'Masking'],
    font: { default: '', content: [], fontId: [] },
  },
  퇴원약국: {
    option: ['Sub', 'Visible', 'Masking'],
    font: { default: '', content: [], fontId: [] },
  },
  외래검진: {
    option: ['Font', 'Sub', 'Visible', 'Masking'],
    font: {
      default: 'OUT_WAITING',
      content: [
        '과 룸번호',
        '과 한글명',
        '과 영문명',
        '센터명',
        '의사한글명',
        '의사영문명',
        '담당이름',
        '환자이름',
        '자막',
      ],
      fontId: [
        'froomNumber',
        'froomKor',
        'froomEng',
        'fcenter',
        'fdoctorKor',
        'fdoctorEng',
        'fadminKor',
        'fpatientName',
        'fsub',
      ],
    },
  },
  검사검진: {
    option: ['Font', 'Sub', 'Visible', 'Masking'],
    font: {
      default: 'LAB_HEALTH',
      content: [
        '과 룸번호',
        '과 한글명',
        '과 영문명',
        '담당이름',
        '환자이름',
        '자막',
      ],
      fontId: [
        'froomNumber',
        'froomKor',
        'froomEng',
        'fadminKor',
        'fpatientName',
        'fsub',
      ],
    },
  },
  종합현황검진: {
    option: ['Font', 'Sub', 'Visible', 'Masking'],
    font: {
      default: 'HEALTH',
      content: ['과 한글명', '환자이름'],
      fontId: ['froomKor', 'fpatientName'],
    },
  },
};
