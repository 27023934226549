import styled from "styled-components";

const BoardVariableStyle = styled.div`
  .modal-body {
    .variable-list {
      .row {
        margin: 0.25vh 0;
        .btn {
          width: 100%;
          color: white;
        }
      }
    }
  }
`;

export default BoardVariableStyle;
