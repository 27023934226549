import * as S from "../../../styles/BoardSettingStyle";
import { CButton, CModal, CModalBody, CModalHeader } from "@coreui/react";
import Img from "@assets/images";
import { TypeSettingData } from "@components/Admin/BoardSetting/containers/Header/Modal/TypeSettingData";
import { BOARD_TYPE_NAME } from "config/board.type.name.config";

type Props = {
  setCurrentModal: React.Dispatch<React.SetStateAction<string | null>>;
  role: string | null;
  selectedType: string;
  setSelectedType: React.Dispatch<React.SetStateAction<string>>;
  onClickApply: () => Promise<void>;
};

const TypeSetting = ({
  setCurrentModal,
  role,
  selectedType,
  setSelectedType,
  onClickApply,
}: Props) => {
  return (
    <CModal
      visible={true}
      onClose={() => setCurrentModal(null)}
      backdrop="static"
      size="lg"
    >
      <CModalHeader>전광판 타입 설정</CModalHeader>
      <CModalBody>
        <CButton color="primary" onClick={onClickApply}>
          적용
        </CButton>
        {/* "ROLE_ROOT" 일 때는 모든 전광판 타입 다 보여주기 */}
        {role ? (
          role.includes("ROLE_ROOT") ? (
            ["ROLE_OUT", "ROLE_LAB", "ROLE_SUR", "ROLE_PMY", "ROLE_HEALTH"].map(
              (role) => (
                <S.BoardTypes key={role}>
                  <p className="title">{BOARD_TYPE_NAME[role]}</p>
                  <div className="content">
                    {TypeSettingData[role].map((type, index) => (
                      <S.BoardType
                        key={type.name + index}
                        id={type.name}
                        onClick={(e) => setSelectedType(e.currentTarget.id)}
                      >
                        <img
                          src={Img[type.src]}
                          alt={type.name}
                          className={selectedType === type.name ? "active" : ""}
                        />
                        <p className="name">{type.name}</p>
                      </S.BoardType>
                    ))}
                  </div>
                </S.BoardTypes>
              )
            )
          ) : (
            <S.BoardTypes>
              <p className="title">{BOARD_TYPE_NAME[role]}</p>
              <div className="content">
                {TypeSettingData[role].map((type, index) => (
                  <S.BoardType
                    key={type.name + index}
                    id={type.name}
                    onClick={(e) => setSelectedType(e.currentTarget.id)}
                  >
                    <img
                      src={Img[type.src]}
                      id={type.name}
                      alt={type.name}
                      className={selectedType === type.name ? "active" : ""}
                    />
                    <p className="name">{type.name}</p>
                  </S.BoardType>
                ))}
              </div>
            </S.BoardTypes>
          )
        ) : null}
      </CModalBody>
    </CModal>
  );
};

export default TypeSetting;
