import React from 'react';
import NotFound from '../NotFound';

type Props = {
  msg?: string;
};

const NotFoundContainer = ({ msg }: Props) => {
  return <NotFound msg={msg} />;
};

export default NotFoundContainer;
