import { useState, useEffect, useCallback, createContext } from 'react';
import BoardSetting from '../BoardSetting';
import TypeSettingContainer from './Header/Modal/TypeSettingContainer';
import useLogined from '@hooks/store/useLogined';
import useGNB from '@hooks/store/useGNB';
import {
  AdminBoardSettingResponseItemTypes,
  AdminBoardSettingTypes,
} from '@typedef/components/Admin/BoardSetting/admin.board.setting.types';
import { apiRoute, requestSecureGet } from '@libs/api';
import VideoModalContainer from './Video/Modal/VideoModalContainer';
import LargeModalContainer from './Header/Modal/LargeModalContainer';

export const ReloadContext = createContext<number>(0);

const BoardSettingContainer = () => {
  const { token } = useLogined();

  const { selected } = useGNB();

  const [boardInfo, setBoardInfo] = useState<AdminBoardSettingTypes>({
    id: 0,
    adminName: '-',
    bname: '-',
    bnameEng: '-',
    boardCode: '-',
    broomNumber: [],
    roomList: [],
    btypeName: '-', // 전광판 타입 구분용 기본 : '-'
    bcentName: '-',
    subtitle: '-',
    ip: '-',
    mac: '-',
    dscyn: '-',
    rosette: '-',
  });

  const [reload, setReload] = useState<number>(0);

  const [currentModal, setCurrentModal] = useState<string | null>(null);

  const modal = {
    type: (
      <TypeSettingContainer
        idx={boardInfo.id}
        boardInfo={boardInfo}
        setCurrentModal={setCurrentModal}
        setReload={setReload}
      />
    ),
    video: (
      <VideoModalContainer
        boardId={boardInfo.id}
        setCurrentModal={setCurrentModal}
        setReload={setReload}
      />
    ),
    large: (
      <LargeModalContainer
        boardId={boardInfo.id}
        setCurrentModal={setCurrentModal}
        setReload={setReload}
      />
    ),
  };

  // GNB에서 선택한 전광판 데이터 조회
  const getCurrentBoardData = useCallback(async () => {
    if (!token) return;

    const { config, data } = await requestSecureGet<AdminBoardSettingResponseItemTypes[]>(
      apiRoute.admin.boardSet.info + selected?.boardId,
      {},
      token,
    );

    if (config.status >= 200 && config.status < 400) {
      if (!selected?.boardId) return;

      setBoardInfo({
        ...data[0],
        id: selected?.boardId,
        broomNumber: data[0].broomNumber ? data[0].broomNumber : [],
        roomList: data[0].roomList ? data[0].roomList : [],
      });
    }
  }, [selected?.boardId, token, reload]);

  useEffect(() => {
    getCurrentBoardData();
  }, [selected, reload]);

  return (
    <ReloadContext.Provider value={reload}>
      <BoardSetting
        boardInfo={boardInfo}
        setBoardInfo={setBoardInfo}
        modal={modal}
        currentModal={currentModal}
        setCurrentModal={setCurrentModal}
        setReload={setReload}
      />
    </ReloadContext.Provider>
  );
};

export default BoardSettingContainer;
