import {
  updateGNBVisibleAction,
  updateSelectedBoardAction,
  updateInfectionFlagAction,
  updateGNBMyBoardReloadAction,
} from "@store/gnb/actions";
import { RootState } from "@store/rootReducer";
import { MyBoardListItemTypes } from "@typedef/components/Login/login.types";
import { useCallback } from "react";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";

export default function useGNB() {
  const gnb = useSelector((root: RootState) => root.gnbReducer);

  const dispatch = useDispatch();

  const __updateGNBVisibleFromHooks = useCallback(
    (diff: boolean) => dispatch(updateGNBVisibleAction(diff)),
    [dispatch]
  );

  const __updateSelectedBoardFromHooks = useCallback(
    (diff: MyBoardListItemTypes) => dispatch(updateSelectedBoardAction(diff)),
    [dispatch]
  );

  const __updateInfectionFlagFromHooks = useCallback(
    (diff: boolean) => dispatch(updateInfectionFlagAction(diff)),
    [dispatch]
  );

  const __updateGNBMyBoardReloadFromHooks = useCallback(
    (diff: number) => dispatch(updateGNBMyBoardReloadAction(diff)),
    [dispatch]
  );

  return {
    ...gnb,
    __updateGNBVisibleFromHooks,
    __updateSelectedBoardFromHooks,
    __updateInfectionFlagFromHooks,
    __updateGNBMyBoardReloadFromHooks,
  };
}
