import { updateLogined } from '@store/logined/actions';
import { RootState } from '@store/rootReducer';
import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';

export default function useLogined() {
  const token = useSelector((root: RootState) => root.loginedReducer.token);

  const dispatch = useDispatch();

  const __updateTokenFromHooks = useCallback(
    (diff: string) => dispatch(updateLogined(diff)),
    [dispatch],
  );

  return {
    token,
    __updateTokenFromHooks,
  };
}
