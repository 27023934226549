import { VideoModalTableFieldsItemTypes } from '@typedef/components/Admin/BoardSetting/components/Video/admin.board.setting.type.video.types';

export const userRoleRootFields: VideoModalTableFieldsItemTypes[] = [
  {
    key: 'check',
    label: '선택',
    _style: { width: '10%', textAlign: 'center' },
  },
  {
    key: 'original',
    label: '파일경로',
    _style: { width: '40%', textAlign: 'center' },
  },
  {
    key: 'type',
    label: '구분',
    _style: { width: '10%', textAlign: 'center' },
  },
  {
    key: 'everyApply',
    label: '일괄적용',
    _style: { width: '15%', textAlign: 'center' },
  },
  {
    key: 'delete',
    label: '삭제',
    _style: { width: '10%', textAlign: 'center' },
  },
];

export const userRoleOtherFields: VideoModalTableFieldsItemTypes[] = [
  {
    key: 'check',
    label: '선택',
    _style: { width: '7%', textAlign: 'center' },
  },
  {
    key: 'original',
    label: '파일경로',
    _style: { width: '48%', textAlign: 'center' },
  },
  {
    key: 'type',
    label: '구분',
    _style: { width: '15%', textAlign: 'center' },
  },
];
