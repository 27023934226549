export const UPDATE_GNB_VISIBLE_ACITON_TYPE =
  "UPDATE_GNB_VISIBLE_ACITON_TYPE" as const;

export const UPDATE_SELECTED_BOARD_ACTION_TYPE =
  "UPDATE_SELECTED_BOARD_ACTION_TYPE" as const;

export const UPDATE_INFECTION_FLAG_ACTION_TYPE =
  "UPDATE_INFECTION_FLAG_ACTION_TYPE" as const;

export const UPDATE_GNB_MYBOARD_RELOAD_ACTION_TYPE =
  "UPDATE_GNB_MYBOARD_RELOAD_ACTION_TYPE" as const;
