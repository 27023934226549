import styled from "styled-components";

export const Main = styled.div`
  flex: 1;
  padding: 1em;
  .loading-spinner {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    position: fixed;
    top: 0;
    left: 16vw;
    right: 0;
    z-index: 10;
    .spinner-border {
      width: 5rem;
      height: 5rem;
    }
  }
  .card {
    box-shadow: 2px 3px 8px 1px gray;
    border-radius: 10px;
    .card-body {
      height: 90vh;
      overflow-y: auto;
    }
  }
`;

export const Btns = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 1em;

  .left {
    display: flex;
    flex-direction: row;
  }

  select {
    width: fit-content;
    margin-right: 10px;
    font-size: 0.9rem;
  }

  button {
    width: fit-content;
    height: 35px;
    padding: 0 10px;
    margin-right: 10px;
    color: #fff;
    font-size: 0.9rem;
    font-weight: 600;
  }
`;

export const Switch = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 35px;
  .form-label {
    line-height: 35px;
    margin-bottom: 0;
    cursor: pointer;
  }

  .form-check {
    margin: 0 10px;
  }

  .form-check-input {
    width: 60px;
    height: 30px;
    /* background: none; */
    border: 1px solid #ddd;
    border-radius: 15px;
    cursor: pointer;
  }
`;
