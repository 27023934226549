import useGNB from '@hooks/store/useGNB';
import useLogined from '@hooks/store/useLogined';
import { apiRoute, requestSecureGet, requestSecurePut } from '@libs/api';
import { AdminBoardVisibleSettingTypes } from '@typedef/components/Admin/BoardSetting/admin.board.setting.types';
import { useCallback, useEffect, useState } from 'react';
import DelaySetting from '../../components/Delay/DelaySetting';
import useBoardSet from '@hooks/store/useBoardSet';

type Props = {
  boardId: number;
};

const DelaySettingContainer = ({ boardId }: Props) => {
  const { token } = useLogined();

  const { selected } = useGNB();

  const { delayReload, __updateBoardSetDelayFromHooks } = useBoardSet();

  const [delay, setDelay] = useState<number>(0);

  // 지연시간 설정 적용
  const delaySettingApply = useCallback(async () => {
    if (!token) return;

    if (boardId) {
      const { config } = await requestSecurePut(
        apiRoute.admin.boardSet.visible + boardId,
        {},
        {
          id: boardId,
          delayTime: delay,
        },
        token,
      );

      if (config.status === 200) {
        __updateBoardSetDelayFromHooks(delayReload + 1);
      }
    }
  }, [boardId, token, delay, delayReload]);

  const loadVisible = useCallback(async () => {
    if (!token) return;

    const { data, config } =
      await requestSecureGet<AdminBoardVisibleSettingTypes>(
        apiRoute.admin.boardSet.visible + boardId,
        {},
        token,
      );

    if (config.status === 200) {
      setDelay(data.delayTime ?? 0);
    }
  }, [boardId, token]);

  useEffect(() => {
    loadVisible();
  }, [boardId, token, selected, delayReload]);

  return (
    <DelaySetting
      delaySettingApply={delaySettingApply}
      delay={delay}
      setDelay={setDelay}
    />
  );
};

export default DelaySettingContainer;
