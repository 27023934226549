import * as S from './styles/CTableStyle';
import 'primeicons/primeicons.css';
import {
  DataTable,
  DataTableSelectionChangeParams,
} from 'primereact/datatable';
import { Column, ColumnEditorType, ColumnEventParams } from 'primereact/column';
import { TableColumnTypes } from '@typedef/components/Common/CTable/table.column.types';

type Props = {
  columns: TableColumnTypes[]; // 테이블 헤더
  tableData: any; // 테이블 데이터
  isCheckboxTable: boolean; // true: 체크박스가 포함된 테이블, false: 체크박스 없는 테이블
  selectedRows?: any; // 체크박스 체크된 값
  onChangeCheckBox: (e: DataTableSelectionChangeParams) => void; // 체크박스 체크 함수
  itemsPerPage?: number; // 몇 개의 데이터를 보여줄 것인지 (default : 15)
  editMode?: 'cell' | 'row'; // cell: td에서 수정, row: 수정 버튼 생성
  onCellEdit?(e: ColumnEventParams): void; // 수정 완료 시 사용하는 함수(cell모드)
  editor?: ColumnEditorType; // 데이터 수정 JSX, (options: any) => JSX.Element;
  filters?: any;
  currentIsPaginator: boolean;
};

const CTable = ({
  columns,
  tableData,
  filters,
  selectedRows,
  onChangeCheckBox,
  isCheckboxTable,
  itemsPerPage,
  editMode,
  editor,
  onCellEdit,
  currentIsPaginator,
}: Props) => {
  return (
    <S.CTable>
      <DataTable
        value={tableData}
        rows={itemsPerPage ? itemsPerPage : 15}
        filters={filters}
        filterDisplay='row'
        paginator={currentIsPaginator}
        paginatorTemplate=' FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown'
        selection={selectedRows}
        onSelectionChange={onChangeCheckBox}
        responsiveLayout='scroll'
        editMode={editMode}
        emptyMessage='등록된 데이터가 없습니다.'>
        {isCheckboxTable && (
          <Column selectionMode='multiple' headerStyle={{ width: '10%' }} />
        )}
        {columns.map((column, index) => (
          <Column
            key={index}
            header={column.header}
            field={column.field}
            filter={column.filter !== false}
            filterPlaceholder=''
            editor={column.isEditable && editor}
            onCellEditComplete={onCellEdit}
            style={{
              width: column.width,
            }}
          />
        ))}
      </DataTable>
    </S.CTable>
  );
};

export default CTable;
