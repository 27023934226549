import { setRoomAction } from '@store/multiRoom/actions';
import { RootState } from '@store/rootReducer';
import { MyBoardListItemTypes } from '@typedef/components/Login/login.types';
import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';

export default function useMultiRoom() {
  const { multiRoomList } = useSelector(
    (root: RootState) => root.multiRoomReducer,
  );

  const dispatch = useDispatch();

  const __setRoomFromHooks = useCallback(
    (diff: MyBoardListItemTypes[]) => dispatch(setRoomAction(diff)),
    [dispatch],
  );

  return {
    multiRoomList,
    __setRoomFromHooks,
  };
}
