import { ExaminationPatientItemTypes } from '@typedef/components/ExaminationPatientWaiting/examination.patient.waiting.types';
import { LoadBasicConfigResponseDataType } from '@typedef/routes/viewer.navigation.types';
import * as Style from '../style/ExaminationPatientMiniWaitingStyle';

type Props = {
  basic: LoadBasicConfigResponseDataType;
  currentExaminationPatient: ExaminationPatientItemTypes[];
  currentExaminationPatientSecond: ExaminationPatientItemTypes[];
};

const ExaminationPatientMiniWaiting = ({
  basic,
  currentExaminationPatient,
  currentExaminationPatientSecond,
}: Props) => {
  return (
    <Style.ExaminationPatientMiniWaitingStyle>
      <header>{basic?.bname}</header>
      {(basic.roomList ? basic?.roomList.length : null) === 1 ? (
        <Style.Main>
          <Style.RoomList width={100}>
            <Style.PatientList>
              <Style.RoomInfo>
                <p>{basic?.broomNumber ? basic?.broomNumber[0] : ''}</p>
                <p>{basic?.subtitle}</p>
              </Style.RoomInfo>
              {currentExaminationPatient.map((data, index) => (
                <Style.Patient key={data.cnt ?? index + 1}>
                  <p>{data?.hngnm ? data.cnt : ''}</p>
                  <p className='mini'>{data?.hngnm}</p>
                </Style.Patient>
              ))}
            </Style.PatientList>
          </Style.RoomList>
        </Style.Main>
      ) : (
        <Style.Main>
          <Style.RoomList width={50}>
            <Style.PatientList>
              <Style.RoomInfo>
                <p>{basic?.broomNumber ? basic?.broomNumber[0] : ''}</p>
                <p>{basic?.subtitle}</p>
              </Style.RoomInfo>
              {currentExaminationPatient.map((data, index) => (
                <Style.Patient key={data.cnt ?? index + 1}>
                  <p>{data?.hngnm ? data.cnt : ''}</p>
                  <p className='name'>{data?.hngnm}</p>
                </Style.Patient>
              ))}
            </Style.PatientList>
          </Style.RoomList>
          <Style.RoomList width={50}>
            <Style.PatientList>
              <Style.RoomInfo>
                <p>{basic?.broomNumber ? basic?.broomNumber[1] : ''}</p>
                <p>{basic?.subtitle}</p>
              </Style.RoomInfo>
              {currentExaminationPatientSecond.map((data, index) => (
                <Style.Patient key={data.cnt ?? index + 1}>
                  <p>{data?.hngnm ? data.cnt : ''}</p>
                  <p className='name'>{data?.hngnm}</p>
                </Style.Patient>
              ))}
            </Style.PatientList>
          </Style.RoomList>
        </Style.Main>
      )}
    </Style.ExaminationPatientMiniWaitingStyle>
  );
};

export default ExaminationPatientMiniWaiting;
