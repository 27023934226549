import React, { useState } from 'react';
import ExaminationPatientWaiting from '../ExaminationPatientWaiting';
import useViewer from '@hooks/store/useViewer';

const ExaminationPatientWaitingContainer = () => {
  const { basic, font, video, notice } = useViewer();

  const [videoIndex, setVideoIndex] = useState<number>(0);

  return basic && font && notice ? (
    <ExaminationPatientWaiting
      basic={basic}
      font={font}
      video={video}
      notice={notice}
      videoIndex={videoIndex}
      setVideoIndex={setVideoIndex}
    />
  ) : null;
};

export default React.memo(ExaminationPatientWaitingContainer);
