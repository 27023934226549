import images from '@assets/images';
import styled from 'styled-components';

const space = '2vw';

export const OutQuadPatientWaitingStyle = styled.div`
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;

  p {
    margin: 0;
  }
`;

export default OutQuadPatientWaitingStyle;

type Props = {
  family?: string;
  fz?: number;
  fw?: boolean;
  color?: string;
  align?: string;
};

export const CustomPTag = styled.p<Props>`
  font-family: ${(props) => props.family};
  font-size: calc(${(props) => props.fz}vh / 11);
  font-weight: ${(props) => (props.fw ? 'bold' : 'normal')};
  color: ${(props) => props.color};
  text-align: ${(props) => props.align ?? 'left'};
`;

export const Header = styled.header`
  width: 100%;
  height: 11vh;
  display: flex;
  flex-direction: row;
  overflow: hidden;

  // 로고, 삼각형
  .logoBox {
    height: 100%;
    display: flex;
    flex-direction: row;

    img {
      height: 100%;
    }

    .triangle {
      background-color: #fff;
      border-bottom: 11vh solid #000655;
      border-left: 11vh solid transparent;
    }
  }

  .titleBox {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 0 ${space};
    background: url(${images.viewerBackground}), #000655;
    background-repeat: repeat-x;

    .subtitle {
      font-size: 1vw;
      word-break: keep-all;
      text-align: left;
      color: white;
    }
  }
`;

export const Main = styled.section`
  flex: 1;
  display: flex;
  flex-direction: column;
  height: calc(100vh - 11vh);
`;

export const Contents = styled.section`
  flex: 1;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
  height: 100%;
`;

export const Content = styled.div`
  min-width: 50vw;
  max-width: 100vw;
  display: flex;
  flex-direction: column;
`;
