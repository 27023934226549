import {
  CButton,
  CCard,
  CCardBody,
  CCardHeader,
  CFormCheck,
  CFormLabel,
  CFormTextarea,
} from "@coreui/react";
import { AdminBoardSettingNoticeTypes } from "@typedef/components/Admin/BoardSetting/admin.board.setting.types";
import { SetStateAction } from "react";
import NoticeSettingStyle from "../../styles/Notice/NoticeSettingStyle";

type Props = {
  notice: AdminBoardSettingNoticeTypes;
  setNotice: React.Dispatch<SetStateAction<AdminBoardSettingNoticeTypes>>;
  updateNotice: () => void;
};

const NoticeSetting = ({ notice, setNotice, updateNotice }: Props) => {
  return (
    <NoticeSettingStyle>
      <CCard>
        <CCardHeader>
          <h5>공지사항 설정</h5>
          <div className="btns">
            <CButton color="info" onClick={updateNotice}>
              적용
            </CButton>
          </div>
        </CCardHeader>
        <CCardBody>
          <div className="menus">
            <div className="visible">
              <CFormCheck
                id="Noticedisplay"
                name="display"
                key="display"
                checked={notice.display}
                onChange={(e) => {
                  setNotice({
                    ...notice,
                    display: e.target.checked,
                  });
                }}
              />
              <CFormLabel htmlFor="Noticedisplay">표시여부</CFormLabel>
            </div>
            <div className="textalign">
              <CFormCheck
                type="radio"
                name="align"
                id="left"
                key="algin_left"
                value="left"
                checked={notice.align === "left"}
                onChange={() => {
                  setNotice({
                    ...notice,
                    align: "left",
                  });
                }}
              />
              <CFormLabel htmlFor="left">왼쪽</CFormLabel>
              <CFormCheck
                type="radio"
                name="align"
                id="center"
                key="algin_center"
                value="center"
                checked={notice.align === "center"}
                onChange={() => {
                  setNotice({
                    ...notice,
                    align: "center",
                  });
                }}
              />
              <CFormLabel htmlFor="center">중앙</CFormLabel>
              <CFormCheck
                type="radio"
                name="align"
                id="right"
                key="algin_right"
                value="right"
                checked={notice.align === "right"}
                onChange={() => {
                  setNotice({
                    ...notice,
                    align: "right",
                  });
                }}
              />
              <CFormLabel htmlFor="right">오른쪽</CFormLabel>
            </div>
            <div className="verticalalign">
              <CFormCheck
                type="radio"
                name="verticalAlign"
                id="top"
                key="algin_top"
                value="top"
                checked={notice.valign === "top"}
                onChange={() => {
                  setNotice({
                    ...notice,
                    valign: "top",
                  });
                }}
              />
              <CFormLabel htmlFor="top">위</CFormLabel>
              <CFormCheck
                type="radio"
                name="verticalAlign"
                id="middle"
                key="algin_middle"
                value="middle"
                checked={notice.valign === "middle"}
                onChange={() => {
                  setNotice({
                    ...notice,
                    valign: "middle",
                  });
                }}
              />
              <CFormLabel htmlFor="middle">중앙</CFormLabel>
              <CFormCheck
                type="radio"
                name="verticalAlign"
                id="bottom"
                key="algin_bottom"
                value="bottom"
                checked={notice.valign === "bottom"}
                onChange={() => {
                  setNotice({
                    ...notice,
                    valign: "bottom",
                  });
                }}
              />
              <CFormLabel htmlFor="bottom">아래</CFormLabel>
            </div>
          </div>
          <div
            className="noticeinput"
            style={{ verticalAlign: notice.valign || "middle" }}
          >
            <CFormTextarea
              style={{
                textAlign: notice.align || "center",
              }}
              id="contents"
              name="contents"
              key="contents"
              placeholder="공지사항 입력"
              autoComplete="off"
              value={notice.contents ?? ""}
              onChange={(e) => {
                setNotice({
                  ...notice,
                  contents: e.target.value,
                });
              }}
            />
          </div>
        </CCardBody>
      </CCard>
    </NoticeSettingStyle>
  );
};

export default NoticeSetting;
