import useViewer from '@hooks/store/useViewer';
import { alertMessage } from '@libs/alert';
import { apiRoute, requestGet } from '@libs/api';
import ViewerNavigation from '@routes/components/ViewerNavigation';
import {
  LoadBasicConfigResponseDataType,
  LoadFontConfigResponseDataType,
  LoadNoticeConfigResponseDataType,
  LoadSubtitleConfigResponseDataType,
  LoadVideoConfigResponseDataType,
  LoadVisibleConfigResponseDataType,
} from '@typedef/routes/viewer.navigation.types';
import { useCallback, useEffect, useState } from 'react';
// import SockJsClient from 'react-stomp';
// import axios from 'axios';
import { checkViewerUseApi } from '@libs/config/config';

// const SOCKET_URL = window.location.href.includes('https')
//   ? `https://${window.location.host}/ws`
//   : `http://${window.location.host}/ws`;

const HALF_OF_DATE_BY_MS = 43200000;

const CONFIG_RELOAD_INTERVAL = 10000;

const LOADING_SPINNER = 1500;

const pathname = window.location.pathname;
const lastSlash = pathname.lastIndexOf('/');
const CLIENT_IP = pathname.substring(lastSlash + 1);

const ViewerNavigationContainer = () => {
  // const $websocket = useRef(null);

  const [loadingSpinner, setLoadingSpinner] = useState<boolean>(false);

  const [loadDone, setLoadDone] = useState(false);

  const [intervalCounter, setIntervalCounter] = useState(0);

  const [basic, setBaisc] = useState<LoadBasicConfigResponseDataType>();

  const {
    __updateBasicConfigFromHooks,
    __updateFontConfigFromHooks,
    __updateNoticeConfigFromHooks,
    __updateSubConfigFromHooks,
    __updateVideoConfigFromHooks,
    __updateVisibleConfigFromHooks,
  } = useViewer();

  const loadingViewerConfig = useCallback(async () => {
    const { data: basicConfig, config } = await requestGet<LoadBasicConfigResponseDataType>(
      apiRoute.viewer.common.basicConfig + CLIENT_IP,
      {},
    );

    if (config.status === 200) {
      __updateBasicConfigFromHooks(basicConfig);
      setBaisc(basicConfig);

      if (checkViewerUseApi(basicConfig.btypeName, 'Font')) {
        await requestGet<LoadFontConfigResponseDataType>(
          apiRoute.viewer.common.fontConfig + basicConfig.boardId,
          {},
        ).then(({ data, config }) => {
          if (config.status === 200) {
            __updateFontConfigFromHooks(data);
          } else {
            alertMessage('전광판 Font옵선 호출에 실패 했습니다 ' + basicConfig.boardId);
          }
        });
      } else {
        __updateFontConfigFromHooks({
          fpatientName: '',
          fdoctorKor: '',
          fdoctorEng: '',
          fcenter: '',
          fadminKor: '',
          froomKor: '',
          fsub: '',
          froomEng: '',
          froomNumber: '',
          fnotice: '',
        });
      }

      if (checkViewerUseApi(basicConfig.btypeName, 'Notice')) {
        await requestGet<LoadNoticeConfigResponseDataType>(
          apiRoute.viewer.common.noticeConfig + basicConfig.boardId,
          {},
        ).then(({ data, config }) => {
          if (config.status === 200) {
            __updateNoticeConfigFromHooks(data);
          } else {
            alertMessage('전광판 Notice옵선 호출에 실패 했습니다 ' + basicConfig.boardId);
          }
        });
      } else {
        __updateNoticeConfigFromHooks({
          contents: null,
          display: true,
          align: 'center',
          valign: 'middle',
        });
      }

      if (checkViewerUseApi(basicConfig.btypeName, 'Sub')) {
        await requestGet<LoadSubtitleConfigResponseDataType>(
          apiRoute.viewer.common.subConfig + basicConfig.boardId,
          {},
        ).then(({ data, config }) => {
          if (config.status === 200) {
            __updateSubConfigFromHooks(data);
          } else {
            alertMessage('전광판 Sub옵선 호출에 실패 했습니다 ' + basicConfig.boardId);
          }
        });
      } else {
        __updateSubConfigFromHooks({
          contents: null,
          display: false,
          speed: 0,
        });
      }

      if (checkViewerUseApi(basicConfig.btypeName, 'Video')) {
        await requestGet<LoadVideoConfigResponseDataType>( // TODO : path 이외 값 있는지 체크필요
          apiRoute.viewer.common.videoConfig + basicConfig.boardId,
          {},
        ).then(({ data, config }) => {
          if (config.status === 200) {
            __updateVideoConfigFromHooks(data?.media);
          } else {
            alertMessage('전광판 Video옵선 호출에 실패 했습니다 ' + basicConfig.boardId);
          }
        });
      } else {
        __updateVideoConfigFromHooks([{ id: '', path: '' }]);
      }

      if (checkViewerUseApi(basicConfig.btypeName, 'Visible')) {
        await requestGet<LoadVisibleConfigResponseDataType>(
          apiRoute.viewer.common.visibleConfig + basicConfig.boardId,
          {},
        ).then(({ data, config }) => {
          if (config.status === 200) {
            __updateVisibleConfigFromHooks(data);
          } else {
            alertMessage('전광판 Visible옵선 호출에 실패 했습니다 ' + basicConfig.boardId);
          }
        });
      } else {
        __updateVisibleConfigFromHooks({
          vdelay: false,
          vlist: false,
          vadmin: false,
          vclock: false,
          delayTime: null,
          position: null,
          maskMark: null,
          vdoctorEng: false,
          vdoctorPhoto: false,
          vmasking: false,
          vvideo: false,
        });
      }

      setLoadDone(true);
    } else {
      alertMessage('전광판 기본옵선 호출에 실패 했습니다 ' + CLIENT_IP);
    }
  }, [
    __updateBasicConfigFromHooks,
    __updateFontConfigFromHooks,
    __updateNoticeConfigFromHooks,
    __updateSubConfigFromHooks,
    __updateVideoConfigFromHooks,
    __updateVisibleConfigFromHooks,
  ]);

  // const onSystemAction = useCallback((address: string) => {
  //   axios.get(`http://localhost:5114/${address}`, {});
  // }, []);

  useEffect(() => {
    const timeout = setTimeout(() => {
      window.location.reload();
    }, HALF_OF_DATE_BY_MS);

    return () => {
      clearTimeout(timeout);
    };
  }, []);

  useEffect(() => {
    loadingViewerConfig();
  }, [loadingViewerConfig, intervalCounter]);

  useEffect(() => {
    setLoadingSpinner(true);

    const timeout = setTimeout(() => {
      setLoadingSpinner(false);
    }, LOADING_SPINNER);

    return () => {
      clearTimeout(timeout);
    };
  }, []);

  useEffect(() => {
    const interval = setInterval(
      () => setIntervalCounter((prev) => prev + 1),
      CONFIG_RELOAD_INTERVAL,
    );

    return () => {
      clearInterval(interval);
    };
  }, []);

  return loadDone && basic ? (
    <>
      {/* <SockJsClient
        url={SOCKET_URL}
        ref={$websocket}
        topics={[
          '/topic',
          `/queue/update/${basic?.boardId}`,
          `/queue/alert/${CLIENT_IP}`,
        ]}
        onMessage={(msg) => {
          if (msg === 'reload') {
            onSystemAction('chrome/reboot');

            setTimeout(() => {
              window.location.reload();
            }, 15000);
          }
          if (msg === 'shutdown') {
            onSystemAction('poweroff');
          }
          if (msg === 'reboot') {
            onSystemAction('reboot');
          }
        }}
      /> */}
      <ViewerNavigation basicConfig={basic} loadingSpinner={loadingSpinner} />
    </>
  ) : null;
};

export default ViewerNavigationContainer;
