import { updateProfileAction } from '@store/profile/actions';
import { RootState } from '@store/rootReducer';
import { LoginRepsonseTypes } from '@typedef/components/Login/login.types';
import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';

export default function useProfile() {
  const profile = useSelector((root: RootState) => root.profileReducer.profile);

  const dispatch = useDispatch();

  const __updateProfileFromHooks = useCallback(
    (diff: LoginRepsonseTypes) => dispatch(updateProfileAction(diff)),
    [dispatch],
  );

  return {
    ...profile,
    __updateProfileFromHooks,
  };
}
