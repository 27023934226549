export const ROOT_ROLE_KEY = 'ROLE_ROOT';
export const OUT_ROLE_KEY = 'ROLE_OUT';
export const LAB_ROLE_KEY = 'ROLE_LAB';
export const PMY_ROLE_KEY = 'ROLE_PMY';
export const SUR_ROLE_KEY = 'ROLE_SUR';
export const HEALTH_ROLE_KEY = 'ROLE_HEALTH';

export type UserNavKeyTypes =
  | typeof ROOT_ROLE_KEY
  | typeof OUT_ROLE_KEY
  | typeof LAB_ROLE_KEY
  | typeof PMY_ROLE_KEY
  | typeof SUR_ROLE_KEY
  | typeof HEALTH_ROLE_KEY;

export const USER_ROUTE = {
  ROLE_ROOT: [
    {
      link: 'patient',
      label: '환자 관리',
    },
    {
      link: 'clinics',
      label: '진료실 관리',
    },
    {
      link: 'boards',
      label: '전광판 관리',
    },
    {
      link: 'board-setting',
      label: '전광판 설정',
    },
    {
      link: 'accounts',
      label: '계정 관리',
    },
  ],
  ROLE_OUT: [
    {
      link: 'patient',
      label: '환자 관리',
    },
    {
      link: 'clinics',
      label: '진료실 관리',
    },
    {
      link: 'board-setting',
      label: '전광판 설정',
    },
  ],
  ROLE_LAB: [
    {
      link: 'boards',
      label: '전광판 관리',
    },
    {
      link: 'board-setting',
      label: '전광판 설정',
    },
  ],
  ROLE_PMY: [
    {
      link: 'boards',
      label: '전광판 관리',
    },
    {
      link: 'board-setting',
      label: '전광판 설정',
    },
  ],
  ROLE_SUR: [
    {
      link: 'boards',
      label: '전광판 관리',
    },
    {
      link: 'board-setting',
      label: '전광판 설정',
    },
  ],
  ROLE_HEALTH: [
    {
      link: 'clinics',
      label: '진료실 관리',
    },
    {
      link: 'boards',
      label: '전광판 관리',
    },
    {
      link: 'board-setting',
      label: '전광판 설정',
    },
  ],
};
