import NotFoundContainer from '@components/common/NotFound/containers/NotFoundContainer';
import { Route, Routes } from 'react-router';
import AdminNavigationContainer from './containers/AdminNavigationContainer';
import ViewerNavigationContainer from './containers/ViewerNavigationContainer';

const RootNavigation = () => {
  return (
    <Routes>
      <Route path='/*' element={<AdminNavigationContainer />} />
      <Route path='/monitor/*' element={<ViewerNavigationContainer />} />
    </Routes>
  );
};

export default RootNavigation;
