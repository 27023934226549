import axios from 'axios';

// export const API_ORIGIN = "";
// export const API_ORIGIN = 'http://192.168.0.215:6060';
export const API_ORIGIN = window.location.hostname.includes('localhost')
  ? 'http://192.168.0.215:11401'
  : 'https://api-kn.j-sol.co.kr';

// process.env.NODE_ENV === 'development' ? 'https://kn.j-sol.co.kr' : 'https://kn.j-sol.co.kr';
// export const API_ORIGIN = "http://172.16.11.127:8083";

axios.defaults.baseURL = `${API_ORIGIN}`;

axios.interceptors.request.use((req) => {
  return req;
});

axios.interceptors.response.use((res) => {
  return res;
});

export const apiRoute = {
  admin: {
    markdown: '/api/v3/common/markdown/',
    login: '/api/v1/signin',
    gnb: {
      loadRoomInfo: '/api/v1/room/',
      loadDoctorList: '/api/v1/myboard/doctors/',
      updateDoctor: '/api/v1/room',
      loadMyBoardInfo: '/api/v1/myboard/',
      loadIpMacInfo: '/api/v1/board/sidebar/info/',
      loadPowerPing: '/socket/common/power/ping/',
      loadPowerOff: '/socket/common/power/off',
    },
    patient: {
      loadPatient: '/api/v1/patients/',
      loadPatients: '/api/v1/patients',
      loadVersion: '/api/v1/room/',
      loadMyRoomList: '/api/v1/rooms/',
      changeOrderPatient: '/api/v1/patient/order/',
      checkInfection: '/api/v1/patient/infection/',
      updatePatient: '/api/v1/patient/order/',
      updatePatient3: '/api/v1/patient/order3/',
      updatePatientArrive: '/api/v1/patient/arrive/',
      loadPatientAlert: '/api/v1/patient/arrive/alert',
      loadPatientInfection: '/api/v1/patient/infection/',
    },
    anticancer: {
      loadAntiCancerPatient: '/api/v1/injections/',
      updateArriveAlert: '/api/v1/injections/patient/arrive/alert',
      updateAntiCnacerWaitPatient: '/api/v1/injections/wait',
      updateAntiCnacerDonePatient: '/api/v1/injections/done',
      updateCancelPatient: '/api/v1/injections/cancel',
      updateCancelDonePatient: '/api/v1/injections/cancel-done',
      updatePatientInjection: '/api/v1/injections/patient/order/',
    },
    account: {
      getAccount: '/api/v1/accounts/detail',
      getAccountLogs: '/api/v1/accounts/log',
      getAccounts: '/api/v1/accounts',
      postAccount: '/api/v1/signup',
      putAccount: '/api/v1/account',
      deleteAccount: '/api/v1/account/',
    },
    clinic: {
      getClinic: '/api/v1/room/',
      getClinics: '/api/v1/rooms',
      postClinic: '/api/v1/room',
      patchClinic: '/api/v1/room',
      deleteClinic: '/api/v1/room',
      validClinic: '/api/v1/departments/',
      getDepts: '/api/v1/departments',
      getCenters: '/api/v1/centers',
      postDoctors: '/api/v1/doctors',
      getBoards: '/api/v1/room/boards',
    },
    board: {
      getBoard: '/api/v1/board/info/',
      getBoards: '/api/v1/boards/info/',
      getMyBoards: '/api/v1/myboard/',
      putBoard: '/api/v1/board/info',
      putMyBoard: '/api/v1/myboard',
      postBoard: '/api/v1/board/info',
      deleteBoard: '/api/v1/board/info',
      checkDupleIp: `/api/v1/board/duplicate/ip/`,
      findMacAddress: '/api/v3/common/mac/',
      getVariable: '/api/v1/board/info/query/',
      putVariable: '/api/v1/board/info/query',
    },
    boardSet: {
      getRoomList: '/api/v1/board/set/roomlist/',
      getVNCPassword: '/api/v1/board/set/info/vnc/',
      getMedias: '/api/v1/board/medias',
      getVideoEvery: '/api/v1/board/media/every/',
      getMyVideo: '/api/v1/board/set/myvideo/',
      info: '/api/v1/board/set/info/',
      font: '/api/v1/board/set/font/',
      fontDefault: '/api/v1/font/defaults',
      visible: '/api/v1/board/set/visible/',
      video: '/api/v1/board/set/video/',
      media: '/api/v1/board/media',
      mediaUrl: '/api/v1/board/url',
      notice: '/api/v1/board/set/notice/',
      subtitle: '/api/v1/board/set/subtitle/',
      subtitleContents: '/api/v1/board/set/subtitle/contents',
      reload: '/socket/common/reload/',
    },
    room: {
      getRoomInfoBoard: '/api/v1/room/info/board/',
      getDepartments: '/api/v1/departments',
    },
  },
  viewer: {
    loadDoctorImage: '/api/v2/outpatient/waiters/doctor/img/',
    common: {
      basicConfig: '/api/v2/outpatient/option/basic/',
      fontConfig: '/api/v2/outpatient/waiters/font/',
      noticeConfig: '/api/v2/outpatient/waiters/notice/',
      subConfig: '/api/v2/outpatient/waiters/sub/',
      videoConfig: '/api/v2/outpatient/waiters/video/',
      visibleConfig: '/api/v2/outpatient/waiters/visible/',
    },
    outPatient: {
      loadWaiterList: '/api/v2/outpatient/waiters/',
      loadReserveList: '/api/v2/outpatient/waiters/book/',
    },
    surgery: {
      surgeryOne: '/api/v2/surgery/one',
      surgeryTwo: '/api/v2/surgery/two',
    },
    pharmacy: {
      exitPharmacy: '/api/v2/pharmacy/discharge',
      outPharmacy: '/api/v2/pharmacy/outpatient',
    },
    outlabPatient: {
      loadWaiterList: '/api/v2/outpatient/waiters/',
      loadClinic: '/api/v1/room/',
    },
    lab: {
      loadTotalList: '/api/v2/health/default/waiters',
      loadOutList: '/api/v2/health/waiters/doctor/',
      loadEndoscopyList: '/api/v2/health/endoscopy/waiters',
      loadInspectList: '/api/v2/health/waiters/room/',
      loadUntested: '/api/v2/health/untested/',
    },
    examination: {
      loadByRooms: '/api/v2/examination/patients/',
      loadAll: '/api/v2/examination/patients/union',
      loadEndoscope: '/api/v2/examination/endoscopy/patients/',
    },
    injectionPatient: {
      loadWaiterList: '/api/v2/injections/waits/',
    },
  },
  system: {
    getFileList: '/api/v3/system/extension',
    getDownloadFile: '/api/v3/system/download/',
  },
};

type BasicApiResponse<T> = {
  data: T;
  config: {
    status: number;
    msg: string;
    code: number;
    success: boolean;
    version: string | null;
  };
};

export function requestGet<T>(url: string, header: object): Promise<BasicApiResponse<T>> {
  return axios
    .get(url, {
      headers: {
        'Content-Type': 'application/json',
        ...header,
      },
    })
    .then(
      (res) =>
        ({
          data: res.data?.data as T,
          config: {
            status: res.status,
            msg: res.data?.msg,
            code: res.data?.code,
            success: res.data?.success,
            version: res.data?.version,
          },
        } as BasicApiResponse<T>),
    )
    .catch((err) => {
      console.error('[Axios Error]', err);
      const errMsg = err.response.data.msg;
      return {
        data: {} as T,
        config: {
          msg: errMsg ? errMsg : null,
          status: -1,
        },
      } as BasicApiResponse<T>;
    });
}

export function requestSecureGet<T>(
  url: string,
  header: object,
  token: string,
): Promise<BasicApiResponse<T>> {
  return axios
    .get(url, {
      headers: {
        'Content-Type': 'application/json',
        'X-AUTH-TOKEN': `${token}`,
        ...header,
      },
    })
    .then(
      (res) =>
        ({
          data: res.data?.data as T,
          config: {
            status: res.status,
            msg: res.data?.msg,
            code: res.data?.code,
            success: res.data?.success,
            version: res.data?.version,
          },
        } as BasicApiResponse<T>),
    )
    .catch((err) => {
      console.error('[Axios Error]', err);

      return {
        data: {} as T,
        config: {
          status: -1,
        },
      } as BasicApiResponse<T>;
    });
}

export function requestDelete<T>(url: string, header: object): Promise<BasicApiResponse<T>> {
  return axios
    .delete(url, {
      headers: {
        'Content-Type': 'application/json',
        ...header,
      },
    })
    .then(
      (res) =>
        ({
          data: res.data?.data as T,
          config: {
            status: res.status,
            msg: res.data?.msg,
            code: res.data?.code,
            success: res.data?.success,
            version: res.data?.version,
          },
        } as BasicApiResponse<T>),
    )
    .catch((err) => {
      console.error('[Axios Error]', err);

      return {
        data: {} as T,
        config: {
          status: -1,
        },
      } as BasicApiResponse<T>;
    });
}

export function requestSecureDelete<T>(
  url: string,
  header: object,
  body: object,
  token: string,
): Promise<BasicApiResponse<T>> {
  return axios
    .delete(url, {
      data: body,
      headers: {
        'Content-Type': 'application/json',
        'X-AUTH-TOKEN': `${token}`,
        ...header,
      },
    })
    .then(
      (res) =>
        ({
          data: res.data?.data as T,
          config: {
            status: res.status,
            msg: res.data?.msg,
            code: res.data?.code,
            success: res.data?.success,
            version: res.data?.version,
          },
        } as BasicApiResponse<T>),
    )
    .catch((err) => {
      console.error('[Axios Error]', err);

      return {
        data: {} as T,
        config: {
          msg: err.msg ? err.msg : null,
          status: -1,
        },
      } as BasicApiResponse<T>;
    });
}

export function requestSecurePost<T>(
  url: string,
  header: object,
  body: object,
  token: string,
): Promise<BasicApiResponse<T>> {
  return axios
    .post(url, body, {
      headers: {
        'Content-Type': 'application/json',
        'X-AUTH-TOKEN': `${token}`,
        ...header,
      },
    })
    .then(
      (res) =>
        ({
          data: res.data?.data as T,
          config: {
            status: res.status,
            msg: res.data?.msg,
            code: res.data?.code,
            success: res.data?.success,
            version: res.data?.version,
          },
        } as BasicApiResponse<T>),
    )
    .catch((err) => {
      console.error('[Axios Error]', err);

      return {
        data: {} as T,
        config: {
          msg: err.msg ? err.msg : null,
          status: -1,
        },
      } as BasicApiResponse<T>;
    });
}

export function requestPost<T>(
  url: string,
  header: object,
  body: object,
): Promise<BasicApiResponse<T>> {
  return axios
    .post(url, body, {
      headers: {
        'Content-Type': 'application/json',
        ...header,
      },
    })
    .then(
      (res) =>
        ({
          data: res.data?.data as T,
          config: {
            status: res.status,
            msg: res.data?.msg,
            code: res.data?.code,
            success: res.data?.success,
            version: res.data?.version,
          },
        } as BasicApiResponse<T>),
    )
    .catch((err) => {
      console.error('[Axios Error]', err);

      return {
        data: {} as T,
        config: {
          msg: err.msg ? err.msg : null,
          status: -1,
        },
      } as BasicApiResponse<T>;
    });
}

export function requestSecurePut<T>(
  url: string,
  header: object,
  body: object,
  token: string,
): Promise<BasicApiResponse<T>> {
  return axios
    .put(url, body, {
      headers: {
        'Content-Type': 'application/json',
        'X-AUTH-TOKEN': `${token}`,
        ...header,
      },
    })
    .then(
      (res) =>
        ({
          data: res.data?.data as T,
          config: {
            status: res.status,
            msg: res.data?.msg,
            code: res.data?.code,
            success: res.data?.success,
            version: res.data?.version,
          },
        } as BasicApiResponse<T>),
    )
    .catch((err) => {
      console.error('[Axios Error]', err);

      return {
        data: {} as T,
        config: {
          msg: err.msg ? err.msg : null,
          status: -1,
        },
      } as BasicApiResponse<T>;
    });
}

export function requestPut<T>(
  url: string,
  header: object,
  body: object,
): Promise<BasicApiResponse<T>> {
  return axios
    .put(url, body, {
      headers: {
        'Content-Type': 'application/json',
        ...header,
      },
    })
    .then(
      (res) =>
        ({
          data: res.data?.data as T,
          config: {
            status: res.status,
            msg: res.data?.msg,
            code: res.data?.code,
            success: res.data?.success,
            version: res.data?.version,
          },
        } as BasicApiResponse<T>),
    )
    .catch((err) => {
      console.error('[Axios Error]', err);

      return {
        data: {} as T,
        config: {
          msg: err.msg ? err.msg : null,
          status: -1,
        },
      } as BasicApiResponse<T>;
    });
}

export function requestSecurePatch<T>(
  url: string,
  header: object,
  body: object,
  token: string,
): Promise<BasicApiResponse<T>> {
  return axios
    .patch(url, body, {
      headers: {
        'Content-Type': 'application/json',
        'X-AUTH-TOKEN': `${token}`,
        ...header,
      },
    })
    .then(
      (res) =>
        ({
          data: res.data?.data as T,
          config: {
            status: res.status,
            msg: res.data?.msg,
            code: res.data?.code,
            success: res.data?.success,
            version: res.data?.version,
          },
        } as BasicApiResponse<T>),
    )
    .catch((err) => {
      console.error('[Axios Error]', err);

      return {
        data: {} as T,
        config: {
          msg: err.msg ? err.msg : null,
          status: -1,
        },
      } as BasicApiResponse<T>;
    });
}

export function requestMultipart<T>(
  url: string,
  header: object,
  body: FormData,
  token: string,
): Promise<BasicApiResponse<T>> {
  return axios
    .post(url, body, {
      headers: {
        'Content-Type': 'multipart/form-data',
        'X-AUTH-TOKEN': `${token}`,
        ...header,
      },
    })
    .then(
      (res) =>
        ({
          data: res.data?.data as T,
          config: {
            status: res.status,
            msg: res.data?.msg,
            code: res.data?.code,
            success: res.data?.success,
            version: res.data?.version,
          },
        } as BasicApiResponse<T>),
    )
    .catch((err) => {
      console.error('[Axios Error]', err);

      return {
        data: {} as T,
        config: {
          msg: err.msg ? err.msg : null,
          status: -1,
        },
      } as BasicApiResponse<T>;
    });
}
