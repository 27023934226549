import * as Style from '../style/ExaminationPatientListWaitingStyle';
import { parseFontString } from '@libs/factory';
import {
  LoadFontConfigResponseDataType,
  LoadVisibleConfigResponseDataType,
} from '@typedef/routes/viewer.navigation.types';
import { ExaminationPatientItemTypes } from '@typedef/components/ExaminationPatientWaiting/examination.patient.waiting.types';
import { ExaminationPatient } from '@components/view/ExaminationPatientWaiting/components/ExaminationPatient';

type Props = {
  isDoubleList?: boolean;
  broomNumber: string | null;
  font: LoadFontConfigResponseDataType;
  visible: LoadVisibleConfigResponseDataType;
  currentExaminationPatient: ExaminationPatientItemTypes[];
  currentExaminationPatient2: ExaminationPatientItemTypes[];
};

const ExaminationPatientListWaiting = ({
  isDoubleList = false,
  font,
  visible,
  broomNumber,
  currentExaminationPatient,
  currentExaminationPatient2 = [],
}: Props) => {
  return (
    <Style.ExaminationPatientListWaitingStyle>
      <Style.Thead>
        <Style.CustomPTag
          family={parseFontString(font.froomNumber).family}
          fz={parseFontString(font.froomNumber).size}
          fw={parseFontString(font.froomNumber).bold}
          color={parseFontString(font.froomNumber).color}>
          {broomNumber}
        </Style.CustomPTag>
      </Style.Thead>
      {isDoubleList ? (
        <section className='row'>
          <Style.Patients isDouble={true}>
            {currentExaminationPatient.map((data, index) => (
              <ExaminationPatient
                key={index}
                font={font}
                visible={visible}
                data={data}
                index={index}
              />
            ))}
          </Style.Patients>
          <Style.Patients isDouble={true}>
            {currentExaminationPatient2.map((data, index) => (
              <ExaminationPatient
                key={index}
                font={font}
                visible={visible}
                data={data}
                index={index + 10}
              />
            ))}
          </Style.Patients>
        </section>
      ) : (
        <Style.Patients isDouble={false}>
          {currentExaminationPatient.map((data, index) => (
            <ExaminationPatient
              key={index}
              font={font}
              visible={visible}
              data={data}
              index={index}
            />
          ))}
        </Style.Patients>
      )}
    </Style.ExaminationPatientListWaitingStyle>
  );
};

export default ExaminationPatientListWaiting;
