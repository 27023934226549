import styled from "styled-components";

const FontSettingItemBackgroundStyle = styled.li`
  list-style: none;
  padding: 0.22em 0;
  height: 3.6vh;
  .font-content {
    p {
      width: 100%;
      height: 100%;
      margin: 0;
      display: flex;
      align-items: center;
      justify-content: start;
      text-align: center;
      font-weight: bold;
      font-size: 1rem;
    }
  }
  .font-color {
    width: 88%;
    .row {
      justify-content: center;
      span {
        width: auto;
        margin: 0;
        font-weight: bold;
      }
      input[type="color"] {
        width: 90%;
        cursor: pointer;
        padding: 0.25em;
        background-color: transparent;
      }
    }
  }

  @media (max-width: 1280px) {
    height: 3.85vh;
    .font-content {
      padding: 0;
      p {
        font-size: 1rem;
      }
    }

    .font-color {
      padding: 0;
      width: 82%;
      .row {
        span {
          padding: 0;
        }
        input[type="color"] {
          width: 90%;
        }
      }
    }
  }
`;

export default FontSettingItemBackgroundStyle;
