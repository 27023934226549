import styled from 'styled-components';
import images from '@assets/images';

const space = '2vh';

export const RoomList = styled.main`
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;

  * {
    margin: 0;
  }
`;

type Props = {
  family?: string;
  fz?: number;
  fw?: boolean;
  color?: string;
  align?: string;
};

export const CustomPTag = styled.p<Props>`
  font-family: ${(props) => props.family};
  font-size: calc(${(props) => props.fz}vh / 11);
  font-weight: ${(props) => (props.fw ? 'bold' : 'normal')};
  color: ${(props) => props.color};
  text-align: ${(props) => props.align ?? 'center'};
  margin: 0;
`;

// 상단헤더(로고, 진료실명)
export const Header = styled.header`
  width: 100%;
  height: 11vh;
  display: flex;
  flex-direction: row;
  overflow: hidden;

  // 로고, 삼각형
  .logoBox {
    height: 100%;
    display: flex;
    flex-direction: row;

    img {
      height: 100%;
    }

    .triangle {
      background-color: #fff;
      border-bottom: 11vh solid #000655;
      border-left: 11vh solid transparent;
    }
  }

  .titleBox {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 0 ${space};
    background: url(${images.viewerBackground}), #000655;
    background-repeat: repeat-x;

    .subtitle {
      font-size: 1vw;
      word-break: keep-all;
      text-align: left;
      color: white;
    }
  }
`;

// Rooms + 자막(subtitle)
export const Main = styled.main`
  flex: 1;
  display: flex;
  flex-direction: column;
`;

export const Rooms = styled.section`
  flex: 1;
  display: flex;
  flex-direction: row;
`;

export const Room = styled.section`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  background: linear-gradient(#231b7f, #530180);
  padding: 0.5vw;
`;

export const PatientList = styled.section`
  height: 100%;
  display: flex;
  flex-direction: column;
  padding: 0.5vw;
  background-color: #fff;
  border-radius: 1vw;
`;

export const RoomHeader = styled.header`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 0.25vw;

  // 의사
  .doctor {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    border: 1px solid #000;
    border-radius: 1vw;
    overflow: hidden;

    // '진료의사'
    .span {
      width: 100%;
      height: 4vh;
      line-height: 4vh;
      background-color: #000655;
      color: #fff;
      font-size: 2vh;
      text-align: center;
    }

    // 의사 이름
    .name {
      width: 100%;
      height: 100%;
      position: relative;

      p {
        width: 100%;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
      }
    }
  }

  // 진료실 번호
  .roomNum {
    width: 12vw;
    height: 7.5vw;
    display: flex;
    flex-direction: column;
    align-items: center;
    border-radius: 1vw;
    background-color: #000655;
    margin-left: 0.5vw;

    .num {
      flex: 1;
      position: relative;
      p {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
      }
    }

    // '진료실'
    span {
      font-size: 2vh;
      color: #fff;
      margin-bottom: 0.25vw;
    }
  }
`;

export const RoomContent = styled.section`
  flex: 1;
  ul {
    height: 100%;
    display: flex;
    flex-direction: column;
    list-style: none;
  }
`;

type PatientProps = {
  arrive: boolean;
};

export const Patient = styled.li<PatientProps>`
  flex: 1;
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 0.25vw;
  background-color: ${(PatientProps) =>
    PatientProps.arrive ? '#3d428a' : 'lightgray'};
  border-radius: 1vw;

  div {
    position: relative;
  }

  .patientName {
    width: 70%;
  }

  .patientFlag {
    width: 30%;
  }

  p {
    width: 100%;
    position: absolute;
    top: 50%;
    transform: translate(0, -50%);
  }
`;
