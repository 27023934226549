import styled from 'styled-components';

const VideoSettingStyle = styled.div`
  .card {
    height: 26vh;
    .card-header {
      .btns {
        input {
          display: none;
        }
        label {
          cursor: pointer;
          margin: 0;
        }
      }
    }
    .card-body {
      width: 100%;
      overflow-y: auto;
      padding: 0.15em;
      table {
        white-space: nowrap;
        thead {
          tr {
            th {
              border-color: #fff;
              position: sticky;
              top: 0;
              background-color: #636f83;
              color: #fff;
              text-align: center;
              border: 1px solid gray;
              margin: auto 0;
              padding: 0;
              font-size: 0.9rem;
            }
          }
        }
        tbody {
          tr {
            td {
              border: 1px solid lightgray;
              text-align: center;
              vertical-align: middle;
              margin: auto 0;
              padding: 0;
              font-size: 0.9rem;
            }
          }
        }
      }
    }
  }
  @media (min-width: 1280px) and (max-width: 1280px) {
    .card {
      height: 33vh;
      .card-body {
        table {
          th,
          td {
            padding: 0;
            font-size: 0.8rem;
            button {
              font-size: 0.8rem;
              padding: 0 0.25em;
            }
          }
        }
      }
    }
  }
`;

export default VideoSettingStyle;
