import useViewer from '@hooks/store/useViewer';
import { useEffect, useState, useMemo } from 'react';
import OutQuadPatientWaiting from '../OutQuadPatientWaiting';
import { getCurrentList } from '@libs/factory';

const ROOM_LIST_INTERVAL = 29000;

const ROOM_SIZE = 4;

const OutQuadPatientWaitingContainer = ({}) => {
  const { basic, font } = useViewer();

  const [intervalCounter, setIntervalCounter] = useState<number>(0);

  const currentReservePatient = useMemo(() => {
    if (basic?.roomList) {
      return getCurrentList(basic?.roomList, ROOM_SIZE, intervalCounter);
    } else {
      return getCurrentList([], ROOM_SIZE, intervalCounter);
    }
  }, [basic?.roomList, intervalCounter]);

  useEffect(() => {
    const interval = setInterval(() => {
      setIntervalCounter((prev) => prev + 1);
    }, ROOM_LIST_INTERVAL);

    return () => {
      clearInterval(interval);
    };
  }, []);

  return basic && font ? (
    <OutQuadPatientWaiting
      basic={basic}
      font={font}
      roomList={currentReservePatient}
    />
  ) : null;
};

export default OutQuadPatientWaitingContainer;
