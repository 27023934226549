import { CommonActionTypes } from "@store/actions";
import { STORE_INIT_ACTION_TYPES } from "@store/modules/actionTypes";
import { MyBoardListItemTypes } from "@typedef/components/Login/login.types";
import { GNBActionTypes } from "./actions";
import {
  UPDATE_GNB_VISIBLE_ACITON_TYPE,
  UPDATE_SELECTED_BOARD_ACTION_TYPE,
  UPDATE_INFECTION_FLAG_ACTION_TYPE,
  UPDATE_GNB_MYBOARD_RELOAD_ACTION_TYPE,
} from "./modules/actionTypes";

export type GNBStateTypes = {
  visible: boolean;
  selected: MyBoardListItemTypes | null;
  infection: boolean;
  myBoardReload: number;
};

const init: GNBStateTypes = {
  visible: true,
  selected: null,
  infection: false,
  myBoardReload: 0,
};

const SELECTED_SESSION_KEY_VALUE = "@selected";

const INFECTION_SESSION_KEY_VALUE = "@infection";

const getSelected = () => {
  const sessionValue = sessionStorage.getItem(SELECTED_SESSION_KEY_VALUE);

  if (sessionValue) {
    return JSON.parse(sessionValue) as MyBoardListItemTypes;
  } else {
    return null;
  }
};

const getInfection = () => {
  const sessionValue = sessionStorage.getItem(INFECTION_SESSION_KEY_VALUE);

  if (sessionValue) {
    return JSON.parse(sessionValue) as boolean;
  } else {
    return false;
  }
};

const setSelected = (selected: MyBoardListItemTypes) =>
  sessionStorage.setItem(SELECTED_SESSION_KEY_VALUE, JSON.stringify(selected));

const setInfection = (infection: boolean) =>
  sessionStorage.setItem(
    INFECTION_SESSION_KEY_VALUE,
    JSON.stringify(infection)
  );

const gnbReducer = (
  prev: GNBStateTypes = init,
  { type, payload }: GNBActionTypes | CommonActionTypes
): GNBStateTypes => {
  switch (type) {
    case UPDATE_GNB_VISIBLE_ACITON_TYPE:
      return {
        ...prev,
        visible: payload,
      };

    case UPDATE_SELECTED_BOARD_ACTION_TYPE: {
      setSelected(payload);

      return {
        ...prev,
        selected: payload,
      };
    }

    case UPDATE_INFECTION_FLAG_ACTION_TYPE: {
      setInfection(payload);

      return {
        ...prev,
        infection: payload,
      };
    }

    case UPDATE_GNB_MYBOARD_RELOAD_ACTION_TYPE:
      return {
        ...prev,
        myBoardReload: payload,
      };

    case STORE_INIT_ACTION_TYPES:
      return {
        ...prev,
        selected: getSelected(),
        infection: getInfection(),
      };

    default:
      return prev;
  }
};

export default gnbReducer;
