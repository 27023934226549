export type VisibleType = {
  vmasking: boolean;
  position: number | null;
  maskMark: string | null;
};

// 시간에 콜론추가
export const timeAddColons = (time: string) => {
  if (time) {
    return `${time?.substring(0, 2)}:${time.substring(2, 4)}`;
  } else {
    return '';
  }
};

// 이름에 마스킹 추가
export const maskingName = (name: string, visible: VisibleType) => {
  if (name === '' || name === null || name === undefined) {
    return name;
  } else {
    if (visible.vmasking && visible.position && visible.maskMark !== '') {
      var originName = name.toString().split('');
      if (name?.length > 2) {
        // 이름수가 2글자 이상일 때
        if (name?.length < visible.position) {
          // 마스킹할 포지션이 이름보다 클때
          originName.forEach(function (name, i) {
            if (i === originName.length - 1) {
              originName[i] = visible.maskMark ?? '';
            }
          });
          return originName.join('');
        } else {
          // 포지션에 따른 이름 마스킹 처리 ( 가장 정상적일 때 )
          originName.forEach(function (name, i) {
            if (i === (visible.position ?? 0) - 1) {
              originName[i] = visible.maskMark ?? '';
            }
          });
          if (originName?.join('')?.includes('[부')) {
            return originName.join('');
          } else {
            return originName.join('').slice(0, 5);
          }
        }
      } else {
        if (visible.position === 1) {
          // 포지션위치가 1일때
          return visible.maskMark + originName[1];
        } else {
          // 포지션위치가 1 이외일때
          return originName[0] + visible.maskMark;
        }
      }
    } else {
      return name;
    }
  }
};
