import AccountDelete from '../components/AccountDelete';
import { useCallback, useState, useEffect } from 'react';
import { apiRoute, requestSecureDelete, requestSecureGet } from '@libs/api';
import useLogined from '@hooks/store/useLogined';
import { LoginRepsonseTypes } from '@typedef/components/Login/login.types';

type Props = {
  id: string | null;
  setCurrentModal: React.Dispatch<
    React.SetStateAction<'update' | 'delete' | null>
  >;
  reload: number;
  setReload: React.Dispatch<React.SetStateAction<number>>;
};

const AccountDeleteContainer = ({
  id,
  setCurrentModal,
  reload,
  setReload,
}: Props) => {
  const { token } = useLogined();

  const [deleteId, setDeleteId] = useState<number>();

  const loadAccountDetail = useCallback(async () => {
    if (!token) return;

    const { data, config } = await requestSecureGet<LoginRepsonseTypes[]>(
      apiRoute.admin.account.getAccount,
      {},
      token,
    );
    if (config.status === 200) {
      data.map((account) => {
        if (account.account === id) {
          setDeleteId(account.id);
        }
      });
    }
  }, [token, id]);

  // 계정 삭제
  const deleteAccount = useCallback(async () => {
    if (!token) return;

    const { config } = await requestSecureDelete(
      apiRoute.admin.account.deleteAccount + deleteId,
      {},
      {},
      token,
    );
    if (config.status >= 200 && config.status < 400) {
      setReload(reload + 1);
      setCurrentModal(null);
    }
  }, [deleteId, setCurrentModal, token, reload]);

  useEffect(() => {
    loadAccountDetail();
  }, []);

  return (
    <AccountDelete
      deleteAccount={deleteAccount}
      setCurrentModal={setCurrentModal}
    />
  );
};

export default AccountDeleteContainer;
