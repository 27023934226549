import images from '@assets/images';
import SubTitleContainer from '@components/SubTitle/containers/SubTitleContainer';
import { parseFontString } from '@libs/factory';
import {
  LoadBasicConfigResponseDataType,
  LoadFontConfigResponseDataType,
  LoadNoticeConfigResponseDataType,
  LoadVideoConfigItemTypes,
} from '@typedef/routes/viewer.navigation.types';
import * as S from './styles/VideoWaitingStyle';

type Props = {
  basic: LoadBasicConfigResponseDataType;
  font: LoadFontConfigResponseDataType;
  video: LoadVideoConfigItemTypes[] | null;
  notice: LoadNoticeConfigResponseDataType;
  videoIndex: number;
  setVideoIndex: React.Dispatch<React.SetStateAction<number>>;
};

const VideoWaiting = ({ basic, font, video, notice, videoIndex, setVideoIndex }: Props) => {
  return (
    <S.VideoWaitingStyle>
      <S.Header>
        <section className='logoBox'>
          <img src={images.jsolViewerLogo} alt='logo' />
          <div className='triangle' />
        </section>
        <section className='titleBox'>
          <p className='subtitle'>
            &nbsp;지역과 함께한 백년, 세계로 나아갈 천년
            <br />
            환자중심의 병원으로 거듭나겠습니다.
          </p>
          <S.CustomPTag
            family={parseFontString(font.froomKor).family}
            fz={parseFontString(font.froomKor).size}
            fw={parseFontString(font.froomKor).bold}
            color={parseFontString(font.froomKor).color}
          >
            {basic?.bname} {/* 진료실이름 */}
          </S.CustomPTag>
        </section>
      </S.Header>
      <S.Main>
        <S.Contents>
          <S.VideoNotice bgc={notice.display ? '#fff' : '#000'}>
            {notice.display ? (
              <S.NoticePre
                family={parseFontString(font.fnotice).family}
                fz={parseFontString(font.fnotice).size}
                fw={parseFontString(font.fnotice).bold}
                color={parseFontString(font.fnotice).color}
                align={notice.align ?? 'left'}
                valign={notice.valign ?? 'top'}
              >
                {notice?.contents}
              </S.NoticePre>
            ) : (
              <S.Iframe
                src='https://www.youtube.com/embed/Ngzr42mz57Y?autoplay=1&mute=1&showinfo=0&modestbranding=0&controls=0&loop=1&platlist=Ngzr42mz57Y'
                title='promotion'
                allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share'
                allowFullScreen
              />
              // <S.Video
              //   src={video ? video[videoIndex]?.path : ''}
              //   autoPlay
              //   muted
              //   loop={video ? video.length === 1 : false}
              //   onEnded={() => {
              //     if (videoIndex === (video ? video.length : 0) - 1) {
              //       setVideoIndex(0);
              //     } else {
              //       setVideoIndex(videoIndex + 1);
              //     }
              //   }}
              // />
            )}
          </S.VideoNotice>
        </S.Contents>
      </S.Main>
      <SubTitleContainer height='90vh' />
    </S.VideoWaitingStyle>
  );
};

export default VideoWaiting;
