import images from '@assets/images';
import React from 'react';
import ExaminationPatientListWaitingContainer from './containers/ExaminationPatientListWaitingContainer';
import * as S from './style/ExaminationPatientWaitingStyle';
import { parseFontString } from '@libs/factory';
import {
  LoadBasicConfigResponseDataType,
  LoadFontConfigResponseDataType,
  LoadNoticeConfigResponseDataType,
  LoadVideoConfigItemTypes,
} from '@typedef/routes/viewer.navigation.types';
import SubTitleContainer from '@components/SubTitle/containers/SubTitleContainer';

type Props = {
  basic: LoadBasicConfigResponseDataType;
  font: LoadFontConfigResponseDataType;
  video: LoadVideoConfigItemTypes[] | null;
  notice: LoadNoticeConfigResponseDataType;
  videoIndex: number;
  setVideoIndex: React.Dispatch<React.SetStateAction<number>>;
};

const ExaminationPatientWaiting = ({
  basic,
  font,
  video,
  notice,
  videoIndex,
  setVideoIndex,
}: Props) => {
  return (
    <S.ExaminationPatientWaitingStyle>
      <S.Header>
        <section className='logoBox'>
          <img src={images.jsolViewerLogo} alt='logo' />
          <div className='triangle' />
        </section>
        <section className='titleBox'>
          <p className='subtitle'>
            &nbsp;지역과 함께한 백년, 세계로 나아갈 천년
            <br />
            환자중심의 병원으로 거듭나겠습니다.
          </p>
          <S.CustomPTag
            family={parseFontString(font.froomKor).family}
            fz={parseFontString(font.froomKor).size}
            fw={parseFontString(font.froomKor).bold}
            color={parseFontString(font.froomKor).color}
          >
            {basic?.bname} {/* 진료실이름 */}
          </S.CustomPTag>
        </section>
      </S.Header>
      <S.Content>
        {basic.btypeName === '영상/1열' ? (
          //  영상/1열
          <S.Row>
            <S.VideoNotice bgc={notice.display ? '#fff' : '#000'}>
              {notice.display ? (
                <S.NoticePre
                  family={parseFontString(font.fnotice).family}
                  fz={parseFontString(font.fnotice).size}
                  fw={parseFontString(font.fnotice).bold}
                  color={parseFontString(font.fnotice).color}
                  align={notice.align ?? 'left'}
                  valign={notice.valign ?? 'top'}
                >
                  {notice?.contents}
                </S.NoticePre>
              ) : (
                <S.Video
                  id='video'
                  autoPlay
                  muted
                  loop={video ? video.length === 1 : false}
                  src={video ? video[videoIndex]?.path : ''}
                  onEnded={() => {
                    if (videoIndex === (video ? video.length : 0) - 1) {
                      setVideoIndex(0);
                    } else {
                      setVideoIndex(videoIndex + 1);
                    }
                  }}
                />
              )}
            </S.VideoNotice>
            <S.List>
              <ExaminationPatientListWaitingContainer index={0} isDoubleList={true} />
            </S.List>
          </S.Row>
        ) : basic.btypeName === '1열/영상' ? (
          // 1열/영상
          <S.Row>
            <S.List>
              <ExaminationPatientListWaitingContainer index={0} isDoubleList={true} />
            </S.List>
            <S.VideoNotice bgc={notice.display ? '#fff' : '#000'}>
              {notice.display ? (
                <S.NoticePre
                  family={parseFontString(font.fnotice).family}
                  fz={parseFontString(font.fnotice).size}
                  fw={parseFontString(font.fnotice).bold}
                  color={parseFontString(font.fnotice).color}
                  align={notice.align ?? 'left'}
                  valign={notice.valign ?? 'top'}
                >
                  {notice?.contents}
                </S.NoticePre>
              ) : (
                <S.Video
                  id='video'
                  autoPlay
                  muted
                  loop={video ? video.length === 1 : false}
                  src={video ? video[videoIndex]?.path : ''}
                  onEnded={() => {
                    if (videoIndex === (video ? video.length : 0) - 1) {
                      setVideoIndex(0);
                    } else {
                      setVideoIndex(videoIndex + 1);
                    }
                  }}
                />
              )}
            </S.VideoNotice>
          </S.Row>
        ) : basic.btypeName.includes('영상/2열') ? (
          // 영상/2열
          <S.Row>
            <S.VideoNotice bgc={notice.display ? '#fff' : '#000'}>
              {notice.display ? (
                <S.NoticePre
                  family={parseFontString(font.fnotice).family}
                  fz={parseFontString(font.fnotice).size}
                  fw={parseFontString(font.fnotice).bold}
                  color={parseFontString(font.fnotice).color}
                  align={notice.align ?? 'left'}
                  valign={notice.valign ?? 'top'}
                >
                  {notice?.contents}
                </S.NoticePre>
              ) : (
                <S.Video
                  id='video'
                  autoPlay
                  muted
                  loop={video ? video.length === 1 : false}
                  src={video ? video[videoIndex]?.path : ''}
                  onEnded={() => {
                    if (videoIndex === (video ? video.length : 0) - 1) {
                      setVideoIndex(0);
                    } else {
                      setVideoIndex(videoIndex + 1);
                    }
                  }}
                />
              )}
            </S.VideoNotice>
            <S.List width={25}>
              <ExaminationPatientListWaitingContainer index={0} isDoubleList={false} />
            </S.List>
            <S.List width={25}>
              <ExaminationPatientListWaitingContainer index={1} isDoubleList={false} />
            </S.List>
          </S.Row>
        ) : basic.btypeName.includes('2열/영상') ? (
          // 2열/영상
          <S.Row>
            <S.List width={25}>
              <ExaminationPatientListWaitingContainer index={0} isDoubleList={false} />
            </S.List>
            <S.List width={25}>
              <ExaminationPatientListWaitingContainer index={1} isDoubleList={false} />
            </S.List>
            <S.VideoNotice bgc={notice.display ? '#fff' : '#000'}>
              {notice.display ? (
                <S.NoticePre
                  family={parseFontString(font.fnotice).family}
                  fz={parseFontString(font.fnotice).size}
                  fw={parseFontString(font.fnotice).bold}
                  color={parseFontString(font.fnotice).color}
                  align={notice.align ?? 'left'}
                  valign={notice.valign ?? 'top'}
                >
                  {notice?.contents}
                </S.NoticePre>
              ) : (
                <S.Video
                  id='video'
                  autoPlay
                  muted
                  loop={video ? video.length === 1 : false}
                  src={video ? video[videoIndex]?.path : ''}
                  onEnded={() => {
                    if (videoIndex === (video ? video.length : 0) - 1) {
                      setVideoIndex(0);
                    } else {
                      setVideoIndex(videoIndex + 1);
                    }
                  }}
                />
              )}
            </S.VideoNotice>
          </S.Row>
        ) : (
          // 1열/영상/1열
          <S.Row>
            <S.List width={25}>
              <ExaminationPatientListWaitingContainer index={0} isDoubleList={false} />
            </S.List>
            <S.VideoNotice bgc={notice.display ? '#fff' : '#000'}>
              {notice.display ? (
                <S.NoticePre
                  family={parseFontString(font.fnotice).family}
                  fz={parseFontString(font.fnotice).size}
                  fw={parseFontString(font.fnotice).bold}
                  color={parseFontString(font.fnotice).color}
                  align={notice.align ?? 'left'}
                  valign={notice.valign ?? 'top'}
                >
                  {notice?.contents}
                </S.NoticePre>
              ) : (
                <S.Video
                  id='video'
                  autoPlay
                  muted
                  loop={video ? video.length === 1 : false}
                  src={video ? video[videoIndex]?.path : ''}
                  onEnded={() => {
                    if (videoIndex === (video ? video.length : 0) - 1) {
                      setVideoIndex(0);
                    } else {
                      setVideoIndex(videoIndex + 1);
                    }
                  }}
                />
              )}
            </S.VideoNotice>
            <S.List width={25}>
              <ExaminationPatientListWaitingContainer index={1} isDoubleList={false} />
            </S.List>
          </S.Row>
        )}
        <SubTitleContainer height='90vh' />
      </S.Content>
    </S.ExaminationPatientWaitingStyle>
  );
};

export default ExaminationPatientWaiting;
