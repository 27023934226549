import styled from 'styled-components';

const SubtitleSettingStyle = styled.div`
  .card {
    height: 25vh;
    .card-body {
      padding: 0.25em;
      /* 자막설정 설정 내부 헤더 */
      .menus {
        display: flex;
        .form-check-input {
          position: relative;
        }
        label {
          margin: 0;
          cursor: pointer;
        }
        /* 자막설정 설정 표시여부  */
        .visible {
          width: 30%;
          margin-right: auto;
          display: flex;
          align-items: center;
          input {
            /* 체크박스 위치조정을 위한 margin: 0; 으로 값 초기화 후 셋팅 */
            cursor: pointer;
            margin: 0;
            margin-left: 0.25em;
          }
        }
        /* 자막설정 자막속도 (스피드) */
        .speed {
          width: 50%;
          display: flex;
          align-items: center;
          input {
            cursor: pointer;
            width: 50%;
            margin-left: 0.25em;
            vertical-align: middle;
          }
        }
      }
      /* 자막설정 내부 메인 */
      .subtitletables {
        margin-top: 0.5em;
        height: 16.5vh;
        overflow-y: auto;
        border: 1px solid gray;
        border-radius: 10px;
        .table {
          margin: 0;
          td {
            border: none;
            padding: 0.25em;
            text-align: center;
            vertical-align: middle;
            input[type='checkbox'] {
              vertical-align: middle;
              margin: 0;
              cursor: pointer;
            }
            .form-control {
              font-size: 1rem;
            }
            .btn {
              color: white;
            }
          }
        }
      }
    }
  }

  @media (max-width: 1280px) {
    .card {
      height: 21vh;
      font-size: 0.8rem;
      .card-body {
        /* 자막설정 내부 메인 */
        .subtitletables {
          height: 13vh;
          .table {
            td {
              input[type='checkbox'] {
                font-size: 0.7rem;
                margin: 0;
              }
              .form-control {
                font-size: 0.8rem;
              }
              .btn {
                font-size: 0.7rem;
              }
            }
          }
        }
      }
    }
  }
`;

export default SubtitleSettingStyle;
