import * as S from './styles/RoomListWaitingStyle';
import images from '@assets/images';
import { parseFontString } from '@libs/factory';
import {
  LoadBasicConfigResponseDataType,
  LoadFontConfigResponseDataType,
  LoadVisibleConfigResponseDataType,
} from '@typedef/routes/viewer.navigation.types';
import SubTitleContainer from '@components/SubTitle/containers/SubTitleContainer';
import RoomListWaitingPatientListContainer from './container/RoomListWaitingPatientListContainer';

type Props = {
  basic: LoadBasicConfigResponseDataType;
  font: LoadFontConfigResponseDataType;
  visible: LoadVisibleConfigResponseDataType;
  roomList: string[];
  roomNums: string[];
};

const RoomListWaiting = ({ basic, font, visible, roomList, roomNums }: Props) => {
  return (
    <S.RoomList>
      <S.Header>
        <section className='logoBox'>
          <img src={images.jsolViewerLogo} alt='logo' />
          <div className='triangle' />
        </section>
        <section className='titleBox'>
          <p className='subtitle'>
            &nbsp;지역과 함께한 백년, 세계로 나아갈 천년
            <br />
            환자중심의 병원으로 거듭나겠습니다.
          </p>
          <S.CustomPTag
            family={parseFontString(font.froomKor).family}
            fz={parseFontString(font.froomKor).size}
            fw={parseFontString(font.froomKor).bold}
            color={parseFontString(font.froomKor).color}
          >
            {basic?.bname} {/* 진료실이름 */}
          </S.CustomPTag>
        </section>
      </S.Header>
      <S.Main>
        <S.Rooms>
          {roomList.map((room, i) => {
            return (
              <S.Room key={room} id={room}>
                <RoomListWaitingPatientListContainer
                  font={font}
                  visible={visible}
                  roomKey={JSON.stringify(room) === '{}' ? '0' : room}
                  broomNumber={JSON.stringify(roomNums[i]) === '{}' ? '' : roomNums[i]}
                />
              </S.Room>
            );
          })}
        </S.Rooms>
        <SubTitleContainer height='90vh' />
      </S.Main>
    </S.RoomList>
  );
};

export default RoomListWaiting;
