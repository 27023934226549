import images from '@assets/images';
import styled from 'styled-components';

const space = '2vw';

export const OutPatientWaitingStyle = styled.div`
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
  background: url(${images.viewerBackground}), linear-gradient(#231b7f, #530180);
  background-repeat: repeat-x;

  p {
    margin-bottom: 0;
  }
`;

type Props = {
  family?: string;
  fz?: number;
  fw?: boolean;
  color?: string;
  align?: string;
};

export const CustomPTag = styled.p<Props>`
  font-family: ${(props) => props.family};
  font-size: calc(${(props) => props.fz}vh / 20);
  font-weight: ${(props) => (props.fw ? 'bold' : 'normal')};
  color: ${(props) => props.color};
  text-align: ${(props) => props.align ?? 'left'};
`;

// 상단헤더(센터명, 진료실명, 룸번호)
export const Header = styled.header`
  width: 100%;
  height: 12vh;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: ${space};
  padding: ${space};

  img {
    width: 12vh;
  }

  .left {
    display: flex;
    flex-direction: column;
  }
  .roomNum {
    width: calc(12vh - 4vw);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: #fff;
    border-radius: 1vh;

    span {
      color: #000;
      font-size: 1.5vh;
      font-weight: 600;
    }
  }
`;

// 본문 + 자막(subtitle)
export const Main = styled.section`
  flex: 1;
  display: flex;
  flex-direction: column;
`;

// 본문(의사, 관리자, 시간, 환자, 공지사항, 비디오, 대기자 명단)
export const Content = styled.section`
  flex: 1;
  display: flex;
  flex-direction: column;
  background-color: #fff;
  margin: ${space};
  padding: ${space};
  border-radius: 1vh;
`;

// 의사, 담당직원
export const ContentHeader = styled.header`
  width: 100%;
  height: 23vw;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: ${space};

  .doctor {
    display: flex;
    flex-direction: row;

    .imageBox {
      width: 20vw;
      display: flex;
      align-items: center;
      justify-content: center;
      z-index: 2;
      border-radius: 25px;
      box-shadow: 0 0 10px 5px gray;
      overflow: hidden;
      img {
        width: 100%;
        height: auto;
      }
    }

    .infoBox {
      display: flex;
      flex-direction: column;
      align-items: center;
      padding: ${space} 0;

      span {
        width: fit-content;
        background-color: #0d0662;
        padding: 0 7vh;
        color: white;
        font-size: 2vh;
        font-weight: 600;
        border-radius: 0 1vh 1vh 0;
      }
    }
  }

  .adminBox {
    width: 20vw;
    height: 100%;
    background-color: #0d0662;
    padding: 1.5vw;
    border-radius: 1vh;
  }

  .admin {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: #533d8a;
    border-radius: 1vh;

    span {
      color: #fff;
      font-size: 1.5vh;
      font-weight: 600;
    }
  }
`;

export const Times = styled.section`
  display: flex;
  flex-direction: row;
  margin-bottom: ${space};
  .clock,
  .delay {
    width: 50%;
    height: 5vh;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    background-color: #0d0662;
    color: #fff;
    border-radius: 1vh;
    font-size: 3vh;
    font-weight: 600;
  }

  .delay {
    justify-content: space-between;
    margin-left: ${space};
    padding: 0 ${space};
    p {
      color: #fffca1;
    }
  }
`;

export const Patients = styled.section`
  .selected {
    background-color: #3d428a;
    color: #fff;
  }

  .notArrive {
    background-color: #ddd;
    color: #444;
  }
`;

export const Patient = styled.div`
  height: 7vh;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-bottom: ${space};
  padding: 0 ${space};
  border-radius: 1vh;

  .delayDescription {
    font-size: 2vh;
    font-weight: 600;
  }
`;

export const Notice = styled.section`
  flex: 1;
  display: flex;
  flex-direction: column;
  background-color: #fff;
  border-radius: 1vh;
  box-shadow: 0 5px 15px gray;
`;

export const NoticePre = styled.div<{
  family?: string;
  fz?: number;
  fw?: boolean;
  color?: string;
  align?: string;
  valign?: string;
}>`
  font-family: ${(props) => props.family};
  font-size: calc(${(props) => props.fz}vh / 11);
  font-weight: ${(props) => (props.fw ? 'bold' : 'normal')};
  color: ${(props) => props.color};
  display: flex;
  height: 100%;
  justify-content: ${(props) =>
    props.align === 'left' ? 'start' : props.align === 'right' ? 'end' : props.align};
  align-items: ${(props) =>
    props.valign === 'top' ? 'start' : props.valign === 'middle' ? 'center' : 'end'};
  overflow: hidden;
  word-break: break-all;
  white-space: pre-wrap;
`;

export const Video = styled(Notice)`
  video,
  iframe {
    border-radius: 1vh;
    object-fit: fill;
    width: 100%;
    height: 55vw;
  }
`;

export const MoreList = styled(Notice)`
  overflow: hidden;
  header {
    height: 6vh;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #533d8a;
    color: #fff;
    font-size: 2.5vh;
    font-weight: 600;
  }

  ul {
    height: 100%;
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr 1fr 1fr 1fr 1fr;
    align-content: stretch;
    list-style: none;
    margin: 0;
  }

  li {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    border: 1px solid #0d0662;
    font-size: 2.5vh;

    p {
      width: 25%;
      text-align: center;
    }

    .name {
      width: 50%;
      font-size: 2.5vh;
      font-weight: 600;
    }
  }
`;
