import * as S from '../styles/RoomListWaitingStyle';
import {
  LoadFontConfigResponseDataType,
  LoadVisibleConfigResponseDataType,
} from '@typedef/routes/viewer.navigation.types';
import { parseFontString } from '@libs/factory';
import { maskingName, timeAddColons } from '@libs/monitor';
import { RoomListWaitingPatientTypes } from '@typedef/components/RoomListWaiting/room.list.waiting.patient.types';

type Props = {
  font: LoadFontConfigResponseDataType;
  visible: LoadVisibleConfigResponseDataType;
  broomNumber: string;
  patients: RoomListWaitingPatientTypes[];
  doctor: {
    doctorKor: string;
    doctorEng?: string;
  };
};

const RoomListWaitingPatientList = ({ font, visible, broomNumber, patients, doctor }: Props) => {
  return (
    <S.PatientList>
      <S.RoomHeader>
        <section className='doctor'>
          <div className='span'>진료의사</div>
          <div className='name'>
            <S.CustomPTag
              family={parseFontString(font.fdoctorKor).family}
              fz={parseFontString(font.fdoctorKor).size}
              fw={parseFontString(font.fdoctorKor).bold}
              color={parseFontString(font.fdoctorKor).color}
            >
              {doctor.doctorKor}
            </S.CustomPTag>
          </div>
          {visible.vdoctorEng && (
            <S.CustomPTag
              family={parseFontString(font.fdoctorEng).family}
              fz={parseFontString(font.fdoctorEng).size}
              fw={parseFontString(font.fdoctorEng).bold}
              color={parseFontString(font.fdoctorEng).color}
            >
              {doctor.doctorEng}
            </S.CustomPTag>
          )}
        </section>
        <section className='roomNum'>
          <div className='num'>
            <S.CustomPTag
              className='number'
              family={parseFontString(font.froomNumber).family}
              fz={parseFontString(font.froomNumber).size}
              fw={parseFontString(font.froomNumber).bold}
              color={parseFontString(font.froomNumber).color}
            >
              {broomNumber ?? ''}
            </S.CustomPTag>
          </div>
          <span>진료실</span>
        </section>
      </S.RoomHeader>
      <S.RoomContent>
        {patients.length > 0 ? (
          <ul>
            {patients.map((patient, index) => (
              <S.Patient
                key={patient.pid + '-' + index}
                arrive={patient.arrflag === '1' ? true : false}
              >
                <div className='patientName'>
                  <S.CustomPTag
                    family={parseFontString(font.fpatientName).family}
                    fz={parseFontString(font.fpatientName).size}
                    fw={parseFontString(font.fpatientName).bold}
                    color={parseFontString(font.fpatientName).color}
                  >
                    {maskingName(patient?.hngnm, visible)?.slice(0, 5)}
                  </S.CustomPTag>
                </div>
                <div className='patientFlag'>
                  <S.CustomPTag
                    family={parseFontString(font.fpatientName).family}
                    fz={parseFontString(font.fpatientName).size - 20}
                    fw={parseFontString(font.fpatientName).bold}
                    color={parseFontString(font.fpatientName).color}
                  >
                    {patient?.arrflag === '0'
                      ? timeAddColons(patient?.ordtm)
                      : patient?.elbulbodstatnm}
                  </S.CustomPTag>
                </div>
              </S.Patient>
            ))}
          </ul>
        ) : (
          <ul>
            <S.Patient arrive={false}></S.Patient>
            <S.Patient arrive={false}></S.Patient>
            <S.Patient arrive={false}></S.Patient>
            <S.Patient arrive={false}></S.Patient>
            <S.Patient arrive={false}></S.Patient>
            <S.Patient arrive={false}></S.Patient>
            <S.Patient arrive={false}></S.Patient>
            <S.Patient arrive={false}></S.Patient>
            <S.Patient arrive={false}></S.Patient>
            <S.Patient arrive={false}></S.Patient>
          </ul>
        )}
      </S.RoomContent>
    </S.PatientList>
  );
};

export default RoomListWaitingPatientList;
