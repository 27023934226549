import * as Style from '../style/ExaminationPatientListWaitingStyle';
import { parseFontString } from '@libs/factory';
import { maskingName } from '@libs/monitor';
import { ExaminationPatientItemTypes } from '@typedef/components/ExaminationPatientWaiting/examination.patient.waiting.types';
import {
  LoadFontConfigResponseDataType,
  LoadVisibleConfigResponseDataType,
} from '@typedef/routes/viewer.navigation.types';

type Props = {
  font: LoadFontConfigResponseDataType;
  visible: LoadVisibleConfigResponseDataType;
  data: ExaminationPatientItemTypes;
  index: number;
};
export const ExaminationPatient = ({ font, visible, data, index }: Props) => (
  <Style.Patinet key={index}>
    <div>
      <Style.CustomPTag
        family={parseFontString(font.fpatientName).family}
        fz={parseFontString(font.fpatientName).size}
        fw={parseFontString(font.fpatientName).bold}
        color={parseFontString(font.fpatientName).color}
      >
        {data.cnt}
      </Style.CustomPTag>
    </div>
    <div className='wide'>
      <Style.CustomPTag
        family={parseFontString(font.fpatientName).family}
        fz={
          data.hngnm?.includes('부재')
            ? parseFontString(font.fpatientName).size - 10
            : parseFontString(font.fpatientName).size
        }
        fw={parseFontString(font.fpatientName).bold}
        color={parseFontString(font.fpatientName).color}
      >
        {maskingName(data.hngnm, {
          vmasking: visible.vmasking,
          position: visible.position,
          maskMark: visible.maskMark,
        })}
      </Style.CustomPTag>
    </div>
  </Style.Patinet>
);
