import styled from 'styled-components';

const MultiRoomItemStyle = styled.div`
  .card {
    max-width: 100%;
    height: 90vh;
    overflow-y: hidden;
    border-radius: 15px 15px 0 0;
    box-shadow: 2px 3px 8px 1px gray;
    margin-bottom: 0.35em;
    border: 1px solid gray;
    .card-header {
      padding: 0;
      display: flex;
      align-items: center;
      border-radius: 15px 15px 0 0;
      background-color: white;
      .ampm-select,
      .room-list-select {
        height: 3vh;
        margin-left: 0.25em;
        padding: 0 2em 0 0.5em;
        border-radius: 15px;
        font-size: 0.9rem;
        font-weight: bold;
        option {
          font-size: 0.8rem;
        }
      }
      .ampm-select {
        width: 13%;
      }
      .room-list-select {
        width: 22%;
      }
      .doctor-name,
      .patient-length {
        font-size: 1rem;
        margin: 0 auto;
        font-weight: bold;
        span {
          color: #2eb85c;
        }
      }
      .refresh {
        margin: auto 0;
        display: flex;
        align-items: center;
        height: 100%;
        .btn {
          padding: 0.3em;
          border-radius: 15px;
          display: flex;
          align-items: center;
          justify-content: center;
          svg {
            font-size: 0.9rem;
            vertical-align: middle;
          }
        }
      }
      .remove {
        border-radius: 15px;
        display: flex;
        align-items: center;
        margin: auto 0;
        height: 100%;
        svg {
          font-size: 1.5rem;
          vertical-align: middle;
        }
      }
    }
    .card-body {
      overflow-y: hidden;
      padding: 0;
      .drag-table-long {
        border: 1px solid gray;
        overflow-y: auto;
        height: 44vh;
      }
      .drag-table-shot {
        border: 1px solid gray;
        overflow-y: auto;
        height: 14vh;
      }
      .drop-table {
        user-select: none;
        overflow-y: auto;
        height: 100%;
        font-family: 'Gothic A1';
        .table {
          margin-bottom: 0;
          border-collapse: collapse;
          tr {
            white-space: nowrap;
          }
          thead {
            position: sticky;
            top: 0;
            tr {
              background-color: #636f83;
              color: #fff;
              text-align: center;
              th {
                border: 1px solid #fff;
                border-collapse: collapse;
                padding: 0.25em 0;
                line-height: 1.5;
                color: #fff;
                font-size: 0.8rem;
                font-weight: bold;
              }
            }
          }
          tbody {
            tr {
              cursor: pointer;
              width: 100%;
              td {
                font-family: 'SCDream';
                padding: 0;
                vertical-align: middle;
                font-size: 0.9rem;
                font-weight: bold;
                border: 1px solid lightgray;
                text-align: center;
                color: #000;
                letter-spacing: 1px;
                overflow: hidden;
              }
              &:hover {
                background-color: lightgray;
              }
            }
          }
        }
      }
    }
  }
  @media (max-width: 1280px) {
    .card {
      .card-header {
        .ampm-select,
        .room-list-select {
          font-size: 0.8rem;
          padding-right: 2.2em;
          height: 2.5vh;
          option {
            font-size: 0.7rem;
          }
        }
        .ampm-select {
          width: 18%;
        }
        .room-list-select {
          width: 22%;
        }
        .doctor-name,
        .patient-length {
          font-size: 0.8rem;
        }
        .refresh {
          .btn {
            svg {
              font-size: 0.9rem;
            }
          }
        }
        .remove {
          padding: 0 0.25em;
        }
      }
      .card-body {
        .drag-table-shot {
          height: 14.3vh;
        }
        .drop-table {
          .table {
            thead {
              tr {
                th {
                  font-size: 0.7rem;
                }
              }
            }
            tbody {
              tr {
                td {
                  font-size: 0.8rem;
                }
              }
            }
          }
        }
      }
    }
  }
`;

export default MultiRoomItemStyle;
