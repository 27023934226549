import useGNB from '@hooks/store/useGNB';
import useLogined from '@hooks/store/useLogined';
import { alertMessage } from '@libs/alert';
import { apiRoute, requestSecureGet, requestSecurePut } from '@libs/api';
import {
  RoomListBoardRoomListItemTypes,
  RoomListInfoDataItemTypes,
  RoomListItemTypes,
  RoomListResponseItemTypes,
} from '@typedef/components/Admin/BoardSetting/components/RoomList/admin.board.setting.type.room.list.types';
import { useCallback, useEffect, useState } from 'react';
import RoomListSetting from '../../components/RoomList/RoomListSetting';

type Props = {
  boardId: number;
  setReload: React.Dispatch<React.SetStateAction<number>>;
};

const RoomListSettingContainer = ({ boardId, setReload }: Props) => {
  const { token } = useLogined();

  const { selected } = useGNB();

  // 진료실 리스트
  const [roomList, setRoomList] = useState<RoomListItemTypes[]>([]);

  const [boardRoomList, setBoardRoomList] = useState<
    RoomListBoardRoomListItemTypes[]
  >([]);

  const [infoData, setInfoData] = useState<RoomListInfoDataItemTypes>({
    adminName: '',
    bname: '',
    bnameEng: '',
    boardCode: '',
    broomNumber: '',
    roomList: '',
    btypeName: '',
    bcentName: '',
    subtitle: '',
    id: 0,
    ip: '',
    mac: '',
    dscyn: '',
    rosette: '',
    topBoardIdx: '',
  });

  // 멀티진료실 추가
  const onRoomListAdd = useCallback(() => {
    setRoomList([
      ...roomList,
      {
        id: roomList.length + 2,
        roomId: 0,
        broomNumber: null,
        roomCode: null,
        doctorKor: null,
      },
    ]);
  }, [roomList]);

  // 멀티진료실 삭제
  const onRoomListRemove = useCallback(
    (id: number) => {
      setRoomList(roomList.filter((data) => data.id !== id));
    },
    [roomList],
  );

  // 멀티진료실 적용
  const onRoomListApply = useCallback(async () => {
    const roomNumberList = roomList.map((room) => {
      return room.broomNumber ?? '';
    });

    const roomCodeList = roomList.map((room) => {
      return room.roomId ?? '0';
    });

    if (!token) return;

    const { config } = await requestSecurePut<string>(
      apiRoute.admin.boardSet.info + boardId,
      {},
      {
        ...infoData[0],
        id: boardId,
        broomNumber: roomNumberList.join(),
        roomList: roomCodeList.join(),
      },
      token,
    );

    if (config.status === 200) {
      loadRoomList();

      loadBoardRoomList();

      loadInfoData();

      setReload((prev) => prev + 1);
    }
  }, [infoData, boardId, token, roomList]);

  // 룸번호 변경값 저장
  const onRoomListNumberChange = useCallback(
    (id: number, value: string) => {
      setRoomList(
        roomList.map((room) => {
          if (room.id === id) {
            return {
              ...room,
              broomNumber: value,
            };
          } else {
            return room;
          }
        }),
      );
    },
    [roomList],
  );

  // 룸리스트 코드 변경값 저장
  const onRoomListCodeChange = useCallback(
    (roomId: number, roomCode: string) => {
      boardRoomList.map((boardRoom) => {
        if (roomCode === '') {
          setRoomList(
            roomList.map((room) => {
              if (room.id === roomId) {
                return {
                  ...room,
                  roomId: 0,
                  roomCode: '',
                  doctorKor: '',
                };
              } else {
                return room;
              }
            }),
          );
        } else if (boardRoom.roomCode === roomCode) {
          setRoomList(
            roomList.map((room) => {
              if (room.id === roomId) {
                return {
                  ...room,
                  roomId: boardRoom.id,
                  roomCode: boardRoom.roomCode,
                  doctorKor: boardRoom.doctorKor,
                };
              } else {
                return room;
              }
            }),
          );
        }
      });
    },
    [boardRoomList, roomList],
  );

  // 진료실 정보불러오기
  const loadRoomList = useCallback(async () => {
    if (!token) return;

    const { data, config } = await requestSecureGet<
      RoomListResponseItemTypes[]
    >(apiRoute.admin.boardSet.getRoomList + boardId, {}, token);

    if (config.status === 200) {
      setRoomList([]);
      data.map((item, index) => {
        setRoomList((prev) => {
          const clone = [...prev];

          clone.push({
            id: index + 1,
            ...item,
          });

          return clone;
        });
      });
    }
  }, [boardId, token]);

  // 전광판에 할당된 진료실 불러오기
  const loadBoardRoomList = useCallback(async () => {
    if (!token) return;

    const { data, config } = await requestSecureGet<
      RoomListBoardRoomListItemTypes[]
    >(apiRoute.admin.clinic.getClinics, {}, token);

    setBoardRoomList([]);

    if (config.status === 200) {
      setBoardRoomList(data);
    }
  }, [boardId, token]);

  // 전광판 정보 불러오기
  const loadInfoData = useCallback(async () => {
    if (!token) return;

    const { data, config } = await requestSecureGet<RoomListInfoDataItemTypes>(
      apiRoute.admin.boardSet.info + boardId,
      {},
      token,
    );

    if (config.status === 200) {
      setInfoData(data);
    }
  }, [boardId, token]);

  useEffect(() => {
    loadRoomList();

    loadBoardRoomList();

    loadInfoData();
  }, [boardId, token, selected]);

  return (
    <RoomListSetting
      roomList={roomList}
      boardRoomList={boardRoomList}
      onRoomListAdd={onRoomListAdd}
      onRoomListRemove={onRoomListRemove}
      onRoomListApply={onRoomListApply}
      onRoomListNumberChange={onRoomListNumberChange}
      onRoomListCodeChange={onRoomListCodeChange}
    />
  );
};

export default RoomListSettingContainer;
