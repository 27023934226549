import images from '@assets/images';
import { parseFontString } from '@libs/factory';
import { maskingName, timeAddColons } from '@libs/monitor';
import {
  OutLabPatientItemTypes,
  OutLabRoomItemTypes,
} from '@typedef/components/OutLabPatient/outlabpatient.waiting.types';
import {
  LoadFontConfigResponseDataType,
  LoadVisibleConfigResponseDataType,
} from '@typedef/routes/viewer.navigation.types';
import React from 'react';
import * as S from '../style/OutLabLargePatientListWaitingStyle';
import { apiRoute } from '@libs/api';

type Props = {
  broomNumber: string | null;
  room: OutLabRoomItemTypes | null;
  font: LoadFontConfigResponseDataType;
  visible: LoadVisibleConfigResponseDataType;
  subtitle: string | null;
  currentReservePatient: OutLabPatientItemTypes[];
};
const OutLabLargePatientListWaiting = ({
  broomNumber,
  room,
  font,
  visible,
  subtitle,
  currentReservePatient,
}: Props) => {
  return (
    <S.OutLabPatientListWaitingStyle>
      <S.Header>
        <section className='doctor'>
          <div className='imageBox'>
            {visible.vdoctorPhoto && room?.doctorId ? (
              <img
                src={`${apiRoute.viewer.loadDoctorImage + room?.doctorId}`}
                alt='의사사진'
                onError={(event: React.SyntheticEvent<HTMLImageElement, Event>) => {
                  event.currentTarget.src = images.doctorPlaceholder;
                }}
              />
            ) : (
              <img src={images.doctorPlaceholder} alt='의사사진' />
            )}
          </div>
          <div className='infoBox'>
            <span>진료의사</span>
            <S.CustomPTag
              family={parseFontString(font.fdoctorKor).family}
              fz={parseFontString(font.fdoctorKor).size}
              fw={parseFontString(font.fdoctorKor).bold}
              color={parseFontString(font.fdoctorKor).color}
            >
              {room?.doctorKor ?? ''}
            </S.CustomPTag>
            <S.CustomPTag
              family={parseFontString(font.fdoctorEng).family}
              fz={parseFontString(font.fdoctorEng).size}
              fw={parseFontString(font.fdoctorEng).bold}
              color={parseFontString(font.fdoctorEng).color}
            >
              {room?.doctorEng}
            </S.CustomPTag>
          </div>
        </section>
        <section className='roomNum'>
          <S.CustomPTag
            family={parseFontString(font.froomNumber).family}
            fz={parseFontString(font.froomNumber).size}
            fw={parseFontString(font.froomNumber).bold}
            color={parseFontString(font.froomNumber).color}
          >
            {broomNumber} {subtitle}
          </S.CustomPTag>
          <span>진료실</span>
        </section>
      </S.Header>
      <S.Patients>
        {currentReservePatient.map((patient, index) => (
          <S.Patient
            key={patient?.pid + '-' + index}
            arrive={patient.arrflag === '1' ? true : false}
          >
            <div>
              <S.CustomPTag
                family={parseFontString(font.fpatientName).family}
                fz={parseFontString(font.fpatientName).size}
                fw={parseFontString(font.fpatientName).bold}
                color={parseFontString(font.fpatientName).color}
              >
                {maskingName(patient?.hngnm, {
                  vmasking: visible.vmasking,
                  position: visible.position,
                  maskMark: visible.maskMark,
                })?.slice(0, 5)}
              </S.CustomPTag>
            </div>
            <div>
              <S.CustomPTag
                family={parseFontString(font.fpatientName).family}
                fz={parseFontString(font.fpatientName).size}
                fw={parseFontString(font.fpatientName).bold}
                color={parseFontString(font.fpatientName).color}
              >
                {patient?.arrflag === '0' ? timeAddColons(patient?.ordtm) : patient?.elbulbodstatnm}
              </S.CustomPTag>
            </div>
          </S.Patient>
        ))}
      </S.Patients>
    </S.OutLabPatientListWaitingStyle>
  );
};

export default OutLabLargePatientListWaiting;
