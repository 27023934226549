import images from '@assets/images';
import SubTitleContainer from '@components/SubTitle/containers/SubTitleContainer';
import { parseFontString } from '@libs/factory';
import {
  LoadBasicConfigResponseDataType,
  LoadFontConfigResponseDataType,
  LoadNoticeConfigResponseDataType,
  LoadVideoConfigItemTypes,
} from '@typedef/routes/viewer.navigation.types';
import React from 'react';
import OutLabPatientListWaitingContainer from './containers/OutLabLargePatientListWaitingContainer';
import * as S from './style/OutLabLargePatientWaitingStyle';

type Props = {
  basic: LoadBasicConfigResponseDataType;
  font: LoadFontConfigResponseDataType;
  video: LoadVideoConfigItemTypes[] | null;
  notice: LoadNoticeConfigResponseDataType;
  videoIndex: number;
  setVideoIndex: React.Dispatch<React.SetStateAction<number>>;
  roomList: string[];
  roomNums: string[];
};

const OutLabLargePatientWaiting = ({
  basic,
  font,
  video,
  notice,
  videoIndex,
  setVideoIndex,
  roomList,
  roomNums,
}: Props) => {
  return (
    <S.OutLabPatientWaitingStyle>
      <S.Header>
        <section className='logoBox'>
          <img src={images.jsolViewerLogo} alt='logo' />
          <div className='triangle' />
        </section>
        <section className='titleBox'>
          <p className='subtitle'>
            &nbsp;지역과 함께한 백년, 세계로 나아갈 천년
            <br />
            환자중심의 병원으로 거듭나겠습니다.
          </p>
          <S.CustomPTag
            family={parseFontString(font.froomKor).family}
            fz={parseFontString(font.froomKor).size}
            fw={parseFontString(font.froomKor).bold}
            color={parseFontString(font.froomKor).color}
          >
            {basic?.bname} {/* 진료실이름 */}
          </S.CustomPTag>
        </section>
      </S.Header>
      <S.Main>
        {basic.btypeName === '영상/2열 (대대합)' ? (
          <S.Content>
            <S.VideoNotice bgc={notice.display ? '#fff' : '#000'}>
              {notice.display ? (
                <S.NoticePre
                  family={parseFontString(font.fnotice).family}
                  fz={parseFontString(font.fnotice).size}
                  fw={parseFontString(font.fnotice).bold}
                  color={parseFontString(font.fnotice).color}
                  align={notice.align ?? 'left'}
                  valign={notice.valign ?? 'top'}
                >
                  {notice?.contents}
                </S.NoticePre>
              ) : (
                <S.Iframe
                  src='https://www.youtube.com/embed/Ngzr42mz57Y?autoplay=1&mute=1&showinfo=0&modestbranding=0&controls=0&loop=1&platlist=Ngzr42mz57Y'
                  title='promotion'
                  allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share'
                  allowFullScreen
                />
                // <S.Video
                //   id='video'
                //   autoPlay
                //   muted
                //   loop={video ? video.length === 1 : false}
                //   src={video ? video[videoIndex]?.path : ''}
                //   onEnded={() => {
                //     if (videoIndex === (video ? video.length : 0) - 1) {
                //       setVideoIndex(0);
                //     } else {
                //       setVideoIndex(videoIndex + 1);
                //     }
                //   }}
              )}
            </S.VideoNotice>
            <S.List width={27}>
              <OutLabPatientListWaitingContainer
                roomIdx={JSON.stringify(roomList[0]) === '{}' ? '' : roomList[0]}
                roomNum={JSON.stringify(roomNums[0]) === '{}' ? '' : roomNums[0]}
              />
            </S.List>
            <S.List width={27}>
              <OutLabPatientListWaitingContainer
                roomIdx={JSON.stringify(roomList[1]) === '{}' ? '' : roomList[1]}
                roomNum={JSON.stringify(roomNums[1]) === '{}' ? '' : roomNums[1]}
              />
            </S.List>
          </S.Content>
        ) : null}
        {basic.btypeName === '2열/영상 (대대합)' ? (
          <S.Content>
            <S.List width={27}>
              <OutLabPatientListWaitingContainer
                roomIdx={JSON.stringify(roomList[0]) === '{}' ? '' : roomList[0]}
                roomNum={JSON.stringify(roomNums[0]) === '{}' ? '' : roomNums[0]}
              />
            </S.List>
            <S.List width={27}>
              <OutLabPatientListWaitingContainer
                roomIdx={JSON.stringify(roomList[1]) === '{}' ? '' : roomList[1]}
                roomNum={JSON.stringify(roomNums[1]) === '{}' ? '' : roomNums[1]}
              />
            </S.List>
            <S.VideoNotice bgc={notice.display ? '#fff' : '#000'}>
              {notice.display ? (
                <S.NoticePre
                  family={parseFontString(font.fnotice).family}
                  fz={parseFontString(font.fnotice).size}
                  fw={parseFontString(font.fnotice).bold}
                  color={parseFontString(font.fnotice).color}
                  align={notice.align ?? 'left'}
                  valign={notice.valign ?? 'top'}
                >
                  {notice?.contents}
                </S.NoticePre>
              ) : (
                <S.Iframe
                  src='https://www.youtube.com/embed/Ngzr42mz57Y?autoplay=1&mute=1&showinfo=0&modestbranding=0&controls=0&loop=1&platlist=Ngzr42mz57Y'
                  title='promotion'
                  allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share'
                  allowFullScreen
                />
                // <S.VideoNotice
                //   id='video'
                //   autoPlay
                //   muted
                //   loop={video ? video.length === 1 : false}
                //   src={video ? video[videoIndex]?.path : ''}
                //   onEnded={() => {
                //     if (videoIndex === (video ? video.length : 0) - 1) {
                //       setVideoIndex(0);
                //     } else {
                //       setVideoIndex(videoIndex + 1);
                //     }
                //   }}
              )}
            </S.VideoNotice>
          </S.Content>
        ) : null}
        {basic.btypeName === '1열/영상/1열 (대대합)' ? (
          <S.Content>
            <S.List width={27}>
              <OutLabPatientListWaitingContainer
                roomIdx={JSON.stringify(roomList[0]) === '{}' ? '' : roomList[0]}
                roomNum={JSON.stringify(roomNums[0]) === '{}' ? '' : roomNums[0]}
              />
            </S.List>
            <S.VideoNotice bgc={notice.display ? '#fff' : '#000'}>
              {notice.display ? (
                <S.NoticePre
                  family={parseFontString(font.fnotice).family}
                  fz={parseFontString(font.fnotice).size}
                  fw={parseFontString(font.fnotice).bold}
                  color={parseFontString(font.fnotice).color}
                  align={notice.align ?? 'left'}
                  valign={notice.valign ?? 'top'}
                >
                  {notice?.contents}
                </S.NoticePre>
              ) : (
                <S.Iframe
                  src='https://www.youtube.com/embed/Ngzr42mz57Y?autoplay=1&mute=1&showinfo=0&modestbranding=0&controls=0&loop=1&platlist=Ngzr42mz57Y'
                  title='promotion'
                  allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share'
                  allowFullScreen
                />
                // <S.Video
                //   id='video'
                //   autoPlay
                //   muted
                //   loop={video ? video.length === 1 : false}
                //   src={video ? video[videoIndex]?.path : '/assets/videos/pormotion.mp4'}
                //   onEnded={() => {
                //     if (videoIndex === (video ? video.length : 0) - 1) {
                //       setVideoIndex(0);
                //     } else {
                //       setVideoIndex(videoIndex + 1);
                //     }
                //   }}
              )}
            </S.VideoNotice>
            <S.List width={27}>
              <OutLabPatientListWaitingContainer
                roomIdx={JSON.stringify(roomList[1]) === '{}' ? '' : roomList[1]}
                roomNum={JSON.stringify(roomNums[1]) === '{}' ? '' : roomNums[1]}
              />
            </S.List>
          </S.Content>
        ) : null}
        <SubTitleContainer height='90vh' />
      </S.Main>
    </S.OutLabPatientWaitingStyle>
  );
};

export default OutLabLargePatientWaiting;
