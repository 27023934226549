import { CommonActionTypes } from '@store/actions';
import { STORE_INIT_ACTION_TYPES } from '@store/modules/actionTypes';
import { LoginRepsonseTypes } from '@typedef/components/Login/login.types';
import { ProfileActionTypes } from './actions';
import { UPDATE_PROFILE_ACTION_TYPES } from './modules/actionTypes';

export type ProfileStateTypes = {
  profile: LoginRepsonseTypes | null;
};

const init: ProfileStateTypes = {
  profile: null,
};

const LOGIN_RESPONSE_SESSION_KEY = '@loginResponse';

const getLoginResponse = () => {
  const sessionValue = sessionStorage.getItem(LOGIN_RESPONSE_SESSION_KEY);

  if (!sessionValue) {
    return null;
  }

  return JSON.parse(sessionValue) as LoginRepsonseTypes;
};

const setLoginResponse = (loginResponse: LoginRepsonseTypes) => {
  sessionStorage.setItem(
    LOGIN_RESPONSE_SESSION_KEY,
    JSON.stringify(loginResponse),
  );
};

const profileReducer = (
  prev: ProfileStateTypes = init,
  { type, payload }: ProfileActionTypes | CommonActionTypes,
): ProfileStateTypes => {
  switch (type) {
    case UPDATE_PROFILE_ACTION_TYPES: {
      setLoginResponse(payload);

      return {
        profile: payload,
      };
    }

    case STORE_INIT_ACTION_TYPES:
      return {
        profile: getLoginResponse(),
      };

    default:
      return prev;
  }
};

export default profileReducer;
