import RoomListWaitingPatientList from '../components/RoomListWaitingPatientList';
import { useState, useEffect, useCallback, useMemo } from 'react';
import {
  LoadFontConfigResponseDataType,
  LoadVisibleConfigResponseDataType,
} from '@typedef/routes/viewer.navigation.types';
import { apiRoute, requestGet } from '@libs/api';
import { RoomListWaitingPatientTypes } from '@typedef/components/RoomListWaiting/room.list.waiting.patient.types';
import { getCurrentList } from '@libs/factory';

type Props = {
  font: LoadFontConfigResponseDataType;
  visible: LoadVisibleConfigResponseDataType;
  roomKey: string;
  broomNumber: string;
};

const LOAD_PATIENT_LIST_INTERVAL = 5000;

const PAGE_SIZE = 10;

const RoomListWaitingPatientListContainer = ({
  font,
  visible,
  roomKey,
  broomNumber,
}: Props) => {
  // 환자 리스트
  const [reservePatient, setReservePatient] = useState<
    RoomListWaitingPatientTypes[]
  >([]);
  // 의사 정보
  const [doctor, setDoctor] = useState<{
    doctorKor: string;
    doctorEng?: string;
  }>({
    doctorKor: '',
    doctorEng: '',
  });

  const [intervalCounter, setIntervalCounter] = useState<number>(0);

  const currentReservePatient = useMemo(() => {
    return getCurrentList(reservePatient ?? [], PAGE_SIZE, intervalCounter);
  }, [reservePatient, intervalCounter]);

  // 환자 리스트 조회
  const getPatientData = useCallback(async () => {
    const { config, data } = await requestGet<RoomListWaitingPatientTypes>(
      apiRoute.viewer.outPatient.loadWaiterList + roomKey,
      {},
    );
    if (config.status === 200) {
      if (Object.keys(data).length) {
        setReservePatient(data[roomKey] ?? []);
      } else {
        setReservePatient([]);
      }
    } else {
      setReservePatient([]);
    }
  }, [roomKey]);

  // 의사 정보 조회 (영어명 키 자체가 없음..! )
  const getDoctorData = useCallback(async () => {
    const { config, data } = await requestGet<{
      doctorKor: string;
    }>(apiRoute.admin.clinic.getClinic + roomKey, {});

    if (config.status >= 200 && config.status < 400) {
      if (Object.keys(data).length) {
        setDoctor({ doctorKor: data.doctorKor });
      } else {
        setDoctor({ doctorKor: '' });
      }
    } else {
      setDoctor({ doctorKor: '' });
    }
  }, [roomKey]);

  useEffect(() => {
    getPatientData();

    getDoctorData();
  }, [roomKey, getPatientData, getDoctorData, intervalCounter]);

  useEffect(() => {
    const interval = setInterval(
      () => setIntervalCounter((prev) => prev + 1),
      LOAD_PATIENT_LIST_INTERVAL,
    );

    return () => {
      clearInterval(interval);
    };
  }, []);

  return (
    <RoomListWaitingPatientList
      font={font}
      visible={visible}
      broomNumber={broomNumber}
      patients={currentReservePatient}
      doctor={doctor}
    />
  );
};

export default RoomListWaitingPatientListContainer;
