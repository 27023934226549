import Clinic from '../Clinic';
import React, { useState, useEffect, useCallback, useMemo } from 'react';
import { apiRoute, requestSecureGet, requestSecurePut } from '@libs/api';
import {
  AdminClinicBoardsTypes,
  AdminClinicModalItemTypes,
  AdminClinicTypes,
} from '@typedef/components/Admin/Clinic/admin.clinic.types';
import useLogined from '@hooks/store/useLogined';
import useProfile from '@hooks/store/useProfile';
import { TableColumnTypes } from '@typedef/components/Common/CTable/table.column.types';
import ClinicUpdateContainer from './ClinicUpdateContainer';
import ClinicDeleteContainer from './ClinicDeleteContainer';
import CTableButton from '@components/common/CTable/components/CTableButton';
import { AdminMyBoardType } from '@typedef/components/Admin/Clinic/admin.myboard.types';
import useGNB from '@hooks/store/useGNB';
import ClinicAddContainer from './CliniAddContainer';

// 테이블 헤더
const columns: TableColumnTypes[] = [
  {
    header: '진료실명',
    field: 'roomCode',
    width: '10%',
  },
  {
    header: '진료과',
    field: 'deptKor',
    width: '10%',
  },
  {
    header: '진료과 영문명',
    field: 'deptEng',
    width: '10%',
  },
  {
    header: '센터명',
    field: 'centerKor',
    width: '10%',
  },
  // {
  //   header: '센터 영문명',
  //   field: 'centerEng',
  //   width: '10%',
  // },
  // {
  //   header: "커스텀 센터",
  //   field: "customCenter",
  //   width: "10%",
  // },
  {
    header: '전광판 번호',
    field: 'boardCode',
    width: '10%',
  },
  {
    header: '담당의사명',
    field: 'doctorKor',
    width: '10%',
  },
  {
    header: '마이전광판 여부',
    field: 'action',
    filter: false,
    width: '10%',
  },
];

const ClinicContainer = () => {
  const { myBoardReload, __updateGNBMyBoardReloadFromHooks } = useGNB();
  const { token } = useLogined();
  const { myBoard, roles } = useProfile();
  const role = useMemo(() => roles, [roles]);
  // 진료실 관리 데이터 재조회
  const [reload, setReload] = useState<number>(0);
  // Switch : true: 전체전광판, false: 마이전광판
  const [isAll, setIsAll] = useState<boolean>(true);
  // 전체진료실
  const [allClinic, setAllClinic] = useState<AdminClinicTypes[] | null>(null);
  // 마이진료실 (data.flag === true)
  const [filteredMyClinic, setFilteredMyClinic] = useState<AdminClinicTypes[] | null>(null);
  // 체크박스 체크 된 값 담는 배열
  const [selectedRows, setSelectedRows] = useState<AdminClinicTypes[]>([]);
  // 몇 개의 데이터를 테이블에 보여줄지
  const [itemsPerPage, setItemsPerPage] = useState<number>(15);
  // 모달 (등록, 수정, 삭제, null)
  const [currentModal, setCurrentModal] = useState<AdminClinicModalItemTypes>(null);
  const boardId = useMemo(() => myBoard?.id, [myBoard]);

  const modal = {
    insert: (
      <ClinicAddContainer setCurrentModal={setCurrentModal} reload={reload} setReload={setReload} />
    ),
    update: (
      <ClinicUpdateContainer
        idx={selectedRows.map((row) => +row.id)[0]}
        setCurrentModal={setCurrentModal}
        reload={reload}
        setReload={setReload}
      />
    ),
    delete: (
      <ClinicDeleteContainer
        idxes={selectedRows.map((row) => +row.id)}
        setCurrentModal={setCurrentModal}
        reload={reload}
        setReload={setReload}
      />
    ),
  };

  // 마이진료실(false) <--> 전체진료실(true)
  const onChangeTab = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    setIsAll(e.target.checked);
  }, []);

  const loadMyBoards = useCallback(async () => {
    if (!token) return;

    const { data, config } = await requestSecureGet<AdminMyBoardType>(
      apiRoute.admin.board.getMyBoards + myBoard?.id,
      {},
      token,
    );

    if (config.status === 200) {
      return data;
    }
  }, [myBoard]);

  // 마이진료실 추가
  const addMyClinic = useCallback(
    async (idx: string) => {
      const myBoards = await loadMyBoards();

      const putData = {
        id: boardId,
        roomList: myBoards?.roomList ? myBoards.roomList.concat(',', idx) : idx,
      };

      if (!token) return;

      const { config } = await requestSecurePut(
        apiRoute.admin.board.putMyBoard,
        {},
        putData,
        token,
      );
      if (config.status === 200) {
        setReload(reload + 1);
        __updateGNBMyBoardReloadFromHooks(myBoardReload + 1);
      }
    },
    [boardId, token, reload, myBoardReload],
  );

  // 마이진료실 삭제
  const removeMyClinic = useCallback(
    async (idx: string) => {
      const myBoards = await loadMyBoards();

      const split = myBoards?.roomList.split(',');

      const filtered = split?.filter((data) => data !== `${idx}`);

      if (!token) return;

      const { config } = await requestSecurePut(
        apiRoute.admin.board.putMyBoard,
        {},
        {
          id: boardId,
          roomList: filtered?.toString(),
        },
        token,
      );
      if (config.status === 200) {
        setReload(reload + 1);
        __updateGNBMyBoardReloadFromHooks(myBoardReload + 1);
      }
    },
    [boardId, token, reload, myBoardReload],
  );

  // 추가, 수정, 삭제 버튼 클릭
  const onClickModalBtn = useCallback(
    (type: AdminClinicModalItemTypes) => {
      if (type === 'update') {
        if (selectedRows.length > 1) {
          alert('수정할 진료실을 하나만 선택해 주세요.');
          return;
        } else if (selectedRows.length === 0) {
          alert('수정할 진료실을 선택해 주세요.');
          return;
        }
      } else if (type === 'delete') {
        if (selectedRows.length === 0) {
          alert('삭제할 진료실을 선택해 주세요.');
          return;
        }
      }
      setCurrentModal(type);
    },
    [selectedRows],
  );

  // 새로고침 버튼 클릭
  // const onClickRoload = useCallback(() => {
  //   setReload(reload + 1);
  // }, [reload]);

  // 진료실 데이터 조회
  const getMyClinicData = useCallback(async () => {
    if (!token) return;

    const roomBoardsResponse = await requestSecureGet<AdminClinicBoardsTypes[]>(
      apiRoute.admin.clinic.getBoards,
      {},
      token,
    );

    const { config, data } = await requestSecureGet<AdminClinicTypes[]>(
      apiRoute.admin.clinic.getClinics + `/${boardId}`,
      {},
      token,
    );
    if (config.status === 200 && roomBoardsResponse.config.status === 200) {
      const tableData = data.map((data) => ({
        ...data,
        boardCode: roomBoardsResponse.data.filter((board) => board.id === data.boardIdx)[0]
          ?.boardCode,
        action: (
          <CTableButton
            buttons={
              data.flag
                ? [
                    {
                      id: data.id,
                      name: '삭제',
                      color: '#e55353',
                      func: () => removeMyClinic(data.id),
                    },
                  ]
                : [
                    {
                      id: data.id,
                      name: '추가',
                      color: '#2eb85c',
                      func: () => addMyClinic(data.id),
                    },
                  ]
            }
          />
        ),
      }));
      setAllClinic(tableData);
      const filtered = tableData.filter((data) => data.flag === true);
      setFilteredMyClinic(filtered);
    }
  }, [addMyClinic, boardId, removeMyClinic, token]);

  useEffect(() => {
    getMyClinicData();
    setSelectedRows([]);
  }, [reload]);

  return (
    <Clinic
      columns={columns}
      tableData={isAll ? allClinic : filteredMyClinic}
      isAll={isAll}
      modal={modal}
      currentModal={currentModal}
      onClickModalBtn={onClickModalBtn}
      selectedRows={selectedRows}
      setSelectedRows={setSelectedRows}
      itemsPerPage={itemsPerPage}
      setItemsPerPage={setItemsPerPage}
      onChangeTab={onChangeTab}
      role={role}
    />
  );
};

export default ClinicContainer;
