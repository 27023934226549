import {
  CButton,
  CCard,
  CCardBody,
  CCardHeader,
  CCol,
  CFormSelect,
  CRow,
  CSpinner,
} from '@coreui/react';
import {
  AMPMTypes,
  PatientListItemTypes,
} from '@typedef/components/Admin/Patient/admin.patient.types';
import { MyBoardListItemTypes } from '@typedef/components/Login/login.types';
import React, { SetStateAction } from 'react';
import { DragDropContext, DropResult } from 'react-beautiful-dnd';
import DropTableContainer from './containers/DropTableContainer';
import PatientStyle from './styles/PatientStyle';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faRedo, faSearch, faUserAlt } from '@fortawesome/free-solid-svg-icons';
import MultiRoomItemContainer from './containers/MultiRoomItemContainer';

type Props = {
  visible: boolean;
  activeRoomList: MyBoardListItemTypes[];
  myRoomList: MyBoardListItemTypes[];
  patientList: PatientListItemTypes[][];
  resvList: PatientListItemTypes[];
  absnList: PatientListItemTypes[];
  checkList: PatientListItemTypes[];
  doneList: PatientListItemTypes[];
  loadingSpinner: boolean;
  ampmList: AMPMTypes[];
  onAmPmSelected: (columnIdx: number, ampm: AMPMTypes) => void;
  searchInput: string;
  setSearchInput: React.Dispatch<SetStateAction<string>>;
  searchOption: string;
  setSearchOption: React.Dispatch<SetStateAction<string>>;
  searchList: MyBoardListItemTypes[];
  searchResultShow: boolean;
  setSearchResultShow: React.Dispatch<SetStateAction<boolean>>;
  delayBtn: boolean;
  onDragEnd: (res: DropResult) => void;
  onAllRefreshClicked: () => void;
  onRefreshClicked: (index: number) => void;
  onAddRoomClicked: () => void;
  onDeleteRoomClicked: (roomId: number, roomIdx: number) => void;
  onRoomSelected: (roomId: number, roomIdx: number) => void;
  loadPatientList: (roomId: number) => void;
};

const Patient = ({
  visible,
  activeRoomList,
  myRoomList,
  patientList,
  resvList,
  absnList,
  checkList,
  doneList,
  loadingSpinner,
  ampmList,
  onAmPmSelected,
  searchInput,
  setSearchInput,
  searchOption,
  setSearchOption,
  searchList,
  searchResultShow,
  setSearchResultShow,
  delayBtn,
  onDragEnd,
  onAllRefreshClicked,
  onRefreshClicked,
  onAddRoomClicked,
  onDeleteRoomClicked,
  onRoomSelected,
  loadPatientList,
}: Props) => {
  return (
    <PatientStyle visible={visible}>
      {loadingSpinner && (
        <div className='loading-spinner'>
          <CSpinner color='primary' />
        </div>
      )}
      <div className='patient-options'>
        <p className='total'>
          {activeRoomList.length === 1 ? `총` : null}
          {activeRoomList.length === 1 ? (
            <span>{resvList.length + absnList.length + checkList.length + doneList.length}</span>
          ) : null}
          {activeRoomList.length === 1 ? `명` : null}
        </p>

        <CFormSelect
          className='search-option-select'
          onChange={(e) => {
            setSearchOption(e.target.value);
          }}
          value={searchOption}
        >
          <option value='HNGNM'>이름</option>
          <option value='PID'>등록번호</option>
          <option value='RRGSTNO1'>주민번호</option>
        </CFormSelect>
        <div className='search-option-input'>
          <input
            type='text'
            placeholder='Search...'
            onChange={(e) => {
              setSearchInput(e.target.value);
            }}
            onBlur={() => {
              setSearchResultShow(false);
            }}
            onFocus={() => {
              setSearchResultShow(true);
            }}
          />
          <CButton>
            <FontAwesomeIcon icon={faSearch} color='black' />
          </CButton>
        </div>
        <div
          className='search-result'
          style={
            searchList.length && searchResultShow
              ? {}
              : {
                  display: 'none',
                }
          }
        >
          <ul>
            {searchInput
              ? searchList.map((roomData) => (
                  <li>
                    {roomData.roomName} ({roomData.docname})에 검색결과가 존재합니다.
                  </li>
                ))
              : null}
          </ul>
        </div>
        <div className='option-btns'>
          <CButton color='dark' onClick={onAllRefreshClicked} disabled={delayBtn ? true : false}>
            <FontAwesomeIcon icon={faRedo} color='white' />
          </CButton>
          <CButton color='success' onClick={onAddRoomClicked}>
            <FontAwesomeIcon icon={faPlus} color='white' />
          </CButton>
        </div>
      </div>

      {activeRoomList.length === 1 ? (
        <DragDropContext onDragEnd={onDragEnd}>
          <CRow className='single'>
            <CCol xl='6' sm='12' xs='12' className='single-col'>
              <CCard className='patinet-list-resv'>
                <CCardHeader>
                  <CFormSelect
                    className='room-list-select'
                    onChange={(e) => {
                      onRoomSelected(Number(e.target.value), 0);
                    }}
                    value={Number(activeRoomList[0].roomId)}
                  >
                    <option value={0}>진료실 선택</option>
                    {myRoomList.map((room) => (
                      <option value={`${room?.roomId}`} key={room?.roomId}>
                        {room?.roomName}
                      </option>
                    ))}
                  </CFormSelect>
                  <CFormSelect
                    onChange={(e) => {
                      if (e.target.value === 'am')
                        onAmPmSelected(0, {
                          label: '오전',
                          type: 'am',
                        });
                      if (e.target.value === 'pm')
                        onAmPmSelected(0, {
                          label: '오후',
                          type: 'pm',
                        });
                    }}
                    value={ampmList[0]?.type}
                  >
                    <option value='am'>오전</option>
                    <option value='pm'>오후</option>
                  </CFormSelect>
                  <p className='doctor-name'>
                    교수 :<span>&nbsp;{activeRoomList[0].docname ?? '휴진'}</span>
                  </p>
                  {/* TODO : 진료실 리스트 데이터 긁어오기 */}
                  {/* <p className='admin-name'>
                    담당자 :<span></span>
                  </p> */}
                  <p className='patient-length'>
                    <span>{resvList.length}</span>명
                  </p>
                  <CButton
                    className='refresh'
                    color='dark'
                    onClick={() => {
                      onRefreshClicked(0);
                    }}
                    disabled={delayBtn ? true : false}
                  >
                    <FontAwesomeIcon icon={faRedo} color='#fff' />
                  </CButton>
                </CCardHeader>
                <CCardBody>
                  <div className='drag-table-long'>
                    <DropTableContainer
                      roomId={activeRoomList[0]?.roomId}
                      tableId='resv-0'
                      patientList={resvList}
                      searchInput={searchInput}
                      searchOption={searchOption}
                      loadPatientList={loadPatientList}
                    />
                  </div>
                </CCardBody>
              </CCard>
            </CCol>
            <CCol xl='6' sm='12' xs='12' className='single-col'>
              <CCard className='patient-list-absn'>
                <CCardBody>
                  <p className='patient-list-title'>
                    <FontAwesomeIcon icon={faUserAlt} color='white' />
                    부재자 명단
                    <span>{absnList.length}명</span>
                  </p>
                  <div className='drag-table-shot'>
                    <DropTableContainer
                      roomId={activeRoomList[0]?.roomId}
                      tableId='absn-0'
                      patientList={absnList}
                      searchInput={searchInput}
                      searchOption={searchOption}
                      loadPatientList={loadPatientList}
                    />
                  </div>
                </CCardBody>
              </CCard>
              <CCard className='patient-list-check'>
                <CCardBody>
                  <p className='patient-list-title'>
                    <FontAwesomeIcon icon={faUserAlt} color='white' />
                    검사자 명단
                    <span>{checkList.length}명</span>
                  </p>
                  <div className='drag-table-shot'>
                    <DropTableContainer
                      roomId={activeRoomList[0]?.roomId}
                      tableId='check-0'
                      patientList={checkList}
                      searchInput={searchInput}
                      searchOption={searchOption}
                      loadPatientList={loadPatientList}
                    />
                  </div>
                </CCardBody>
              </CCard>
              <CCard className='patient-list-done'>
                <CCardBody>
                  <p className='patient-list-title'>
                    <FontAwesomeIcon icon={faUserAlt} color='white' />
                    완료자 명단
                    <span>{doneList.length}명</span>
                  </p>
                  <div className='drag-table-done'>
                    <DropTableContainer
                      roomId={activeRoomList[0]?.roomId}
                      tableId='done-0'
                      patientList={doneList}
                      searchInput={searchInput}
                      searchOption={searchOption}
                      loadPatientList={loadPatientList}
                    />
                  </div>
                </CCardBody>
              </CCard>
            </CCol>
          </CRow>
        </DragDropContext>
      ) : (
        <DragDropContext onDragEnd={onDragEnd}>
          <CRow className='multi'>
            {activeRoomList.map((room, index) => (
              <CCol
                className='multi-col'
                xs={activeRoomList.length === 2 ? '6' : '4'}
                key={room.roomId === 0 ? index : room?.roomId}
                id={`${room.roomId === 0 ? index : room?.roomId}`}
              >
                <MultiRoomItemContainer
                  room={room}
                  roomIdx={index}
                  activeRoomList={activeRoomList}
                  myRoomList={myRoomList}
                  patientList={patientList[index]}
                  ampmList={ampmList[index]}
                  searchInput={searchInput}
                  searchOption={searchOption}
                  onRoomSelected={onRoomSelected}
                  onDeleteClicked={onDeleteRoomClicked}
                  onRefreshClicked={onRefreshClicked}
                  onAmPmSelected={onAmPmSelected}
                  loadPatientList={loadPatientList}
                />
              </CCol>
            ))}
          </CRow>
        </DragDropContext>
      )}
    </PatientStyle>
  );
};

export default Patient;
