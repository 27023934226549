import useGNB from '@hooks/store/useGNB';
import useLogined from '@hooks/store/useLogined';
import AdminNavigation from '@routes/components/AdminNavigation';

const AdminNavigationContainer = () => {
  const { token } = useLogined();

  const { visible } = useGNB();

  return <AdminNavigation visible={visible} token={token} />;
};

export default AdminNavigationContainer;
