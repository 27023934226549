import useViewer from '@hooks/store/useViewer';
import { apiRoute, requestGet } from '@libs/api';
import { AdminBoardTypes } from '@typedef/components/Admin/Board/admin.board.types';
import { AdminClinicTypes } from '@typedef/components/Admin/Clinic/admin.clinic.types';
import { OutPatientItemTypes } from '@typedef/components/OutPatientWaiting/out.patient.types';
import {
  LoadBasicConfigResponseDataType,
  LoadVisibleConfigResponseDataType,
} from '@typedef/routes/viewer.navigation.types';
import { useCallback, useEffect, useRef, useState } from 'react';
import OutSinglePatientWaiting from '../components/OutSinglePatientWaiting';
import EXIF from 'exif-js';

const LOAD_CLINIC_INTERVAL = 10000;

const LOAD_PATIENT_LIST_INTERVAL = 5000;

type Props = {
  roomId: string;
};

const OutSinglePatientWaitingContainer = ({ roomId }: Props) => {
  const { font } = useViewer();

  const [imgLoad, setImgLoad] = useState<boolean>(false);

  const [waitingPatientList, setWaitingPatientList] = useState<
    OutPatientItemTypes[]
  >([]);

  const [clinicIntervalCounter, setClinicIntervalCounter] = useState<number>(0);

  const [patientIntervalCounter, setPaitnetIntervalCounter] =
    useState<number>(0);

  const [visible, setVisible] = useState<LoadVisibleConfigResponseDataType>();

  const [basic, setBasic] = useState<LoadBasicConfigResponseDataType>();

  const imgRef = useRef<HTMLImageElement>(null);

  // Todo : API 교체 필요. roomId로 ip를 조회하기위해서 너무 많은 api를 사용하여 requests 요청수가 너무 많음.

  // 전광판 기본 정보 조회
  const loadBasic = useCallback(
    async (boardIp: string) => {
      const { config, data } =
        await requestGet<LoadBasicConfigResponseDataType>(
          apiRoute.viewer.common.basicConfig + boardIp,
          {},
        );

      if (config.status === 200) {
        setBasic(data);
      } else {
        setBasic({
          rosette: null,
          bnameEng: null,
          docId: null,
          docEng: null,
          bcentName: null,
          adminName: null,
          broomNumber: null,
          bname: null,
          subtitle: null,
          btypeName: '',
          docKor: null,
          boardId: 0,
          roomList: null,
          dscyn: null,
        });
      }
    },
    [roomId],
  );

  const loadVisible = useCallback(
    async (boardId: string) => {
      const { config, data } =
        await requestGet<LoadVisibleConfigResponseDataType>(
          apiRoute.viewer.common.visibleConfig + boardId,
          {},
        );

      if (config.status === 200) {
        setVisible(data);
      } else {
        setVisible({
          vdelay: false,
          vlist: false,
          vadmin: false,
          vclock: false,
          delayTime: null,
          position: null,
          maskMark: null,
          vdoctorEng: false,
          vdoctorPhoto: false,
          vmasking: false,
          vvideo: false,
        });
      }
    },
    [roomId],
  );

  // 전광판 정보 조회 ( ip 조회용 )
  const loadBoard = useCallback(
    async (boardId: string) => {
      const { config, data } = await requestGet<AdminBoardTypes>(
        apiRoute.admin.board.getBoard + boardId,
        {},
      );

      if (config.status === 200) {
        loadBasic(data.ip);
      } else {
        loadBasic('0');
      }
    },
    [roomId],
  );

  // 진료실 정보 조회 ( boardId 조회용 )
  const loadClinic = useCallback(async () => {
    const { config, data } = await requestGet<AdminClinicTypes>(
      apiRoute.admin.clinic.getClinic + roomId,
      {},
    );

    if (config.status === 200) {
      loadVisible(data.boardIdx);
      loadBoard(data.boardIdx);
    } else {
      loadBoard('0');
      loadVisible('0');
    }
  }, [roomId]);

  // 대기환자 조회 ( 3명 )
  const loadPatient = useCallback(async () => {
    const { config, data } = await requestGet<OutPatientItemTypes[]>(
      apiRoute.viewer.outPatient.loadWaiterList + roomId,
      {},
    );

    if (config.status === 200) {
      if (Object.keys(data).length) {
        if (Number(new Date().getHours()) < 13) {
          // 오전
          const timeFilterPatient = data[roomId].filter((patient) => {
            if (parseInt(patient.ORDTM) < 1230 || patient.DISPALLTIME === 'Y') {
              return true;
            }
          });

          setWaitingPatientList([
            timeFilterPatient[0] ?? {},
            timeFilterPatient[1] ?? {},
            timeFilterPatient[2] ?? {},
          ]);
        } else {
          // 오전
          const timeFilterPatient = data[roomId].filter((patient) => {
            if (
              parseInt(patient.ORDTM) >= 1230 ||
              patient.DISPALLTIME === 'Y'
            ) {
              return true;
            }
          });

          setWaitingPatientList([
            timeFilterPatient[0] ?? {},
            timeFilterPatient[1] ?? {},
            timeFilterPatient[2] ?? {},
          ]);
        }
      } else {
        setWaitingPatientList([]);
      }
    } else {
      setWaitingPatientList([]);
    }
  }, [roomId]);

  const setOrientation = useCallback(() => {
    const { current } = imgRef;

    if (!!!current) return;

    // EXIF.getData()는 이미지가 완전히 로드되지 않으면 호출 안됨
    EXIF.getData(current.src, function () {
      let orientation = EXIF.getTag(current, 'Orientation');

      switch (orientation) {
        case 6:
          // 이미지 90도 회전 (오른쪽으로 270도 회전)
          current.style.transform = 'rotate( 270deg )';
          // current.style.width = '130%';
          break;

        case 3:
          // 이미지 180도 회전 (오른쪽으로 180도 회전)
          current.style.transform = 'rotate( 180deg )';
          // current.style.width = '130%';
          break;

        case 8:
          // 이미지 270도 회전 (오른쪽으로 90도 회전)
          current.style.transform = 'rotate( 90deg )';
          // current.style.width = '130%';
          break;
      }
    });
  }, [imgRef, roomId, basic]);

  useEffect(() => {
    if (imgLoad) {
      setOrientation();
    }
  }, [imgLoad, roomId, basic]);

  useEffect(() => {
    const patientInterval = setInterval(() => {
      setPaitnetIntervalCounter((prev) => prev + 1);
    }, LOAD_PATIENT_LIST_INTERVAL);

    const clinicInterval = setInterval(() => {
      setClinicIntervalCounter((prev) => prev + 1);
    }, LOAD_CLINIC_INTERVAL);

    return () => {
      clearInterval(clinicInterval);
      clearInterval(patientInterval);
    };
  }, []);

  useEffect(() => {
    loadClinic();
  }, [roomId, clinicIntervalCounter]);

  useEffect(() => {
    loadPatient();
  }, [roomId, patientIntervalCounter]);

  return basic && font && visible ? (
    <OutSinglePatientWaiting
      basic={basic}
      font={font}
      visible={visible}
      waitingPatientList={waitingPatientList}
      imgRef={imgRef}
      onLoadFunc={() => setImgLoad(true)}
    />
  ) : null;
};

export default OutSinglePatientWaitingContainer;
