import React from 'react';

type Props = {
  msg?: string;
};

const NotFound = ({ msg }: Props) => {
  return <div>{msg || 'Not Found'}</div>;
};

export default NotFound;
