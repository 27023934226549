import { RootState } from "./../../store/rootReducer";
import {
  updateBoardSetNoticeAction,
  updateBoardSetSubtitleAction,
  updateBoardSetDelayAction,
} from "@store/boardset/actions";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { useCallback } from "react";

export default function useBoardSet() {
  const boardSet = useSelector((root: RootState) => root.boardSetReducer);

  const dispatch = useDispatch();

  const __updateBoardSetSubtitleFromHooks = useCallback(
    (diff: number) => dispatch(updateBoardSetSubtitleAction(diff)),
    [dispatch]
  );

  const __updateBoardSetNoticeFromHooks = useCallback(
    (diff: number) => dispatch(updateBoardSetNoticeAction(diff)),
    [dispatch]
  );

  const __updateBoardSetDelayFromHooks = useCallback(
    (diff: number) => dispatch(updateBoardSetDelayAction(diff)),
    [dispatch]
  );

  return {
    ...boardSet,
    __updateBoardSetSubtitleFromHooks,
    __updateBoardSetNoticeFromHooks,
    __updateBoardSetDelayFromHooks,
  };
}
