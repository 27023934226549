import {
  CButton,
  CCard,
  CCardBody,
  CCardHeader,
  CFormInput,
} from '@coreui/react';
import { SetStateAction } from 'react';
import MaskingSettingStyle from '../../styles/Masking/MaskingSettingStyle';

type Props = {
  maskingSettingApply: () => void;
  mark: string;
  setMark: React.Dispatch<SetStateAction<string>>;
  position: number;
  setPosition: React.Dispatch<SetStateAction<number>>;
};
const MarkingSetting = ({
  maskingSettingApply,
  mark,
  setMark,
  position,
  setPosition,
}: Props) => {
  return (
    <MaskingSettingStyle>
      <CCard>
        <CCardHeader>
          <h5>마스킹 설정</h5>
          <div className='btns'>
            <CButton color='info' onClick={maskingSettingApply}>
              적용
            </CButton>
          </div>
        </CCardHeader>
        <CCardBody>
          <div className='masking-position'>
            <p>마스킹 위치</p>
            <CFormInput
              type='number'
              // delay 값이 0 이라면 Blank 출력
              value={position === 0 ? '' : position}
              onChange={(e) => {
                setPosition(Number(e.target.value));
              }}
            />
          </div>
          <div className='masking-mark'>
            <p>마스킹 문자</p>
            <CFormInput
              autoComplete='off'
              value={mark}
              onChange={(e) => {
                setMark(e.target.value);
              }}
            />
          </div>
        </CCardBody>
      </CCard>
    </MaskingSettingStyle>
  );
};

export default MarkingSetting;
