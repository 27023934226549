import * as S from './styles/AccountStyle';
import CTableContainer from '@components/common/CTable/containers/CTableContainer';
import { TableColumnTypes } from '@typedef/components/Common/CTable/table.column.types';
import { CButton, CCard, CCardBody } from '@coreui/react';

type Props = {
  columns: TableColumnTypes[];
  tableData: any;
  currentTab: string;
  onClickTab: (tab: string) => void;
  modal: {
    update: JSX.Element;
    delete: JSX.Element;
  };
  currentModal: 'update' | 'delete' | null;
  onClickButton: (type: 'update' | 'delete', id: string) => void;
};

const Account = ({
  columns,
  tableData,
  currentTab,
  onClickTab,
  modal,
  currentModal,
  onClickButton,
}: Props) => {
  return (
    <S.Main height={tableData?.length}>
      {currentModal && modal[currentModal]}
      <S.Tabs>
        {['접속기록', '계정목록'].map((tab, index) => (
          <S.Tab
            key={index}
            onClick={() => onClickTab(tab)}
            className={currentTab === tab ? 'active' : ''}
          >
            {tab}
          </S.Tab>
        ))}
      </S.Tabs>
      <CCard>
        <CCardBody>
          <S.Btns>
            {currentTab === '계정목록' && (
              <CButton type='button' onClick={() => onClickButton('update', '')}>
                추가
              </CButton>
            )}
          </S.Btns>
          <CTableContainer
            columns={columns}
            tableData={tableData}
            isCheckboxTable={false}
            itemsPerPage={tableData?.length}
            isPaginator={false}
          />
        </CCardBody>
      </CCard>
    </S.Main>
  );
};

export default Account;
