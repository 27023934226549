import { PatientListItemTypes } from '@typedef/components/Admin/Patient/admin.patient.types';
import { useCallback, useMemo, useState } from 'react';
import DropTable from '../components/DropTable';

type Props = {
  roomId: number | null;
  tableId: string;
  patientList: PatientListItemTypes[];
  searchInput: string;
  searchOption: string;

  loadPatientList: (roomId: number) => void;
};

const DropTableContainer = ({
  roomId,
  tableId,
  patientList,
  searchInput,
  searchOption,

  loadPatientList,
}: Props) => {
  const [sortOption, setSortOption] = useState<string>('DISPSEQ');

  const [sortOrderOption, setSortOrderOption] = useState<number | null>(1);

  const [canDrag, setCanDrag] = useState<boolean>(true);

  const sortPatientList = useMemo(
    () =>
      patientList?.sort((a, b) => {
        if (sortOrderOption !== null) {
          // 이름만 다르게 정렬
          if (sortOption === 'HNGNM') {
            if (sortOrderOption === 1) {
              return a.hngnm > b.hngnm ? 1 : -1;
            } else {
              return a.hngnm > b.hngnm ? -1 : 1;
            }
          } else {
            return (a[sortOption] - b[sortOption]) * (sortOrderOption ?? 0);
          }
        } else {
          return (a.dispseq - b.dispseq) * 1;
        }
      }),
    [sortOrderOption, sortOption, patientList],
  );

  // 컬럼 정렬 ( 예약, 도착시간, 등록번호, 이름, 주민(성)나이  )
  const setSortBy = useCallback(
    (sortName: string) => {
      if (sortName === sortOption && sortOrderOption === -1) {
        setSortOrderOption(null);
        setCanDrag(true);
      } else if (sortOrderOption === null) {
        setSortOrderOption(1);
        setCanDrag(false);
      } else if (sortName === sortOption) {
        setSortOrderOption(-1);
        setCanDrag(false);
      } else {
        setSortOrderOption(1);
        setCanDrag(false);
      }
      setSortOption(sortName);
    },
    [sortOption, sortOrderOption, canDrag],
  );

  // 정렬시 해당 컬럼에 정렬문자 표시 ( (⬆), (⬇) )
  const sortingArg = useCallback(
    (sortName: string, sortTarget: string, orderOption: number | null) => {
      if (sortName === 'DISPSEQ' && sortTarget === 'DISPSEQ') {
        if (orderOption === -1) {
          return '(⬆)';
        }
        return;
      }
      if (sortName === sortTarget) {
        if (orderOption === null) {
          return '';
        } else if (orderOption === -1) {
          return '(⬆)';
        } else {
          return '(⬇)';
        }
      }
    },
    [],
  );

  return (
    <DropTable
      roomId={roomId}
      tableId={tableId}
      patientList={sortPatientList}
      canDrag={canDrag}
      searchInput={searchInput}
      searchOption={searchOption}
      sortOption={sortOption}
      sortOrderOption={sortOrderOption}
      setSortBy={setSortBy}
      sortingArg={sortingArg}
      loadPatientList={loadPatientList}
    />
  );
};

export default DropTableContainer;
