import useViewer from '@hooks/store/useViewer';
import { alertMessage } from '@libs/alert';
import { apiRoute, requestPost } from '@libs/api';
import { getCurrentList } from '@libs/factory';
import { Surgery1PatientItemTypes } from '@typedef/components/SurgeryWaiting/surgery.waiting.types';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import SurgeryVerticalWaiting from '../components/SurgeryVerticalWaiting';

const ONE_PAGE_SIZE = 8;

const LOAD_PATIENT_LIST_INTERVAL = 5000;

// 수술실 타입1
const SurgeryVerticalWaitingContainer = () => {
  const { basic, font, visible } = useViewer();

  const [surgeryList, setSurgeryList] = useState<Surgery1PatientItemTypes[]>(
    [],
  );
  const [intervalCounter, setIntervalCounter] = useState(0);

  const currentSurgeryList = useMemo(() => {
    return getCurrentList(surgeryList, ONE_PAGE_SIZE, intervalCounter);
  }, [surgeryList, intervalCounter]);

  const loadSurgeryList = useCallback(async () => {
    if (!basic) {
      alertMessage('전광판 기본 정보를 불러오지 못했습니다!');
      return;
    }

    const { data, config } = await requestPost<Surgery1PatientItemTypes[]>(
      apiRoute.viewer.surgery.surgeryOne,
      {},
      {
        boardId : basic.boardId,
        dscyn: basic.dscyn,
        roseete: basic.rosette,
      },
    );

    if (config.status === 200) {
      setSurgeryList(data);
    } else {
      alertMessage('수술 환자 목록을 부르지 못했습니다.');
    }
  }, [basic]);

  useEffect(() => {
    loadSurgeryList();
  }, [basic, loadSurgeryList, intervalCounter]);

  useEffect(() => {
    const interval = setInterval(
      () => setIntervalCounter((prev) => prev + 1),
      LOAD_PATIENT_LIST_INTERVAL,
    );
    return () => {
      clearInterval(interval);
    };
  }, []);

  return visible && font ? (
    <SurgeryVerticalWaiting
      font={font}
      currentSurgeryList={currentSurgeryList}
      visible={visible}
    />
  ) : null;
};

export default SurgeryVerticalWaitingContainer;
