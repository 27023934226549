type VisibleListType = {
  vistype:
    | 'vdoctorPhoto'
    | 'vdoctorEng'
    | 'vadmin'
    | 'vclock'
    | 'vdelay'
    | 'vmasking'
    | 'vvideo'
    | 'vlist';
  content: string;
};

export const visibleList: VisibleListType[] = [
  {
    vistype: 'vdoctorPhoto',
    content: '의사 사진',
  },
  {
    vistype: 'vdoctorEng',
    content: '의사 영문명',
  },
  {
    vistype: 'vadmin',
    content: '담당자 표시 여부',
  },
  {
    vistype: 'vclock',
    content: '시계',
  },
  {
    vistype: 'vdelay',
    content: '지연 시간',
  },
  {
    vistype: 'vmasking',
    content: '환자이름 마스킹',
  },
  {
    vistype: 'vvideo',
    content: '동영상 컨텐츠',
  },
  {
    vistype: 'vlist',
    content: '예약전체 List',
  },
];
