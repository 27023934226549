import useViewer from "@hooks/store/useViewer";
import React, { useState } from "react";
import ExaminationEndoscopePatientWaiting from "../ExaminationEndoscopePatientWaiting";

const ExaminationEndoscopePatientWaitingContainer = () => {
  const { basic, font, video, notice } = useViewer();

  const [videoIndex, setVideoIndex] = useState<number>(0);

  return basic && font && notice ? (
    <ExaminationEndoscopePatientWaiting
      basic={basic}
      font={font}
      video={video}
      notice={notice}
      videoIndex={videoIndex}
      setVideoIndex={setVideoIndex}
    />
  ) : null;
};

export default React.memo(ExaminationEndoscopePatientWaitingContainer);
