import images from '@assets/images';
import { parseFontString } from '@libs/factory';
import { maskingName } from '@libs/monitor';
import { OutPatientItemTypes } from '@typedef/components/OutPatientWaiting/out.patient.types';
import {
  LoadBasicConfigResponseDataType,
  LoadFontConfigResponseDataType,
  LoadVisibleConfigResponseDataType,
} from '@typedef/routes/viewer.navigation.types';
import * as S from '../styles/OutSinglePatientWaitingStyle';

type Props = {
  basic: LoadBasicConfigResponseDataType;
  font: LoadFontConfigResponseDataType;
  visible: LoadVisibleConfigResponseDataType;
  waitingPatientList: OutPatientItemTypes[];
  imgRef: React.RefObject<HTMLImageElement>;
  onLoadFunc: () => void;
};

const OutSinglePatientWaiting = ({
  basic,
  font,
  visible,
  waitingPatientList,
  imgRef,
  onLoadFunc,
}: Props) => {
  return (
    <S.OutSinglePatientWaitingStyle>
      <S.Header>
        <section className='left'>
          <S.CustomPTag
            family={parseFontString(font.froomKor).family}
            fz={parseFontString(font.froomKor).size}
            fw={parseFontString(font.froomKor).bold}
            color={parseFontString(font.froomKor).color}
          >
            {basic?.bname} {/* 진료실이름 */}
          </S.CustomPTag>
          <S.CustomPTag
            family={parseFontString(font.froomEng).family}
            fz={parseFontString(font.froomEng).size}
            fw={parseFontString(font.froomEng).bold}
            color={parseFontString(font.froomEng).color}
          >
            {basic?.bnameEng} {/* 진료실이름(영어) */}
          </S.CustomPTag>
        </section>
        <section className='roomNum'>
          <S.CustomPTag
            family={parseFontString(font.froomNumber).family}
            fz={parseFontString(font.froomNumber).size}
            fw={parseFontString(font.froomNumber).bold}
            color={parseFontString(font.froomNumber).color}
          >
            {basic.broomNumber ? basic.broomNumber[0] : null}
          </S.CustomPTag>
          <p
            className='clinicName'
            style={{
              fontFamily: parseFontString(font.froomNumber).family,
            }}
          >
            진료실
          </p>
        </section>
      </S.Header>
      <S.Main>
        <S.Clinic>
          <section className='doctor'>
            <div className='imageBox'>
              {visible.vdoctorPhoto && basic?.docId ? (
                <img
                  src={`/api/v2/outpatient/waiters/doctor/img/${basic?.docId}`}
                  alt='의사사진'
                  onError={(
                    event: React.SyntheticEvent<HTMLImageElement, Event>,
                  ) => {
                    event.currentTarget.src = images.doctorPlaceholder;
                  }}
                  ref={imgRef}
                  onLoad={onLoadFunc}
                />
              ) : (
                <img src={images.doctorPlaceholder} alt='의사사진' />
              )}
            </div>
            <div className='infoBox'>
              <p className='doctorTitle'>진료의사</p>
              <S.CustomPTag
                family={parseFontString(font.fdoctorKor).family}
                fz={parseFontString(font.fdoctorKor).size}
                fw={parseFontString(font.fdoctorKor).bold}
                color={parseFontString(font.fdoctorKor).color}
              >
                {basic.docKor === '' ? '휴진' : basic.docKor}
              </S.CustomPTag>
              <S.CustomPTag
                family={parseFontString(font.fdoctorEng).family}
                fz={parseFontString(font.fdoctorEng).size}
                fw={parseFontString(font.fdoctorEng).bold}
                color={parseFontString(font.fdoctorEng).color}
              >
                {visible.vdoctorEng ? basic.docEng : ' '}
              </S.CustomPTag>
            </div>
          </section>
          <section className='option'>
            <div className='delayBox'>
              <span>지연시간</span>
              <p>
                {visible?.vdelay && visible.delayTime
                  ? visible.delayTime + ' 분'
                  : ' '}
              </p>
            </div>
            <div className='adminBox'>
              <div className='admin'>
                <span>담당직원</span>
                <S.CustomPTag
                  family={parseFontString(font.fadminKor).family}
                  fz={parseFontString(font.fadminKor).size}
                  fw={parseFontString(font.fadminKor).bold}
                  color={parseFontString(font.fadminKor).color}
                >
                  {visible.vadmin && basic.adminName}
                </S.CustomPTag>
              </div>
            </div>
          </section>
        </S.Clinic>
        <S.Patients>
          {waitingPatientList.length > 0
            ? waitingPatientList.map((patient, index) =>
                index < 3 ? (
                  <S.Patient
                    className={
                      patient?.arrflag === '1' ? 'selected' : 'notArrive'
                    }
                    key={patient?.pid + '-' + index}
                  >
                    <S.CustomPTag
                      family={parseFontString(font.fpatientName).family}
                      fz={parseFontString(font.fpatientName).size}
                      fw={parseFontString(font.fpatientName).bold}
                      color={parseFontString(font.fpatientName).color}
                    >
                      {maskingName(patient?.hngnm, {
                        vmasking: visible?.vmasking,
                        position: visible?.position,
                        maskMark: visible?.maskMark,
                      })?.slice(0, 5)}
                    </S.CustomPTag>
                    <p
                      className='delayDescription'
                      style={{
                        fontFamily: parseFontString(font.fpatientName).family,
                      }}
                    >
                      {index === 0 && '호명하면 들어오십시오.'}
                      {index === 1 && '다음 순서입니다.'}
                      {index > 1 && '잠시만 기다려주세요.'}
                    </p>
                  </S.Patient>
                ) : null,
              )
            : [
                '호명하면 들어오십시오.',
                '다음 순서입니다.',
                '잠시만 기다려주세요.',
              ].map((data, index) => (
                <S.Patient className='notArrive' key={index}>
                  <p className='name'></p>
                  <p className='delayDescription'>{data}</p>
                </S.Patient>
              ))}
        </S.Patients>
      </S.Main>
    </S.OutSinglePatientWaitingStyle>
  );
};

export default OutSinglePatientWaiting;
