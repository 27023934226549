import {
  UPDATE_BOARDSET_NOTICE_ACTION_TYPE,
  UPDATE_BOARDSET_SUBTITLE_ACTION_TYPE,
  UPDATE_BOARDSET_DELAY_ACTION_TYPE,
} from "./modules/actionTypes";

export const updateBoardSetSubtitleAction = (subtitleReload: number) => ({
  type: UPDATE_BOARDSET_SUBTITLE_ACTION_TYPE,
  payload: subtitleReload,
});

export const updateBoardSetNoticeAction = (noticeReload: number) => ({
  type: UPDATE_BOARDSET_NOTICE_ACTION_TYPE,
  payload: noticeReload,
});

export const updateBoardSetDelayAction = (delayReload: number) => ({
  type: UPDATE_BOARDSET_DELAY_ACTION_TYPE,
  payload: delayReload,
});

export type BoardSetActionTypes =
  | ReturnType<typeof updateBoardSetSubtitleAction>
  | ReturnType<typeof updateBoardSetNoticeAction>
  | ReturnType<typeof updateBoardSetDelayAction>;
