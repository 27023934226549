import {
  LAB_ROLE_KEY,
  OUT_ROLE_KEY,
  PMY_ROLE_KEY,
  SUR_ROLE_KEY,
  HEALTH_ROLE_KEY,
} from '@routes/config/admin.nav.confg';
import { RoleTypes } from '@typedef/common/common.types';

export type AdminBoardTypes = {
  id: number; // board id
  boardCode: string; // 전광판명
  mac: string; // pc mac address
  ip: string; // ipv4
  deptEng: string | null;
  deptId: string | null;
  deptKor: string; // 진료과
  location: string | null; // 위치
  adminName: string;
  belong: RoleTypes; // 소속
  btypeName: string; // 유형
  roomList: null | string; // null 또는 room id list
  broomNumber: string;
  bname: string;
  bnameEng: string;
  flag: boolean; // 마이전광판 여부
};

export type AdminBoardInputTypes = {
  id: number; // board id
  boardCode: string; // 전광판명
  mac: string; // pc mac address
  ip: string; // ipv4
  deptId: string | null;
  deptKor: string | null; // 진료과
  location: string | null; // 위치
  belong: RoleTypes; // 소속
  btypeName: string; // 유형
};

export type AdminBoardVariable = {
  [key: string]: string;
};

export type AdminBoardModalItemTypes =
  | 'insert'
  | 'update'
  | 'delete'
  | 'variable'
  | null;

// 전광판 타입
export const BoardTypes = [
  {
    type: 'check',
    role: LAB_ROLE_KEY,
    label: '내시경',
  },
  {
    type: 'check',
    role: LAB_ROLE_KEY,
    label: '영상/1열',
  },
  {
    type: 'check',
    role: LAB_ROLE_KEY,
    label: '1열/영상',
  },
  {
    type: 'check',
    role: LAB_ROLE_KEY,
    label: '영상/2열',
  },
  {
    type: 'check',
    role: LAB_ROLE_KEY,
    label: '2열/영상',
  },
  {
    type: 'check',
    role: LAB_ROLE_KEY,
    label: '1열/영상/1열',
  },
  // {
  //   type: "patient",
  //   role: OUT_ROLE_KEY,
  //   label: "영상/1열 (외래)",
  // },
  // {
  //   type: "patient",
  //   role: OUT_ROLE_KEY,
  //   label: "1열/영상 (외래)",
  // },
  // {
  //   type: "patient",
  //   role: OUT_ROLE_KEY,
  //   label: "영상/2열 (외래)",
  // },
  // {
  //   type: "patient",
  //   role: OUT_ROLE_KEY,
  //   label: "2열/영상 (외래)",
  // },
  // {
  //   type: "patient",
  //   role: OUT_ROLE_KEY,
  //   label: "1열/영상/1열 (외래)",
  // },
  {
    type: 'patient',
    role: OUT_ROLE_KEY,
    label: '영상/2열 (대대합)',
  },
  {
    type: 'patient',
    role: OUT_ROLE_KEY,
    label: '2열/영상 (대대합)',
  },
  {
    type: 'patient',
    role: OUT_ROLE_KEY,
    label: '1열/영상/1열 (대대합)',
  },
  {
    type: 'patient',
    role: OUT_ROLE_KEY,
    label: '열',
  },
  {
    type: 'patient',
    role: OUT_ROLE_KEY,
    label: '항암주사실',
  },
  {
    type: 'patient',
    role: OUT_ROLE_KEY,
    label: '동영상 (대대합)',
  },
  {
    type: 'patient',
    role: OUT_ROLE_KEY,
    label: '외래대기전광판',
  },
  {
    type: 'patient',
    role: OUT_ROLE_KEY,
    label: '외래4열대기전광판',
  },
  {
    type: 'surgery',
    role: SUR_ROLE_KEY,
    label: '수술실 타입1',
  },
  {
    type: 'surgery',
    role: SUR_ROLE_KEY,
    label: '수술실 타입2',
  },
  {
    type: 'pharmaacy',
    role: PMY_ROLE_KEY,
    label: '외래약국',
  },
  {
    type: 'pharmaacy',
    role: PMY_ROLE_KEY,
    label: '퇴원약국',
  },
  {
    type: 'health',
    role: HEALTH_ROLE_KEY,
    label: '외래검진',
  },
  {
    type: 'health',
    role: HEALTH_ROLE_KEY,
    label: '검사검진',
  },
  {
    type: 'health',
    role: HEALTH_ROLE_KEY,
    label: '종합현황검진',
  },
];
