import {
  CModal,
  CModalHeader,
  CButton,
  CModalBody,
  CModalFooter,
} from "@coreui/react";
// 계정관리 > 삭제 모달
import { AdminBoardModalItemTypes } from "@typedef/components/Admin/Board/admin.board.types";

type Props = {
  deleteBoard: () => Promise<void>;
  setCurrentModal: React.Dispatch<
    React.SetStateAction<AdminBoardModalItemTypes>
  >;
  ids: number[];
};

const BoardDelete = ({ deleteBoard, setCurrentModal, ids }: Props) => {
  return (
    <CModal visible={true} onClose={() => setCurrentModal(null)}>
      <CModalHeader>전광판 삭제</CModalHeader>
      <CModalBody>
        정말로 {ids.length}개의 전광판을 삭제하시겠습니까?
      </CModalBody>
      <CModalFooter>
        <CButton color="primary" onClick={deleteBoard}>
          삭제하기
        </CButton>
        <CButton color="secondary" onClick={() => setCurrentModal(null)}>
          취소
        </CButton>
      </CModalFooter>
    </CModal>
  );
};
export default BoardDelete;
