import useViewer from "@hooks/store/useViewer";
import { alertMessage } from "@libs/alert";
import { apiRoute, requestGet } from "@libs/api";
import { getCurrentList } from "@libs/factory";
import { ExaminationPatientItemTypes } from "@typedef/components/ExaminationPatientWaiting/examination.patient.waiting.types";
import { useCallback, useEffect, useMemo, useState } from "react";
import ExaminationPatientMiniWaiting from "../components/ExaminationPatientMiniWaiting";

const LOAD_PATIENT_LIST_INTERVAL = 5000;

const PAGE_SIZE = 10;

const ExaminationPatientMiniWaitingContainer = () => {
  const { basic } = useViewer();

  const [examinationPatient, setExaminationPatient] = useState<
    ExaminationPatientItemTypes[]
  >([]);

  const [examinationPatientSecond, setExaminationPatientSecond] = useState<
    ExaminationPatientItemTypes[]
  >([]);

  const [intervalCounter, setIntervalCounter] = useState<number>(0);

  const currentExaminationPatient = useMemo(() => {
    return getCurrentList(examinationPatient ?? [], PAGE_SIZE, intervalCounter);
  }, [examinationPatient, intervalCounter]);

  const currentExaminationPatientSecond = useMemo(() => {
    return getCurrentList(
      examinationPatientSecond ?? [],
      PAGE_SIZE,
      intervalCounter
    );
  }, [examinationPatientSecond, intervalCounter]);

  const loadExaminationPatientList = useCallback(async () => {
    if (!basic) {
      alertMessage("전광판 기본 정보를 불러오지 못했습니다!");
      return;
    }

    const examinationPatientList = await requestGet<
      ExaminationPatientItemTypes[]
    >(
      apiRoute.viewer.examination.loadByRooms +
        (basic.roomList ? basic?.roomList[0] : ""),
      {}
    );
    const examinationPatientSecondList = await requestGet<
      ExaminationPatientItemTypes[]
    >(
      apiRoute.viewer.examination.loadByRooms +
        (basic.roomList ? basic?.roomList[1] : ""),
      {}
    );
    if (basic.roomList) {
      if (examinationPatientList.config.status === 200) {
        if (examinationPatientList.data[basic.roomList[0]]) {
          const cntArray = examinationPatientList.data[basic.roomList[0]].map(
            (patient, index) => {
              return {
                ...patient,
                CNT: index + 1,
              };
            }
          );
          setExaminationPatient(cntArray);
        } else {
          setExaminationPatient([]);
        }
      } else {
        setExaminationPatient([]);
      }
      if (examinationPatientSecondList.config.status === 200) {
        if (examinationPatientSecondList.data[basic.roomList[1]]) {
          const cntArray = examinationPatientSecondList.data[
            basic.roomList[1]
          ].map((patient, index) => {
            return {
              ...patient,
              CNT: index + 1,
            };
          });

          setExaminationPatientSecond(cntArray);
        } else {
          setExaminationPatientSecond([]);
        }
      } else {
        setExaminationPatientSecond([]);
      }
    }
    // }
  }, [basic]);

  useEffect(() => {
    loadExaminationPatientList();
  }, [intervalCounter, basic, loadExaminationPatientList]);

  useEffect(() => {
    const interval = setInterval(() => {
      setIntervalCounter((prev) => prev + 1);
    }, LOAD_PATIENT_LIST_INTERVAL);
    return () => {
      clearInterval(interval);
    };
  }, []);

  return basic ? (
    <ExaminationPatientMiniWaiting
      basic={basic}
      currentExaminationPatient={currentExaminationPatient}
      currentExaminationPatientSecond={currentExaminationPatientSecond}
    />
  ) : null;
};

export default ExaminationPatientMiniWaitingContainer;
