import styled from 'styled-components';

const NoticeSettingStyle = styled.div`
  .card {
    height: 22vh;
    .card-body {
      padding: 0.25em;
      /* 공지사항 설정 내부 헤더 */
      .menus {
        display: flex;
        .form-check-input {
          vertical-align: middle;
        }
        label {
          margin: 0;
          cursor: pointer;
        }
        /* 표시여부  */
        .visible {
          margin-right: 1em;
          display: flex;
          align-items: center;
          input[type='checkbox'] {
            cursor: pointer;
            margin: 0;
            margin-left: 0.25em;
          }
          .form-label {
            margin-left: 0.25em;
            cursor: pointer;
          }
        }
        .textalign {
          width: 35%;
          display: flex;
          align-items: center;
          .form-check-input {
            cursor: pointer;
          }
          .form-label {
            margin-left: 0.25em;
            margin-right: 1em;
          }
        }
        .verticalalign {
          width: 35%;
          display: flex;
          align-items: center;
          .form-check-input {
            cursor: pointer;
          }
          .form-label {
            margin-left: 0.25em;
            margin-right: 1em;
          }
        }
      }
      /* 내부 메인 */
      .noticeinput {
        margin-top: 0.5em;
        height: 80%;
        textarea {
          height: 100%;
          resize: none;
          border: 1px solid gray;
          border-radius: 10px;
        }
      }
    }
  }

  @media (max-width: 1280px) {
    .card {
      height: 21vh;
      font-size: 0.8rem;
      .card-body {
        /* 공지사항 설정 내부 메인 */
        .noticeinput {
          margin-top: 0.25em;
          height: 80%;
          textarea {
            font-size: 0.8rem;
            padding: 0.5em;
          }
        }
      }
    }
  }
`;

export default NoticeSettingStyle;
