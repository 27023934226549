import useGNB from '@hooks/store/useGNB';
import useLogined from '@hooks/store/useLogined';
import useMultiRoom from '@hooks/store/useMultiRoom';
import useProfile from '@hooks/store/useProfile';
import { apiRoute, requestSecureGet, requestSecurePut } from '@libs/api';
import { getId, move, reOrder } from '@libs/factory';
import {
  AMPMTypes,
  dispFlag,
  PatientListItemTypes,
  PatientVersionItemTypes,
  PatientVersionResponseTypes,
} from '@typedef/components/Admin/Patient/admin.patient.types';
import { MyBoardListItemTypes } from '@typedef/components/Login/login.types';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { DropResult } from 'react-beautiful-dnd';
import Patient from '../Patient';
import _ from 'lodash';

// 대기자 : 0, 검사자 : 1, 부재자 : 8, 완료자 : 9
const GUBUN = {
  resv: 0,
  check: 1,
  absn: 8,
  done: 9,
};

const PatientContainer = () => {
  const { token } = useLogined();

  const { myBoard } = useProfile();

  const { visible, selected, myBoardReload } = useGNB();

  // 멀티 진료실
  const { multiRoomList, __setRoomFromHooks } = useMultiRoom();

  // 로딩 스피너
  const [loadingSpinner, setLoadingSpinner] = useState<boolean>(false);

  // 새로고침 버튼 클릭후 1.5초 동안 delay
  const [delayBtn, setDelayBtn] = useState<boolean>(false);

  // 현재 활성화 된 진료실 ( 화면에 보이는 진료실 리스트 )
  const [activeRoomList, setActiveRoomList] = useState<MyBoardListItemTypes[]>([]);

  // 마이전광판에 있는 진료실 리스트
  const [myRoomList, setMyRoomList] = useState<MyBoardListItemTypes[]>([]);

  // 검색 옵션 선택 값
  const [searchOption, setSearchOption] = useState<string>('hngnm');

  // 검색 입력 값
  const [searchInput, setSearchInput] = useState<string>('');

  const [searchList, setSearchList] = useState<MyBoardListItemTypes[]>([]);

  const [searchResultShow, setSearchResultShow] = useState<boolean>(false);

  // 각 진료실 별 오전 오후 선택 값
  const [ampmList, setAmPmList] = useState<AMPMTypes[]>([]);

  // 진료실별 환자 리스트
  const [patientList, setPatientList] = useState<PatientListItemTypes[][]>([]);

  // 진료실별 환자 버전
  const [patientVersion, setPatientVersion] = useState<PatientVersionItemTypes>({});

  // 대기자 명단
  const resvList = useMemo(
    () =>
      patientList.length === 0
        ? []
        : patientList[0]
            ?.filter((item) => item.dispflag === dispFlag.RESV)
            .filter((item) => {
              if (item.dispalltime === 'Y') return true;
              if (ampmList[0]?.type === 'am') return parseInt(item.ordtm) < 1230;
              if (ampmList[0]?.type === 'pm') return parseInt(item.ordtm) >= 1230;
            }),
    [patientList[0], ampmList[0]],
  );

  // 부재자 명단
  const absnList = useMemo(
    () =>
      patientList.length === 0
        ? []
        : patientList[0].filter((item) => item.dispflag === dispFlag.ABSN),
    [patientList[0]],
  );
  // 검사자 명단
  const checkList = useMemo(
    () =>
      patientList.length === 0
        ? []
        : patientList[0]?.filter((item) => item?.dispflag === dispFlag.CHECK),
    [patientList[0]],
  );

  // 완료자 명단
  const doneList = useMemo(
    () =>
      patientList.length === 0
        ? []
        : patientList[0]
            ?.filter((item) => item?.dispflag === dispFlag.DONE)
            .filter((item) => {
              if (ampmList[0]?.type === 'am') return parseInt(item.ordtm) < 1230;
              if (ampmList[0]?.type === 'pm') return parseInt(item.ordtm) >= 1230;
            }),
    [patientList[0], ampmList[0]],
  );

  // 진료실 환자 불러오기
  const loadPatientList = useCallback(
    async (roomId: number) => {
      if (!token) return [];

      const stringRoomId = roomId.toString();

      if (stringRoomId.includes('0.')) {
        return [];
      }

      const { data, config } = await requestSecureGet<PatientListItemTypes[]>(
        apiRoute.admin.patient.loadPatient + roomId,
        {},
        token,
      );
      if (config.status === 200) {
        return data;
      } else {
        return [];
      }
    },
    [token],
  );

  // 전체 진료실 환자 불러오기
  const loadAllPatientListOfRoomList = useCallback(
    async (roomList: MyBoardListItemTypes[]) => {
      setLoadingSpinner(true);

      setPatientList(
        await Promise.all(
          roomList.map((item) => (item?.roomId ? loadPatientList(item?.roomId) : [])),
        ),
      );

      setLoadingSpinner(false);
    },
    [activeRoomList, multiRoomList, selected],
  );

  // 모든 진료실 버전 셋팅 ( 멀티진료실, 최초사용 )
  // const loadAllVersiongSetting = useCallback(
  //   (roomList: MyBoardListItemTypes[], index: number = 0) => {
  //     // 선택된 진료/전광판 정보가 있다면
  //     if (roomList[index]?.roomId !== 0) {
  //       // 싱글/멀티 환자관리 room 정보들을 전부 version 업데이트 한다.
  //       roomList.map(async (room) => {
  //         if (room.roomId && room.roomId !== 0) {
  //           loadVersionSetting(room.roomId);
  //         }
  //       });
  //     }
  //   },
  //   [activeRoomList, multiRoomList, selected],
  // );

  // 진료실 버전 셋팅
  // const loadVersionSetting = useCallback(
  //   async (roomId: number) => {
  //     if (!token) return;

  //     const stringRoomId = roomId.toString();

  //     if (stringRoomId.includes('0.')) {
  //       return;
  //     }

  //     const { data, config } = await requestSecureGet<string>(
  //       apiRoute.admin.patient.loadVersion + roomId + '/version',
  //       {},
  //       token,
  //     );

  //     if (config.status === 200) {
  //       const time = data?.split('.')[0];
  //       setPatientVersion((prev) => ({
  //         ...prev,
  //         [`${roomId}`]: time,
  //       }));
  //     }
  //   },
  //   [token],
  // );

  // 진료실 버전 체크
  // const versionSettingCheck = useCallback(
  //   async (roomId: number): Promise<boolean> => {
  //     if (!token) return true;

  //     const { data, config } = await requestSecureGet<string>(
  //       apiRoute.admin.patient.loadVersion + roomId + '/version',
  //       {},
  //       token,
  //     );
  //     if (config.status === 200) {
  //       const time = data?.split('.')[0];
  //       if (patientVersion[roomId] === time) {
  //         return true;
  //       }
  //     }
  //     return false;
  //   },
  //   [patientVersion, token],
  // );

  // DND시 실행
  const onDragEnd = useCallback(
    async (result: DropResult) => {
      const { source, destination } = result;

      const patientPid = Number(result.draggableId.split('-')[1]);

      if (!destination) {
        return;
      }

      const [sourceId, sourceNum] = getId(source.droppableId);

      const [destId, destNum] = getId(destination.droppableId);

      const roomId = activeRoomList[sourceNum].roomId;

      if (sourceNum !== destNum) {
        return;
      }

      const { index: sourceIndex } = source;
      const { index: destIndex } = destination;

      if (sourceId === destId) {
        // 환자 정렬 이동 ( 같은 명단내에서 이동 )
        let list: AMPMTypes = ampmList[sourceNum];
        let object: PatientListItemTypes[] = patientList[sourceNum];

        let patient = reOrder(object, sourceIndex, destIndex, GUBUN[sourceId], list.type);

        setLoadingSpinner(true);

        patientList[sourceNum] = patient.sort((a, b) => a.dispseq - b.dispseq);

        let fuc = () => patientList;
        setPatientList(fuc);
      } else {
        // 환자 명단 이동 ( 다른 명단으로 이동 )
        let list: AMPMTypes = ampmList[sourceNum];
        let object: PatientListItemTypes[] = patientList[sourceNum];

        let patient = move(
          object,
          destIndex,
          GUBUN[sourceId],
          GUBUN[destId],
          list.type,
          patientPid,
        );

        setLoadingSpinner(true);

        patientList[sourceNum] = patient.sort((a, b) => a.dispseq - b.dispseq);
        let fuc = () => patientList;
        setPatientList(fuc);
      }

      if (!token) return;

      const { config, data } = await requestSecurePut<PatientVersionResponseTypes>(
        apiRoute.admin.patient.updatePatient + roomId,
        {},
        {
          order: patientList[sourceNum].map((patient) => {
            return {
              pid: Number(patient.pid),
              dispflag: Number(patient.dispflag),
              dispseq: Number(patient.dispseq),
            };
          }),
          start: sourceIndex,
          end: destIndex,
          pid: patientPid,
          source: GUBUN[sourceId],
          dest: GUBUN[destId],
        },
        token,
      );
      if (config.status === 200) {
        if (data) {
          loadAllPatientListOfRoomList(activeRoomList);
        }
        setLoadingSpinner(false);
      }
    },
    [activeRoomList, ampmList, patientList, patientVersion, loadPatientList],
  );

  // 진료실 선택
  const onRoomSelected = useCallback(
    async (roomId: number, roomIdx: number) => {
      setLoadingSpinner(true);

      myBoard?.myBoardList.map((data) => {
        if (data?.roomId === roomId) {
          setActiveRoomList((prev) => {
            const clone = [...prev];

            clone[roomIdx] = data;

            return clone;
          });
        } else if (roomId === 0) {
          setActiveRoomList((prev) => {
            const clone = [...prev];

            clone[roomIdx] = {
              boardId: 0,
              roomId: Math.random(),
              boardName: '',
              roomName: '',
              docname: '',
              roomOrBoard: false,
            };

            return clone;
          });
        }
      });

      const result = await loadPatientList(roomId);

      setPatientList((prev) => {
        const clone = [...prev];

        clone[roomIdx] = result;

        return clone;
      });

      // loadVersionSetting(roomId);

      setLoadingSpinner(false);
    },
    [myBoard],
  );

  // 오전 오후 선택
  const onAmPmSelected = useCallback((roomIdx: number, ampm: AMPMTypes) => {
    setAmPmList((prev) => {
      const clone = [...prev];

      clone[roomIdx] = ampm;

      return clone;
    });
  }, []);

  // 진료실 추가
  const onAddRoomClicked = useCallback(() => {
    const randomRoomId = Math.random();

    setActiveRoomList([
      ...activeRoomList,
      {
        boardId: 0,
        roomId: randomRoomId,
        boardName: '',
        roomName: '',
        docname: '',
        roomOrBoard: false,
      },
    ]);

    loadAllPatientListOfRoomList([
      ...activeRoomList,
      {
        boardId: 0,
        roomId: randomRoomId,
        boardName: '',
        roomName: '',
        docname: '',
        roomOrBoard: false,
      },
    ]);

    // loadAllVersiongSetting([
    //   ...activeRoomList,
    //   {
    //     boardId: 0,
    //     roomId: randomRoomId,
    //     boardName: '',
    //     roomName: '',
    //     docname: '',
    //     roomOrBoard: false,
    //   },
    // ]);

    if (Number(new Date().getHours()) < 13) {
      setAmPmList([
        ...ampmList,
        {
          label: '오전',
          type: 'am',
        },
      ]);
    } else {
      setAmPmList([
        ...ampmList,
        {
          label: '오후',
          type: 'pm',
        },
      ]);
    }
  }, [activeRoomList, ampmList]);

  // 진료실 삭제
  const onDeleteRoomClicked = useCallback(
    async (roomId: number, roomIdx: number) => {
      setActiveRoomList(activeRoomList.filter((item) => item?.roomId !== roomId));

      setAmPmList(ampmList.splice(roomIdx, roomIdx));

      loadAllPatientListOfRoomList(activeRoomList.filter((item) => item?.roomId !== roomId));

      // loadAllVersiongSetting(activeRoomList.filter((item) => item?.roomId !== roomId));
    },
    [activeRoomList, ampmList],
  );

  // 진료실 전체 새로고침
  const onAllRefreshClicked = useCallback(() => {
    loadAllPatientListOfRoomList(activeRoomList);
    // loadAllVersiongSetting(activeRoomList);
    setDelayBtn(true);

    setTimeout(() => {
      setDelayBtn(false);
    }, 1500);
  }, [activeRoomList]);

  // 진료실 새로고침
  const onRefreshClicked = useCallback(
    async (roomIdx: number) => {
      setLoadingSpinner(true);

      const roomId = activeRoomList[roomIdx]?.roomId;

      if (roomId) {
        const result = await loadPatientList(roomId);

        setPatientList((prev) => {
          const clone = [...prev];

          clone[roomIdx] = result;

          return clone;
        });

        // loadVersionSetting(roomId);
      }

      setLoadingSpinner(false);

      setDelayBtn(true);

      setTimeout(() => {
        setDelayBtn(false);
      }, 1500);
    },
    [activeRoomList],
  );

  // 환자 검색
  const onSearchPatient = useCallback(async () => {
    if (!token) return;

    const { data, config } = await requestSecureGet<{
      [key: string]: PatientListItemTypes[];
    }>(
      apiRoute.admin.patient.loadPatients + `?${searchOption.toLowerCase()}=${searchInput}`,
      {},
      token,
    );

    if (config.status === 200) {
      myRoomList.map((room) => {
        if (Object.keys(data).includes(String(room.roomId))) {
          setSearchList((prev) => {
            const clone = [...prev];

            clone.push(room);

            return _.uniq(clone, 'roomId');
          });
        }
      });
    }
  }, [myRoomList, searchOption, searchInput]);

  // 의사변경시 감지하여 환자 재호출
  const loadChangeDoctorPatients = useCallback(() => {
    if (!selected) return;

    const rId = activeRoomList.findIndex((ar) => {
      return ar?.roomId === selected?.roomId;
    });

    const ar = activeRoomList[rId];

    if (ar) {
      ar.docname = selected.docname;
      setActiveRoomList(activeRoomList);
      onRefreshClicked(rId);
    }
  }, [activeRoomList, selected]);

  // 검색 결과 항시 감시
  useEffect(() => {
    setSearchList([]);
    if (searchInput) {
      onSearchPatient();
    }
  }, [myRoomList, searchOption, searchInput]);

  // 각 진료실별 오전 오후 셋팅
  useEffect(() => {
    __setRoomFromHooks(activeRoomList);

    setAmPmList((prev) => {
      const clone = [...prev];

      while (clone.length < activeRoomList.length) {
        if (Number(new Date().getHours()) < 13) {
          clone.push({ label: '오전', type: 'am' });
        } else {
          clone.push({ label: '오후', type: 'pm' });
        }
      }
      return clone;
    });
  }, [activeRoomList, __setRoomFromHooks]);

  // 마이전광판에 전광판중 진료실만 필터링
  useEffect(() => {
    if (myBoard) {
      setMyRoomList(myBoard?.myBoardList.filter((data) => data.roomOrBoard === true));
    }
  }, [myBoard]);

  // 환자 전체 조회 ( 최초 또는 멀티진료실 값이 바뀔때 마다 )
  useEffect(() => {
    if (!selected) return;

    if (multiRoomList.length > 1) {
      setActiveRoomList(multiRoomList);
      loadAllPatientListOfRoomList(multiRoomList);
      // loadAllVersiongSetting(multiRoomList);
    } else {
      setActiveRoomList([selected]);
      loadAllPatientListOfRoomList([selected]);
      // loadAllVersiongSetting([selected]);
    }
  }, [selected, myBoardReload, myBoard]);

  // GNB에서 의사변경시 작동
  useEffect(() => {
    loadChangeDoctorPatients();
  }, [selected?.docname]);

  return (
    <Patient
      visible={visible}
      activeRoomList={activeRoomList}
      myRoomList={myRoomList}
      patientList={patientList}
      resvList={resvList}
      absnList={absnList}
      checkList={checkList}
      doneList={doneList}
      loadingSpinner={loadingSpinner}
      ampmList={ampmList}
      onAmPmSelected={onAmPmSelected}
      searchInput={searchInput}
      setSearchInput={setSearchInput}
      searchOption={searchOption}
      setSearchOption={setSearchOption}
      searchList={searchList}
      searchResultShow={searchResultShow}
      setSearchResultShow={setSearchResultShow}
      delayBtn={delayBtn}
      onDragEnd={onDragEnd}
      onAllRefreshClicked={onAllRefreshClicked}
      onRefreshClicked={onRefreshClicked}
      onAddRoomClicked={onAddRoomClicked}
      onDeleteRoomClicked={onDeleteRoomClicked}
      onRoomSelected={onRoomSelected}
      loadPatientList={loadPatientList}
    />
  );
};

export default PatientContainer;
