import * as S from './style/PharmacyWaitingStyle';
import images from '@assets/images';
import Clock from 'react-live-clock';
import ExitPharmacyWaitingContainer from './containers/ExitPharmacyWaitingContainer';
import OutPharmacyWaitingContainer from './containers/OutPharmacyWaitingContainer';
import { LoadVisibleConfigResponseDataType } from '@typedef/routes/viewer.navigation.types';
import SubTitleContainer from '@components/SubTitle/containers/SubTitleContainer';
import 'moment/locale/ko';

type Props = {
  visible: LoadVisibleConfigResponseDataType;
  componentFlag: 'EXIT' | 'OUT' | string;
};

const PharmacyWaiting = ({ visible, componentFlag }: Props) => {
  return (
    <S.PharmacyWaitingStyle>
      <S.Header>
        <section className='logoBox'>
          <img src={images.jsolViewerLogo} alt='logo' />
          <div className='triangle' />
        </section>
        <section className='titleBox'>
          <p className='subtitle'>
            &nbsp;지역과 함께한 백년, 세계로 나아갈 천년
            <br />
            환자중심의 병원으로 거듭나겠습니다.
          </p>
          <div className='clock'>
            {visible.vclock ? (
              <Clock className='timeclock' format={'YYYY.MM.DD(dd) HH:mm'} ticking={true} />
            ) : null}
          </div>
        </section>
      </S.Header>
      <S.Content>
        {componentFlag === 'EXIT' ? (
          <ExitPharmacyWaitingContainer />
        ) : (
          <OutPharmacyWaitingContainer />
        )}
        <SubTitleContainer height='90vh' />
      </S.Content>
    </S.PharmacyWaitingStyle>
  );
};

export default PharmacyWaiting;
