import styled from "styled-components";

export const AdminNavigationStyle = styled.div`
  display: flex;
  flex-direction: row;
  flex: 1;
  font-family: "NotoSans";
  overflow: hidden;

  .content-root {
    display: flex;
    flex-direction: column;
    flex: 1;
  }
`;

type Props = {
  visible: boolean;
};

export const AdminRoutes = styled.section<Props>`
  margin-left: ${({ visible }) => (visible ? "256px" : 0)};
  transition: all 0.3s ease-in-out;
  @media (max-width: 1280px) {
    margin-left: ${({ visible }) => (visible ? "16.45vw" : 0)};
  }
`;
